define("fitbit-site-ui/templates/settings/application-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aQL+BvfQ",
    "block": "{\"symbols\":[\"application\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"app-loader \",[27,\"unless\",[[23,[\"isLoading\"]],\"hide\"],null]]]],[9],[1,[27,\"format\",[\"loading\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row column\"],[11,\"id\",\"application-settings\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"format-by-namespace\",[\"applications_title\",\"templates.settings.application-settings\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"app-settings-desc\"],[9],[1,[27,\"format-by-namespace\",[\"app_settings_desc_new\",\"templates.settings.application-settings\"],null],false],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"manuallyFilteredThirdPartyApps\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"application-item\",null,[[\"application\",\"onConfirmRevokeAccess\",\"class\",\"data-test-qa\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"revokeAccess\"],null],\"row\",\"application-item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/application-settings.hbs"
    }
  });

  _exports.default = _default;
});