define("fitbit-site-ui/serializers/legacy-food-date-log", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/constants/food-logging"], function (_exports, _application, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var foods = Ember.get(payload, 'foods') || [];
      var foodLogs = foods.filter(function (food) {
        var _food$loggedFood = food.loggedFood,
            brand = _food$loggedFood.brand,
            foodId = _food$loggedFood.foodId; // Check foodId === 0 in addition to brand === 'MyFitnessPal' because it seems that third party foods
        // have a 0 foodId.

        return brand === 'MyFitnessPal' || foodId === 0;
      }).map(function (food) {
        var logId = Ember.get(food, 'logId');
        var nutritionalValues = Ember.get(food, 'nutritionalValues');
        var loggedFood = Ember.get(food, 'loggedFood') || {};

        var _EmberGetProperties = Ember.getProperties(loggedFood, 'accessLevel', 'amount', 'brand', 'calories', 'name', 'mealTypeId'),
            accessLevel = _EmberGetProperties.accessLevel,
            amount = _EmberGetProperties.amount,
            brand = _EmberGetProperties.brand,
            calories = _EmberGetProperties.calories,
            name = _EmberGetProperties.name,
            mealTypeId = _EmberGetProperties.mealTypeId;

        var mealType = _foodLogging.MEAL_TYPE_ID_TO_MEAL_TYPE[mealTypeId];
        var loggedCalories = {
          calories: Ember.get(nutritionalValues, 'calories'),
          mealType: mealType
        };
        return {
          id: logId,
          logId: logId,
          loggedCalories: loggedCalories,
          nutritionalValues: {
            calories: nutritionalValues.calories,
            dietaryFiber: nutritionalValues.fiber,
            protein: nutritionalValues.protein,
            sodium: nutritionalValues.sodium,
            totalCarbohydrate: nutritionalValues.carbs,
            totalFat: nutritionalValues.fat
          },
          loggedFood: {
            accessLevel: accessLevel,
            amount: amount,
            brand: brand,
            calories: calories,
            name: name,
            mealType: mealType
          }
        };
      });
      var newPayload = {
        'food-logs': foodLogs,
        'legacy-food-date-log': {
          id: id
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});