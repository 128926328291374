define("fitbit-site-ui/components/user-profile-personal-info", ["exports", "snapdragon-common/utils/unit-converter", "fitbit-site-ui/constants/profile-roles-permissions", "numbro"], function (_exports, _unitConverter, _profileRolesPermissions, _numbro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.privacyIds = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var cmToInches = _unitConverter.default.cmToInches,
      remainingInches = _unitConverter.default.remainingInches,
      inchesToFeet = _unitConverter.default.inchesToFeet,
      cmToKm = _unitConverter.default.cmToKm,
      milesToCm = _unitConverter.default.milesToCm;
  var privacyIds = ['LOCATION', 'AVERAGE-STEP-COUNT', 'BIRTHDAY', 'HEIGHT', 'WEIGHT', 'LIFETIME-STATS'];
  _exports.privacyIds = privacyIds;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    moment: Ember.inject.service(),
    distanceUnit: Ember.computed.readOnly('currentUser.distanceUnit'),
    privacySettings: Ember.computed('user.privacySettings', function () {
      var userPrivacySettings = Ember.get(this, 'user.privacySettings');
      return privacyIds.reduce(function (acc, curVal) {
        var privacySetting = userPrivacySettings.findBy('id', curVal);

        if (privacySetting) {
          var key = curVal.toLowerCase();
          acc[Ember.String.camelize(key)] = privacySetting.value.toLowerCase();
        }

        return acc;
      }, {});
    }),
    birthday: Ember.computed('user.profile.dateOfBirth', function () {
      var moment = Ember.get(this, 'moment');
      var dob = Ember.get(this, 'user.profile.dateOfBirth');

      if (!dob) {
        return false;
      }

      return moment.moment(dob).format('MMMM Do YYYY');
    }),
    joined: Ember.computed('user.profile.memberSince', function () {
      var moment = Ember.get(this, 'moment');
      var memberSince = Ember.get(this, 'user.profile.memberSince');
      return moment.moment(memberSince).format('MMMM YYYY');
    }),
    hasResidenceInfo: Ember.computed('user.profile.{city,state}', function () {
      var userProfileCity = Ember.get(this, 'user.profile.city') || false;
      var userProfileState = Ember.get(this, 'user.profile.state') || false;
      /**
       * It's possible that a city or state is never selected.
       * E.g. France does not have a state, but does have cities.
       */

      return userProfileCity || userProfileState;
    }),

    /*
     * Used to hide elements when a profile owner is viewing their own
     * profile in different roles e.g. friends or public
     *
     * @returns {boolean}
     * */
    profileViewRoleOwner: Ember.computed('profileViewRole', function () {
      return Ember.get(this, 'profileViewRole') === _profileRolesPermissions.VIEW_ROLE_OWNER;
    }),

    /*
     * If user has length preference set to en_US this function will
     * convert from the default centimeters to feet/inches
     *
     * @returns {object}
     * */
    convertedHeight: Ember.computed('user.profile.height', function () {
      var heightInCm = Ember.get(this, 'user.profile.height');
      var totalInches = cmToInches(heightInCm);
      var heightInFeetInches = {};
      heightInFeetInches.feet = inchesToFeet(totalInches);
      heightInFeetInches.inches = remainingInches(totalInches);
      return heightInFeetInches;
    }),

    /**
     * Evaluates whether the user profile distance unit is "miles", aka "en_US".
     *
     * @returns {Boolean}
     */
    distanceUnitIsMiles: Ember.computed.equal('distanceUnit', 'en_US'),

    /**
     * Check that some of the arguuments passed in are present.
     * @return {boolean}
     * @private
     */
    _hasSomeStats: function _hasSomeStats() {
      return Array.prototype.slice.call(arguments).some(Ember.isPresent);
    },

    /**
     * An object containing formatted value of lifetime achievement stats.
     *
     * `user.achievementSummary.lifetime` contains `total` and `tracker` fields.
     *
     * The `tracker` field is used because it contains all the data points that Weightsite is currently displaying,
     * which includes steps, floors, and distance. `total` on the other hand only contains floors.
     *
     * Steps, floors, and distance are all number values that need to be formatted.
     *
     * By default, the API returns distance as miles so it must be converted if user distance unit is METRIC.
     *
     * @returns {Object|null}
     */
    lifetimeAchievementStats: Ember.computed('user.achievementSummary.lifetime.tracker', 'distanceUnit', function () {
      var stats = Ember.get(this, 'user.achievementSummary.lifetime.tracker');
      var distanceUnit = Ember.get(this, 'distanceUnit');
      var lifetimeAchievementStats = null;

      if (stats) {
        var steps = stats.steps,
            floors = stats.floors,
            distance = stats.distance; // Check that some of the stats exist before setting lifetimeAchievementStats

        var hasSomeStats = this._hasSomeStats(steps, floors, distance);

        if (hasSomeStats) {
          if (distance && distanceUnit === 'METRIC') {
            distance = cmToKm(milesToCm(distance || 0), 2);
          } // Check that the stat is truthy before setting it because it can be undefined.


          lifetimeAchievementStats = {
            steps: steps && (0, _numbro.default)(steps || 0).format(),
            floors: floors && (0, _numbro.default)(floors || 0).format(),
            // Display 2 decmial places as Weightsite did.
            distance: distance && (0, _numbro.default)(distance || 0).format('0,0.00')
          };
        }
      }

      return lifetimeAchievementStats;
    }),

    /**
     * An object containing formatted value of best achievement stats.
     *
     * `user.achievementSummary.best` contains `total` and `tracker` fields.
     *
     * The `tracker` field is used because it contains all the data points that Weightsite is currently displaying,
     * which includes steps, floors, and distance. `total` fields also contains distance, floors, and steps, but that's
     * not what is being shown at the time of the page migration from Weightsite.
     *
     * Steps, floors, and distance are objects with `date` and `value` keys. `date` is a string with YYYY-MM-DD, and
     * value is a number that needs to be formatted.
     *
     * By default, the API returns distance as miles so it must be converted if user distance unit is METRIC.
     *
     * @returns {Object|null}
     */
    bestAchievementStats: Ember.computed('user.achievementSummary.best.tracker', 'distanceUnit', function () {
      var stats = Ember.get(this, 'user.achievementSummary.best.tracker');
      var distanceUnit = Ember.get(this, 'distanceUnit');
      var bestAchievementStats = null;

      if (stats) {
        var steps = stats.steps,
            floors = stats.floors,
            distance = stats.distance; // Check that some of the stats exist before setting bestAchievementStats

        var hasSomeStats = this._hasSomeStats(steps, floors, distance);

        if (distance && distanceUnit === 'METRIC') {
          distance = _objectSpread({}, distance, {
            value: cmToKm(milesToCm(distance.value || 0), 2)
          });
        }

        if (hasSomeStats) {
          // Check that the stat is truthy before setting it because it can be undefined.
          bestAchievementStats = {
            steps: steps && _objectSpread({}, steps, {
              value: (0, _numbro.default)(steps.value || 0).format()
            }),
            floors: floors && _objectSpread({}, floors, {
              value: (0, _numbro.default)(floors.value || 0).format()
            }),
            distance: distance && _objectSpread({}, distance, {
              // Display 2 decmial places as Weightsite did.
              value: (0, _numbro.default)(distance.value || 0).format('0,0.00')
            })
          };
        }
      }

      return bestAchievementStats;
    })
  });

  _exports.default = _default;
});