define("fitbit-site-ui/routes/settings/scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      var userScale = model.scale;
      return "".concat(userScale.get('deviceType.name'), " - ").concat(userScale.get('name'));
    },
    breadCrumb: Ember.computed(function () {
      return {};
    }),
    model: function model(params) {
      var deviceId = params.id;
      var currentUser = Ember.get(this, 'currentUser');
      var userID = Ember.get(currentUser, 'id');
      return Ember.RSVP.hash({
        scale: this.store.findRecord('device/scale/user-scale', deviceId),
        privacy: this.store.findAll('privacy-settings-ui-group'),
        multipleScalesPaired: this.store.findRecord('user-device-list', userID).then(function (devices) {
          return devices.list.filter(function (device) {
            return device.isScale;
          }).length > 1;
        })
      });
    },
    afterModel: function afterModel(model) {
      // Set the breadcrumb here using the titleToken because they're basically the same
      this.set('breadCrumb', {
        title: this.titleToken(model)
      });
    },
    actions: {
      error: function error(_error) {
        var errors = _error.errors; // Handle 400 status by transitioning to 404 page.

        if (errors && errors.length) {
          var _errors$ = errors[0],
              errorType = _errors$.errorType,
              fieldName = _errors$.fieldName;

          if (errorType === 'validation' && fieldName === 'serialNumber') {
            // Incorrect device ID.
            this.transitionTo('/404');
          }
        }

        Ember.Logger.error(_error);
      }
    }
  });

  _exports.default = _default;
});