define("fitbit-site-ui/adapters/health-programs/program-access", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    namespace: '1/user/:userid/hp/admin',
    pathForType: function pathForType() {
      return 'acl';
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var userId = query && Ember.get(query, 'userId') || snapshot && Ember.get(snapshot, 'adapterOptions.userId') || Ember.get(this, 'currentUser.id') || '-';

      if (query) {
        delete query.userId;
      }

      var url = this._super.apply(this, arguments) + '.json';
      return url.replace(':userid', userId);
    }
  });

  _exports.default = _default;
});