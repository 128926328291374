define("fitbit-site-ui/templates/settings/guides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e6DHjzOu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"101-content\"],[9],[0,\"\\n    \"],[1,[21,\"model\"],true],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/guides.hbs"
    }
  });

  _exports.default = _default;
});