define("fitbit-site-ui/serializers/sleep-date", ["exports", "fitbit-site-ui/serializers/application", "fitbit-site-ui/mixins/sleep-date-mixin"], function (_exports, _application, _sleepDateMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_sleepDateMixin.default, {
    moment: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var moment = this.get('moment');
      var sleep = payload.sleep;
      var startDate = null,
          endDate = null;

      if (requestType === 'query') {
        startDate = moment.moment(payload.startDate);
        endDate = moment.moment(payload.endDate);
      } else if (requestType === 'queryRecord') {
        startDate = endDate = moment.moment(payload.date);
      }

      var newPayload = {
        'sleep-log': sleep,
        'sleep-date': this.sleepByDate(sleep, startDate, endDate)
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var sleepDate = Ember.get(payload, 'sleep-date.firstObject') || {};
      Ember.set(payload, 'sleep-date', sleepDate);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});