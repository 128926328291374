define("fitbit-site-ui/serializers/data-deletion-type-group-delete-info", ["exports", "gdd-console-engine/serializers/data-deletion-type-group-delete-info"], function (_exports, _dataDeletionTypeGroupDeleteInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dataDeletionTypeGroupDeleteInfo.default;
    }
  });
});