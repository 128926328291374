define("fitbit-site-ui/routes/profile/index", ["exports", "fitbit-site-ui/routes/authenticated", "fitbit-site-ui/services/user-actions"], function (_exports, _authenticated, _userActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    userActions: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    titleToken: function titleToken() {
      var currentUserName = Ember.get(this, 'currentModel.profile.displayName');
      return Ember.get(this, 'i18n').formatByNamespace('route_title', 'route.profile', {
        userName: currentUserName
      });
    },

    /**
     * Sends a cheer or taunt to id based on actionType
     * @param {String} actionType - 'cheer' or 'taunt'
     * @param {Object} profile - user id to send cheer or taunt to
     * @returns {*}
     * @private
     */
    _sendActionToId: function _sendActionToId(actionType, profile, userAction) {
      var _this = this;

      var id = profile.id,
          name = profile.displayName;
      var controller = Ember.get(this, 'controller');
      var isLoading = Ember.get(controller, 'isLoading');

      if (!isLoading) {
        Ember.set(controller, 'isLoading', true);
        return userAction(id, Ember.get(this, 'userActions')).then(function () {
          var formatter = Ember.get(_this, 'i18n');
          var flashMessages = Ember.get(_this, 'flashMessages');
          return flashMessages.success(formatter.formatByNamespace(actionType, 'route.friends', {
            name: name
          }));
        }).catch(function (e) {
          var flashMessages = Ember.get(_this, 'flashMessages');
          return flashMessages.alert(e);
        }).finally(function () {
          Ember.set(controller, 'isLoading', false);
        });
      }
    },

    /**
     * Sends message to user passed through fitbit
     * @param {Object} profile  {id, displayName}
     * @param {String} message  Message to send
     * @returns {Promise}
     * @private
     */
    _sendMessageToId: function _sendMessageToId(profile, message) {
      var _this2 = this;

      var id = profile.id,
          name = profile.displayName;
      var controller = Ember.get(this, 'controller');
      var isLoading = Ember.get(controller, 'isLoading');

      if (!isLoading) {
        Ember.set(controller, 'isLoading', true);
        return (0, _userActions.sendMessage)(id, message, Ember.get(this, 'userActions')).then(function () {
          var formatter = Ember.get(_this2, 'i18n');
          var flashMessages = Ember.get(_this2, 'flashMessages');
          return flashMessages.success(formatter.formatByNamespace('message', 'route.friends', {
            name: name
          }));
        }).catch(function (e) {
          var flashMessages = Ember.get(_this2, 'flashMessages');
          return flashMessages.alert(e);
        }).finally(function () {
          Ember.set(controller, 'isLoading', false);
        });
      }
    },
    actions: {
      /**
       * Send a cheer to a user.
       * @param {Object} profile
       * @returns {Promise}
       */
      sendCheer: function sendCheer(profile) {
        return this._sendActionToId(_userActions.CHEER, profile, _userActions.sendCheer);
      },

      /**
       * Send a taunt to a user.
       * @param {Object} profile
       * @returns {Promise}
       */
      sendTaunt: function sendTaunt(profile) {
        return this._sendActionToId(_userActions.TAUNT, profile, _userActions.sendTaunt);
      },

      /**
       * Send a message to a user.
       * @param {Object} profile
       * @param {String} message
       * @returns {Promise}
       */
      sendMessage: function sendMessage(profile, message) {
        return this._sendMessageToId(profile, message);
      }
    }
  });

  _exports.default = _default;
});