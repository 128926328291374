define("fitbit-site-ui/templates/components/device-menu-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zz40D/qm",
    "block": "{\"symbols\":[\"group\",\"menuItem\"],\"statements\":[[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"menu_setting_description\"],[[\"deviceName\"],[[23,[\"device\",\"name\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"sortable-group\",null,[[\"class\",\"onChange\"],[\"sortable-group\",\"reorderItems\"]],{\"statements\":[[4,\"each\",[[23,[\"menuItems\",\"menuItems\"]]],[[\"key\"],[\"type\"]],{\"statements\":[[4,\"sortable-item\",null,[[\"model\",\"group\"],[[22,2,[]],[22,1,[]]]],{\"statements\":[[0,\"            \"],[1,[27,\"device-sortable-item\",null,[[\"display\",\"toggleAction\",\"showDragHandle\",\"disableTranslations\",\"data-test-qa\"],[[22,2,[]],[27,\"action\",[[22,0,[]],\"toggleMenu\",[22,2,[]]],null],true,true,[27,\"concat\",[\"menu-items-\",[22,2,[\"setting\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-menu-setting.hbs"
    }
  });

  _exports.default = _default;
});