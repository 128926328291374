define("fitbit-site-ui/adapters/health-programs/membership", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user/:userid/hp',
    currentUser: Ember.inject.service(),
    methodPostForTypes: Ember.computed(function () {
      return [];
    }),
    pathForType: function pathForType() {
      return 'memberships';
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var userId = query && Ember.get(query, 'userId') || snapshot && Ember.get(snapshot, 'adapterOptions.userId') || Ember.get(this, 'currentUser.id') || '-';

      if (query) {
        delete query.userId;
      }

      var url = "".concat(this._super.apply(this, arguments), ".json");
      return url.replace(':userid', userId);
    },
    // Query is used to fetch all of a user's programs while findRecord is used to fetch a specific program's details
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var snapshotId = snapshot && Ember.get(snapshot, 'adapterOptions.userId');
      var currentUserId = Ember.get(this, 'currentUser.id');
      var url = "".concat(this._super.apply(this, arguments));
      return url.replace(':userid', snapshotId || currentUserId);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      var host = this.host,
          namespace = this.namespace;
      var urlNamespace = namespace.replace(':userid', '-');
      return "".concat(host, "/").concat(urlNamespace, "/admin/memberships/").concat(id);
    },
    //  Find All should be used to find all of a user's memberships (summary)
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var snapshotId = snapshot && Ember.get(snapshot, 'adapterOptions.userId');
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "".concat(this._super.apply(this, arguments)).replace(':userid', snapshotId || currentUserId);
    },
    // Find should be used to get the details of a specific membership (passing in membership id)
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var snapshotId = snapshot && Ember.get(snapshot, 'adapterOptions.userId');
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "".concat(this._super.apply(this, arguments)).replace(':userid', snapshotId || currentUserId);
    }
  });

  _exports.default = _default;
});