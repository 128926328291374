define("fitbit-site-ui/models/activity-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    list: _emberData.default.hasMany('activity'),
    nextRecordUrl: _emberData.default.attr('string'),
    hasMoreActivities: _emberData.default.attr('boolean'),
    fetchMoreActivities: function fetchMoreActivities() {
      var _this = this;

      var url = this.get('nextRecordUrl'); // Fetch more activities using 'next' pagination url from fitbit api

      return this._nonRestActionWithUrl(url, 'GET').then(function (results) {
        var modelName = _this.constructor.modelName;

        var store = _this.get('store');

        _this.set('nextRecordUrl', results.pagination.next);

        var listIds = results.activities.map(function (activity) {
          return activity.logId;
        });
        store.pushPayload(modelName, {
          activities: results.activities
        });
        var activities = listIds.map(function (id) {
          return store.peekRecord('activity', id);
        }); // Update the list here by pushing the listIDs.

        _this.get('list').pushObjects(activities); // If less than 10 returned, set hasMoreActivities to false


        var moreActivitiesExist = listIds.length >= 10;

        _this.set('hasMoreActivities', moreActivitiesExist);
      });
    },
    _nonRestActionWithUrl: function _nonRestActionWithUrl(url, method, data) {
      var modelName = this.constructor.modelName;
      var adapter = Ember.getOwner(this).lookup("adapter:".concat(modelName));
      return adapter.ajax(url, method, {
        data: data
      });
    }
  });

  _exports.default = _default;
});