define("fitbit-site-ui/models/notification-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    label: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    emailState: _emberData.default.attr('string'),
    pushState: _emberData.default.attr('string'),
    emailCheckState: _emberData.default.attr('boolean'),
    pushCheckState: _emberData.default.attr('boolean'),
    emailDisableState: _emberData.default.attr('boolean'),
    pushDisableState: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});