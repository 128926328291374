define("fitbit-site-ui/metrics-adapters/cbs", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function generateCbsScriptUrl(env) {
    var DOMAINS = {
      int: 'fitbit-int',
      stage: 'fitbit-stage',
      prod: 'fitbit'
    };
    var CHANNELS = {
      int: 'int',
      stage: 'stage',
      prod: 'prod'
    }; // fallback to prod by default

    var domain = DOMAINS[env] || DOMAINS.prod;
    var channelName = CHANNELS[env] || CHANNELS.prod;
    var scriptUrl = "https://corporate-webapps.".concat(domain, ".com/cookie-blocking-script/channels/").concat(channelName, "/bundle.js");
    return scriptUrl;
  }

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'cbs'; // Cookie Auto Blocking Script
    },
    // required
    init: function init() {
      var _EmberGet = Ember.get(this, 'config'),
          environment = _EmberGet.environment;

      var scriptSrc = generateCbsScriptUrl(environment);
      var scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', scriptSrc);
      scriptTag.defer = true;
      document.head.appendChild(scriptTag);
    },
    // required
    willDestroy: function willDestroy() {}
  });

  _exports.default = _default;
});