define("fitbit-site-ui/adapters/weight-log", ["exports", "fitbit-site-ui/adapters/body-log"], function (_exports, _bodyLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bodyLog.default.extend({
    body_type: 'weight'
  });

  _exports.default = _default;
});