define("fitbit-site-ui/templates/components/device-clock-face-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jnWrSXAt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[28,[\"clock-face-option \",[27,\"if\",[[23,[\"active\"]],\"active\"],null]]]],[12,\"data-test-qa\",[28,[\"clock-face-option-\",[21,\"label\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"clock-face-visual\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"clock-face-img-gutter\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"class\",\"clock-face-img\"],[12,\"src\",[28,[[21,\"imageUrl\"]]]],[11,\"alt\",\"\"],[11,\"role\",\"presentation\"],[12,\"data-test-qa\",[28,[\"clock-image-\",[21,\"label\"]]]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"active\"]]],null,{\"statements\":[[0,\"            \"],[7,\"img\"],[11,\"class\",\"check-mark\"],[11,\"src\",\"/assets/images/devices/clock-faces/circle_checked.png\"],[11,\"alt\",\"\"],[11,\"role\",\"presentation\"],[12,\"data-test-qa\",[28,[\"clock-selected-\",[21,\"label\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"span\"],[11,\"class\",\"show-for-sr\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"active\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"clock_face_chosen_is\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"clock_face_is\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\" \"],[1,[21,\"label\"],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[23,[\"onClick\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-clock-face-option.hbs"
    }
  });

  _exports.default = _default;
});