define("fitbit-site-ui/constants/data-export-status", ["exports", "ude-console-engine/constants/data-export-status"], function (_exports, _dataExportStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_dataExportStatus).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _dataExportStatus[key];
      }
    });
  });
});