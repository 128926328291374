define("fitbit-site-ui/models/sleep-date", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr(),
    sleepLogs: _emberData.default.hasMany('sleep-log', {
      inverse: 'sleepDateRecord'
    }),
    minutesAsleep: _emberData.default.attr(),

    /**
     * Return whether sleep goal was reached for this sleep date
     */
    goalReached: Ember.computed('minutesAsleep', 'currentUser.sleepGoal.minDuration', function () {
      var sleepGoal = Ember.get(this, 'currentUser.sleepGoal.minDuration');
      var goalReached = false;

      if (sleepGoal) {
        goalReached = Ember.get(this, 'minutesAsleep') >= sleepGoal;
      }

      return goalReached;
    })
  });

  _exports.default = _default;
});