define("fitbit-site-ui/templates/components/remove-participation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z8u/yzr8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-qa\",\"remove-participation-button\"],[11,\"class\",\"remove-participation-button\"],[9],[1,[27,\"format-by-namespace\",[\"confirm_remove_participation_button\",\"templates.components.corporate-wellness\"],null],false],[3,\"action\",[[22,0,[]],[23,[\"onParticipationRemove\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/remove-participation-button.hbs"
    }
  });

  _exports.default = _default;
});