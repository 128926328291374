define("fitbit-site-ui/mixins/weight-unit", ["exports", "snapdragon-common/utils/unit-converter"], function (_exports, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_unitConverter.default);

  _exports.default = _default;
});