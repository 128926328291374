define("fitbit-site-ui/routes/settings/trackers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return model && Ember.getWithDefault(model, 'deviceType.name', '') || '';
    },
    breadCrumb: Ember.computed(function () {
      return {};
    }),
    model: function model(params) {
      var deviceId = params.id;
      var key = 'id'; // Weightsite is using wireId in the navigation so using that to look up the device.

      if (IS_WEIGHTSITE) {
        key = 'wireId';
      }

      return this.store.peekAll('user-device').findBy(key, deviceId);
    },
    afterModel: function afterModel(model, transition) {
      // The model doesn't exist, go to 404
      if (!model) {
        transition.abort();
        this.transitionTo('/404');
      } // Set the breadcrumb here using the titleToken because they're basically the same


      Ember.set(this, 'breadCrumb', {
        title: this.titleToken(model)
      });
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.reset();
    },
    actions: {
      error: function error(_error) {
        // Handle 400 status by transitioning to 404 page.
        if (_error.toString() === "TypeError: Cannot read property 'get' of null") {
          // Incorrect device ID.
          this.transitionTo('/404');
        }

        Ember.Logger.error(_error);
      },
      // Data Down, Actions Up
      saveSettings: function saveSettings(settings) {
        return settings.then(function (obj) {
          return obj.save();
        });
      },
      // This is passing the newAlarm from the controller.
      createAlarm: function createAlarm(alarmData) {
        var device = Ember.get(this, 'controller.model');
        var list = Ember.get(device, 'settings.alarms');
        Ember.set(alarmData, 'device', device);
        return this.store.createRecord('device/tracker/alarm', alarmData).save().then(function (alarm) {
          // Adding the list relationship after saving otherwise the alarm could be loaded without an id.
          Ember.set(alarm, 'list', list);
        });
      },
      editAlarm: function editAlarm(alarm, alarmData) {
        Ember.setProperties(alarm, alarmData);
        return alarm.save();
      },
      deleteAlarm: function deleteAlarm(alarm) {
        return alarm.destroyRecord();
      },
      removeTracker: function removeTracker(tracker) {
        var _this = this;

        return tracker.destroyRecord().then(function () {
          return _this.replaceWith('settings.devices');
        });
      },

      /**
       * Makes call to deactivation endpoint then displays
       * feedback in the flash message module
       * @param model
       */
      deactivatePaymentMethods: function deactivatePaymentMethods(model) {
        var controller = Ember.get(this, 'controller');
        model.deactivatePayment().then(function () {
          controller.deactivationSuccess();
        }).catch(function (error) {
          var statusCode = error.jqXHR.status;

          switch (statusCode) {
            case 400:
              controller.noPaymentMethodFound();
              break;

            default:
              controller.deactivationError();
              break;
          }

          throw error;
        });
      },

      /**
       * Makes call to receipts request endpoint then displays
       * feedback in the flash message module
       * @param model
       */
      receiptsRequestMethods: function receiptsRequestMethods(model) {
        var controller = Ember.get(this, 'controller');
        model.receiptsRequest().then(function () {
          controller.receiptsRequestSuccess();
        }).catch(function (error) {
          var statusCode = error.jqXHR.status;

          switch (statusCode) {
            case 400:
              controller.noReceiptsRequestMethodFound();
              break;

            default:
              controller.receiptsRequestError();
              break;
          }

          throw error;
        });
      }
    }
  });

  _exports.default = _default;
});