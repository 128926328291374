define("fitbit-site-ui/serializers/locale", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'value',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var supportedLocalesPayload = [];

      for (var locale in payload.supportedLocales) {
        supportedLocalesPayload.push({
          value: locale,
          countryName: payload.supportedLocales[locale]
        });
      }

      var newPayload = {
        locales: supportedLocalesPayload
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});