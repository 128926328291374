define("fitbit-site-ui/adapters/device/scale/invited-user-list", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'devices/scale';
    },
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '/users/invitations.json';
    }
  });

  _exports.default = _default;
});