define("fitbit-site-ui/models/locale", ["exports", "ember-data", "ember-get-config"], function (_exports, _emberData, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    countryName: _emberData.default.attr(),
    value: _emberData.default.attr(),

    /**
     * The display name is composed of both the country and language.
     *
     * The country and language are translated into the language of the language name. E.g. Nederlands is the translation for Dutch in Dutch.
     *
     * @returns {String}
     */
    displayName: Ember.computed('countryName', 'value', function () {
      var config = (_emberGetConfig.default || {})['ember-fitbit-i18n'] || {};
      var supportedLocales = config.supportedLocales;
      var value = Ember.get(this, 'value'),
          language;

      switch (true) {
        case supportedLocales.indexOf(value) > -1:
          language = value;
          break;

        case /^es_\w{2}$/.test(value):
          language = 'es_ES';
          break;

        case /^de_\w{2}$/.test(value):
          language = 'de_DE';
          break;

        case /^fr_\w{2}$/.test(value):
          language = 'fr_FR';
          break;

        case /^it_\w{2}$/.test(value):
          language = 'it_IT';
          break;

        default:
          language = 'en_US';
      }

      var countryName = Ember.String.capitalize(Ember.get(this, 'countryName'));
      var matchedLanguage = countryName.match(/\((.+)\)/i);
      var displayName = countryName;
      /*
      If the country name contains the language, capitalize the language.
       Otherwise lookup the translated language name from the LANGUAGE_NAMES constant.
       */

      if (matchedLanguage) {
        language = matchedLanguage[1]; // The capitalize function will not capitalize strings within parentheses, so capitalize the language separately.

        displayName = countryName.replace(language, Ember.String.capitalize(language));
      } else {
        var LANGUAGE_NAMES = Ember.get(this, 'constants.LANGUAGE_NAMES');
        var languageName = LANGUAGE_NAMES[language]; // Only append the language if it exists. Otherwise we could see "undefined" show up as part string.

        if (languageName) {
          displayName = "".concat(countryName, " (").concat(Ember.String.capitalize(languageName), ")");
        }
      }

      return displayName;
    })
  });

  _exports.default = _default;
});