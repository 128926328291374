define("fitbit-site-ui/adapters/time-series/water", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      return "".concat(this._super(), "/foods/log/water/date/").concat(query.startDate, "/").concat(query.endDate, ".json");
    }
  });

  _exports.default = _default;
});