define("fitbit-site-ui/components/linear-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Parameters:
   *   changeFnc(settingName, value): required callback
   *   min: optional minimum value (default 1)
   *   max: optional maximum value (default 5)
   *   step: optional step value (default 1)
   *   allowNoSelection: optional, allows clearing the selection (default false)
   *   formatter(v): optional function to style each value v
   * Use-case examples:
   *   Minimal example: {{linear-input setting='name' changeFnc=(action 'cb')}}
   *   Custom range: {{linear-input min=5 max=10 setting='fiveToTen' changeFnc=(action 'cb')}}
   *   Allow clearing selection: {{linear-input allowNoSelection=true setting='clearable' changeFnc=(action 'cb')}}
   *   Custom formatting: {{linear-input setting='custom' formatter=formatter changeFnc=(action 'cb')}}
   *       Where: foo(v) { return Ember.String.htmlSafe(`<span class='custom'>${v}.0</span>`); }
   */
  var _default = Ember.Component.extend({
    classNames: ['linear-input'],
    selectionIndex: undefined,
    _isValidNumber: function _isValidNumber(number) {
      if (!number && number !== 0 || isNaN(number)) {
        return false;
      }

      return true;
    },

    /**
     * The lower bound to start the linear input (inclusive)
     * @type {Number} [minValue=1]
     */
    minValue: Ember.computed('min', function () {
      var min = Ember.get(this, 'min');

      if (!this._isValidNumber(min)) {
        // Default minimum = 1
        return 1;
      }

      return min;
    }),

    /**
     * The upper bound to start the linear input (inclusive)
     * @type {Number} [maxValue=5]
     */
    maxValue: Ember.computed('max', function () {
      var max = Ember.get(this, 'max');

      if (!this._isValidNumber(max)) {
        // Default maximum = 1
        return 5;
      }

      return max;
    }),

    /**
     * The step between each item of the linear input
     * @type {Number} [stepValue=1]
     */
    stepValue: Ember.computed('step', function () {
      var step = Ember.get(this, 'step');

      if (!this._isValidNumber(step)) {
        // Default step = 1
        return 1;
      }

      return step;
    }),

    /**
     * The computed array of steps from minValue to maxValue
     *
     * @return {Array}
     */
    steps: Ember.computed('{minValue,maxValue,stepValue}', function () {
      var steps = [];
      var start = Ember.get(this, 'minValue');
      var end = Ember.get(this, 'maxValue');
      var step = Ember.get(this, 'stepValue');

      while (start <= end) {
        steps.push(start);
        start += step;
      }

      return steps;
    }),

    /**
     * The computed array of steps with display formatting applied
     *
     * @return {Array}
     */
    formattedSteps: Ember.computed('{formatter,steps}', function () {
      var formatter = Ember.get(this, 'formatter');
      var steps = Ember.get(this, 'steps');

      if (!formatter || typeof formatter !== 'function') {
        // Default formatter does nothing
        formatter = function formatter(v) {
          return v;
        };
      }

      return steps.map(formatter);
    }),
    actions: {
      /**
       * When an item is clicked, update the value and call cb()
       *  with the setting name and new value. If allowNoSelection
       *  is true, then clicking the same value twice will unselect the input
       */
      valueChanged: function valueChanged(selectedIndex) {
        var setting = Ember.get(this, 'setting');
        var steps = Ember.get(this, 'steps');
        var previousIndex = Ember.get(this, 'selectionIndex');
        var allowNoSelection = Ember.get(this, 'allowNoSelection');
        var cb = Ember.get(this, 'changeFnc');
        var selectedStep = steps[selectedIndex];

        if (selectedIndex === previousIndex && allowNoSelection) {
          // Clicked on the active selection should select none
          selectedStep = undefined;
          selectedIndex = undefined;
        }

        Ember.set(this, 'selectionIndex', selectedIndex);
        cb && cb(setting, selectedStep);
      }
    }
  });

  _exports.default = _default;
});