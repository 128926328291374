define("fitbit-site-ui/routes/settings/data/account-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        authorizations: store.findAll('application-authorization'),
        applications: store.peekAll('my-application')
      });
    }
  });

  _exports.default = _default;
});