define("fitbit-site-ui/serializers/friend/leaderboard", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var users = Ember.get(payload, 'data') || [];
      var included = Ember.get(payload, 'included') || [];
      included.forEach(function (includedObject) {
        var type = Ember.get(includedObject, 'type');
        Ember.set(includedObject, 'type', "friend/leaderboard/".concat(type));
      });
      var newIncluded = users.concat(included);
      var relationships = users.reduce(function (acc, user) {
        var type = Ember.String.camelize(Ember.get(user, 'type'));
        var pluralType = "".concat(type, "s");
        Ember.set(user, 'relationships.user.data.type', 'friend/leaderboard/person'); // Remove relationships attr

        var userType = "friend/leaderboard/".concat(user.type);
        var userObj = {
          id: user.id,
          type: userType
        };
        Ember.set(user, 'type', userType);
        acc[pluralType].data.push(userObj);
        return acc;
      }, {
        rankedUsers: {
          data: []
        },
        inactiveUsers: {
          data: []
        },
        hiddenUsers: {
          data: []
        }
      });
      var newPayload = {
        data: {
          attributes: {},
          id: id,
          type: 'friend/leaderboard',
          relationships: relationships
        },
        included: newIncluded
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});