define("fitbit-site-ui/models/food-database", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    barcode: _emberData.default.attr('boolean'),
    imageUpload: _emberData.default.attr('boolean'),
    label: _emberData.default.attr('string'),
    //Example: "France"
    value: _emberData.default.attr('string'),
    //Used to render in select-menu
    displayName: Ember.computed.alias('label')
  });

  _exports.default = _default;
});