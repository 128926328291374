define("fitbit-site-ui/controllers/settings/application-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /**
     * List of applications where firstPartyApp = false
     *
     */
    thirdPartyApps: Ember.computed.filterBy('model', 'firstPartyApp', false),

    /**
     * Temporarily required to stop users from revoking access to twine
     */
    manuallyFilteredThirdPartyApps: Ember.computed.filter('thirdPartyApps', function (app) {
      var name = Ember.get(app, 'name');
      var organization = Ember.get(app, 'organization');
      var organizationUri = Ember.get(app, 'organizationUri');
      return name !== 'Twine' && organization !== 'Twine Health' && organizationUri !== 'http://www.twinehealth.com';
    }),
    actions: {
      /**
       * Revokes authorization from application
       * @param auth
       */
      revokeAccess: function revokeAccess(auth) {
        var _this = this;

        if (!Ember.get(this, 'isLoading')) {
          Ember.set(this, 'isLoading', true);
          var app = Ember.get(auth, 'application');
          auth.destroyRecord().then(function () {
            app.unloadRecord();
          }).catch(function () {
            var _EmberGetProperties = Ember.getProperties(_this, 'flashMessages', 'i18n'),
                flashMessages = _EmberGetProperties.flashMessages,
                formatter = _EmberGetProperties.i18n;

            flashMessages.alert(formatter.formatByNamespace('revoke_err', 'route.settings.application-settings'));
          }).finally(function () {
            return Ember.set(_this, 'isLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});