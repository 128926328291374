define("fitbit-site-ui/serializers/water-log", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/utils/unit-converter", "snapdragon-common/constants/food-logging"], function (_exports, _application, _unitConverter, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'logId',
    currentUser: Ember.inject.service(),

    /**
     * Logs will always be serialized to store amounts equal to the current users water unit
     * Payload: {
     *      waterLog - {Object} water-log record
     *      date - {String} YYYY-MM-DD of log
     *      passedUnit - {String} on of 'ml', 'fl oz', or 'cup'
     * }
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var waterLog = payload.waterLog,
          date = payload.date,
          passedUnit = payload.passedUnit;
      var currentUserWaterUnit = Ember.get(this, 'currentUser.waterUnit');
      var currentUserWaterSymbol = _foodLogging.WATER_UNIT_TO_SYMBOL[currentUserWaterUnit];

      if (currentUserWaterSymbol != passedUnit) {
        switch (currentUserWaterSymbol) {
          case 'cup':
            waterLog.amount = passedUnit === 'ml' ? _unitConverter.default.mlToCups(waterLog.amount, 2) : _unitConverter.default.ozToCups(waterLog.amount, 2);
            break;

          case 'fl oz':
            waterLog.amount = passedUnit === 'ml' ? _unitConverter.default.mlToOz(waterLog.amount, 1) : _unitConverter.default.cupsToOz(waterLog.amount);
            break;

          case 'ml':
            waterLog.amount = passedUnit === 'fl oz' ? _unitConverter.default.ozToMl(waterLog.amount) : _unitConverter.default.cupsToMl(waterLog.amount);
            break;
        }
      }

      var newPayload = {
        'water-log': {
          unit: passedUnit,
          waterDate: date,
          logId: waterLog.logId,
          amount: waterLog.amount
        },
        'water-date': {
          id: date
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      var date = Ember.get(snapshot, 'record.date'); // API expects weekDays data to be comma separated.

      json.date = date;
      delete json.waterDate;
      return json;
    }
  });

  _exports.default = _default;
});