define("fitbit-site-ui/mixins/device-time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),
    CLOCK_24_TIME_FORMAT: Ember.computed.readOnly('constants.CLOCK_24_TIME_FORMAT'),
    CLOCK_12_TIME_FORMAT: Ember.computed.readOnly('constants.CLOCK_12_TIME_FORMAT'),
    CLOCK_12_DISPLAY_FORMAT: Ember.computed.readOnly('constants.TWELVE_HOUR_FORMAT'),
    isClock12: null,

    /**
     * Return the outputformat determined by isClock12.
     * @property timeFormat
     * @readOnly
     * @return {String} The output format determined by isClock12
     */
    timeFormat: Ember.computed(function () {
      var _this$getProperties = this.getProperties('isClock12', 'CLOCK_12_TIME_FORMAT', 'CLOCK_24_TIME_FORMAT'),
          isClock12 = _this$getProperties.isClock12,
          CLOCK_12_TIME_FORMAT = _this$getProperties.CLOCK_12_TIME_FORMAT,
          CLOCK_24_TIME_FORMAT = _this$getProperties.CLOCK_24_TIME_FORMAT;

      var ret = CLOCK_24_TIME_FORMAT;

      if (isClock12) {
        ret = CLOCK_12_TIME_FORMAT;
      }

      return ret;
    }).readOnly()
  });

  _exports.default = _default;
});