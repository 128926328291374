define("fitbit-site-ui/components/user-action-button", ["exports", "fitbit-site-ui/services/user-actions"], function (_exports, _userActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-unused-vars
  var _default = Ember.Component.extend({
    tagName: ''
    /**
     * The user action type from the user-actions service.
     * Used to display the appropriate glyph and, if `@dataTestQa is undefined`, create the data-test-qa attribute value.
     *
     * @name @userActionType
     * @type {CHEER|TAUNT|MESSAGE}
     */

    /**
     * Button title.
     *
     * @name @buttonTitle
     * @type {String}
     */

    /**
     * Value for data-test-qa attribute. If undefined, a value will be created with the `@userActionType`.
     *
     * @name @dataTestQa
     * @type {String}
     */

    /**
     * Button type.
     *
     * @name @buttonType
     * @type {String}
     * @default button
     */

    /**
     * Action triggered when the button is clicked.
     *
     * @name @onClick
     * @type {Function}
     */

  });

  _exports.default = _default;
});