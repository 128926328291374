define("fitbit-site-ui/serializers/device/scale/user-scale", ["exports", "ember-inflector", "fitbit-site-ui/serializers/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var inflector = _emberInflector.default.inflector;

  var _default = _application.default.extend({
    _processUserScale: function _processUserScale(scale) {
      scale.scaleName = scale.name;
      scale.userList = scale.id;
      scale.invitedUserList = scale.id;
      scale.deviceType = scale.version;
      return scale;
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var payloadKey = inflector.pluralize(primaryModelClass.modelName),
          scales = payload.scales;
      scales.forEach(function (device) {
        _this._processUserScale(device);
      });

      var data = _defineProperty({}, payloadKey, scales);

      return this._super(store, primaryModelClass, data, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = primaryModelClass.modelName,
          payloadData = payload.scale;

      this._processUserScale(payloadData);

      var data = _defineProperty({}, payloadKey, payloadData);

      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});