define("fitbit-site-ui/serializers/user-device", ["exports", "ember-inflector", "fitbit-site-ui/serializers/application", "fitbit-site-ui/constants/legacy-device-types"], function (_exports, _emberInflector, _application, _legacyDeviceTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var inflector = _emberInflector.default.inflector;

  var _default = _application.default.extend({
    attrs: {
      productType: 'type',
      deviceType: 'deviceVersion'
    },
    DEVICE_TYPE_MAP: Ember.computed(function () {
      return {
        Classic: 'Ultra'
      };
    }),
    _processUserDevice: function _processUserDevice(id, device) {
      var deviceVersion = device.deviceVersion;
      var mappedDeviceType = this.DEVICE_TYPE_MAP[deviceVersion];
      device.type = device.type.toLowerCase(); // Support a `device/scale/user-scale` relationship,
      // Enables access to a scale.

      if (device.type === 'scale') {
        device.scale = device.id;
      } // Need to save the name in case the device-type isn't loaded, like MobileTrack.


      device.name = deviceVersion; // Associate user

      device.user = id;
      device.deviceList = id; // Used for polymorphic relationship

      device.settings = {
        id: device.id,
        type: "device/".concat(device.type, "/settings")
      }; // Use the DEVICE_TYPE_MAP to identify the correct deviceVersion/deviceType
      // because some deviceVersion's have a shared device type, like Ultra and Classic.

      if (mappedDeviceType) {
        device.deviceVersion = mappedDeviceType;
      }

      return device;
    },

    /**
     * Indicates if a device is a legacy device or not
     * @param device {Object} payload device object
     * @returns {Boolean} - True if legacy
     * @private
     */
    _isLegacyDevice: function _isLegacyDevice(device) {
      return _legacyDeviceTypes.LEGACY_DEVICE_VERSIONS.includes(device.deviceVersion.toLowerCase());
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var payloadKey = inflector.pluralize(primaryModelClass.modelName);
      var devices = payload[payloadKey];
      devices.forEach(function (device) {
        _this._processUserDevice(id, device);

        if (_this._isLegacyDevice(device)) {
          Ember.set(device, 'isLegacy', true);
          Ember.set(device, 'deviceVersion', null);
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = primaryModelClass.modelName;
      var userId = this.get('currentUser.encodedId');

      var device = this._processUserDevice(userId, payload.device);

      if (this._isLegacyDevice(device)) {
        Ember.set(device, 'isLegacy', true);
        Ember.set(device, 'deviceVersion', null);
      }

      var data = _defineProperty({}, payloadKey, device);

      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});