define("fitbit-site-ui/models/device/scale/user-scale", ["exports", "ember-data", "fitbit-site-ui/models/user-device"], function (_exports, _emberData, _userDevice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _userDevice.default.extend({
    network: attr(),
    defaultUnit: attr(),
    version: attr(),
    brightness: attr(),
    scaleName: attr(),
    firmwareVersion: attr(),
    iconsSupported: attr(),
    deviceType: belongsTo('device-type'),
    battery: attr('string', {
      defaultValue: 'Unknown'
    }),
    //Magic data weirdness
    userList: belongsTo('device/scale/user-list'),
    invitedUserList: belongsTo('device/scale/invited-user-list'),
    scaleNameDisplay: Ember.computed('scaleName', function () {
      return Ember.getWithDefault(this, 'scaleName', '').toUpperCase();
    }),
    defaultUnitDisplay: Ember.computed('defaultUnit', function () {
      var defaultUnit = Ember.get(this, 'defaultUnit');
      (false && !(['en_US', 'en_GB', 'METRIC'].includes(defaultUnit)) && Ember.assert('Default scale unit must be set', ['en_US', 'en_GB', 'METRIC'].includes(defaultUnit)));

      switch (defaultUnit) {
        case 'METRIC':
          return 'weight_metric';

        case 'en_US':
          return 'weight_US';

        case 'en_GB':
          return 'weight_GB';

        default:
          return '';
        //Should never reach here
      }
    }),

    /**
     * Generate a route name by using the productType. Used for links.
     */
    routeName: Ember.computed('productType', function () {
      return 'settings.scales';
    })
  });

  _exports.default = _default;
});