define("fitbit-site-ui/models/sleep-log/segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    dateTime: null,
    level: null,
    seconds: null,
    start: Ember.computed('dateTime', function () {
      return this.get('dateTime');
    }),
    end: Ember.computed('dateTime', 'seconds', function () {
      return this.get('dateTime').clone().add(this.get('seconds'), 'seconds');
    })
  });

  _exports.default = _default;
});