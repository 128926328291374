define("fitbit-site-ui/serializers/device/tracker/alarm/list", ["exports", "fitbit-site-ui/serializers/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var inflector = _emberInflector.default.inflector;

  var _default = _application.default.extend({
    _normalizeAlarms: function _normalizeAlarms(store, payload, id) {
      var model = store.modelFor('device/tracker/alarm');
      var modelName = model.modelName;
      var serializer = store.serializerFor(modelName);
      var payloadKey = serializer.payloadKeyFromModelName(modelName);
      var pluralPayloadKey = inflector.pluralize(payloadKey);
      return serializer.normalizeArrayResponse(store, model, _defineProperty({}, pluralPayloadKey, payload), id);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName);
      var alarms = payload.trackerAlarms;

      var data = _defineProperty({}, payloadKey, {
        id: id,
        list: alarms.map(function (alarm) {
          return alarm.alarmId.toString();
        })
      });

      var ret = this._super(store, primaryModelClass, data, id, requestType);

      ret.included = this._normalizeAlarms(store, alarms, id).data;
      return ret;
    }
  });

  _exports.default = _default;
});