define("fitbit-site-ui/components/device-auto-exercise-setting", ["exports", "fitbit-site-ui/components/panel-component", "moment"], function (_exports, _panelComponent, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    maxMinuteThreshold: 91,
    //Returns a range of minutes a user can set their threshold to
    minutesRange: Ember.computed('maxMinuteThreshold', function () {
      var formatter = this.get('i18n');

      var minRange = _toConsumableArray(Array(this.get('maxMinuteThreshold')).keys()).splice(10),
          minArr = minRange.map(function (min) {
        return {
          displayName: formatter.format('interval_display_minutes', {
            minutes: min
          }),
          mill: _moment.default.duration(min, 'minutes').asMilliseconds()
        };
      });

      minArr.unshift({
        //Enables a user to disable this type of auto exercise
        displayName: formatter.format('ignored_minutes'),
        mill: -1
      });
      return minArr;
    }),
    actions: {
      selectRange: function selectRange(event) {
        var _this = this;

        var formatter = this.get('i18n'),
            isEnabled = this.get('autoExerciseSettings.autoDetection'),
            flashMessages = this.get('flashMessages');

        if (isEnabled === 'on') {
          var $target = this.$(event.target)[0],
              activityName = $target.name,
              value = $target.value,
              thresholds = this.get('autoExerciseSettings.detectionThresholds'),
              threshold = thresholds.findBy('activityName', activityName);

          if (value < 0) {
            //Means that we disabled this threshold for that specific activity
            Ember.set(threshold, 'enabled', false);
          } else {
            Ember.set(threshold, 'enabled', true);
            Ember.set(threshold, 'minDuration', value);
          }
        }

        this.get('autoExerciseSettings').then(function (model) {
          return model.save();
        }).then(function () {
          var successMessage = formatter.format('device_setting_saved');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        }).catch(function () {
          var failureMessage = formatter.format('something_went_wrong');

          _this._clearMessageFromQueue(failureMessage);

          flashMessages.alert(failureMessage);
        });
      }
    }
  });

  _exports.default = _default;
});