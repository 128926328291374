define("fitbit-site-ui/templates/components/device-dominant-hand-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BVxifnb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"dominant_hand_detail\"],[[\"deviceName\"],[[23,[\"deviceName\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-6 radio-group\"],[9],[0,\"\\n        \"],[1,[27,\"double-radio-button\",null,[[\"toggleValue\",\"firstValue\",\"firstDescription\",\"secondValue\",\"secondDescription\",\"propertyName\",\"changeFnc\"],[[23,[\"dominantHand\"]],true,[27,\"format\",[\"dominant\"],null],false,[27,\"format\",[\"non_dominant\"],null],\"dominant-hand\",[27,\"action\",[[22,0,[]],\"dominantHandChanged\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-dominant-hand-setting.hbs"
    }
  });

  _exports.default = _default;
});