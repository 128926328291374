define("fitbit-site-ui/models/time-series/calorie", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    dateTime: attr(),
    value: attr('number'),
    intraDay: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    dataset: Ember.computed.alias('intraDay.dataset')
  }); // Sample URL & Data
  // GET /1/user/-/activities/calories/date/2016-05-07/1d/1min.json
  // Unknown possibly sensitive origin
  // {
  //   "activities-calories":  [
  //      {
  //       "dateTime": "2016-05-07",
  //       "value": "3699"
  //     }
  //   ],
  //   "activities-calories-intraday":  {
  //     "dataset":  [
  //        {
  //         "level": 1,
  //         "mets": 79,
  //         "time": "00:00:00",
  //         "value": 10.092249870300293
  //       },
  //        {
  //         "level": 1,
  //         "mets": 72,
  //         "time": "00:01:00",
  //         "value": 9.197999954223633
  //       },


  _exports.default = _default;
});