define("fitbit-site-ui/serializers/food-goal", ["exports", "fitbit-site-ui/serializers/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      /* the food goal endpoint will sometimes return a 200 but only have errors in the response */
      var payloadErrors = Ember.get(payload, 'errors');

      if (payloadErrors) {
        throw payloadErrors[0];
      }

      payload.id = id;
      var newPayload = {
        'food-goal': payload
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var intensity = Ember.get(json, 'foodPlan.intensity');

      if (intensity) {
        json = {
          intensity: intensity,
          personalized: Ember.get(json, 'foodPlan.personalized')
        };
      } else {
        json = {
          calories: Math.trunc(Ember.get(json, 'goals.calories'))
        };
      }

      return json;
    }
  });

  _exports.default = _default;
});