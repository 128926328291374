define("fitbit-site-ui/templates/components/user-notifications/user-notification-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHlZ10dx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"user-notifications/user-notification-base\",null,[[\"notification\",\"markAsRead\"],[[23,[\"notification\"]],[23,[\"markAsRead\"]]]],{\"statements\":[[4,\"unless\",[[23,[\"isWebFeaturesDeprecationFlagEnabled\"]]],null,{\"statements\":[[4,\"user-actions-button-group\",null,[[\"class\"],[\"align-spaced\"]],{\"statements\":[[0,\"            \"],[1,[27,\"user-action-button\",null,[[\"userActionType\",\"onClick\",\"buttonTitle\"],[\"taunt\",[23,[\"onTaunt\"]],[27,\"format-by-namespace\",[\"send_taunt\",\"route.friends\"],[[\"name\"],[[23,[\"notification\",\"sentBy\",\"displayName\"]]]]]]]],false],[0,\"\\n\\n            \"],[1,[27,\"user-action-button\",null,[[\"userActionType\",\"onClick\",\"buttonTitle\"],[\"cheer\",[23,[\"onCheer\"]],[27,\"format-by-namespace\",[\"send_cheer\",\"route.friends\"],[[\"name\"],[[23,[\"notification\",\"sentBy\",\"displayName\"]]]]]]]],false],[0,\"\\n\\n            \"],[1,[27,\"user-action-button\",null,[[\"userActionType\",\"onClick\",\"buttonTitle\"],[\"message\",[23,[\"openMessageModal\"]],[27,\"format-by-namespace\",[\"send_message\",\"route.friends\"],[[\"name\"],[[23,[\"notification\",\"sentBy\",\"displayName\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-notifications/user-notification-default.hbs"
    }
  });

  _exports.default = _default;
});