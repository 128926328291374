define("fitbit-site-ui/adapters/user-notification", ["exports", "fitbit-site-ui/adapters/application", "fitbit-site-ui/constants/user-notifications"], function (_exports, _application, _userNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/notifications-service',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super(), "/user/-/destination/NOTIFICATION_CENTER/notifications?maxResults=").concat(_userNotifications.MAX_NOTIFICATIONS_REQUESTED);
    },
    handleResponse: function handleResponse(status, headers, body) {
      return status === 204 ? this._super(status, headers, {
        notifications: []
      }) : this._super(status, headers, body);
    }
  });

  _exports.default = _default;
});