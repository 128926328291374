define("fitbit-site-ui/components/device-run-cue-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    /**
     * One way bind of the setting.
     * @public
     * @property cueSetting
     */
    cueSetting: Ember.computed.oneWay('setting'),
    onSettingsChanged: function onSettingsChanged() {},
    actions: {
      onCueChanged: function onCueChanged(value) {
        var _this = this;

        var formatter = this.get('i18n');
        this.set('setting', value); // Wrap in a promise in case the onSettingsChanged doesn't return one.

        Ember.RSVP.resolve(this.get('onSettingsChanged')()).then(function () {
          _this.showFlashMessage('success', formatter.format('updated_settings'));
        }).catch(function (err) {
          var error = err && err.errors[0];
          var errorMessage = error.message || formatter.format('device_setting_err');

          _this.showFlashMessage('alert', errorMessage);
        });
      }
    }
  });

  _exports.default = _default;
});