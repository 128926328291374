define("fitbit-site-ui/models/device/tracker/settings/options", ["exports", "fitbit-site-ui/models/device/settings/options", "ember-data/attr", "ember-data/relationships"], function (_exports, _options, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _options.default.extend({
    bluetoothSupported: (0, _attr.default)('boolean'),
    currentFirmware: (0, _attr.default)(),
    imageUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    apiExerciseOptions: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    autoLapInterval: (0, _attr.default)(),
    availableNotificationTypes: (0, _attr.default)(),
    clockFacesSupported: (0, _attr.default)(),
    latestFirmware: (0, _attr.default)(),
    primaryGoalsSupported: (0, _attr.default)(),
    simpleWatchCheckSupported: (0, _attr.default)(),
    deviceType: (0, _relationships.belongsTo)('device-type', {
      async: false
    }),
    availableWidgets: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    settings: (0, _relationships.belongsTo)('device/tracker/settings'),
    //[{displayName: "Off", name: "DISABLED"},{displayName: "Show Clock", name: "CLOCK"}....]
    tapGestureOptions: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    maxNumberOfExerciseShortcuts: (0, _attr.default)('number')
  });

  _exports.default = _default;
});