define("fitbit-site-ui/components/scale-user-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['data-test-qa'],
    'data-test-qa': 'icon-select-button',
    tagName: 'button',
    classNameBindings: ['iconOwned:owned', 'selected'],
    clickAction: function clickAction() {},
    users: Ember.computed(function () {
      return [];
    }),
    iconOwned: Ember.computed('icon.userIconId', 'users.[]', function () {
      var icon = this.get('icon');
      var users = this.get('users');
      return users.any(function (user) {
        return user.get('userIconId') === icon.userIconId;
      });
    }),
    iconOwner: Ember.computed('icon.userIconId', 'users.[]', function () {
      var icon = this.get('icon');
      var users = this.get('users');
      return users.find(function (user) {
        return user.get('userIconId') === icon.userIconId;
      });
    }),
    click: function click(e) {
      if (!this.get('iconOwned')) {
        var clickAction = Ember.get(this, 'clickAction');
        clickAction(Ember.get(this, 'icon.userIconId'), e);
      }
    }
  });

  _exports.default = _default;
});