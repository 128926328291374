define("fitbit-site-ui/models/recent-food", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    loggedFood: _emberData.default.belongsTo('food/logged-food'),

    /**
     * Alias for log-food-list-group
     */
    foodListItem: Ember.computed.alias('loggedFood')
  });

  _exports.default = _default;
});