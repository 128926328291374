define("fitbit-site-ui/serializers/journal-survey-question-response-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    attrs: {
      entryType: 'entry-type'
    }
  });

  _exports.default = _default;
});