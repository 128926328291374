define("fitbit-site-ui/components/flexible-prototype-feedback", ["exports", "snapdragon-common/components/flexible-prototype-feedback"], function (_exports, _flexiblePrototypeFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _flexiblePrototypeFeedback.default;
    }
  });
});