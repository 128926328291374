define("fitbit-site-ui/services/performance-report", ["exports", "snapdragon-common/constants/performance"], function (_exports, _performance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    metrics: Ember.inject.service(),
    reported: false,

    /**
     * Returns set of performance entries
     * @private
     */
    _getPerformanceEvents: function _getPerformanceEvents() {
      var performanceMarks = (0, _performance.performanceGetEntriesByType)('mark');
      var marks = performanceMarks.reduce(function (acc, mark) {
        var name = mark.name,
            startTime = mark.startTime;
        acc[name] = startTime;
        return acc;
      }, {});
      var navigationMarks = (0, _performance.performanceGetEntriesByType)('navigation');
      var htmlRequest = navigationMarks.reduce(function (acc, nav) {
        acc.indexRequestTime = nav.requestStart;
        acc.indexResolvedTime = nav.responseEnd;
        return acc;
      }, {});
      return _objectSpread({}, marks, {}, htmlRequest);
    },
    reportIfMetrics: function reportIfMetrics(pageName) {
      // If marks have already been reported, or there are no marks return immediately
      if (this.reported || !_performance.HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE || !(0, _performance.performanceGetEntriesByType)('mark').length) {
        return;
      }

      var marks = this._getPerformanceEvents();

      this.metrics.trackEvent('fsc', _objectSpread({
        eventType: 'Track',
        pageName: pageName
      }, marks, {
        fscOptions: {
          owner: 'Wellness',
          feature: 'Webview Performance'
        }
      }));
      (0, _performance.performanceClearMarks)();
      Ember.set(this, 'reported', true);
    }
  });

  _exports.default = _default;
});