define("fitbit-site-ui/models/food-meal", ["exports", "ember-data", "ember-data/attr", "snapdragon-common/constants/macro-nutrients", "ember-cp-validations"], function (_exports, _emberData, _attr, _macroNutrients, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: Ember.computed(function () {
        var i18n = Ember.get(this, 'model.i18n');
        return i18n.formatByNamespace('name_label', 'models.food-meal');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        max: 255,
        useBetweenMessage: true
      })]
    },
    description: {
      description: Ember.computed(function () {
        var i18n = Ember.get(this, 'model.i18n');
        return i18n.formatByNamespace('description_label', 'models.food-meal');
      }),
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        allowBlank: true,
        min: 1,
        max: 255,
        useBetweenMessage: true
      })]
    },
    mealFoods: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: Ember.computed(function () {
        var formatter = Ember.get(this, 'model.i18n');
        return formatter.formatByNamespace('meal_foods_empty', 'models.food-meal');
      })
    })
  });
  /**
   * @typedef {Object} MacroNutrients
   * @property {Number} carbs Carbohydrates in grams
   * @property {Number} fat Fat in grams
   * @property {Number} protein Protein in grams
   */

  var _default = _emberData.default.Model.extend(Validations, {
    /**
     * Description of the meal.
     *
     * @returns {String}
     */
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),

    /**
     * Name of the meal.
     *
     * @returns {String}
     */
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),

    /**
     * Contains `food` objects.
     *
     * This property is not a `hasMany` relationship to another model because the `food` objects contained here are
     * unique but do not have a unique ID. E.g. a food object with the same foodId but different amount will affect
     * other meals that should have different amounts.
     *
     * @returns {Object[]} food
     * @returns {Number} food.foodId ID of the food object.
     * @returns {Number} food.amount The amount of food.
     * @returns {String} food.unitName The unit name of the amount of food.
     * @returns {Number} food.calories The amount of calories per serving (unitName).
     * @returns {String} food.name The name of the food.
     * @returns {String} food.brand The brand of the food.
     */
    mealFoods: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * Collects calories from objects in mealFoods.
     * @returns {Array}
     */
    foodCalories: Ember.computed.mapBy('mealFoods', 'calories'),

    /**
     * Sum of calories from the collection of calories provided by `foodCalories`.
     * @returns {Number};
     */
    calories: Ember.computed.sum('foodCalories'),

    /**
     * Fitbit food records found using the foodId from objects in `mealFoods` array.
     *
     * Objects in `mealFoods` array have a different shape and no UID.
     *
     * @readonly
     * @returns {Array}
     */
    fitbitFoods: Ember.computed('mealFoods.[]', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'store', 'mealFoods'),
          store = _EmberGetProperties.store,
          mealFoods = _EmberGetProperties.mealFoods;

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(mealFoods.map(function (_ref) {
          var foodId = _ref.foodId;
          return store.peekRecord('fitbit-food', foodId) || store.findRecord('fitbit-food', foodId);
        }))
      });
    }).readOnly(),

    /**
     * Compute macro nutrients of `mealFoods` using servings and nutritional data from `fitbitFoods`.
     *
     * Values are in grams.
     *
     * @readonly
     * @returns {MacroNutrients}
     */
    macroNutrients: Ember.computed('{mealFoods.[],fitbitFoods.[]}', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, 'mealFoods', 'fitbitFoods'),
          mealFoods = _EmberGetProperties2.mealFoods,
          fitbitFoods = _EmberGetProperties2.fitbitFoods;

      var MACRONUTRIENTS = [_macroNutrients.CARBS, _macroNutrients.FAT, _macroNutrients.PROTEIN];
      var macroNutrients = null;

      if (Ember.isPresent(mealFoods) && Ember.isPresent(fitbitFoods)) {
        var _mealFoods$map$reduce;

        var fitbitFoodsByIdHash = fitbitFoods.reduce(function (acc, fitbitFood) {
          var id = fitbitFood.id;
          acc[id] = fitbitFood;
          return acc;
        }, {});
        macroNutrients = mealFoods.map(function (mealFood) {
          var _ref2;

          // Collect and calculate the macro nutrients per meal food.
          var foodId = mealFood.foodId,
              _mealFood$amount = mealFood.amount,
              amount = _mealFood$amount === void 0 ? 1 : _mealFood$amount,
              servingsType = mealFood.unitName;
          var food = fitbitFoodsByIdHash[foodId];

          var _EmberGetProperties3 = Ember.getProperties(food, 'servings', 'nutritionalValues'),
              servings = _EmberGetProperties3.servings,
              _EmberGetProperties3$ = _EmberGetProperties3.nutritionalValues,
              _EmberGetProperties3$2 = _EmberGetProperties3$.totalCarbohydrate,
              totalCarbohydrate = _EmberGetProperties3$2 === void 0 ? 0 : _EmberGetProperties3$2,
              _EmberGetProperties3$3 = _EmberGetProperties3$.totalFat,
              totalFat = _EmberGetProperties3$3 === void 0 ? 0 : _EmberGetProperties3$3,
              _EmberGetProperties3$4 = _EmberGetProperties3$.protein,
              protein = _EmberGetProperties3$4 === void 0 ? 0 : _EmberGetProperties3$4;

          var _servings$servingsTyp = servings[servingsType].multiplier,
              multiplier = _servings$servingsTyp === void 0 ? 1 : _servings$servingsTyp;
          return _ref2 = {}, _defineProperty(_ref2, _macroNutrients.CARBS, totalCarbohydrate * multiplier * amount), _defineProperty(_ref2, _macroNutrients.FAT, totalFat * multiplier * amount), _defineProperty(_ref2, _macroNutrients.PROTEIN, protein * multiplier * amount), _ref2;
        }).reduce(function (acc, mealFoodMacroNutrients) {
          // Total up the macro nutrients
          MACRONUTRIENTS.forEach(function (key) {
            acc[key] = acc[key] + mealFoodMacroNutrients[key];
          });
          return acc;
        }, (_mealFoods$map$reduce = {}, _defineProperty(_mealFoods$map$reduce, _macroNutrients.CARBS, 0), _defineProperty(_mealFoods$map$reduce, _macroNutrients.FAT, 0), _defineProperty(_mealFoods$map$reduce, _macroNutrients.PROTEIN, 0), _mealFoods$map$reduce));
      }

      return macroNutrients;
    }).readOnly(),

    /**
     * Add food to `mealFoods` array.
     * Creates an object containing the properties from `food`. The object is added to the `mealFoods` array and returned.
     * The returned object is useful for removing from mealFoods array.
     *
     * @param {Object} food The food object to look up properties.
     * @param {Number} food.foodId ID of the food object.
     * @param {Number} food.amount The amount of food.
     * @param {String} food.unitName The unit name of the amount of food.
     * @param {String} food.unitNamePlural Plural of unit name.
     * @param {String} food.calories Calories of the food.
     * @param {String} food.name Name of the food.
     * @param {String} food.brand Brand of the food.
     * @returns {Object|undefined} New food item that's been added to mealFoods array. Undefined if food can not be added.
     */
    addFood: function addFood() {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          foodId = _ref3.foodId,
          amount = _ref3.amount,
          unitName = _ref3.unitName,
          _ref3$unitNamePlural = _ref3.unitNamePlural,
          unitNamePlural = _ref3$unitNamePlural === void 0 ? unitName : _ref3$unitNamePlural,
          calories = _ref3.calories,
          name = _ref3.name,
          brand = _ref3.brand;

      if (foodId !== undefined && amount !== undefined && unitName !== undefined && calories !== undefined && name !== undefined && brand !== undefined) {
        var food = {
          foodId: foodId,
          amount: amount,
          unitName: unitName,
          unitNamePlural: unitNamePlural,
          calories: calories,
          name: name,
          brand: brand
        };
        var mealFoods = Ember.get(this, 'mealFoods').slice();
        mealFoods.pushObject(food);
        Ember.set(this, 'mealFoods', mealFoods);
        return food;
      }
    },

    /**
     * Remove a food object from `mealFoods` array.
     *
     * @param {Object} food
     */
    removeFood: function removeFood(food) {
      var mealFoods = Ember.get(this, 'mealFoods').slice();
      mealFoods.removeObject(food);
      Ember.set(this, 'mealFoods', mealFoods);
    },

    /**
     * Clear `mealFoods` array.
     */
    clearFoods: function clearFoods() {
      Ember.set(this, 'mealFoods', []);
    }
  });

  _exports.default = _default;
});