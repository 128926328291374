define("fitbit-site-ui/templates/components/panel-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "klslFMe0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"heading-button\"],[11,\"data-test-qa\",\"toggle-panel\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row heading\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column small-6\"],[9],[0,\"\\n            \"],[7,\"h2\"],[11,\"class\",\"alternate\"],[12,\"data-test-qa\",[28,[\"device-setting-\",[21,\"ariaLabel\"]]]],[12,\"id\",[28,[[21,\"ariaLabel\"]]]],[9],[1,[21,\"title\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column small-6 text-right\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"data-test-qa\",\"toggle-panel-display-setting\"],[9],[1,[21,\"displaySetting\"],false],[10],[0,\"\\n\"],[4,\"fit-glyph\",null,[[\"type\"],[[27,\"if\",[[23,[\"panelOpened\"]],\"chevron-up\",\"chevron-down\"],null]]],{\"statements\":[[0,\"                \"],[7,\"span\"],[11,\"class\",\"show-for-sr\"],[9],[0,\"\\n                    \"],[1,[27,\"format\",[\"panel_component_toggler\"],[[\"title\",\"togglerState\"],[[23,[\"title\"]],[27,\"if\",[[23,[\"panelOpened\"]],[27,\"format-by-namespace\",[\"close\",\"common.messages\"],null],[27,\"format-by-namespace\",[\"open\",\"common.messages\"],null]],null]]]],false],[0,\"\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"togglePanel\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"panelOpened\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row column\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[23,[\"body-panel\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/panel-component.hbs"
    }
  });

  _exports.default = _default;
});