define("fitbit-site-ui/mixins/friend-management-actions-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /**
     * Generates a friend request url based on a friend suggestion
     * @param suggestion {Object} - { id, email, name } (one property required)
     * @returns {string} - URL Path to pass to ajax service
     * @private
     */
    _generateInviteUrl: function _generateInviteUrl(suggestion) {
      var id = Ember.get(suggestion, 'id');
      var invitedUserIdString = id ? "invitedUserId=".concat(id, "&") : '';
      var email = Ember.get(suggestion, 'email');
      var invitedUserEmailString = email ? "invitedUserEmail=".concat(email, "&") : '';
      var name = Ember.get(suggestion, 'name');
      var invitedUserNameString = name ? "invitedUserName=".concat(name, "&") : ''; // Slice last index because string will end with '&'

      var queryParams = "".concat(invitedUserIdString).concat(invitedUserEmailString).concat(invitedUserNameString).slice(0, -1);
      var currentUserId = Ember.get(this, 'currentUser.id') || '-';
      return "/user/".concat(currentUserId, "/friends/invitations?").concat(queryParams);
    },
    actions: {
      /**
       * Invitation endpoint returns 201 {}, so keeping it as an ajax call, then refreshing
       * the invitations model makes more sense because the endpoint doesn't return the model
       * @param userToInvite {Object} - Object must contain one of
       *                                  1. userToInvite.id (valid encoded userId)
       *                                  2. userToInvite.email
       *                                  3. userToInvite.name
       * @returns {*}
       */
      sendFriendInvite: function sendFriendInvite(userToInvite) {
        var _this = this;

        var controller = Ember.get(this, 'controller');
        var isLoading = Ember.get(controller, 'isLoading');

        if (!isLoading) {
          Ember.set(controller, 'isLoading', true);
          var ajax = Ember.get(this, 'ajax');
          var store = Ember.get(this, 'store');

          var url = this._generateInviteUrl(userToInvite);

          return ajax.post(url, {
            namespace: '1.1',
            contentType: 'text/plain',
            dataType: 'text'
          }).then(function () {
            // Reload friend model
            if (_this._reloadFriendInvitations) {
              return _this._reloadFriendInvitations();
            }

            var userID = Ember.get(_this, 'currentUser.id');
            return store.findRecord('friend/leaderboard', userID, {
              reload: true
            });
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          });
        }
      },

      /**
       * Given an inbox-invitation record, accept its invitation and reload the model data
       * @param friendInvitation {Object} - inbox-invitation record
       * @returns {Promise}
       */
      acceptFriendInvite: function acceptFriendInvite(friendInvitation) {
        var _this2 = this;

        var controller = Ember.get(this, 'controller');
        var isLoading = Ember.get(controller, 'isLoading');

        if (!isLoading) {
          Ember.set(controller, 'isLoading', true);
          var store = Ember.get(this, 'store');
          return friendInvitation.acceptInvitation().then(function () {
            if (_this2._reloadModelData) {
              return _this2._reloadModelData();
            } // Reload friend model


            var userID = Ember.get(_this2, 'currentUser.id');
            return store.findRecord('friend/leaderboard', userID);
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          });
        }
      },

      /**
       * Given an inbox-invitation record, decline its invitation and reload the model data
       * @param friendInvitation {Object} - inbox-invitation record
       * @returns {Promise}
       */
      declineFriendInvite: function declineFriendInvite(friendInvitation) {
        var _this3 = this;

        var controller = Ember.get(this, 'controller');
        var isLoading = Ember.get(controller, 'isLoading');

        if (!isLoading) {
          Ember.set(controller, 'isLoading', true);
          var store = Ember.get(this, 'store');
          return friendInvitation.declineInvitation().then(function () {
            if (_this3._reloadModelData) {
              return _this3._reloadModelData();
            } // Reload friend model


            var userID = Ember.get(_this3, 'currentUser.id');
            return store.findRecord('friend/leaderboard', userID);
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          });
        }
      },

      /**
       * Given a friend's encodedId, destroy the record removing the friendship
       * @param friendRecord {String} - encoded id of friend
       * @returns {*}
       */
      removeFriend: function removeFriend(friendId) {
        var _this4 = this;

        var controller = Ember.get(this, 'controller');
        var isLoading = Ember.get(controller, 'isLoading');

        if (!isLoading) {
          Ember.set(controller, 'isLoading', true);
          var ajax = Ember.get(this, 'ajax');
          var store = Ember.get(this, 'store');
          var currentUserId = Ember.get(this, 'currentUser.id');
          return ajax.delete("/user/".concat(currentUserId, "/friends/").concat(friendId), {
            namespace: '1.1',
            contentType: 'text/plain',
            dataType: 'text'
          }).then(function () {
            // unload friend record from currentUser's friends
            var currentUserFriendList = Ember.get(_this4, 'currentUser.friends.list');
            var friendRecord = currentUserFriendList.find(function (friend) {
              return Ember.get(friend, 'id') === friendId;
            });
            return friendRecord && currentUserFriendList.removeObject(friendRecord);
          }).then(function () {
            if (_this4._reloadModelData) {
              return _this4._reloadModelData();
            } // Reload friend model


            var userID = Ember.get(_this4, 'currentUser.id');
            return store.findRecord('friend/leaderboard', userID);
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});