define("fitbit-site-ui/serializers/time-series/floor", ["exports", "fitbit-site-ui/serializers/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    getPayloadKey: function getPayloadKey() {
      return 'time-series/floor';
    },
    attrs: {
      activitiesFloors: 'activities-floors',
      activitiesFloorsIntraday: 'activities-floors-intraday'
    }
  });

  _exports.default = _default;
});