define("fitbit-site-ui/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    navigation: Ember.inject.service(),
    mobileView: Ember.inject.service(),
    queryParams: ['redirect', {
      webview: {
        type: 'boolean'
      }
    }, 'signature'],
    pageType: Ember.computed.readOnly('navigation.pageType'),
    pageName: Ember.computed.readOnly('navigation.pageName'),
    hideHeaderFooterPathList: Object.freeze(['deezer.index', 'deezer.confirm', 'launch', 'sleep-score-help', 'health-updates.feedback', 'health-updates.learn-more', 'heart-benchmark.index', 'heart-benchmark.daily-survey', 'care/go.index', 'care/go.code', 'fhs-onboarding.welcome', 'fhs-onboarding.consent', 'fhs-onboarding.application-form', 'fhs-onboarding.success', 'fhs-onboarding.redirect', 'fhs-onboarding.from-redirect', 'fhs-onboarding_error', 'sleep./.date', 'afib-ecg.onboarding.index', 'afib-ecg.onboarding.how-it-works', 'afib-ecg.onboarding.possible-results', 'afib-ecg.onboarding.video', 'afib-ecg.onboarding.privacy', 'afib-ecg.results.index', 'afib-ecg.results.export', 'afib-ecg.results.details']),

    /**
     * For pages which should not use header-nav and footer-nav components
     * The path should be added to the hideHeaderFooter array
     * @returns { boolean } - true if the header and footer should not appear
     */
    hideHeaderFooter: Ember.computed('currentPath', 'mobileView.isWebView', function () {
      // Target Name used because when in the loading state, the currentPath will be loading,
      // and the header footer will be displayed in this case.
      var targetName = Ember.get(this, 'target.targetState.routerJs.activeTransition.targetName');
      var currentPath = Ember.get(this, 'currentPath'); // Place route names to not display header footer here

      var hideHeaderFooter = Ember.get(this, 'hideHeaderFooterPathList');
      return Ember.get(this, 'mobileView.isWebView') || hideHeaderFooter.includes(targetName || currentPath);
    }),

    /**
     * For pages which should not use the footer-nav component
     * The path should be added to hideFooter array
     * @returns { boolean } - true if footer should not appear
     */
    hideFooter: Ember.computed('currentPath', function () {
      // Place route names to not display only footer here
      var hideHeaderFooter = ['error', '404'];
      return hideHeaderFooter.includes(Ember.get(this, 'currentPath'));
    }),

    /**
     * For pages which should be full screen white
     * The path should be added to hideFooter array
     * @returns { boolean } - true if footer should not appear
     */
    fullWhitePage: Ember.computed('currentPath', function () {
      // Place route names to not display only footer here
      var hideHeaderFooter = ['error', '404'];
      return hideHeaderFooter.includes(Ember.get(this, 'currentPath'));
    })
  });

  _exports.default = _default;
});