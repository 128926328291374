define("fitbit-site-ui/models/feature", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    flags: (0, _attr.default)(),
    hasPremium: Ember.computed('flags', function () {
      var flags = Ember.get(this, 'flags');
      return flags && flags.includes('PREMIUM_USER');
    })
  });

  _exports.default = _default;
});