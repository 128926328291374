define("fitbit-site-ui/serializers/family", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      ownerId: 'owner_id',
      termsAccepted: 'terms_accepted',
      dateCreated: 'date_created',
      maxMembers: 'max_members'
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var familyAccounts = []; // Maps userId => profile object mapping userId to the family accounts that are theirs
      // { id: userId, familyAccounts: [ userId-famId, userId-famId2 ] }

      var profile = {};
      payload = Array.isArray(payload) ? payload : [payload];
      payload.forEach(function (family) {
        var members = Ember.get(family, 'members');
        var memberIds = [];
        var mapMembers = members.map(function (member) {
          var familyId = Ember.get(family, 'id');
          var memberUserId = Ember.get(member, 'member_id');
          var familyAccountId = "".concat(memberUserId, "-").concat(familyId);
          Ember.set(member, 'id', familyAccountId);
          Ember.set(member, 'family', familyId);
          Ember.set(member, 'user', memberUserId);
          memberIds.push(familyAccountId);
          var profileUser = Ember.get(profile, memberUserId);

          if (!profileUser) {
            Ember.set(profile, memberUserId, {
              encodedId: memberUserId,
              id: memberUserId,
              familyAccounts: []
            });
            profileUser = Ember.get(profile, memberUserId);
          }

          var profileUserFamilyAccounts = Ember.get(profileUser, 'familyAccounts');
          profileUserFamilyAccounts.push(familyAccountId);
          return member;
        });
        familyAccounts = familyAccounts.concat(mapMembers);
        Ember.set(family, 'members', memberIds);
      });
      var newPayload = {
        family: payload,
        familyAccounts: familyAccounts,
        profile: Object.values(profile)
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});