define("fitbit-site-ui/components/cw-current-program-form", ["exports", "fitbit-site-ui/constants/corporate-program-features", "ember-cp-validations", "snapdragon-common/mixins/validations/date-of-birth", "snapdragon-common/constants/user-agent", "fitbit-site-ui/templates/components/cw-current-program-form", "fitbit-site-ui/constants/corporate-program-states", "snapdragon-common/constants/corporate-profile-gender"], function (_exports, _corporateProgramFeatures, _emberCpValidations, _dateOfBirth, _userAgent, _cwCurrentProgramForm, _corporateProgramStates, Gender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var CW_MAX_LENGTH = 45;
  var GENDER_EMPTY_VALUES = [undefined, null];

  function fieldDisabled(context, fieldStr) {
    var field = Ember.get(context, 'model.userProgram.program.bulkFields.fields').findBy('name', fieldStr);
    return field ? !field.enabled : true;
  }

  var cwValidator = (0, _emberCpValidations.validator)('length', {
    max: CW_MAX_LENGTH,
    min: 1,
    message: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('cw_field_error', 'models.cw-program');
    }),
    disabled: Ember.computed(function () {
      var fieldName = Ember.get(this, 'attribute');
      fieldName = fieldName.slice(fieldName.lastIndexOf('.') + 1);
      return fieldDisabled(this, fieldName);
    })
  });
  var Validations = (0, _emberCpValidations.buildValidations)(Ember.assign({
    'programFields.firstName': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidfirstName.[]'),
      message: Ember.computed.oneWay('model.invalidfirstNameMessage')
    })],
    'programFields.lastName': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidlastName.[]'),
      message: Ember.computed.oneWay('model.invalidlastNameMessage')
    })],
    'programFields.dateOfBirth': {
      allowBlank: true,
      disabled: Ember.computed(function () {
        return fieldDisabled(this, 'dateOfBirth');
      }),
      validators: _userAgent.IS_MOBILE ? [(0, _emberCpValidations.validator)('alias', 'mobileDateOfBirth')] : [(0, _emberCpValidations.validator)('alias', 'year'), (0, _emberCpValidations.validator)('alias', 'birthdate'), (0, _emberCpValidations.validator)('exclusion', {
        in: Ember.computed.oneWay('model.invaliddateOfBirth.[]'),
        message: Ember.computed.oneWay('model.invaliddateOfBirthMessage')
      })]
    },
    'programFields.corporateEmail': [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('email_validation_msg', 'components.profile-settings');
      }),
      disabled: Ember.computed(function () {
        return fieldDisabled(this, 'corporateEmail');
      })
    }), (0, _emberCpValidations.validator)('length', {
      max: CW_MAX_LENGTH,
      min: 1,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('cw_field_error', 'models.cw-program');
      }),
      disabled: Ember.computed(function () {
        return fieldDisabled(this, 'corporateEmail');
      })
    }), (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidcorporateEmail.[]'),
      message: Ember.computed.oneWay('model.invalidcorporateEmailMessage')
    })],
    'programFields.gender': [(0, _emberCpValidations.validator)('inclusion', {
      in: GENDER_EMPTY_VALUES.concat([Gender.NOT_AVAILABLE, Gender.MALE, Gender.FEMALE]),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('cw_select_field_error', 'models.cw-program');
      }),
      disabled: Ember.computed(function () {
        return fieldDisabled(this, 'gender');
      })
    }), (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidgender.[]'),
      message: Ember.computed.oneWay('model.invalidgenderMessage')
    })],
    'programFields.phoneNumber': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidphoneNumber.[]'),
      message: Ember.computed.oneWay('model.invalidphoneNumberMessage')
    })],
    'programFields.employeeId': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidemployeeId.[]'),
      message: Ember.computed.oneWay('model.invalidemployeeIdMessage')
    })],
    'programFields.jobTitle': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidjobTitle.[]'),
      message: Ember.computed.oneWay('model.invalidjobTitleMessage')
    })],
    'programFields.additionalInfo': [cwValidator, (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay('model.invalidadditionalInfo.[]'),
      message: Ember.computed.oneWay('model.invalidadditionalInfoMessage')
    })],
    mobileDateOfBirth: {
      validators: [_dateOfBirth.birthdate],
      disabled: !_userAgent.IS_MOBILE
    },
    'userProgram.dimensionsById': (0, _emberCpValidations.validator)('has-many', {
      disabled: Ember.computed.alias('model.selectMenusDisabled')
    })
  }, _dateOfBirth.default));

  var _default = Ember.Component.extend(Validations, {
    // -------------------------------------------------------------------------
    // Dependencies
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes
    layout: _cwCurrentProgramForm.default,
    tagName: 'form',
    classNames: ['cw-current-program-form'],
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      dobChange: function dobChange(year, month, day) {
        Ember.set(this, 'userProgram.programFields.dateOfBirth', "".concat(year, "-").concat(month, "-").concat(day));
      },
      genderChange: function genderChange(gender) {
        Ember.set(this, 'userProgram.programFields.gender', gender);
      },

      /**
       * Decline the form.
       * @param {Object} userProgram
       * @returns {*|Promise.<Object>|null}
       */
      declineForm: function declineForm(userProgram) {
        this.onDecline(userProgram);
      },

      /**
       * Submit the form.
       * @param {Object} userProgram
       * @param data
       * @returns {*|Promise.<Object>|null}
       */
      submitForm: function submitForm(userProgram, data) {
        var _this = this;

        var formatter = Ember.get(this, 'i18n');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (!validations.get('isValid')) {
            Ember.get(_this, 'flashMessages').alert(formatter.formatByNamespace('fix_errors', 'common.messages'));
          } else {
            data.sliceIds = _this._createSliceIdsArray();
            Ember.RSVP.resolve(_this.onSubmit(userProgram, data)).then(function (results) {
              if (results) {
                var newRecords = Ember.get(results, 'records');

                if (newRecords) {
                  Ember.set(userProgram, 'records', newRecords);
                  return;
                }
              }

              var moment = Ember.get(_this, 'moment');
              var programStartDate = moment.moment(Ember.get(userProgram, 'program.period.startDate'));
              var today = moment.moment();
              var newActiveStartDate = programStartDate <= today ? today.format('YYYY-MM-DD') : null;
              var records = Ember.get(userProgram, 'records');
              records.pushObject({
                startDate: newActiveStartDate,
                endDate: null,
                status: _corporateProgramStates.ACTIVE_PROGRAM_STATUS
              }); // Records array was created with default, meaning it has to be set

              if (records.length === 1) {
                Ember.set(userProgram, 'records', records);
              }
            }).catch(function (adapterError) {
              var errors = Ember.get(adapterError, 'errors');
              errors.forEach(function (error) {
                if (error.field) {
                  var invalidFieldValues = Ember.get(_this, "invalid".concat(error.field));
                  var invalidValue = Ember.get(_this, "programFields.".concat(error.field));
                  invalidFieldValues.pushObject(invalidValue);
                  Ember.set(_this, "invalid".concat(error.field), invalidFieldValues);
                  Ember.set(_this, "invalid".concat(error.field, "Message"), error.message);
                } else {
                  Ember.set(userProgram, 'generalErrorMessage', error.message);
                }
              });
            });
          }
        });
      },
      setTagValue: function setTagValue(tag, slice) {
        Ember.set(tag, 'selected', slice);
      },
      validateSlice: function validateSlice(tag) {
        Ember.set(tag, 'validateSlice', true);
      }
    },
    // -------------------------------------------------------------------------
    // Events
    onSubmit: function onSubmit(userProgram, data) {},
    // eslint-disable-line no-unused-vars
    onDecline: function onDecline(userProgram) {},
    // eslint-disable-line no-unused-vars
    onLeave: function onLeave() {},
    // -------------------------------------------------------------------------
    // Properties
    AGGREGATE: _corporateProgramFeatures.AGGREGATE,
    AGGREGATE_INDIVIDUAL: _corporateProgramFeatures.AGGREGATE_INDIVIDUAL,
    AGGREGATE_OPT_OUT: _corporateProgramFeatures.AGGREGATE_OPT_OUT,
    IS_MOBILE: _userAgent.IS_MOBILE,
    invalidfirstName: null,
    invalidlastName: null,
    invaliddateOfBirth: null,
    invalidcorporateEmail: null,
    invalidgender: null,
    invalidphoneNumber: null,
    invalidemployeeId: null,
    invalidjobTitle: null,
    invalidadditionalInfo: null,
    invalidfirstNameMessage: null,
    invalidlastNameMessage: null,
    invaliddateOfBirthMessage: null,
    invalidcorporateEmailMessage: null,
    invalidgenderMessage: null,
    invalidphoneNumberMessage: null,
    invalidemployeeIdMessage: null,
    invalidjobTitleMessage: null,
    invalidadditionalInfoMessage: null,
    generalErrorMessage: null,
    init: function init() {
      Ember.set(this, 'invalidfirstName', []);
      Ember.set(this, 'invalidlastName', []);
      Ember.set(this, 'invaliddateOfBirth', []);
      Ember.set(this, 'invalidcorporateEmail', []);
      Ember.set(this, 'invalidgender', []);
      Ember.set(this, 'invalidphoneNumber', []);
      Ember.set(this, 'invalidemployeeId', []);
      Ember.set(this, 'invalidjobTitle', []);
      Ember.set(this, 'invalidadditionalInfo', []);

      this._super.apply(this, arguments);
    },

    /**
     * Alias to userPorgrams.programFields
     * @returns {Object}
     */
    programFields: Ember.computed.alias('userProgram.programFields'),

    /**
     * Bulk fields for rendering HTML in cw-current-program-form template
     * @returns {Object}
     * */
    fieldsList: Ember.computed('userProgram.program.bulkFields.fields', function () {
      var program = Ember.get(this, 'userProgram.program');
      var fields = Ember.get(program, 'bulkFields.fields');
      return fields.filter(function (field) {
        return !field.prepopulate;
      }).map(function (field) {
        var preventEdit = field.eligibilityCheck;
        return _objectSpread({}, field, {
          preventEdit: preventEdit
        });
      });
    }),

    /**
     * Check for the presence of slices within any dimensions
     * @return {Boolean}
     */
    noDimensionHasSlices: Ember.computed('userProgram.dimensionsById', function () {
      var dimensionsById = Ember.get(this, 'userProgram.dimensionsById');

      if (Ember.isEmpty(dimensionsById)) {
        return true;
      } else {
        return !dimensionsById.some(function (elem) {
          return elem.slices.length > 0;
        });
      }
    }),

    /**
     * Find out if the current program has any selectable dropdown menus
     * If dimensions can be selected the update button should be enabled
     * @returns {Boolean}
     */
    selectMenusDisabled: Ember.computed.or('userProgram.program.prepopulateEnabled', 'noDimensionHasSlices'),

    /**
     * Used to disable the CW program edit form 'update' button
     * If all fields are disabled there should be nothing to submit
     * @returns {Boolean}
     */
    allFieldsDisabled: Ember.computed('fieldsList', 'selectMenusDisabled', function () {
      var fieldList = Ember.get(this, 'fieldsList');
      var selectMenusDisabled = Ember.get(this, 'selectMenusDisabled');
      var fields = fieldList.filter(function (field) {
        return field.enabled;
      });
      var allFieldsAreDisabled = fields.every(function (field) {
        return field.preventEdit;
      });
      return allFieldsAreDisabled && selectMenusDisabled;
    }),

    /**
     * One way access to to the program period's type.
     * @returns {String}
     */
    programType: Ember.computed.readOnly('userProgram.program.period.type'),

    /**
     *  One way access to programFields dateOfBirth property
     *  @returns {String|null}
     */
    birthdate: Ember.computed.readOnly('programFields.dateOfBirth'),

    /**
     * Computed property to retrieve year from birthdate.
     * @returns {String|null}
     */
    year: Ember.computed('birthdate', function () {
      var birthdate = Ember.get(this, 'birthdate');
      return birthdate ? birthdate.split('-')[0] : null;
    }),
    disabledSubmit: Ember.computed('validations.isInvalid', 'programFields', 'userProgram.program.bulkFields.fields', function () {
      var fields = Ember.get(this, 'userProgram.program.bulkFields.fields');
      var values = Ember.get(this, 'programFields');
      var isInvalid = Ember.get(this, 'validations.isInvalid');
      var fieldsBlank = false;
      fields.some(function (field) {
        if (!Ember.get(values, field.name) && Ember.get(field, 'enabled')) {
          // if field is enabled and it is blank
          fieldsBlank = true;
          return true;
        }
      });
      return isInvalid || fieldsBlank;
    }),
    mobileDateOfBirth: Ember.computed.readOnly('birthdate'),
    genders: Ember.computed(function () {
      return [{
        value: Gender.NOT_AVAILABLE,
        displayName: 'not_available'
      }, {
        value: Gender.MALE,
        displayName: 'male'
      }, {
        value: Gender.FEMALE,
        displayName: 'female'
      }];
    }),
    // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Keypress event handler from DOM events bubbled up from child nodes.
     * Using keyPress instead of keyUp because it's been observed that keyUp does not prevent submit method
     * from executing twice.
     * @param e
     */
    keyPress: function keyPress(e) {
      // Only submit when user presses enter key and submit is not disabled.
      if (e.keyCode === 13 && !Ember.get(this, 'disabledSubmit')) {
        this.submit(e);
      }
    },

    /**
     * Form submit event handler. Handles triggering the submitForm.
     * Allows `enter` key to submit the form.
     * Buttons with type=submit will also trigger this event.
     * @param {Object} e DOM event object.
     */
    submit: function submit(e) {
      e.preventDefault();

      var _EmberGetProperties = Ember.getProperties(this, 'userProgram', 'programFields'),
          userProgram = _EmberGetProperties.userProgram,
          programFields = _EmberGetProperties.programFields; // Trigger the submitForm action defined in this component.
      // Using sendAction would instead bubble up the action to the parent component or controller.


      this.send('submitForm', userProgram, programFields);
    },
    _createSliceIdsArray: function _createSliceIdsArray() {
      var dimensionsById = Ember.get(this, 'userProgram.dimensionsById');
      var sliceIds = [];

      if (Ember.isPresent(dimensionsById)) {
        dimensionsById.forEach(function (tag) {
          var selected = tag.selected;

          if (Ember.isPresent(selected)) {
            sliceIds.pushObject(selected.id);
          } else if (tag.slice) {
            sliceIds.pushObject(tag.slice.id);
          }
        });
      }

      return sliceIds;
    }
  });

  _exports.default = _default;
});