define("fitbit-site-ui/routes/settings/www-profile-settings", ["exports", "fitbit-site-ui/routes/settings/profile-settings"], function (_exports, _profileSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _profileSettings.default.extend({
    templateName: 'settings.profile-settings'
  });

  _exports.default = _default;
});