define("fitbit-site-ui/adapters/device/tracker/alarm", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    pathForType: function pathForType(modelName) {
      var path = 'devices/tracker/alarms';

      if (modelName === 'devices/tracker') {
        path = 'devices/tracker';
      }

      return path;
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query); // createRecord, updateRecord, and deleteRecord will append .json on their own.


      switch (requestType) {
        case 'findRecord':
        case 'createRecord':
        case 'updateRecord':
        case 'deleteRecord':
          break;

        default:
          url = url + '.json';
      }

      return url;
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._super(id, 'devices/tracker', snapshot) + '/alarms.json';
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var deviceID = snapshot.record.get('device.id');
      return this._super('devices/tracker', snapshot) + "/".concat(deviceID, "/alarms.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var deviceID = snapshot.record.get('device.id');
      return this._super(deviceID, 'devices/tracker', snapshot) + "/alarms/".concat(id, ".json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var deviceID = snapshot.record.get('device.id');
      return this._super(deviceID, 'devices/tracker', snapshot) + "/alarms/".concat(id, ".json");
    },

    /**
     * Override deleteRecord because the original does not send data and the API expects data.
     * This is basically a copy of updateRecord with changes to use `DELETE` method and `deleteRecord`.
     * TODO: Update when ember-data@2.7 is updated because 2.7 introduces a better way to construct a request.
     * @param store
     * @param type
     * @param snapshot
     * @returns {*}
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');
      return this.ajax(url, 'DELETE', {
        data: data
      });
    }
  });

  _exports.default = _default;
});