define("fitbit-site-ui/serializers/health-programs/admin/program", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/constants/program-service"], function (_exports, _application, _programService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'health-programs/admin/program';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'program';
    },
    _mapArrayToSet: function _mapArrayToSet(arr) {
      return arr.reduce(function (acc, prop) {
        acc.add(prop);
        return acc;
      }, new Set());
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var program = Ember.get(payload, 'program');
      program.visibleDiscoverBundles = this._mapArrayToSet(program.visibleDiscoverBundles);

      if (program.programVersions) {
        Ember.set(program, 'programVersions', program.programVersions.sortBy('versionName'));
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var programs = Ember.get(payload, 'programs');
      programs.forEach(function (p) {
        p.visibleDiscoverBundles = _this._mapArrayToSet(p.visibleDiscoverBundles);

        if (p.programVersions) {
          p.programVersions.forEach(function (v) {
            return _this.setVersionService(v);
          });
          Ember.set(p, 'programVersions', p.programVersions.sortBy('versionName'));
        }
      });
      Ember.set(payload, 'programs', programs.sortBy('id'));
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var payload = this._super.apply(this, arguments);

      payload.visibleDiscoverBundles = Array.from(payload.visibleDiscoverBundles);
      delete payload.programVersions;
      return payload;
    },
    setVersionService: function setVersionService(version) {
      if (!version.serviceRole) {
        version.serviceRole = 'health-programs';
      }

      if (!version.serviceEnv) {
        version.serviceEnv = 'qa2';
      }

      if (version.serviceEnv in _programService.GCP_ENVS) {
        Ember.set(version, 'auroraHost', _programService.AURORA_GCP);
      } else {
        Ember.set(version, 'auroraHost', _programService.AURORA_SL);
      }
    }
  });

  _exports.default = _default;
});