define("fitbit-site-ui/serializers/time-series/heart", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'time-series/heart';
    },
    getPayloadKey: function getPayloadKey() {
      return 'time-series/heart';
    },
    attrs: {
      activitiesHeart: 'activities-heart',
      activitiesHeartIntraday: 'activities-heart-intraday'
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var activitiesHeart = payload['activities-heart'][0];
      var activitiesHeartIntraday = payload['activities-heart-intraday'];
      var data = {
        id: id,
        type: 'activities-heart',
        attributes: {
          'activities-heart': activitiesHeart,
          'activities-heart-intraday': activitiesHeartIntraday
        }
      };
      return this._super(store, primaryModelClass, {
        data: data
      }, id, requestType);
    }
  });

  _exports.default = _default;
});