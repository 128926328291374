define("fitbit-site-ui/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hTXtlGPG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n        \"],[7,\"form\"],[9],[0,\"\\n            \"],[7,\"strong\"],[9],[1,[27,\"format\",[\"login_with_fitbit\"],null],false],[10],[0,\"\\n\\n            \"],[7,\"fieldset\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"id\",\"class\",\"type\",\"placeholder\",\"value\"],[\"identification\",\"form-control\",\"email\",[27,\"format\",[\"username_placeholder\"],null],[23,[\"identification\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"fieldset\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"id\",\"class\",\"placeholder\",\"type\",\"value\"],[\"password\",\"form-control\",[27,\"format\",[\"password_placeholder\"],null],\"password\",[23,[\"password\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"button\"],[11,\"class\",\"button submit\"],[11,\"type\",\"submit\"],[9],[1,[27,\"format\",[\"login_button_label\"],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"                \"],[7,\"p\"],[9],[1,[21,\"errorMessage\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[3,\"action\",[[22,0,[]],\"fitbitPasswordOauth2Login\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/login.hbs"
    }
  });

  _exports.default = _default;
});