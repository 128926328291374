define("fitbit-site-ui/controllers/settings/resend-email", ["exports", "fitbit-site-ui/constants/email-resend-state"], function (_exports, _emailResendState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    responseState: null,
    VERIFIED: _emailResendState.VERIFIED,
    FAILURE: _emailResendState.FAILURE
  });

  _exports.default = _default;
});