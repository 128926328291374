define("fitbit-site-ui/routes/launch", ["exports", "snapdragon-common/mixins/fit-deeplink-nav", "snapdragon-common/constants/user-agent"], function (_exports, _fitDeeplinkNav, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // GDPR-related deeplinking
  var _default = Ember.Route.extend(_fitDeeplinkNav.default, {
    IS_MOBILE: _userAgent.IS_MOBILE,
    _navigateToWebDashboard: function _navigateToWebDashboard() {
      return location.replace('/');
    },

    /**
     * If the user is on desktop, replace them to web dashboard
     * If the user is on mobile, attempt to deeplink them to the app dashboard, otherwise
     * after a small amount of time, send them to web dashboard
     * @param transition
     */
    beforeModel: function beforeModel() {
      var _this = this;

      var IS_MOBILE = Ember.get(this, 'IS_MOBILE');

      if (IS_MOBILE) {
        // Attempt to deeplink
        Ember.set(this, 'deeplinkPath', 'dashboard');
        return new Ember.RSVP.Promise(function (resolve) {
          setTimeout(function () {
            resolve();
          });
        }).then(function () {
          return _this._navigateToWebDashboard();
        });
      } else {
        return this._navigateToWebDashboard();
      }
    }
  });

  _exports.default = _default;
});