define("fitbit-site-ui/models/snore-log", ["exports", "ember-data", "snapdragon-common/utils/unit-converter"], function (_exports, _emberData, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var minutesToHoursAndMinutes = _unitConverter.default.minutesToHoursAndMinutes;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    moment: Ember.inject.service(),
    //id: startTime === date === id,
    startTime: attr('date'),
    endTime: attr('date'),
    snoreDuration: attr(),
    // Snore duration in minutes
    severity: attr('string'),
    date: Ember.computed.alias('endTime'),

    /**
     * Snore duration object
     * @returns Object - { hours: int, minutes: int }
     */
    displaySnoreDuration: Ember.computed('snoreDurtion', function () {
      var snoreDuration = Ember.get(this, 'snoreDuration');
      return minutesToHoursAndMinutes(snoreDuration);
    }),

    /**
     * Sleep duration in minutes
     */
    sleepDuration: Ember.computed('startTime', 'endTime', function () {
      var moment = Ember.get(this, 'moment');
      var startTimeMoment = moment.moment(Ember.get(this, 'startTime'));
      var endTimeMoment = moment.moment(Ember.get(this, 'endTime'));
      return endTimeMoment.diff(startTimeMoment, 'minutes');
    }),
    formattedDate: Ember.computed('date', function () {
      var date = Ember.get(this, 'date');
      var moment = Ember.get(this, 'moment');
      return moment.moment(date).format('ll');
    }),
    displayTimeAsleep: Ember.computed('sleepDuration', function () {
      var minutesAsleep = Ember.get(this, 'sleepDuration');
      return minutesToHoursAndMinutes(minutesAsleep);
    }),
    percentageSnoring: Ember.computed('snoreDuration', 'sleepDuration', function () {
      var snoreDuration = Ember.get(this, 'snoreDuration');
      var sleepDuration = Ember.get(this, 'sleepDuration');
      return snoreDuration / sleepDuration;
    })
  });

  _exports.default = _default;
});