define("fitbit-site-ui/routes/settings/resend-email", ["exports", "snapdragon-common/mixins/email-actions", "ember-ajax/errors", "fitbit-site-ui/constants/email-resend-state"], function (_exports, _emailActions, _errors, _emailResendState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * This route will check the user's email verification state and
   * resend the 'verify email' message to unverified users.
   */
  var _default = Ember.Route.extend(_emailActions.default, {
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    titleToken: function titleToken() {
      var i18n = Ember.get(this, 'i18n');
      return i18n.formatByNamespace('route_title', 'route.email-resend');
    },
    afterModel: function afterModel() {
      var _this = this,
          _arguments = arguments;

      Ember.set(this, 'breadCrumb', {
        title: this.titleToken()
      });
      var controller = this.controllerFor('settings.resend-email');
      var userNeedsVerification = Ember.get(this, 'currentUser.emailVerificationRequired');

      if (userNeedsVerification === false) {
        Ember.set(controller, 'responseState', _emailResendState.VERIFIED);
        return;
      }

      return this._resendEmailAddress().then(function () {
        Ember.set(controller, 'responseState', _emailResendState.SUCCESS);
      }).catch(function (e) {
        if ((0, _errors.isBadRequestError)(e)) {
          Ember.set(controller, 'responseState', _emailResendState.VERIFIED);
          var formatter = Ember.get(_this, 'i18n');
          var verifiedText = Ember.get(e, 'payload.errors.0.message') || formatter.formatByNamespace('already_verified', 'route.email-resend');
          Ember.set(controller, 'verifiedText', verifiedText);
        } else {
          var error = Ember.get(e, 'payload.errors.0.message');
          Ember.set(controller, 'failureText', error);
          var flashMessages = Ember.get(_this, 'flashMessages');
          flashMessages.alert(error);
          Ember.set(controller, 'responseState', _emailResendState.FAILURE);
        }
      }).finally(function () {
        return _this._super.apply(_this, _toConsumableArray(_arguments));
      });
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var id = Ember.get(this, 'currentUser.id');
      return {
        profile: store.peekRecord('profile', id)
      };
    }
  });

  _exports.default = _default;
});