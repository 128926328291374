define("fitbit-site-ui/models/time-series/distance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    activitiesDistance: _emberData.default.attr(),
    activitiesDistanceIntraday: _emberData.default.attr(),
    date: Ember.computed.alias('activitiesDistance.0.dateTime'),
    summaryVal: Ember.computed.alias('activitiesDistance.0.value'),
    dataset: Ember.computed.alias('activitiesDistanceIntraday.dataset')
  }); // Sample URL & Data
  // GET /1/user/-/activities/floors/date/2016-05-07/1d/1sec/time/12:20/12:45.json
  // Unknown possibly sensitive origin
  // {
  //   "activities-distance":  [
  //      {
  //       "dateTime": "2016-05-07",
  //       "value": "7.081259999999999"
  //     }
  //   ],
  //   "activities-distance-intraday":  {
  //     "dataset":  [
  //        {
  //         "time": "00:00:00",
  //         "value": 0
  //       },
  //        {
  //         "time": "00:01:00",
  //         "value": 0.005289999768137932
  // 		 },
  //  	...


  _exports.default = _default;
});