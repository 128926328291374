define("fitbit-site-ui/serializers/friend/friend-invitation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'person') {
        return this._super('friend/leaderboard/person');
      }

      return this._super(key);
    },
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var data = Ember.get(payload, 'data') || [];
      data.forEach(function (invite) {
        var type = Ember.get(invite, 'type');
        var source = Ember.get(invite, 'attributes.source'); // If you have sent an invite to an email without an associated user, or the invite does not
        // have a user relationship, set it as a sent-email-invitation which doesn't require a user

        if (type === 'sent-invite' && source === 'EMAIL_INVITATION' || !Ember.get(invite, 'relationships')) {
          Ember.set(invite, 'type', 'friend/sent-email-invitation');
        } else {
          Ember.set(invite, 'type', "friend/".concat(type)); // friend/sent-invitation or friend/inbox-invitation
        }

        if (Ember.get(invite, 'relationships')) {
          Ember.set(invite, 'relationships.user.data.type', 'friend/leaderboard/person');
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});