define("fitbit-site-ui/serializers/user-notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    moment: Ember.inject.service(),
    attrs: {
      sentBy: 'authorId'
    },
    supportedTypes: Object.freeze(['LEADERBOARD_MESSAGE', 'LEADERBOARD_CHEER', 'LEADERBOARD_TAUNT', 'FRIEND_REQUEST', 'CORPORATE_PARTNER', 'CORPORATE_MESSAGE']),
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      if (typeof payload === 'undefined' || payload === null) {
        payload = [];
      } else {
        var moment = Ember.get(this, 'moment');
        var supportedTypes = Ember.get(this, 'supportedTypes');
        var notifications = payload.notifications || [];
        payload = notifications.reduce(function (filtered, notification) {
          var id = notification.id,
              time = notification.creationTime,
              isRead = notification.isRead,
              message = notification.message,
              notificationType = notification.notificationType,
              authorId = notification.sender; // exclude unsupported types

          if (supportedTypes.indexOf(notificationType) < 0) {
            return filtered;
          }

          var momentTime = moment.moment(time);
          filtered.push({
            id: id,
            time: momentTime.valueOf(),
            timeRelative: momentTime.fromNow(),
            isRead: isRead,
            message: message,
            notificationType: Ember.String.dasherize(notification.notificationType),
            authorId: authorId
          });
          return filtered;
        }, []);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});