define("fitbit-site-ui/models/profile", ["exports", "ember-data", "fitbit-site-ui/mixins/weight-unit"], function (_exports, _emberData, _weightUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend(_weightUnit.default, {
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    linkedGoogleAccountStatus: attr('boolean', {
      defaultValue: false
    }),
    aboutMe: attr('string'),
    age: attr('number'),
    autoStrideEnabled: _emberData.default.attr('string', {
      defaultValue: 'disabled'
    }),
    //otherwise true or false
    avatar: attr('string'),
    avatar150: attr('string'),
    averageDailySteps: attr('number'),
    clockTimeDisplayFormat: attr('string', {
      defaultValue: '12hour'
    }),
    corporate: attr('boolean', {
      defaultValue: false
    }),
    corporateAdmin: attr('boolean', {
      defaultValue: false
    }),
    country: attr('string'),
    coverPhoto: belongsTo('cover-photo'),
    state: attr('string'),
    city: attr('string'),
    customHeartRateZone: attr({
      defaultValue: function defaultValue() {
        return {
          max: 30,
          min: 31,
          enabled: false
        };
      }
    }),
    customMaxHeartRate: attr({
      defaultValue: function defaultValue() {
        return {
          maxHeartRate: 30,
          enabled: false
        };
      }
    }),
    dateOfBirth: attr('string'),
    devices: belongsTo('user-device-list'),
    achievementSummary: belongsTo('user-achievement-summary', {
      async: false,
      inverse: 'user'
    }),
    displayName: attr('string', {
      defaultValue: ''
    }),
    distanceUnit: attr('string'),
    email: attr('string'),
    emailVerificationRequired: attr('boolean'),
    encodedId: attr('string'),
    exercisePreferences: belongsTo('device/tracker/exercise-preferences'),
    features: belongsTo('feature'),
    foodsLocale: attr('string'),
    fullName: attr('string', {
      defaultValue: ''
    }),
    firstName: attr('string', {
      defaultValue: ''
    }),
    lastName: attr('string', {
      defaultValue: ''
    }),
    gender: attr('string'),
    glucoseUnit: attr('string'),
    height: attr('number'),
    heightUnit: attr('string'),
    inactivityAlerts: attr(),
    locale: attr('string'),
    memberSince: attr('date'),
    offsetFromUTCMillis: attr('number'),
    pendingDeletion: attr('boolean'),
    sedentaryTime: attr(),
    sleepGoal: belongsTo('sleep-goal', {
      async: true,
      inverse: 'user'
    }),
    sleepTracking: attr('string', {
      defaultValue: 'Normal'
    }),
    startDayOfWeek: attr('string'),
    strideLengthRunning: attr('number'),
    strideLengthWalking: attr('number'),
    timezone: attr('string'),
    topBadges: hasMany('badge'),
    waterUnit: attr('string'),
    waterUnitName: attr('string'),
    visibleUser: attr('boolean', {
      defaultValue: false
    }),
    // In grams, set by Accept-Language: x_machine header
    weight: attr('number'),
    weightUnit: attr('string'),
    friends: belongsTo('friend', {
      async: true,
      inverse: 'user'
    }),
    isInUS: Ember.computed.equal('country', 'US'),
    isMetricHeight: Ember.computed.equal('heightUnit', 'METRIC'),
    isUSWeight: Ember.computed.equal('weightUnit', 'en_US'),
    isUKWeight: Ember.computed.equal('weightUnit', 'en_GB'),
    isMetricWeight: Ember.computed.equal('weightUnit', 'METRIC'),
    isClock12: Ember.computed.equal('clockTimeDisplayFormat', '12hour').readOnly(),
    weightInKilograms: Ember.computed('weight', function () {
      var grams = Ember.get(this, 'weight');
      return this.gramsToKilograms(grams);
    }).readOnly(),
    weightInPounds: Ember.computed('weight', function () {
      var grams = Ember.get(this, 'weight');
      return this.gramsToPounds(grams);
    }).readOnly(),
    weightInStonesAndPounds: Ember.computed('weight', function () {
      var grams = Ember.get(this, 'weight');
      return this.gramsToStonesAndPounds(grams);
    }).readOnly(),
    hasAutoStride: Ember.computed('autoStrideEnabled', function () {
      return Ember.get(this, 'autoStrideEnabled') !== 'disabled';
    }),
    hasCustomHeartRateZone: Ember.computed('customHeartRateZone.{enabled}', function () {
      return Ember.get(this, 'customHeartRateZone').enabled;
    }),
    hasCustomMaxHeartRate: Ember.computed('customMaxHeartRate.{enabled}', function () {
      return Ember.get(this, 'customMaxHeartRate').enabled;
    }),
    offsetFromUTCSeconds: Ember.computed('offsetFromUTCMillis', function () {
      return Ember.get(this, 'offsetFromUTCMillis') / 1000;
    }),
    offsetFromUTCMinutes: Ember.computed('offsetFromUTCSeconds', function () {
      return Ember.get(this, 'offsetFromUTCSeconds') / 60;
    }),
    offsetFromUTCHours: Ember.computed('offsetFromUTCMinutes', function () {
      return Ember.get(this, 'offsetFromUTCMinutes') / 60;
    }),
    shouldShowFirstAndLast: Ember.computed('firstName', function () {
      var firstName = Ember.get(this, 'firstName');
      var browserLocaleEN = Ember.isPresent(Ember.get(this, 'i18n.browserLocale').match(/en_/i));
      return firstName && browserLocaleEN;
    }),

    /**
     * Requests account deletion. User is sent an email in which they can confirm to delete account.
     * @param password
     */
    deleteAccount: function deleteAccount(password) {
      var ajax = Ember.get(this, 'ajax');
      return ajax.post('/account/delete.json', {
        data: {
          password: password
        }
      });
    },
    familyAccounts: _emberData.default.hasMany('family-account', {
      async: false,
      inverse: 'user'
    }),
    childrenIds: Ember.computed('familyAccounts.[]', function () {
      var childrenAccounts = [];
      var familyAccounts = Ember.get(this, 'familyAccounts');

      if (!familyAccounts) {
        return childrenAccounts;
      }
      /**
       * Given a set of family-account models associated with the user,
       * find all of the child ids within the corresponding family models
       * @param familyAccounts
       * @returns {Array<string>}
       * @private
       */


      familyAccounts.forEach(function (familyAccount) {
        if (Ember.get(familyAccount, 'role') === 'OWNER') {
          var members = Ember.get(familyAccount, 'family.members');

          if (members) {
            childrenAccounts = childrenAccounts.concat(members.filter(function (member) {
              return Ember.get(member, 'role') === 'CHILD';
            }).map(function (child) {
              return Ember.get(child, 'user.encodedId');
            }));
          }
        }
      });
      return childrenAccounts;
    })
  });

  _exports.default = _default;
});