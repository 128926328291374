define("fitbit-site-ui/adapters/activity", ["exports", "fitbit-site-ui/adapters/application", "snapdragon-common/errors/client-error"], function (_exports, _application, _clientError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    namespace: '1.1/user/-',
    handleResponse: function handleResponse(status, headers, body) {
      // 400 Error
      if (status >= 400 && status < 500) {
        return new _clientError.default();
      } else {
        return this._super(status, headers, body);
      }
    },
    headers: Ember.computed('i18n.userLocale', 'currentUser.distanceUnit', function () {
      var formatter = Ember.get(this, 'i18n');
      var headers = this.createHeaders();
      return Object.assign(headers, {
        'Accept-Locale': formatter.get('userLocale'),
        'Accept-Language': Ember.get(this, 'currentUser.distanceUnit')
      });
    }),
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return "".concat(this._super(null, null, snapshot), "/activities/").concat(id, ".json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return "".concat(this._super(null, null, snapshot), "/activities/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});