define("fitbit-site-ui/components/webview-support", ["exports", "snapdragon-common/components/webview-support"], function (_exports, _webviewSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _webviewSupport.default;
    }
  });
});