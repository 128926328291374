define("fitbit-site-ui/templates/components/fitbit-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZm2Lc2Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"radio-button\",null,[[\"value\",\"groupValue\",\"radioClass\",\"changed\"],[[23,[\"value\"]],[23,[\"groupValue\"]],[23,[\"radioClass\"]],[27,\"action\",[[22,0,[]],\"valueChanged\"],null]]],{\"statements\":[[0,\"        \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/fitbit-radio-button.hbs"
    }
  });

  _exports.default = _default;
});