define("fitbit-site-ui/templates/components/device-battery-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WqKzeL0f",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fit-glyph\",null,[[\"type\"],[[23,[\"level\"]]]],{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"path1\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"show-for-sr\"],[9],[1,[27,\"format-by-namespace\",[\"battery_level_icon\",\"templates.components.device-settings\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-battery-icon.hbs"
    }
  });

  _exports.default = _default;
});