define("fitbit-site-ui/templates/onboarding/scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BoXr245W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"src\",\"/assets/images/onboarding/scale_header_icon_select@2x.png\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"padded-content\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"scale-invite-header\"],[9],[1,[27,\"format-by-namespace\",[\"select_scale_icon\",\"templates.onboarding\"],[[\"deviceName\"],[[23,[\"deviceName\"]]]]],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"scale-invite-sub-header\"],[9],[1,[27,\"format-by-namespace\",[\"select_scale_icon_desc\",\"templates.onboarding\"],null],false],[10],[0,\"\\n\\n    \"],[1,[27,\"scale-user-icon-select\",null,[[\"icons\",\"users\",\"onSave\"],[[23,[\"model\",\"scale\",\"iconsSupported\"]],[23,[\"model\",\"scale\",\"userList\",\"users\"]],[27,\"action\",[[22,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/onboarding/scales.hbs"
    }
  });

  _exports.default = _default;
});