define("fitbit-site-ui/adapters/user-message", ["exports", "fitbit-site-ui/adapters/application", "fitbit-site-ui/constants/user-notifications"], function (_exports, _application, _userNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/conversations',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super(), "/-/messages.json?limit=").concat(_userNotifications.MAX_MESSAGES_REQUESTED);
    }
  });

  _exports.default = _default;
});