define("fitbit-site-ui/serializers/food-meal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    /**
     * Convert `meals` and `meal` payload keys to `food-meal` modelName.
     *
     * @override
     * @param {string} modelName
     * @returns {string}
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(modelName) {
      if (modelName === 'meals' || modelName === 'meal') {
        return 'food-meal';
      }

      return this._super(modelName);
    },

    /**
     * Override the default so that serialized data is not placed under a root key in the hash.
     *
     * @override
     * @param {object} hash
     * @param {object} typeClass
     * @param {object} snapshot
     * @param {object} options
     */
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      Object.assign(hash, this.serialize(snapshot, options));
    }
  });

  _exports.default = _default;
});