define("fitbit-site-ui/components/profile-settings-stride-length", ["exports", "ember-cp-validations", "snapdragon-common/utils/unit-converter"], function (_exports, _emberCpValidations, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cmToInches = _unitConverter.default.cmToInches,
      inchesToCm = _unitConverter.default.inchesToCm;
  var Validations = (0, _emberCpValidations.buildValidations)({
    strideLengthWalkingCm: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lte: 600,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('stride_validations_cm', {
          min: 0,
          max: 600
        });
      })
    }),
    strideLengthRunningCm: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lte: 600,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('stride_validations_cm', {
          min: 0,
          max: 600
        });
      })
    }),
    strideLengthRunning: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lt: 237,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('stride_validations_in', {
          min: 0,
          max: 237
        });
      })
    }),
    strideLengthWalking: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lt: 237,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('stride_validations_in', {
          min: 0,
          max: 237
        });
      })
    })
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    autoStrideEnabled: Ember.computed.equal('profile.autoStrideEnabled', 'true'),
    strideLengthRunning: Ember.computed('strideLengthRunningCm', function () {
      return cmToInches(this.get('strideLengthRunningCm'));
    }),
    strideLengthWalking: Ember.computed('strideLengthWalkingCm', function () {
      return cmToInches(this.get('strideLengthWalkingCm'));
    }),
    keyDown: function keyDown(e) {
      // If it's the enter key, do a validation check before bubbling up.
      if (e.keyCode === 13) {
        var _this$validateSync = this.validateSync(),
            validations = _this$validateSync.validations;

        if (!validations.get('isValid')) {
          e.preventDefault();
        }
      }
    },
    actions: {
      autoStrideToggled: function autoStrideToggled(value) {
        this.set('profile.autoStrideEnabled', value);
      },
      strideChanged: function strideChanged() {
        var strideChangedFnc = this.get('onStrideChanged');

        var _this$getProperties = this.getProperties('strideLengthRunning', 'strideLengthWalking'),
            strideLengthRunning = _this$getProperties.strideLengthRunning,
            strideLengthWalking = _this$getProperties.strideLengthWalking;

        if (!this.get('profile.isMetricHeight')) {
          this.setProperties({
            strideLengthWalkingCm: inchesToCm(strideLengthWalking),
            strideLengthRunningCm: inchesToCm(strideLengthRunning)
          });
        }

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          // Notify that the stridelength change was valid or not.
          if (strideChangedFnc) {
            var isValid = validations.get('isValid');
            strideChangedFnc(isValid);
          }
        });
      }
    }
  });

  _exports.default = _default;
});