define("fitbit-site-ui/models/device-type", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    assetBundle: (0, _attr.default)('string'),
    assetsBaseUrl: (0, _attr.default)('string'),
    assetsToken: (0, _attr.default)('string'),
    bluetoothSupported: (0, _attr.default)('boolean'),
    caption: (0, _attr.default)('string'),
    clockFacesSupported: (0, _attr.default)(),
    colorImageUrl: (0, _attr.default)('string'),
    descriptionBody: (0, _attr.default)('string'),
    descriptionImageUrl: (0, _attr.default)('string'),
    descriptionTitle: (0, _attr.default)('string'),
    deviceCtor: (0, _attr.default)('string'),
    deviceFeatureSupported: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    imageUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    primaryGoalsSupported: (0, _attr.default)(),
    productIds: (0, _attr.default)(),

    /**
     * Determine whether this device type needs to display 1x images.
     * @returns {boolean}
     */
    use1xImageRes: Ember.computed.match('name', /alta/i)
  });

  _exports.default = _default;
});