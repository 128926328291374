define("fitbit-site-ui/adapters/time-series/heart", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    pathForType: function pathForType(modelName) {
      return 'activities/heart/date/' + modelName + '/1d/1min';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = Ember.get(snapshot, 'adapterOptions.heartRateUrl');

      if (!url) {
        url = this._super.apply(this, arguments);
      }

      return url;
    }
  }); // GET /1/user/-/activities/heart/date/2016-05-07/1d/1min.json
  // GET /1/user/-/activities/heart/date/2016-05-07/1d/1sec.json


  _exports.default = _default;
});