define("fitbit-site-ui/adapters/health-programs/admin/program", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user/-/hp/admin',
    methodPostForTypes: Ember.computed(function () {
      return [];
    }),
    pathForType: function pathForType() {
      return 'programs';
    },
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '.json';
    }
  });

  _exports.default = _default;
});