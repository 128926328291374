define("fitbit-site-ui/models/device/tracker/inactivity-alerts", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "fitbit-site-ui/mixins/device-time-format"], function (_exports, _model, _attr, _relationships, _deviceTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_deviceTimeFormat.default, {
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    sedentaryTime: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    inactivityAlerts: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    device: (0, _relationships.belongsTo)('user-device', {
      async: false
    }),
    startTime: Ember.computed.alias('sedentaryTime.startTime'),
    duration: Ember.computed.alias('sedentaryTime.duration'),
    //The 12 hour time without meridiem
    startTime12: Ember.computed('sedentaryTime.startTime', function () {
      var momentService = this.get('moment');
      return momentService.moment(this.get('startTime'), this.get('CLOCK_24_TIME_FORMAT')).format(this.get('CLOCK_12_TIME_FORMAT'));
    }),
    //12 hour time with meridiem
    startTimeDisplay12: Ember.computed('sedentaryTime.startTime', function () {
      var formatter = this.get('i18n');
      var momentService = this.get('moment');
      var timeFormat = momentService.moment().localeData().longDateFormat('LT');
      var clockFormat = formatter.formatTo12Hours(timeFormat, momentService.get('locale'));
      return momentService.moment(this.get('sedentaryTime.startTime'), clockFormat).format(clockFormat);
    }),
    startTimeDisplay24: Ember.computed.alias('sedentaryTime.startTime'),
    endTime: Ember.computed('sedentaryTime.duration', function () {
      var momentService = this.get('moment');
      var startTime = momentService.moment(this.get('sedentaryTime.startTime'), this.get('CLOCK_24_TIME_FORMAT')),
          duration = this.get('sedentaryTime.duration');
      startTime.add(duration, 'h');
      return startTime.format(this.get('CLOCK_24_TIME_FORMAT'));
    }),
    endTime12: Ember.computed('sedentaryTime.duration', function () {
      var momentService = this.get('moment');
      return momentService.moment(this.get('endTime'), this.get('CLOCK_24_TIME_FORMAT')).format(this.get('CLOCK_12_TIME_FORMAT'));
    }),
    endTimeDisplay12: Ember.computed('endTime', function () {
      var formatter = this.get('i18n');
      var momentService = this.get('moment');
      var timeFormat = momentService.moment().localeData().longDateFormat('LT');
      var clockFormat = formatter.formatTo12Hours(timeFormat, momentService.get('locale'));
      return momentService.moment(this.get('endTime'), clockFormat).format(clockFormat);
    }),
    endTimeDisplay24: Ember.computed.alias('endTime'),
    startTimeAmPm: Ember.computed('sedentaryTime.startTime', function () {
      var momentService = this.get('moment');
      return momentService.moment(this.get('startTime'), this.get('CLOCK_24_TIME_FORMAT')).format('A');
    }),
    endTimeAmPm: Ember.computed('endTime', function () {
      var momentService = this.get('moment');
      return momentService.moment(this.get('endTime'), this.get('CLOCK_24_TIME_FORMAT')).format('A');
    })
  });

  _exports.default = _default;
});