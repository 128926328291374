define("fitbit-site-ui/routes/settings/privacy-settings", ["exports", "fitbit-site-ui/mixins/prevent-flash-duplicate"], function (_exports, _preventFlashDuplicate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_preventFlashDuplicate.default, {
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    titleToken: function titleToken() {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('route_title', 'route.settings.privacy-settings');
    },
    model: function model() {
      return this.store.findAll('privacy-settings-ui-group');
    },
    afterModel: function afterModel() {
      this.set('breadCrumb', {
        title: this.titleToken()
      });
    },
    actions: {
      settingChanged: function settingChanged(setting) {
        var _this = this;

        var flashMessages = this.get('flashMessages'),
            formatter = this.get('i18n');
        setting.save().then(function () {
          var successMessage = formatter.format('privacy_settings_updated');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        }).catch(function () {
          var failureMessage = formatter.format('privacy_settings_error');

          _this._clearMessageFromQueue(failureMessage);

          flashMessages.alert(formatter.format('privacy_settings_error'));
        });
      }
    }
  });

  _exports.default = _default;
});