define("fitbit-site-ui/controllers/onboarding/get-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var imageMap = {
    apple: {
      cn: '/assets/images/onboarding/appstores/app-store-cn.png',
      de: '/assets/images/onboarding/appstores/app-store-de.png',
      es: '/assets/images/onboarding/appstores/app-store-es.png',
      fr: '/assets/images/onboarding/appstores/app-store-fr.png',
      gb: '/assets/images/onboarding/appstores/app-store-us.png',
      it: '/assets/images/onboarding/appstores/app-store-it.png',
      jp: '/assets/images/onboarding/appstores/app-store-jp.png',
      kr: '/assets/images/onboarding/appstores/app-store-kr.png',
      la: '/assets/images/onboarding/appstores/app-store-es.png',
      tw: '/assets/images/onboarding/appstores/app-store-tw.png',
      us: '/assets/images/onboarding/appstores/app-store-us.png'
    },
    google: {
      cn: '/assets/images/onboarding/appstores/googleplay-cn.png',
      de: '/assets/images/onboarding/appstores/googleplay-de.png',
      es: '/assets/images/onboarding/appstores/googleplay-es.png',
      fr: '/assets/images/onboarding/appstores/googleplay-fr.png',
      gb: '/assets/images/onboarding/appstores/googleplay-us.png',
      it: '/assets/images/onboarding/appstores/googleplay-it.png',
      jp: '/assets/images/onboarding/appstores/googleplay-jp.png',
      kr: '/assets/images/onboarding/appstores/googleplay-kr.png',
      la: '/assets/images/onboarding/appstores/googleplay-es.png',
      tw: '/assets/images/onboarding/appstores/googleplay-tw.png',
      us: '/assets/images/onboarding/appstores/googleplay-us.png'
    },
    windows: {
      cn: '/assets/images/onboarding/appstores/WindowsStore-cn.png',
      de: '/assets/images/onboarding/appstores/WindowsStore-de.png',
      es: '/assets/images/onboarding/appstores/WindowsStore-es.png',
      fr: '/assets/images/onboarding/appstores/WindowsStore-fr.png',
      gb: '/assets/images/onboarding/appstores/WindowsStore-us.png',
      it: '/assets/images/onboarding/appstores/WindowsStore-it.png',
      jp: '/assets/images/onboarding/appstores/WindowsStore-jp.png',
      kr: '/assets/images/onboarding/appstores/WindowsStore-kr.png',
      la: '/assets/images/onboarding/appstores/WindowsStore-es.png',
      tw: '/assets/images/onboarding/appstores/WindowsStore-tw.png',
      us: '/assets/images/onboarding/appstores/WindowsStore-us.png'
    }
  };

  var _default = Ember.Controller.extend({
    queryParams: ['forward-url'],
    'forward-url': null,
    i18n: Ember.inject.service(),
    appleImageUrl: Ember.computed(function () {
      return imageMap.apple[this.get('appleLocaleCode')];
    }),
    googleImageUrl: Ember.computed(function () {
      return imageMap.google[this.get('appleLocaleCode')];
    }),
    windowsImageUrl: Ember.computed(function () {
      return imageMap.windows[this.get('appleLocaleCode')];
    }),
    appleLocaleCode: Ember.computed('i18n.userLanguageLocale', function () {
      var locale = this.get('i18n.userLanguageLocale');
      return locale.split('_')[1].toLowerCase();
    }),
    googleLocaleCode: Ember.computed('i18n.userLanguageLocale', function () {
      var locale = this.get('i18n.userLanguageLocale');
      return locale.split('_')[0];
    }),
    windowsLocaleCode: Ember.computed('i18n.userLanguageLocale', function () {
      var locale = this.get('i18n.userLanguageLocale');
      return locale.replace('_', '-');
    }),
    deviceName: Ember.computed.readOnly('model.device-name'),
    isBowie: Ember.computed(function () {
      return this.get('deviceName') === 'Aria Air';
    }),
    actions: {
      flowDone: function flowDone() {
        var forwardUrl = this.get('forward-url') || 'settings';
        this.transitionToRoute(forwardUrl);
      }
    }
  });

  _exports.default = _default;
});