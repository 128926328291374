define("fitbit-site-ui/serializers/cw-feature", ["exports", "fitbit-site-ui/serializers/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    attrs: {
      flags: {
        key: 'features'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'corporate-user-features':
          return 'cw-feature';

        default:
          return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});