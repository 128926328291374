define("fitbit-site-ui/components/remove-participation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Removes the wrapping element

    /**
     * On participation remove.
     */
    onParticipationRemove: function onParticipationRemove() {}
  });

  _exports.default = _default;
});