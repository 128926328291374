define("fitbit-site-ui/serializers/sleep-stat", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload.sleep // Form the record according to JSONAPI Spec
      .map(function (record) {
        var id = record.dateOfSleep,
            stages = record.stages,
            classic = record.classic;
        return {
          id: id,
          type: 'sleep-stat',
          attributes: {
            stages: stages || null,
            classic: classic || null
          },
          relationships: {
            sleepDate: {
              data: {
                id: id,
                type: 'sleep-date'
              }
            }
          }
        };
      });
      return this._super(store, primaryModelClass, {
        data: data
      }, id, requestType);
    }
  });

  _exports.default = _default;
});