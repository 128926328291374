define("fitbit-site-ui/metrics-adapters/fsc", ["exports", "ember-metrics/metrics-adapters/base", "snapdragon-common/helpers/titleize"], function (_exports, _base, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getFSCSettings = _exports.FEATURE_SNAPDRAGON = _exports.OWNER_SNAPDRAGON = _exports.EXPERIMENT_ACTIVATION = _exports.FEATURE_ENABLEMENT = _exports.SHOWN = _exports.SELECTED = _exports.TAPPED = _exports.VIEWED = void 0;

  var _DOMAINS, _CHANNELS;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* eslint-disable */
  var VIEWED = 'Viewed';
  _exports.VIEWED = VIEWED;
  var TAPPED = 'Tapped';
  _exports.TAPPED = TAPPED;
  var SELECTED = 'Selected';
  _exports.SELECTED = SELECTED;
  var SHOWN = 'Shown';
  _exports.SHOWN = SHOWN;
  var FEATURE_ENABLEMENT = 'Feature Enablement';
  _exports.FEATURE_ENABLEMENT = FEATURE_ENABLEMENT;
  var EXPERIMENT_ACTIVATION = 'Experiment Activation';
  _exports.EXPERIMENT_ACTIVATION = EXPERIMENT_ACTIVATION;
  var OWNER_SNAPDRAGON = 'Snapdragon';
  _exports.OWNER_SNAPDRAGON = OWNER_SNAPDRAGON;
  var FEATURE_SNAPDRAGON = 'Snapdragon';
  /* eslint-enable */

  _exports.FEATURE_SNAPDRAGON = FEATURE_SNAPDRAGON;
  var ENVIRONMENT = {
    INT: 'int',
    INT_LEGACY: 'qa2',
    STAGE: 'stage',
    STAGE_LEGACY: 'qa1',
    PROD: 'prod',
    PRODUCTION: 'production',
    TEST: 'test',
    DEVEL: 'development'
  };
  var DOMAINS = (_DOMAINS = {}, _defineProperty(_DOMAINS, ENVIRONMENT.INT, 'fitbit-int'), _defineProperty(_DOMAINS, ENVIRONMENT.INT_LEGACY, 'fitbit-int'), _defineProperty(_DOMAINS, ENVIRONMENT.STAGE, 'fitbit-stage'), _defineProperty(_DOMAINS, ENVIRONMENT.STAGE_LEGACY, 'fitbit-stage'), _defineProperty(_DOMAINS, ENVIRONMENT.PROD, 'fitbit'), _defineProperty(_DOMAINS, ENVIRONMENT.PRODUCTION, 'fitbit'), _DOMAINS);
  var CHANNELS = (_CHANNELS = {}, _defineProperty(_CHANNELS, ENVIRONMENT.INT, 'qa'), _defineProperty(_CHANNELS, ENVIRONMENT.INT_LEGACY, 'qa'), _defineProperty(_CHANNELS, ENVIRONMENT.STAGE, 'qa'), _defineProperty(_CHANNELS, ENVIRONMENT.STAGE_LEGACY, 'qa'), _defineProperty(_CHANNELS, ENVIRONMENT.PROD, 'prod'), _defineProperty(_CHANNELS, ENVIRONMENT.PRODUCTION, 'prod'), _CHANNELS);
  /**
   * Returns URL which points to FSC stats host endpoint depending on environment.
   * Use INT as a target environment by default
   *
   * @param {String} env
   * @returns {String}
   */

  var getFscHost = function getFscHost(env) {
    var domain = DOMAINS[env] || DOMAINS[ENVIRONMENT.INT];
    return "https://fsc.".concat(domain, ".com");
  };
  /**
   * Returns URL which points to fsc-web-client depending on environment
   * Use INT as a target environment by default
   *
   * @param {String} channel Name of the channel distributed to
   * @returns {String}
   */


  var getFscJsHost = function getFscJsHost(channel) {
    var domain = DOMAINS[channel] || DOMAINS[ENVIRONMENT.INT];
    var channelName = CHANNELS[channel] || CHANNELS[ENVIRONMENT.INT];
    return "https://corporate-webapps.".concat(domain, ".com/fsc-web-client/channels/").concat(channelName);
  };

  var getFSCSettings = function getFSCSettings(environment) {
    switch (environment) {
      case ENVIRONMENT.TEST:
      case ENVIRONMENT.DEVEL:
        return {
          host: null,
          jsHost: null
        };

      default:
        return {
          host: getFscHost(environment),
          jsHost: getFscJsHost(environment)
        };
    }
  };

  _exports.getFSCSettings = getFSCSettings;

  var shouldTrackEventsFor = function shouldTrackEventsFor(env) {
    return [ENVIRONMENT.INT_LEGACY, ENVIRONMENT.INT, ENVIRONMENT.STAGE_LEGACY, ENVIRONMENT.STAGE, ENVIRONMENT.PROD, ENVIRONMENT.PRODUCTION].includes(env);
  };
  /**
   * This adapter provides support of FSC service for `ember-metrics` library.
   *
   * Each FSC event should be sent with `owner` and `feature` information.
   * * `owner` should identify team that *owns* event (`Snapdragon`, `corporate-wellness`, etc)
   * * `feature` should identify functionality scope this event belongs to.
   *
   * There are two ways to emit FSC events for specific owner/feature.
   * 1. **For shared code.** (`snapdragon-common` engine, under sources under `app/` directory)
   *    `feature` and `owner` should be sent for each event using `metrics` service.
   *    ```
   *    metrics: service(),
   *
   *    onSomeAction() {
   *        ...
   *        this.metrics.trackPage({
   *            action: 'some-action',
   *            fscOptions: {
   *                feature: 'FHS Onboarding',
   *                owner: 'corporate-wellness',
   *            },
   *        });
   *    }
   *    ```
   *
   * 2. **On engine level.**
   *    Typically, each engine belongs to specific *feature*.
   *    Thereby, all FSC events should emit with common `feature`/`owner` fields.
   *
   *    To simplify sending of events, developer can use `feature-metrics` service.
   *    First of all, define `feature`/`owner` in `&lt;engine>/config/environment.js`.
   *    For example,
   *    ```
   *    const ENV = {
   *      modulePrefix: 'corporate-onboarding-engine',
   *      environment,
   *      fsc: {
   *          feature: 'FHS Onboarding',
   *          owner: 'corporate-wellness',
   *      },
   *    };
   *    ```
   *
   *    and use `feature-metrics` in this engine the same way as `metrics` service:
   *    ```
   *    metrics: service('feature-metrics'), // inject feature-metrics service here
   *
   *    onSomeAction() {
   *        ...
   *        this.metrics.trackPage({ action: 'some-action' });
   *    }
   *    ```
   *    All events sent in this engine using `feature-metrics` service will be sent
   *    for `FHS Onboarding` feature and `corporate-wellness` owner.
   */


  var _default = _base.default.extend({
    webviewComms: Ember.inject.service(),
    toStringExtension: function toStringExtension() {
      return 'fsc';
    },

    /**
     * When a snapdragon session is started in a webview events need to be sent immediately because when webview
     * is destroyed, the cached events are lost and may not be sent.
     */
    immediately: Ember.computed.alias('webviewComms.isSupported'),
    init: function init() {
      var _EmberGet = Ember.get(this, 'config'),
          environment = _EmberGet.environment,
          feature = _EmberGet.feature,
          fscHost = _EmberGet.fscHost,
          fscJsHost = _EmberGet.fscJsHost,
          owner = _EmberGet.owner,
          userId = _EmberGet.userId,
          userLocale = _EmberGet.userLocale,
          userTimezone = _EmberGet.userTimezone,
          _EmberGet$immediately = _EmberGet.immediately,
          immediatelyDefault = _EmberGet$immediately === void 0 ? false : _EmberGet$immediately,
          platform = _EmberGet.platform;

      window.FSC_JS_HOST = fscJsHost;
      var immediately = this.immediately || immediatelyDefault;

      if (shouldTrackEventsFor(environment)) {
        this._attachScriptTag();
      } else {
        // for local debugging of fsc events
        window.FSC = window.FSC || {
          send: console.log.bind(console, '[FSC]: '),
          // eslint-disable-line no-console
          init: function init() {}
        };
      }

      window.FSC.init({
        host: fscHost,
        clientId: 'Fitbit',
        immediately: immediately,
        defaultProps: {
          owner: owner,
          data: {
            environment: environment,
            feature: feature,
            locale: userLocale,
            user_id: userId,
            user_timezone: userTimezone,
            platform: platform
          }
        }
      });
    },

    /**
     * Uses TAPPED event type by default unless eventType passed in options
     * @param analyticsName
     * @param options
     */
    trackEvent: function trackEvent(options) {
      var eventType = Ember.get(options, 'eventType');

      this._track.apply(this, [eventType || TAPPED].concat(Array.prototype.slice.call(arguments)));
    },
    trackPage: function trackPage() {
      this._track.apply(this, [VIEWED].concat(Array.prototype.slice.call(arguments)));
    },
    willDestroy: function willDestroy() {
      var fscScriptTag = document.querySelector("script[src*=\"".concat(window.FSC_JS_HOST, "\"]"));

      if (fscScriptTag) {
        fscScriptTag.remove();
      }

      delete window.FSC_JS_HOST;
      delete window.FSC;
    },
    //#region PRIVATE

    /**
     * FSC _send takes two arguments, event and props, to overwrite specific default properties
     * you must call trackEvent or trackPage with the following format
     * {
     *   pageName,  // maps to the `view` property
     *   eventType, // maps to `event` property
     *
     *   payloadPropOne, // maps directly to a property in the to fsc event's 'props' object)
     *   payloadPropTwo, // maps directly to a property in the to fsc event's 'props' object)
     *
     *   fscOptions: {
     *      owner, // maps to `owner` property
     *      schema,    // maps to `schema` property
     *      feature, // maps to `feature` property
     *      element,   // maps to `element` property
     *
     *      data: {
     *        dataPropOne, // maps directly to a property in the fsc event's `data` object
     *        dataPropTwo, // maps directly to a property in the fsc event's `data` object
     *      }
     *   }
     * }
     *
     * ==========================================================================================
     *
     * The following arguments to _track will generate the the corresponding FSC event
     *
     * Arguments
     * _track('TAPPED', {
     *      pageName: 'Index',
     *      program_id: 'Snore Detect',
     *      fscOptions: {
     *          owner: 'premium-programs-platform',
     *          schema: 'schema-name',
     *          feature: 'Program',
     *          element: 'Submit Button',
     *          data: {
     *              browser_version: 'Chrome 66.1'
     *          }
     *      }
     * }
     *
     * FSC Event
     * {
     *   name: Program: Index Survey Submit Button - TAPPED // (name = `feature`: `view` `element` - `action`)
     *   schema: schema-name
     *   owner: premium-programs-platform
     *   payload: { program_id: 'Snore Detect' }
     *   data: { feature: "Program", view: "Index", element: 'Submit Button', action: "TAPPED", browser_version: 'Chrome 66.1', ... },
     *   (data includes environment, locale, user_id, user_timezone, screen_scale, screen_width, screen_height default)
     * }
     * @private
     */
    _track: function _track(action, _ref) {
      var pageName = _ref.pageName,
          fscOptions = _ref.fscOptions,
          payload = _objectWithoutProperties(_ref, ["pageName", "fscOptions"]);

      var props = {};
      var event = {
        action: action,
        view: (0, _titleize.titleize)(pageName)
      };

      if (fscOptions) {
        var owner = fscOptions.owner,
            schema = fscOptions.schema,
            feature = fscOptions.feature,
            element = fscOptions.element,
            data = fscOptions.data;

        if (feature) {
          event.feature = feature;
        } else {
          // eslint-disable-next-line no-console
          console.warn('FSC: no feature was specified');
        }

        if (schema) {
          event.schema = schema;
        }

        if (element) {
          event.element = element;
        }

        if (data) {
          props.data = data;
        }

        if (owner) {
          props.owner = owner;
        } else {
          // eslint-disable-next-line no-console
          console.warn('FSC: no owner was specified');
        }
      }

      this._send(event, _objectSpread({}, props, {
        payload: payload
      }));
    },
    _attachScriptTag: function _attachScriptTag() {
      /* eslint-disable */
      !function (n, e) {
        var _ = n.FSC = n.FSC || {};

        _.init = function __i() {
          _.init === __i ? _._i = arguments : _.init.apply(_, arguments);
        }, _.send = function __s() {
          _.send === __s ? ((_._s = _._s || []).push(arguments), (_._d = _._d || []).push(Date.now())) : _.send.apply(_, arguments);
        }, _.onReady = function __r() {
          _.onReady === __r ? (_._r = _._r || []).push(arguments) : _.onReady.apply(_, arguments);
        }, _._l = Date.now();
        var s = e.createElement('script'),
            t = e.getElementsByTagName('script')[0];
        s.async = !0, s.src = n.FSC_JS_HOST + '/fsc.js', t.parentNode.insertBefore(s, t);
      }(window, document);
      /* eslint-enable */
    },
    _send: function _send(event, props) {
      if (window.FSC) {
        return window.FSC.send(event, props);
      }
    }
  });

  _exports.default = _default;
});