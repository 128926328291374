define("fitbit-site-ui/models/food/logged-food", ["exports", "ember-data", "fitbit-site-ui/models/food/base-food"], function (_exports, _emberData, _baseFood) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseFood.default.extend({
    amount: _emberData.default.attr('number'),
    dateLastEaten: _emberData.default.attr('date', {
      defaultValue: null
    }),
    mealType: _emberData.default.attr('string', {
      defaultValue: 'Anytime'
    }),
    unitName: _emberData.default.attr('string'),
    unitPlural: _emberData.default.attr('string'),
    favorite: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});