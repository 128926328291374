define("fitbit-site-ui/adapters/user-device", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'devices';
    },
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '.json';
    },
    urlForFindAll: function urlForFindAll() {
      return this._super.apply(this, arguments) + '.json';
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return this._super.apply(this, arguments) + '.json';
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this._super(null, modelName, snapshot) + "/tracker/".concat(id, ".json");
    }
  });

  _exports.default = _default;
});