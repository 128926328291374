define("fitbit-site-ui/components/device-tap-gesture-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    change: function change() {
      var _this = this;

      var formatter = this.get('i18n');
      var flashMessages = this.get('flashMessages');
      this.get('settings').then(function (model) {
        return model.save();
      }).then(function () {
        var successMessage = formatter.format('device_setting_saved');

        _this._clearMessageFromQueue(successMessage);

        flashMessages.success(successMessage);
      }).catch(function () {
        var failureMessage = formatter.format('something_went_wrong');

        _this._clearMessageFromQueue(failureMessage);

        flashMessages.alert(formatter.format('something_went_wrong'));
      });
    }
  });

  _exports.default = _default;
});