define("fitbit-site-ui/serializers/time-series/activity/list", ["exports", "fitbit-site-ui/serializers/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    getPayloadKey: function getPayloadKey() {
      return 'time-series/activity/list';
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var activitiesList = payload.activities; // We'll be adding the payloadkey in the timeserisserializer class.

      var data = {
        id: id,
        list: activitiesList.map(function (list) {
          return list.startTime;
        })
      };

      var ret = this._super(store, primaryModelClass, data, id, requestType); // Construct the array of the list


      var newPayload = activitiesList.map(function (list) {
        return {
          activityIntraday: list,
          activityGoal: payload.goals,
          activitySummary: payload.summary,
          _id: list.startTime
        };
      });

      var resActivityDetails = this._normalizeActivityDetails(store, newPayload, id).data;

      ret.included = resActivityDetails;
      return ret;
    },
    _normalizeActivityDetails: function _normalizeActivityDetails(store, payload, id) {
      var activityDetailsModel = store.modelFor('time-series/activity');
      var activityDetailsSerializer = store.serializerFor(activityDetailsModel.modelName);
      return activityDetailsSerializer.normalizeArrayResponse(store, activityDetailsModel, {
        'time-series/activity': payload
      }, id);
    }
  });

  _exports.default = _default;
});