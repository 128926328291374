define("fitbit-site-ui/components/device-exercise-shortcuts-setting", ["exports", "fitbit-site-ui/components/panel-component", "ember-cp-validations"], function (_exports, _panelComponent, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Exercise = Ember.Object.extend({
    model: null,
    id: null,
    isSelected: Ember.computed.gt('order', -1),
    order: Ember.computed('model._setting', function () {
      var setting = Ember.get(this, 'model._setting');
      return setting.indexOf(Ember.get(this, 'id'));
    })
  });
  var Validations = (0, _emberCpValidations.buildValidations)({
    selectedOptions: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: Ember.computed.readOnly('model.min'),
        max: Ember.computed.readOnly('model.max')
      })],
      message: Ember.computed(function () {
        return this.get('model.i18n').format('exercise_shortcuts_validation');
      })
    }
  });

  var _default = _panelComponent.default.extend(Validations, {
    i18n: Ember.inject.service(),
    isEditing: false,
    maxNumberOfExerciseShortcuts: 7,

    /**
     * Maximum number of selected exercises
     */
    max: Ember.computed('maxNumberOfExerciseShortcuts', function () {
      return Ember.get(this, 'maxNumberOfExerciseShortcuts');
    }),

    /**
     * Minimum number of selected exercises
     */
    min: 1,
    deviceName: '',
    setting: '',
    options: null,
    onSettingsChanged: function onSettingsChanged() {},
    isSurge: Ember.computed('deviceName', function () {
      return Ember.get(this, 'deviceName') === 'Surge';
    }),

    /**
     * Helper property to convert setting string into an array.
     * @private
     */
    _setting: Ember.computed('setting', function () {
      var setting = Ember.get(this, 'setting');
      return setting.split(',').map(Number);
    }),

    /**
     * Exercise options array
     */
    exerciseOptions: Ember.computed('options', function () {
      var _this = this;

      var options = Ember.get(this, 'options'); // Copy the object and set isSelected

      return options.map(function (option) {
        var exerciseOption = Object.assign(option);
        return Exercise.create(_objectSpread({
          model: _this
        }, exerciseOption));
      });
    }),

    /**
     * Array of exercise options filtered by order and is selected
     */
    sortedOptions: Ember.computed('exerciseOptions.@each.order', function () {
      var exerciseOptions = Ember.get(this, 'exerciseOptions');
      return exerciseOptions.filterBy('isSelected', true).sortBy('order');
    }),

    /**
     * Array of exercise options not selected
     */
    availableOptions: Ember.computed('exerciseOptions.@each.order', function () {
      var exerciseOptions = Ember.get(this, 'exerciseOptions');
      return exerciseOptions.filterBy('isSelected', false);
    }),

    /**
     * Array of selected options from a combination of options that are already selected and available options
     */
    selectedOptions: Ember.computed('sortedOptions.@each.isSelected', 'availableOptions.@each.isSelected', function () {
      var sortedOptions = Ember.get(this, 'sortedOptions').filterBy('isSelected');
      var availableOptions = Ember.get(this, 'availableOptions').filterBy('isSelected');
      return Ember.A([]).concat(sortedOptions, availableOptions);
    }),
    actions: {
      edit: function edit() {
        this.toggleProperty('isEditing');
      },
      reorderItems: function reorderItems(items) {
        var _this2 = this;

        var formatter = this.get('i18n');
        var setting = Ember.get(this, 'setting');
        var exercises = items.map(function (item, index) {
          Ember.set(item, 'order', index);
          return Ember.get(item, 'id');
        }).join(','); // Compare the strings.
        // Only trigger onSettingsChanged if the exercises are different.

        if (!Ember.isEqual(setting, exercises)) {
          Ember.set(this, 'setting', exercises);
          Ember.RSVP.resolve(Ember.get(this, 'onSettingsChanged')()).then(function () {
            _this2.showFlashMessage('success', formatter.format('updated_settings'));
          }).catch(function (err) {
            var error = err && err.errors[0];
            var errorMessage = error.message || formatter.format('device_setting_err');

            _this2.showFlashMessage('alert', errorMessage);
          });
        }
      },
      submit: function submit() {
        var _this3 = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (Ember.get(validations, 'isValid')) {
            var formatter = _this3.get('i18n');

            var selectedOptions = Ember.get(_this3, 'selectedOptions');
            var exercises = selectedOptions.mapBy('id').join(',');
            Ember.set(_this3, 'setting', exercises);
            Ember.RSVP.resolve(Ember.get(_this3, 'onSettingsChanged')()).then(function () {
              _this3.showFlashMessage('success', formatter.format('updated_settings'));

              _this3.toggleProperty('isEditing');
            }).catch(function (err) {
              var error = err && err.errors[0];
              var errorMessage = error.message || formatter.format('device_setting_err');

              _this3.showFlashMessage('alert', errorMessage);
            });
          }
        });
      },
      cancel: function cancel() {
        var _EmberGetProperties = Ember.getProperties(this, 'sortedOptions', 'availableOptions'),
            sortedOptions = _EmberGetProperties.sortedOptions,
            availableOptions = _EmberGetProperties.availableOptions; // Revert the options back to what they were.


        sortedOptions.forEach(function (option) {
          Ember.set(option, 'isSelected', true);
        });
        availableOptions.forEach(function (option) {
          Ember.set(option, 'isSelected', false);
        });
        this.toggleProperty('isEditing');
      }
    }
  });

  _exports.default = _default;
});