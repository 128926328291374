define("fitbit-site-ui/adapters/friend/leaderboard", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/leaderboard/friends.json");
    }
  });

  _exports.default = _default;
});