define("fitbit-site-ui/serializers/weight-log", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'logId',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.hasOwnProperty('pagination') && payload.hasOwnProperty('weight') && !id) {
        payload = {
          weightLog: Ember.get(payload, 'weight')
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var weightLog = Ember.get(payload, 'weightLog.firstObject');
      return this._super(store, primaryModelClass, // ember-data loops through the keys to process the data. If the key has an falsey value, ember-data will throw.
      // Prevent error by providing an empty object if weightLog does not exist.
      weightLog ? {
        weightLog: weightLog
      } : {}, id, requestType);
    }
  });

  _exports.default = _default;
});