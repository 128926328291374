define("fitbit-site-ui/serializers/food-database", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'value',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {
        'food-databases': payload
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});