define("fitbit-site-ui/models/cw-program-participant", ["exports", "ember-data", "snapdragon-common/mixins/models/non-rest-action", "ember-cp-validations", "fitbit-site-ui/constants/corporate-program-states", "fitbit-site-ui/constants/corporate-program-features"], function (_exports, _emberData, _nonRestAction, _emberCpValidations, _corporateProgramStates, _corporateProgramFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Dimension = Ember.Object.extend((0, _emberCpValidations.buildValidations)({
    selected: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('cw_slice_error', 'models.cw-program');
      })
    })
  }), {
    i18n: Ember.inject.service()
  });
  var FITBIT_PERIOD_TYPES = [_corporateProgramFeatures.AGGREGATE, _corporateProgramFeatures.AGGREGATE_INDIVIDUAL, _corporateProgramFeatures.AGGREGATE_OPT_OUT];

  var _default = _emberData.default.Model.extend(_nonRestAction.default, {
    dimensions: _emberData.default.attr(),
    fields: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    meta: _emberData.default.attr(),
    onboardingToken: _emberData.default.attr('string'),
    moment: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /*
     TODO: Flatten this program?
     Creating a bunch of computed.readOnly properties? or set the program fields as attributes of  the record's payload?
     Either case will create additional fields on the model to make it easier to see what fields are available.
      */
    program: _emberData.default.belongsTo('cw-program', {
      async: false
    }),
    userFeatures: _emberData.default.belongsTo('cw-feature', {
      async: false
    }),
    programState: _emberData.default.attr('string'),
    records: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    role: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    participantFeatures: _emberData.default.attr(),
    programBenefits: _emberData.default.attr('array-string'),

    /**
     * Determine whether the program is current.
     * @returns {Boolean}
     */
    isCurrentProgram: Ember.computed.equal('programState', _corporateProgramStates.CURRENT_PROGRAM),

    /**
     * Determine whether the program is available.
     * @returns {Boolean}
     */
    isAvailableProgram: Ember.computed.equal('programState', _corporateProgramStates.AVAILABLE_PROGRAM),

    /**
     * Determine whether user can longer join to the program.
     * @returns {Boolean}
     */
    isEndedProgram: Ember.computed.equal('programState', _corporateProgramStates.ENDED_PROGRAM),

    /**
     * Determine whether the program is completed.
     * @returns {Boolean}
     */
    isCompletedProgram: Ember.computed.equal('programState', _corporateProgramStates.COMPLETED_PROGRAM),

    /**
     * Determine whether user was removed from the program.
     * @returns {Boolean}
     */
    isRemovedProgram: Ember.computed.equal('programState', _corporateProgramStates.OTHER_PROGRAM),

    /**
     * Filters this programs records array checking for LEFT events
     * @returns {Array} - Array of left records
     */
    leftProgramRecords: Ember.computed.filterBy('records', 'status', _corporateProgramStates.LEFT_PROGRAM_STATUS),

    /**
     * Determine whether user has left the program.
     * @returns {Boolean}
     */
    isLeftProgram: Ember.computed.notEmpty('leftProgramRecords'),

    /**
     * True if the program has ended and the user left before its completion
     * @returns { bool }
     */
    isEndedAndLeft: Ember.computed.and('isEndedProgram', 'isLeftProgram'),

    /**
     * Determine if user was in the program
     */
    isInLastProgramState: Ember.computed.or('isCompletedProgram', 'isEndedProgram'),

    /**
     * True if the program is available and the user left before its completion
     */
    isAvailableAndLeft: Ember.computed.and('isAvailableProgram', 'isLeftProgram'),
    hasOnboardingToken: Ember.computed('onboardingToken', 'program.onboardingToken', function () {
      return !!this.onboardingToken || !!Ember.get(this, 'program.onboardingToken');
    }),

    /**
     * Determine if program is available to be joined
     */
    isJoinable: Ember.computed.and('isAvailableProgram', 'hasOnboardingToken'),

    /**
     * Determine if program is actionable
     */
    isActionable: Ember.computed.or('isUserConsentRequired', 'isJoinable'),

    /**
     * Determine if user was in the program
     */
    wasInProgram: Ember.computed.or('isCompletedProgram', 'isEndedAndLeft', 'isRemovedProgram', 'isAvailableAndLeft'),

    /**
     * Determine if there is a record about previous participation of the user in the program
     */
    hasParticipationHistory: Ember.computed.and('wasInProgram', 'completedDates'),

    /**
     * Determine whether participant has ACTIVE status
     * @returns {Boolean}
     */
    isUserActive: Ember.computed.equal('status', _corporateProgramStates.ACTIVE_PROGRAM_STATUS),

    /**
     * Determine whether participant has INACTIVE status
     * @returns {Boolean}
     */
    isUserInactive: Ember.computed.equal('status', _corporateProgramStates.INACTIVE_PROGRAM_STATUS),

    /**
     * Determine whether participant has INVITED status
     * @returns {Boolean}
     */
    isUserInvited: Ember.computed.equal('status', _corporateProgramStates.INVITED_PROGRAM_STATUS),

    /**
     * Determine whether participant has LEFT status
     * @returns {Boolean}
     */
    isUserLeft: Ember.computed.equal('status', _corporateProgramStates.LEFT_PROGRAM_STATUS),

    /**
     * Determine whether participant has DECLINED status
     * @returns {Boolean}
     */
    isUserDeclined: Ember.computed.equal('status', _corporateProgramStates.DECLINED_PROGRAM_STATUS),

    /**
     * Determine whether user has REMOVED status
     * @returns {Boolean}
     */
    isUserRemoved: Ember.computed.equal('status', _corporateProgramStates.REMOVED_PROGRAM_STATUS),

    /**
     * Determine whether user has COMPLETED status
     * @returns {Boolean}
     */
    isUserCompleted: Ember.computed.equal('status', _corporateProgramStates.COMPLETED_PROGRAM_STATUS),

    /**
     * Determine whether user has CONSENT_REQUIRED status
     * @returns {Boolean}
     */
    isUserConsentRequired: Ember.computed.equal('status', _corporateProgramStates.CONSENT_REQUIRED_PROGRAM_STATUS),

    /**
     * Determine if user has a 1P-linked account
     */
    isUserAccountLinked: Ember.computed.alias('currentUser.linkedGoogleAccountStatus'),
    isProgramPeriodNotAvailableForAccountStatus: Ember.computed('isUserAccountLinked', 'program.period.type', function () {
      var isUserAccountLinked = Ember.get(this, 'isUserAccountLinked');
      var periodType = Ember.get(this, 'program.period.type');
      return Boolean(isUserAccountLinked && FITBIT_PERIOD_TYPES.indexOf(periodType) >= 0);
    }),

    /**
     * An Ember Object that is a copy of current program fields.
     * A copy prevents changes to the source.
     * @returns {Object|null}
     */
    programFields: Ember.computed('fields', function () {
      var fields = Ember.get(this, 'fields');

      if (Ember.isPresent(fields)) {
        return Ember.Object.create(fields);
      }

      return null;
    }),
    dimensionsById: Ember.computed('dimensions.[]', 'program.dimensions.[]', function () {
      var ownerInjection = Ember.getOwner(this).ownerInjection();
      var isAvailableProgram = Ember.get(this, 'isAvailableProgram');

      var dimensions = this._indexById(Ember.get(this, 'dimensions'));

      var programDimensions = this._indexById(Ember.get(this, 'program.dimensions'));

      var dimensionsById = [];

      for (var tag in programDimensions) {
        if (programDimensions[tag].slices.length) {
          var slice = dimensions[tag] && dimensions[tag].slice;
          var newTag = Dimension.create(ownerInjection, _objectSpread({}, programDimensions[tag], {
            slice: slice,
            selected: isAvailableProgram ? null : slice || null,
            validateSlice: false
          }));
          dimensionsById.push(newTag);
        }
      }

      return dimensionsById;
    }),
    selectedSliceIds: Ember.computed('dimensionsById.@each.selected', function () {
      return this.dimensionsById.map(function (tag) {
        if (tag.selected) {
          return tag.selected.id;
        } else if (tag.slice) {
          return tag.slice.id;
        }
      }).filter(Boolean);
    }),
    originalSliceIds: Ember.computed('dimensionsById', 'dimensionsById.[]', function () {
      return this.dimensionsById.map(function (tag) {
        return tag.slice && tag.slice.id;
      }).filter(Boolean);
    }),

    /**
     * Returns earliest UNIT_CHANGED, ACTIVE, or INACTIVE record's start date
     * @returns {string} - date string YYYY-MM-DD
     */
    activeStartDate: Ember.computed('records', 'records.[]', function () {
      var records = Ember.get(this, 'records');
      var userJoinDate = null;
      var hasActiveOrInactive = false;
      records.some(function (record) {
        var status = Ember.get(record, 'status');

        if (!hasActiveOrInactive && (status === _corporateProgramStates.ACTIVE_PROGRAM_STATUS || status === _corporateProgramStates.INACTIVE_PROGRAM_STATUS)) {
          hasActiveOrInactive = true;
        }

        if (!userJoinDate) {
          var startDate = Ember.get(record, 'startDate');

          if (startDate) {
            userJoinDate = startDate;
          }
        }

        return userJoinDate && hasActiveOrInactive;
      });
      return userJoinDate;
    }),
    resetProgramFields: function resetProgramFields() {
      var _this = this;

      this.programFields.setProperties(this.fields);
      this.dimensionsById.forEach(function (dimension) {
        return dimension.set('selected', _this.isAvailableProgram ? null : dimension.slice);
      });
    },

    /**
     * Returns either max participation dates for records or null
     * @param records - records array [{startDate, endDate, status}]
     * @returns {Null | {end: *, start: *}}
     * @private
     */
    _getRecordsDatesRange: function _getRecordsDatesRange(records) {
      if (Ember.isPresent(records)) {
        var moment = Ember.get(this, 'moment');
        var earliestStart = null;
        var earliestStartStr = null;
        var latestEnd = null;
        var latestEndStr = null;
        records.forEach(function (record) {
          if (record.startDate === null || record.endDate === null) {
            return;
          }

          if (earliestStart === null || latestEnd === null) {
            earliestStart = new Date(record.startDate);
            earliestStartStr = record.startDate;
            latestEnd = new Date(record.endDate);
            latestEndStr = record.endDate;
          } else {
            var newStartDate = new Date(record.startDate);

            if (newStartDate < earliestStart) {
              earliestStart = newStartDate;
              earliestStartStr = record.startDate;
            }

            var newEndDate = new Date(record.endDate);

            if (newEndDate > latestEnd) {
              latestEnd = newEndDate;
              latestEndStr = record.endDate;
            }
          }
        });
        /**
         * If the program is available BUT HAS NOT STARTED (future period)
         * Then the api will return a records array with
         * { startDate: null, endDate: null, status: LEFT }
         * for every "left" action.  In this case return null as they have not participated.
         */

        if (!latestEndStr || !earliestStartStr) {
          return null;
        }

        var end = moment.moment(latestEndStr).format('LL');
        var start = moment.moment(earliestStartStr).format('LL');
        return {
          start: start,
          end: end
        };
      }

      return null;
    },
    availableLeftDates: Ember.computed('records.[]', function () {
      var records = Ember.get(this, 'records');

      if (Ember.isPresent(records)) {
        return this._getRecordsDatesRange(records);
      }
    }),

    /**
     * The participated dates for a completed program, program that has
     * ended and been left, or removed program
     * @returns {object} - {start: date, end: date}
     */
    completedDates: Ember.computed('records', 'programState', function () {
      var records = Ember.get(this, 'records');

      if (Ember.isPresent(records)) {
        return this._getRecordsDatesRange(records);
      }

      return null;
    }),

    /**
     * Update the user program data.
     * @param {Object|undefined} data The data to update the participant.
     * @returns {*|Object|undefined}
     */
    update: function update(data) {
      var fields = Ember.get(this, 'fields');
      Ember.setProperties(fields, data);
      return this.save();
    },
    _indexById: function _indexById(dimensions) {
      var dimensionsById = {};

      if (Ember.isPresent(dimensions)) {
        dimensions.map(function (tag) {
          dimensionsById[tag.id] = tag;
        });
      }

      return dimensionsById;
    }
  });

  _exports.default = _default;
});