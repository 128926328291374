define("fitbit-site-ui/models/family-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Family account models are the bridge between users and a family, and contain the data about how the user is related
   * to the specific family.  the /1/family-accounts/family/{encodedId}/member/{encodedId} can be used to fetch more
   * information about the specific users relation to a specific family
   */
  var _default = _emberData.default.Model.extend({
    role: _emberData.default.attr('string'),
    addedById: _emberData.default.attr('string'),
    familyOwnerId: _emberData.default.attr('string'),
    familyId: _emberData.default.attr('string'),
    memberId: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    family: _emberData.default.belongsTo('family', {
      async: false,
      inverse: 'members'
    }),
    user: _emberData.default.belongsTo('profile', {
      async: false,
      inverse: 'familyAccounts'
    })
  });

  _exports.default = _default;
});