define("fitbit-site-ui/mixins/sleep-date-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),

    /**
     * Creates an array of objects, where each object is a date of sleep containing
     * an array of sleep logs for that date and other useful information
     */
    sleepByDate: function sleepByDate(sleepList, leastRecent, mostRecent) {
      var sleepData = [];

      if (leastRecent && mostRecent) {
        var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
        var numDates = mostRecent.diff(leastRecent, 'days');

        for (var i = 0; i <= numDates; i++) {
          var date = leastRecent.format(YEAR_MONTH_DAY);
          var sleepByDate = this.getSleepByDate(sleepList, date);
          sleepData.push({
            id: date,
            date: date,
            minutesAsleep: sleepByDate.timeAsleep,
            sleepLogs: sleepByDate.sleepLogs
          });
          leastRecent.add(1, 'days');
        }
      }

      return sleepData;
    },
    getSleepByDate: function getSleepByDate(sleepList, date) {
      var timeAsleep = 0,
          sleepLogs = [];
      sleepList.filter(function (sleep) {
        return sleep.dateOfSleep === date;
      }).forEach(function (sleep) {
        timeAsleep += sleep.minutesAsleep;
        sleepLogs.push(sleep.logId);
      });
      return {
        timeAsleep: timeAsleep,
        sleepLogs: sleepLogs
      };
    }
  });

  _exports.default = _default;
});