define("fitbit-site-ui/adapters/fhs-enrollment-program-membership", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // api url: '1/user/-/fhs-enrollment/:programId/membership.json',
  // api url: '1/user/-/fhs-enrollment/memberships.json',
  var _default = _applicationJsonapi.default.extend({
    namespace: '1/user/-/fhs-enrollment',
    pathForType: function pathForType() {
      return 'membership';
    },
    // d should be program id
    urlForFindRecord: function urlForFindRecord(id) {
      var baseUrl = this.buildURL();
      return "".concat(baseUrl, "/").concat(id, "/membership.json");
    },
    urlForFindAll: function urlForFindAll() {
      var baseUrl = this.buildURL();
      return "".concat(baseUrl, "/memberships.json");
    }
  });

  _exports.default = _default;
});