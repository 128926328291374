define("fitbit-site-ui/serializers/notification-settings-list", ["exports", "ember-data", "fitbit-site-ui/constants/settings/notifications"], function (_exports, _emberData, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _emberData.default.JSONAPISerializer.extend({
    currentUser: Ember.inject.service(),
    serialize: function serialize(rec) {
      var list = Ember.get(rec, 'record.list');
      var data = list.map(function (record) {
        var _EmberGetProperties = Ember.getProperties(record, 'id', 'emailState', 'pushState'),
            id = _EmberGetProperties.id,
            emailState = _EmberGetProperties.emailState,
            pushState = _EmberGetProperties.pushState;

        return {
          attributes: {
            emailState: emailState,
            pushState: pushState
          },
          id: id,
          type: 'notification-settings'
        };
      });
      return {
        data: data
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      id = id || '-';
      var data = payload.data;
      var notificationsList = [];
      var notifications = data.map(function (notification) {
        var _EmberGetProperties2 = Ember.getProperties(notification, 'id', 'attributes'),
            attributes = _EmberGetProperties2.attributes,
            id = _EmberGetProperties2.id;

        var _EmberGetProperties3 = Ember.getProperties(attributes, 'label', 'description', 'emailState', 'pushState'),
            label = _EmberGetProperties3.label,
            description = _EmberGetProperties3.description,
            emailState = _EmberGetProperties3.emailState,
            pushState = _EmberGetProperties3.pushState;

        notificationsList.push({
          id: id,
          type: 'notification-setting'
        });
        return {
          id: id,
          type: 'notification-setting',
          attributes: {
            label: label,
            description: description,
            'email-state': emailState,
            'email-check-state': emailState === _notifications.STATE_ON,
            'email-disable-state': emailState === _notifications.STATE_READ_ONLY,
            'push-state': pushState,
            'push-check-state': pushState === _notifications.STATE_ON,
            'push-disable-state': pushState === _notifications.STATE_READ_ONLY
          }
        };
      });
      var newPayload = {
        data: {
          id: id,
          type: 'notification-settings-list',
          relationships: {
            list: {
              data: notificationsList
            }
          }
        },
        included: _toConsumableArray(notifications)
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});