define("fitbit-site-ui/models/health-programs/admin/citadel-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    deployDate: _emberData.default.attr('string'),
    deployJobUrl: _emberData.default.attr('string'),
    deployPath: _emberData.default.attr('string'),
    dockerTag: _emberData.default.attr('string')
  });

  _exports.default = _default;
});