define("fitbit-site-ui/models/time-series/activity/list", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    list: (0, _relationships.hasMany)('time-series/activity', {
      async: false
    })
  });

  _exports.default = _default;
});