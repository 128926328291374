define("fitbit-site-ui/routes/redirect-user-profile-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A redirect route to handle legacy URL user/profile/edit and redirect to settings/profile
   * When we stop building for weightsite, remove with the command: ember destroy route redirect-user-profile-edit
   */
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.replaceWith('settings.profile-settings');
    }
  });

  _exports.default = _default;
});