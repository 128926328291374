define("fitbit-site-ui/templates/authenticated", ["exports", "snapdragon-common/templates/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _authenticated.default;
    }
  });
});