define("fitbit-site-ui/models/fhs-enrollment-program-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    values: _emberData.default.attr('string'),
    selected: _emberData.default.attr('string'),
    selectedId: _emberData.default.attr('string'),
    readOnly: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});