define("fitbit-site-ui/serializers/device-type", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'name',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var deviceTypes = payload.deviceTypes;
      deviceTypes.forEach(function (device) {
        device.name = device.name.replace('_', ' ');
      });
      return this._super(store, primaryModelClass, {
        deviceTypes: deviceTypes
      }, id, requestType);
    }
  });

  _exports.default = _default;
});