define("fitbit-site-ui/models/search-food", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),
    foodItem: _emberData.default.belongsTo('food/food-item'),
    servingDisplay: Ember.computed('foodItem.defaultServingUnit', function () {
      var formatter = Ember.get(this, 'i18n');
      var calories = Ember.get(this, 'foodItem.calories');
      var calorieString = '';

      if (calories > 1) {
        calorieString = formatter.formatByNamespace('calories', 'models.search-food', {
          calories: calories
        });
      } else {
        calorieString = formatter.formatByNamespace('calorie', 'models.search-food');
      }

      var defaultServingUnit = Ember.get(this, 'foodItem.defaultServingUnit');
      var defaultServingUnitString = defaultServingUnit ? "".concat(defaultServingUnit, ", ") : '';
      return "".concat(defaultServingUnitString).concat(calorieString);
    }),

    /**
     * Alias for log food list
     */
    foodListItem: Ember.computed.alias('foodItem')
  });

  _exports.default = _default;
});