define("fitbit-site-ui/components/device-alarms-setting", ["exports", "fitbit-site-ui/components/panel-component", "fitbit-site-ui/mixins/device-time-format"], function (_exports, _panelComponent, _deviceTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend(_deviceTimeFormat.default, {
    i18n: Ember.inject.service(),
    timeFormat12: Ember.computed.readOnly('constants.CLOCK_12_TIME_FORMAT'),
    timeFormat24: Ember.computed.readOnly('constants.CLOCK_24_TIME_FORMAT'),
    timeFormatWithUTC: Ember.computed.readOnly('constants.TIME_FORMAT_WITH_UTC'),
    meridiemFormat: Ember.computed.readOnly('constants.MERIDIEM_FORMAT'),
    UTCOffset: null,
    alarms: null,
    isFormVisible: false,
    actions: {
      toggleAlarmForm: function toggleAlarmForm() {
        this.toggleProperty('isFormVisible');
      },
      create: function create(alarm) {
        var _this = this;

        var formatter = this.get('i18n');
        this.get('onCreateAlarm')(alarm).then(function () {
          _this.toggleProperty('isFormVisible');

          _this.showFlashMessage('success', formatter.format('create_alarm_success'));

          _this.set('alarmChanged', 'device_alarm_created');
        }).catch(function (err) {
          var error = err && err.errors[0];
          var errorMessage = error.message || formatter.format('create_alarm_err');

          _this.showFlashMessage('alert', errorMessage);
        });
      },
      edit: function edit(alarm, alarmData) {
        var _this2 = this;

        var formatter = this.get('i18n');
        return this.get('onEditAlarm')(alarm, alarmData).then(function () {
          _this2.showFlashMessage('success', formatter.format('updated_settings'));

          _this2.set('alarmChanged', 'device_alarm_edited');
        }).catch(function () {
          _this2.showFlashMessage('alert', formatter.format('device_setting_err'));
        });
      },
      cancel: function cancel() {
        this.set('alarmChanged', null);
        this.set('isFormVisible', false);
      },
      delete: function _delete(alarm) {
        var _this3 = this;

        var formatter = this.get('i18n');
        this.get('onDeleteAlarm')(alarm).then(function () {
          _this3.showFlashMessage('success', formatter.format('delete_alarm_success'));

          _this3.set('alarmChanged', 'device_alarm_deleted');
        }).catch(function () {
          _this3.showFlashMessage('alert', formatter.format('delete_alarm_err'));
        });
      }
    }
  });

  _exports.default = _default;
});