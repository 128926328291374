define("fitbit-site-ui/models/food-date-log", ["exports", "ember-data", "food-engine/mixins/food-data", "snapdragon-common/utils/unit-converter", "numbro", "snapdragon-common/constants/food-logging"], function (_exports, _emberData, _foodData, _unitConverter, _numbro, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var roundToDigits = _unitConverter.default.roundToDigits,
      ozToMl = _unitConverter.default.ozToMl;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend(_foodData.default, {
    currentUser: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    date: attr(),
    summary: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    foodLogs: hasMany('food-log', {
      async: false
    }),
    caloriesOut: belongsTo('time-series/calorie', {
      async: false
    }),
    foodGoal: belongsTo('food-goal', {
      async: false
    }),
    isToday: Ember.computed('date', function () {
      var moment = Ember.get(this, 'moment');
      var date = Ember.get(this, 'date');
      return moment.moment().isSame(date, 'day');
    }),
    isFuture: Ember.computed('date', function () {
      var moment = Ember.get(this, 'moment');
      var date = Ember.get(this, 'date');
      return moment.moment(date).isAfter(moment, 'day');
    }),
    hasFoodGoal: Ember.computed('foodGoal', function () {
      var foodGoal = Ember.get(this, 'foodGoal.goals');
      return foodGoal ? !!Object.keys(foodGoal).length : false;
    }),
    hasFoodPlan: Ember.computed('foodGoal', function () {
      var foodPlan = Ember.get(this, 'foodGoal.foodPlan');
      return foodPlan ? !!Object.keys(foodPlan).length : false;
    }),
    calZone: Ember.computed('foodGoal', 'summary', 'caloriesOut', 'isToday', 'hasFoodPlan', 'hasFoodGoal', function () {
      if (Ember.get(this, 'isFuture')) {
        // There is no calories out data for the future
        return _foodLogging.FUTURE_ZONE;
      }

      var isToday = Ember.get(this, 'isToday');
      var foodPlan = Ember.get(this, 'foodGoal.foodPlan');
      var foodCalorieGoal = Ember.get(this, 'foodGoal.goals');
      var hasFoodGoal = Ember.get(this, 'hasFoodGoal');
      var hasFoodPlan = Ember.get(this, 'hasFoodPlan');
      var caloriesIn = Ember.get(this, 'summary.calories');
      var caloriesOut = Ember.get(this, 'caloriesOut.value');
      return this._getZone(foodPlan, foodCalorieGoal, caloriesIn, caloriesOut, isToday, hasFoodPlan, hasFoodGoal);
    }),
    macroNutrients: Ember.computed('summary', 'foodLogs.[]', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'foodLogs', 'summary'),
          foodLogs = _EmberGetProperties.foodLogs,
          summary = _EmberGetProperties.summary;

      var macroData = null;

      if (Ember.isPresent(foodLogs)) {
        macroData = this._calculateMacronutrients(true, foodLogs, summary);

        if (macroData.carbs === 0 && macroData.fat === 0 && macroData.protein === 0) {
          return null;
        }
      }

      return macroData;
    }),

    /**
     * Format `summary` values to 2 digits.
     * @returns {Object}
     */
    formattedSummary: Ember.computed('summary', 'currentUser.waterUnit', 'foodLogs.[]', function () {
      var summary = _objectSpread({}, Ember.get(this, 'summary'));

      var waterUnit = Ember.get(this, 'currentUser.waterUnit');
      var water = summary.water; // Water unit needs to be converted from oz to ml manually because API returns oz.

      if (Ember.isPresent(water) && waterUnit === 'METRIC') {
        summary.water = ozToMl(water, 2);
      } // At the time of this PR, summary does not contain sugars so add it from food log nutritional values.


      if (Ember.isEmpty(summary.sugars)) {
        var foodLogs = Ember.get(this, 'foodLogs');
        summary.sugars = foodLogs.reduce(function (acc, log) {
          // Quick Calories Add has no servings, so just return the acc.
          if (Ember.get(log, 'isQuickCaloriesAdd')) {
            return acc;
          } else {
            var loggedServings = Ember.get(log, 'loggedServings') || 0;
            var sugars = loggedServings * (Ember.get(log, 'nutritionalValues.sugars') || 0);
            return acc + sugars;
          }
        }, 0);
      }

      Object.keys(summary).forEach(function (key) {
        var value = summary[key];
        summary[key] = (0, _numbro.default)(roundToDigits(value, 2)).format('0[.]00');
      });
      return summary;
    }),

    /**
     * Relationship to food-note-date containing the food notes for that day
     */
    foodNoteDate: _emberData.default.belongsTo('food-note-date', {
      async: false
    }),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),

    /**
     * The start date of the week that the log belongs to. Uses the current users startDayOfWeek to offset and compute.
     *
     * @returns {String} Formatted date string.
     */
    startDateOfWeek: Ember.computed('date', 'currentUser.startDayOfWeek', function () {
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var moment = Ember.get(this, 'moment');
      var date = moment.moment(Ember.get(this, 'date'));
      var startDayOfWeek = Ember.get(this, 'currentUser.startDayOfWeek') === 'SUNDAY' ? 0 : 6;
      var daysToSubtract = (date.isoWeekday() + startDayOfWeek) % 7;
      return date.clone().subtract(daysToSubtract, 'days').format(YEAR_MONTH_DAY);
    })
  });

  _exports.default = _default;
});