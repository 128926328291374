define("fitbit-site-ui/components/privacy-setting-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    currentValue: Ember.computed('setting.value', function () {
      return Ember.get(this, 'setting.value');
    }),
    isEditActive: false,
    // create computed property that is an array of states like in exercise shortcuts
    settingOptions: Ember.computed('setting.options', function () {
      var _this = this;

      var formatter = Ember.get(this, 'i18n');
      var options = Ember.get(this, 'setting.options');
      return options.map(function (option) {
        return Ember.Object.create({
          value: option,
          name: formatter.formatByNamespace(option.toLowerCase(), 'templates.settings.privacy-setting-dropdown'),
          className: "privacy-option-".concat(option.toLowerCase()),
          isSelected: option === Ember.get(_this, 'currentValue'),
          isHighlighted: false
        });
      });
    }),
    actions: {
      valueChangedAction: function valueChangedAction(newValue, dropdown, e) {
        this._valueChanged(newValue, dropdown, e);
      },
      onTriggerKeydown: function onTriggerKeydown(dropdown, e) {
        return this._routeKeydown(dropdown, e);
      },
      onTriggerFocus: function onTriggerFocus() {
        Ember.set(this, 'isEditActive', true);
      },
      onTriggerBlur: function onTriggerBlur() {
        Ember.set(this, 'isEditActive', false);
      },
      onTriggerMouseEnter: function onTriggerMouseEnter() {
        Ember.set(this, 'isEditActive', true);
      },
      onTriggerMouseLeave: function onTriggerMouseLeave() {
        Ember.set(this, 'isEditActive', false);
      },
      highlightOnHover: function highlightOnHover(option) {
        this._clearHighlight();

        option.set('isHighlighted', true);
      },
      onOpen: function onOpen() {
        this._highlightFirstOpt();
      },
      onClose: function onClose() {
        this._clearHighlight();
      }
    },

    /**
     * _routeKeydown handles key events on the trigger
     * @param dropdown
     * @param e
     * @returns function to onTriggerKeyDown()
     * @private
     */
    _routeKeydown: function _routeKeydown(dropdown, e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
        // Up & Down
        return this._handleKeyUpDown(dropdown, e);
      } else if (e.keyCode === 13) {
        // ENTER
        return this._handleKeyEnter(dropdown, e);
      } else if (e.keyCode === 9) {
        // Tab
        return this._handleKeyTab(dropdown, e);
      }
    },
    _handleKeyUpDown: function _handleKeyUpDown(dropdown, e) {
      e.preventDefault();
      e.stopPropagation();

      if (dropdown.isOpen) {
        var step = e.keyCode === 38 ? -1 : 1;

        this._toggleHighlightedOpt(step);
      } else {
        dropdown.actions.open(e);
      }
    },
    _handleKeyEnter: function _handleKeyEnter(dropdown, e) {
      if (dropdown.isOpen) {
        this._changeValue(dropdown, e);

        return false;
      }
    },
    _handleKeyTab: function _handleKeyTab(dropdown, e) {
      dropdown.actions.close(e);
    },
    _highlightFirstOpt: function _highlightFirstOpt() {
      var firstOpt = Ember.get(this, 'settingOptions')[0];
      firstOpt.set('isHighlighted', true);
    },
    _getHighlightedOpt: function _getHighlightedOpt() {
      var ops = Ember.get(this, 'settingOptions');
      return ops.filterBy('isHighlighted', true)[0];
    },
    _toggleHighlightedOpt: function _toggleHighlightedOpt(step) {
      var currentOpt = this._getHighlightedOpt();

      var settingOptions = Ember.get(this, 'settingOptions');
      var currentIndex = settingOptions.indexOf(currentOpt);
      var numSettingOpts = settingOptions.length;
      var nextOpt = settingOptions[(currentIndex + step + numSettingOpts) % numSettingOpts];
      currentOpt.set('isHighlighted', false);
      nextOpt.set('isHighlighted', true);
    },
    _clearHighlight: function _clearHighlight() {
      var settingOptions = Ember.get(this, 'settingOptions');
      settingOptions.setEach('isHighlighted', false);
    },
    _valueChanged: function _valueChanged(newValue, dropdown, e) {
      var setting = Ember.get(this, 'setting');
      var currentVal = Ember.get(setting, 'value');

      if (currentVal !== newValue) {
        Ember.set(this, 'setting.value', newValue);
        Ember.get(this, 'changeFnc')(setting, newValue);
      }

      dropdown.actions.close(e);
    },
    _changeValue: function _changeValue(dropdown, e) {
      var currentOpt = this._getHighlightedOpt();

      this._valueChanged(currentOpt.value, dropdown, e);
    }
  });

  _exports.default = _default;
});