define("fitbit-site-ui/adapters/goal/daily", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REST_URL_PATH = 'activities/goals/daily.json';

  var _default = _application.default.extend(_formDataAdapter.default, {
    namespace: '1/user',
    disableRoot: true,
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/").concat(REST_URL_PATH);
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return "".concat(this._super.apply(this, arguments), "/").concat(REST_URL_PATH);
    }
  });

  _exports.default = _default;
});