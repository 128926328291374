define("fitbit-site-ui/serializers/goal/daily", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = this._getNewPayload(payload);

      var ret = this._super(store, primaryModelClass, newPayload, id, requestType);

      ret.data.id = id;
      return ret;
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = this._getNewPayload(payload);

      var ret = this._super(store, primaryModelClass, newPayload, id, requestType);

      ret.data.id = id;
      return ret;
    },
    _getNewPayload: function _getNewPayload(payload) {
      var payloadKey = 'goal/daily';
      return _defineProperty({}, payloadKey, payload.goals);
    }
  });

  _exports.default = _default;
});