define("fitbit-site-ui/constants/data-export-forbidden-reasons", ["exports", "ude-console-engine/constants/data-export-forbidden-reasons"], function (_exports, _dataExportForbiddenReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_dataExportForbiddenReasons).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _dataExportForbiddenReasons[key];
      }
    });
  });
});