define("fitbit-site-ui/routes/settings", ["exports", "fitbit-site-ui/routes/authenticated", "consent-data/constants/gdpr-consent-ids"], function (_exports, _authenticated, _gdprConsentIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    deviceGuides: Ember.inject.service(),
    gdpr: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    optimizely: Ember.inject.service(),
    titleToken: Ember.computed(function () {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('route_title', 'route.settings');
    }),
    model: function model() {
      var _EmberGetProperties = Ember.getProperties(this, 'store', 'gdpr', 'deviceGuides', 'optimizely'),
          store = _EmberGetProperties.store,
          gdpr = _EmberGetProperties.gdpr,
          deviceGuides = _EmberGetProperties.deviceGuides,
          optimizely = _EmberGetProperties.optimizely;

      var currentUser = Ember.get(this, 'currentUser');
      var userID = Ember.get(currentUser, 'id');
      var consentModel = null;

      if (GDPR_ENABLED) {
        if (Ember.get(gdpr, 'isRequired') && gdpr.hasConsentId(_gdprConsentIds.SETTINGS_CHECKUP)) {
          var _this$paramsFor = this.paramsFor('application'),
              redirect = _this$paramsFor.redirect;
          /*
          Store the redirect, if it exists, so that it can be used when gdpr.redirectToNextRoute
          is initiated. This is necessary when the user navigates from the `consent` route with
          a `redirect` query param or when the user arrives and refreshes the page.
           */


          if (redirect) {
            Ember.set(gdpr, 'redirectURL', redirect);
          }

          consentModel = Ember.RSVP.hash({
            consent: store.peekRecord('consent', _gdprConsentIds.SETTINGS_CHECKUP),
            consentContents: Ember.get(gdpr, 'gdprStatus.consentContents')
          });
        }
      }

      var hasGddConsoleFeature = optimizely.featureEnabled('gdd-console', {
        pageName: 'settings',
        feature: 'gdd-console',
        owner: 'Snapdragon'
      }).catch(function () {
        // If an error has occurred, default to returning false.
        return false;
      });
      return Ember.RSVP.hash({
        deviceTypes: store.findAll('device-type'),
        userDevices: store.findRecord('user-device-list', userID),
        userScales: store.findAll('device/scale/user-scale', userID),
        deviceGuides: deviceGuides.getManifest(),
        consentModel: consentModel,
        hasGddConsoleFeature: hasGddConsoleFeature,
        profileTabEnabled: store.findAll('cw-program-participant', {
          include: 'program',
          reload: true
        }).then(function (models) {
          return models.any(function (model) {
            return Ember.get(model, 'program.profileTabEnabled');
          });
        })
      });
    },

    /**
     * Toggles the controller property animate
     * @param controller
     * @returns null
     */
    _triggerButtonAnimation: function _triggerButtonAnimation(controller) {
      controller.toggleProperty('animate');
    },
    actions: {
      /**
       * Prevent transition if consent is still required.
       * If user is unauthenticated, don't block the transition to logout route.
       * @param transition
       * @returns {*|void}
       */
      willTransition: function willTransition(transition) {
        var gdpr = Ember.get(this, 'gdpr');

        if (Ember.get(this, 'session.isAuthenticated') && Ember.get(gdpr, 'isRequired')) {
          if (!gdpr.validateTransitionRoute(transition.targetName)) {
            this._triggerButtonAnimation(Ember.get(this, 'controller'));

            return transition.abort();
          }
        }
      },
      acceptConsent: function acceptConsent(consent) {
        var _this = this;

        var _EmberGetProperties2 = Ember.getProperties(this, 'gdpr', 'controller'),
            gdpr = _EmberGetProperties2.gdpr,
            controller = _EmberGetProperties2.controller;

        if (!Ember.get(controller, 'isLoading')) {
          Ember.set(controller, 'isLoading', true);
          /*
          After consent has been accepted, invoke `gdpr.redirectToNextRoute` to navigate user to the next route
          The next route is determined by simple checks in `gdpr.nextConsentRoute`, and logic to send user to a
          redirect query param, previous transition callback, previous transition, or the default which is `/`.
           */

          return gdpr.accept(consent).catch(function (err) {
            var _EmberGetProperties3 = Ember.getProperties(_this, 'flashMessages', 'i18n'),
                flashMessages = _EmberGetProperties3.flashMessages,
                i18n = _EmberGetProperties3.i18n;

            flashMessages.alert(i18n.formatByNamespace('consent_accept_error', 'route.settings'));
            throw err;
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          }).then(function () {
            return gdpr.redirectToNextRoute();
          }).catch(function (err) {
            /*
            We don't want to catch the TransitionAborted error. That happens whenever a transition is aborted
            via the abort method, or another transition aborts a previous one.
            We do want to surface other errors though, so throw again if it's not TransitionAborted error.
            */
            if (err.name !== 'TransitionAborted') {
              throw err;
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});