define("fitbit-site-ui/adapters/sleep-list", ["exports", "fitbit-site-ui/adapters/application", "snapdragon-common/constants/query-params"], function (_exports, _application, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    namespace: '1.2/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      var _EmberGetProperties = Ember.getProperties(this, 'moment', 'YEAR_MONTH_DAY'),
          moment = _EmberGetProperties.moment,
          YEAR_MONTH_DAY = _EmberGetProperties.YEAR_MONTH_DAY;

      var today = moment.moment().add(1, 'days').format(YEAR_MONTH_DAY); //add day to account for the beforeDate

      return "".concat(this._super.apply(this, arguments), "/sleep/list.json?limit=").concat(_queryParams.LIMIT, "&offset=").concat(_queryParams.OFFSET, "&sort=").concat(_queryParams.DESC_SORT, "&beforeDate=").concat(today);
    }
  });

  _exports.default = _default;
});