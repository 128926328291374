define("fitbit-site-ui/components/no-join-cw-program", ["exports", "fitbit-site-ui/constants/corporate-program-states"], function (_exports, _corporateProgramStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cw-program', 'no-join-cw-program'],
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),

    /**
     * Checks if the program is a completed program that should display participation dates
     */
    isCompletedProgram: Ember.computed.equal('userProgram.programState', _corporateProgramStates.COMPLETED_PROGRAM),

    /**
     * Checks if the program is a removed program that should display participation dates
     */
    isRemovedProgram: Ember.computed.equal('userProgram.programState', _corporateProgramStates.OTHER_PROGRAM),

    /**
     * Checks if the program is an inactive program that should hide the remove participation link
     */
    isInactiveProgram: Ember.computed.equal('userProgram.status', _corporateProgramStates.INACTIVE_PROGRAM_STATUS),

    /**
     * Checks to see if the userProgram is a program that is ended and has been left
     */
    isEndedAndLeftProgram: Ember.computed.oneWay('userProgram.isEndedAndLeft'),

    /**
     * Participation dates should be displayed for Completed, Removed, and (Left && Ended) programs
     */
    shouldDisplayParticipationDates: Ember.computed.or('isCompletedProgram', 'isEndedAndLeftProgram', 'isRemovedProgram'),

    /**
     * Formats startDate and endDate for participated dates in LL format
     * Returns {Object} - {start: string, end: string}
     */
    completedProgramDatesFormatted: Ember.computed('userProgram.completedDates', function () {
      var moment = Ember.get(this, 'moment');
      var completedDates = Ember.get(this, 'userProgram.completedDates');

      if (!completedDates || completedDates.start === null || completedDates.end === null) {
        return;
      }

      var start = moment.moment(completedDates.start, 'll').format('LL');
      var end = moment.moment(completedDates.end, 'll').format('LL');
      return {
        start: start,
        end: end
      };
    }),

    /**
     * Determines the correct message to display for programs based on their state and features
     * @returns {string}
     */
    message: Ember.computed('userProgram.program.period', function () {
      var formatter = Ember.get(this, 'i18n');
      var state = Ember.get(this, 'userProgram.programState');

      if (state === _corporateProgramStates.OTHER_PROGRAM) {
        return formatter.formatByNamespace('program_removed', 'templates.components.corporate-wellness');
      } else if (state === _corporateProgramStates.COMPLETED_PROGRAM) {
        var period = Ember.get(this, 'userProgram.program.period');
        var moment = Ember.get(this, 'moment');
        var today = moment.moment();
        var startDate = moment.moment(period.startDate);

        if (startDate > today) {
          // Future period
          if (Ember.get(period, 'requireConsent')) {
            return formatter.formatByNamespace('program_complete_future_period', 'templates.components.corporate-wellness', {
              startDate: startDate.format('LL')
            });
          } else {
            return formatter.formatByNamespace('program_complete_future_period_no_rc', 'templates.components.corporate-wellness', {
              startDate: startDate.format('LL')
            });
          }
        }
      }

      return formatter.formatByNamespace('program_complete', 'templates.components.corporate-wellness');
    }),

    /**
     * Remove participation data.
     */
    onParticipationRemove: function onParticipationRemove() {}
  });

  _exports.default = _default;
});