define("fitbit-site-ui/services/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    i18n: Ember.inject.service(),

    /**
     * The format for displaying 24hr time.
     * @property CLOCK_24_TIME_FORMAT
     * @type String
     * @public
     */
    CLOCK_24_TIME_FORMAT: 'HH:mm',

    /**
     * The format for displaying 12hr time.
     * @property CLOCK_12_TIME_FORMAT
     * @type String
     * @public
     */
    CLOCK_12_TIME_FORMAT: 'hh:mm',

    /**
     * The time format including utc. This format is specifc for saving the alarm.
     * @property TIME_FORMAT_WITH_UTC
     * @type String
     * @default "HH:mmZ"
     * @public
     */
    TIME_FORMAT_WITH_UTC: 'HH:mmZ',

    /**
     * The meridiemFormat.
     * @property MERIDIEM_FORMAT
     * @type String
     * @public
     */
    MERIDIEM_FORMAT: 'A',

    /**
     * The format for displaying 24 hours.
     * @property TWENTY_FOUR_HOUR_FORMAT
     * @type String
     * @public
     */
    TWENTY_FOUR_HOUR_FORMAT: 'HH',

    /**
     * The format for displaying 12 hours with meridiem.
     * @property TWELVE_HOUR_FORMAT
     * @type String
     * @public
     */
    TWELVE_HOUR_FORMAT: 'hh:mm A',

    /**
     * The format for displaying date and hour and minute using dashes.
     * @property FULL_DASH_FORMAT
     * @type String
     * @public
     */
    FULL_DASH_FORMAT: 'YYYY-MM-DD-HH-mm',

    /**
     * The format for displaying date in a nice readable format.
     * @property FULL_READABLE_DATE
     * @type String
     * @public
     */
    FULL_READABLE_DATE: 'ddd ll',

    /**
     * The format for parsing date times sent from the server.
     * @property YEAR_MONTH_DAY
     * @type String
     * @public
     */
    YEAR_MONTH_DAY: 'YYYY-MM-DD',

    /**
     * A way of formatting dates for the weight-graph endpoint.
     * @property YEAR_MONTH
     * @type String
     * @public
     */
    YEAR_MONTH: 'YYYY/MM',

    /**
     * The format for storing hour/minute/second data
     * @property HOUR_MIN_SEC
     * @type String
     * @public
     */
    HOUR_MIN_SEC: 'HH:mm:ss',

    /**
     * End of day time - 11:59:59 PM
     * @property END_HOUR_MIN_SEC
     * @type String
     * @public
     */
    END_HOUR_MIN_SEC: '23:59:59',

    /**
     * The format for parsing a date object without its timezone offset.
     *
     * @property NON_LOCALIZED_FORMAT
     */
    NON_LOCALIZED_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS',

    /**
     * The earliest date accepted by the back end for calorie data queries
     *
     * @property START_DATE_LIMIT
     */
    START_DATE_LIMIT: '2009-01-01',

    /**
     * The labels for clock faces. Blaze's labels are included in the API response
     * This should be temporary because i18n is missing
     *
     * TODO Replace with i18n
     *
     * @property CLOCKFACE_LABELS
     * @type Object
     * @public
     */
    CLOCK_FACE_LABELS: Ember.computed(function () {
      return {
        hhmm_h: 'Minimal',
        hhmm_v: 'Minimal',
        hhmm_date_h: 'Date',
        hhmm_date_v: 'Date',
        hhmmss_h: 'Seconds',
        hhmmss_v: 'Seconds',
        hhmm_goal_h: 'Main Goal',
        hhmm_goal_v: 'Main Goal',
        typographic_h: 'Typographic',
        hhmm_flower_v: 'Activity Flower',
        hours_minutes: 'Hours and Minutes',
        hours_minutes_period: 'Hours, Minutes, AM/PM',
        hours_minutes_seconds: 'Hours, Minutes, Seconds',
        hours_minutes_date: 'Hours, Minutes, Date',
        laryon_default: 'Default',
        analog: 'Analog',
        big_time: 'Big Time',
        stacked: 'Stacked',
        double_clock: 'Double Clock',
        beat_rings: 'Beat Rings',
        punctual: 'Punctual'
      };
    }),
    WEEKDAYS: Ember.computed(function () {
      return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    }),
    LANGUAGE_NAMES: Ember.computed(function () {
      return {
        en_US: 'English',
        de_DE: 'Deutsch',
        en_GB: 'English, U.K.',
        es_ES: 'Español',
        es_LA: 'Español, Latinoamérica',
        fr_FR: 'Français',
        it_IT: 'Italiano',
        ja_JP: '日本語',
        ko_KR: '한국어',
        zh_CN: '简体中文',
        zh_TW: '繁體中文',
        nl_NL: 'Nederlands',
        sv_SE: 'Svenska',
        cs_CZ: 'čeština',
        pl_PL: 'Polskie',
        pt_PT: 'Português',
        pt_BR: 'Português',
        ro_RO: 'Românește',
        ru_BY: 'Русский',
        id_ID: 'Bahasa Indonesia',
        in_ID: 'Bahasa Indonesia'
      };
    }),
    MOMENT_LOCALES: Ember.computed(function () {
      return {
        de_DE: 'de',
        en_US: 'en',
        en_AU: 'en-au',
        en_CA: 'en-ca',
        en_GB: 'en-gb',
        en_MY: 'ms-my',
        en_PH: 'tl-ph',
        en_TH: 'th',
        es_ES: 'es',
        fr_FR: 'fr',
        it_IT: 'it',
        ja_JP: 'ja',
        ko_KR: 'ko',
        zh_CN: 'zh-cn',
        zh_TW: 'zh-tw',
        nl_NL: 'nl',
        sv_SE: 'sv',
        cs_CZ: 'cs',
        pl_PL: 'pl',
        pt_BR: 'pt-br',
        ro_RO: 'ro',
        ru_RU: 'ru',
        id_ID: 'id',
        in_ID: 'id',
        nb_NO: 'nb'
      };
    }),
    NUMBRO_LOCALES: Ember.computed(function () {
      return {
        en_US: 'en-US',
        de_DE: 'de-DE',
        en_GB: 'en-GB',
        es_ES: 'es-ES',
        es_LA: 'es-CL',
        fr_FR: 'fr-FR',
        it_IT: 'it-IT',
        ja_JP: 'ja-JP',
        ko_KR: 'ko-KR',
        zh_CN: 'zh-CN',
        zh_TW: 'zh-TW',
        nl_NL: 'nl-NL',
        sv_SE: 'sv-SE',
        cs_CZ: 'cs-CZ',
        pl_PL: 'pl-PL',
        pt_BR: 'pt-BR',
        ro_RO: 'ro-RO',
        ru_RU: 'ru-RU',
        id_ID: 'id',
        in_ID: 'id',
        nb_NO: 'nb'
      };
    }),

    /**
     * Get vendor-specific locale based on Fitbit's locale code
     * @param fibitLocaleCode {string}
     * @param vendorLocaleDict {string}
     * @return {string}
     *
     * @example
     * var code = 'es_ES';
     * var dict = 'MOMENT_LOCALES';
     * console.log( getVendorLocale(code, dict) ); // 'es'
     */
    getVendorLocale: function getVendorLocale(fitbitLocaleCode, vendorLocaleDict) {
      var vendorLocale;
      var LOCALES = this.get(vendorLocaleDict);

      switch (true) {
        case !!LOCALES[fitbitLocaleCode]:
          vendorLocale = LOCALES[fitbitLocaleCode];
          break;

        case /^es/.test(fitbitLocaleCode):
          vendorLocale = LOCALES.es_ES;
          break;

        case /^de/.test(fitbitLocaleCode):
          vendorLocale = LOCALES.de_DE;
          break;

        case /^fr/.test(fitbitLocaleCode):
          vendorLocale = LOCALES.fr_FR;
          break;

        case /^it/.test(fitbitLocaleCode):
          vendorLocale = LOCALES.it_IT;
          break;

        default:
          vendorLocale = LOCALES.en_US;
      }

      return vendorLocale;
    },
    OS: Ember.computed(function () {
      return {
        IOS: 'IOS',
        ANDROID: 'ANDROID',
        WINDOWS_MOBILE: 'WINDOWS_MOBILE'
      };
    }),
    getOsLocaleCode: function getOsLocaleCode(OS) {
      var userLocale = Ember.get(this, 'i18n.userLanguageLocale');

      switch (OS) {
        case Ember.get(this, 'OS.IOS'):
          return userLocale.split('_')[1].toLowerCase();

        case Ember.get(this, 'OS.ANDROID'):
          return userLocale.split('_')[0];

        case Ember.get(this, 'OS.WINDOWS_MOBILE'):
          return userLocale.replace('_', '-');
      }

      return userLocale;
    },
    getStoreURL: function getStoreURL(OS) {
      var locale = this.getOsLocaleCode(OS);

      switch (OS) {
        case Ember.get(this, 'OS.IOS'):
          return "https://itunes.apple.com/".concat(locale, "/app/fitbit/id462638897?mt=8");

        case Ember.get(this, 'OS.ANDROID'):
          return "https://play.google.com/store/apps/details?id=com.fitbit.FitbitMobile&hl=".concat(locale);

        case Ember.get(this, 'OS.WINDOWS_MOBILE'):
          return "https://www.windowsphone.com/".concat(locale, "/store/app/fitbit/378ebdb6-f31f-4505-a00d-c460ef22e7ff");
      }
    },

    /**
     * Path to a web worker.
     * @property PATH_WORKER_GOAL_PREDICTION
     * @type String
     * @public
     */
    PATH_WORKER_GOAL_PREDICTION: 'assets/workers/goalPrediction.js',

    /**
     * Links for localized Fitbit help
     * from weightsite com.fitbit.app
     *
     */
    FITBIT_LINKS_HELP: Ember.computed(function () {
      return {
        en_US: '',
        en_GB: '',
        de_DE: '/customer/de/portal/articles',
        es_ES: '/customer/es_es/portal/articles',
        fr_FR: '/customer/fr/portal/articles',
        it_IT: '/customer/it/portal/articles',
        ja_JP: '/customer/ja/portal/articles',
        ko_KR: '/customer/ko/portal/articles',
        zh_CN: '/customer/zh_cn/portal/articles',
        zh_TW: '/?l=zh_TW'
      };
    }),
    SOCIAL_MEDIA_LINKS: Ember.computed(function () {
      return {
        FACEBOOK: {
          id: 'FACEBOOK',
          baseURL: '//www.facebook.com/',
          sharedURLContext: [{
            keys: ['en_GB', 'en_EU', 'en_NL', 'en_NO', 'en_SE', 'en_BE', 'en_DK', 'en_FI'],
            value: 'FitbitUKIreland'
          }, {
            keys: ['zh_TW', 'en_HK'],
            value: 'FitbitHKTW'
          }],
          URLContext: {
            ja_JP: 'fitbitjapan',
            ko_KR: 'Fitbitkr',
            en_AU: 'FitbitAU',
            en_NZ: 'FitbitNZ',
            en_SG: 'FitbitSG',
            en_TH: 'FitbitTH',
            en_MY: 'FitbitMY',
            en_IN: 'FitbitIN',
            fr_FR: 'FitbitFrance',
            other: 'fitbit'
          }
        },
        TWITTER: {
          id: 'TWITTER',
          baseURL: '//twitter.com/',
          sharedURLContext: [{
            keys: ['en_GB', 'en_EU', 'en_NL', 'en_NO', 'en_SE', 'en_BE', 'en_DK', 'en_FI'],
            value: 'FitbitUK'
          }, {
            keys: ['de_DE', 'de_CH'],
            value: 'fitbit_de'
          }, {
            keys: ['fr_CH', 'fr_FR'],
            value: 'fitbit_FR'
          }, {
            keys: ['it_CH', 'it_IT'],
            value: 'fitbit_italy'
          }],
          URLContext: {
            ja_JP: 'fitbit_jp',
            en_IN: 'fitbitIN',
            es_ES: 'fitbitES',
            other: 'fitbit'
          }
        },
        INSTAGRAM: {
          id: 'INSTAGRAM',
          baseURL: '//instagram.com/',
          sharedURLContext: [{
            keys: ['en_HK', 'zh_TW'],
            value: 'fitbithk'
          }],
          URLContext: {
            ko_KR: 'fitbitkr',
            en_AU: 'fitbitau',
            en_IN: 'fitbitin',
            other: 'fitbit'
          }
        },
        YOUTUBE: {
          id: 'YOUTUBE',
          baseURL: '//www.youtube.com/',
          URLContext: {
            zh_TW: 'c/fitbithk',
            other: 'user/FitbitOfficialSite'
          }
        }
      };
    }),

    /**
     * Return a processed object of social media links.
     * sharedURLContext array is a mapping of keys to one value, which gets added to the URLContext.
     */
    socialMediaContexts: Ember.computed(function () {
      var locale = this.get('i18n.userLocale');
      var links = this.get('SOCIAL_MEDIA_LINKS');
      var contexts = Object.values(links);
      return contexts.reduce(function (previous, current) {
        var id = current.id,
            baseURL = current.baseURL,
            sharedURLContext = current.sharedURLContext,
            baseURLContext = current.URLContext; // Prevent mutating the source by copying

        var URLContext = Object.assign({}, baseURLContext); // Process sharedURLContext

        if (sharedURLContext) {
          sharedURLContext.forEach(function (context) {
            var keys = context.keys,
                value = context.value;
            keys.forEach(function (key) {
              (false && !(!URLContext[key]) && Ember.assert("".concat(key, " already exists on the URLContext for ").concat(id), !URLContext[key]));
              URLContext[key] = value;
            });
          });
        }

        previous[id] = {
          locale: locale,
          baseURL: baseURL,
          URLContext: URLContext
        };
        return previous;
      }, {});
    }),
    MULTILINGUAL_LOCALES: Ember.computed(function () {
      return ['fr_ch', 'it_ch', 'de_ch', 'fr_ca'];
    }),

    /**
     * gets urlBase based on locale
     * @param locale
     * @returns {string}
     */
    getUrlContext: function getUrlContext(locale) {
      if (locale) {
        locale = locale.toLowerCase();

        if (this.get('MULTILINGUAL_LOCALES').indexOf(locale) < 0) {
          var _locale$split = locale.split('_'),
              _locale$split2 = _slicedToArray(_locale$split, 2),
              language = _locale$split2[0],
              region = _locale$split2[1];

          locale = region || language;
        }
      } // If locale is null or undefined, return an empty string.


      return locale || '';
    },

    /**
     * Sleep - Max number of days in the past a user can edit a sleep log.
     *         Note: 90 days worth of PPG data (necessary for sleep stages) is retained.
     * @property EDIT_SLEEP_MAX_DAYS
     * @type Number
     */
    EDIT_SLEEP_MAX_DAYS: 90,

    /**
     * time-series/calories - Max number of days allowed in a query.
     *         Note: If exceeded a back-end error is thrown
     * @property CALORIES_OUT_QUERY_MAX_DAYS
     * @type Number
     */
    CALORIES_OUT_MAX_DAYS: 1094,

    /**
     * TCX file mime type, used for file saving.
     * @type {string}
     */
    TCX_MIME_TYPE: 'application/vnd.garmin.tcx+xml;charset=UTF-8',

    /**
     * ZIP file type, used for file saving.
     * @type {string}
     */
    ZIP_FILE_TYPE: 'application/zip'
  });

  _exports.default = _default;
});