define("fitbit-site-ui/templates/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "roU8fZGS",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"app-loader \",[27,\"unless\",[[23,[\"isLoading\"]],\"hide\"],null]]]],[9],[1,[27,\"format-by-namespace\",[\"loading\",\"templates.loading\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row column flash-message-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"flash-message\",null,[[\"flash\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/profile.hbs"
    }
  });

  _exports.default = _default;
});