define("fitbit-site-ui/models/fitbit-explore", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    categoryId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    tagline: _emberData.default.attr('string'),
    backgroundImageUrl: _emberData.default.attr('string'),
    backgroundColor1: _emberData.default.attr('string'),
    backgroundColor2: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    items: _emberData.default.hasMany('fitbit-explore-item')
  });

  _exports.default = _default;
});