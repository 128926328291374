define("fitbit-site-ui/templates/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MeRRJnQf",
    "block": "{\"symbols\":[\"FlexiLayout\"],\"statements\":[[4,\"with\",[[27,\"-inject-layout\",null,null]],null,{\"statements\":[[4,\"if\",[[22,1,[\"isAtLeastTablet\"]]],null,{\"statements\":[[0,\"\\n\"],[7,\"main\"],[11,\"class\",\"row column\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n        \"],[7,\"br\"],[9],[10],[0,\"\\n\\n        \"],[7,\"h1\"],[9],[1,[27,\"format-by-namespace\",[\"heading\",\"templates.settings.index\"],null],false],[10],[0,\"\\n\\n        \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[27,\"format-by-namespace\",[\"description\",\"templates.settings.index\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[1,[27,\"sustain-settings-nav\",null,[[\"model\"],[[27,\"hash\",null,[[\"profile\",\"deviceTypes\",\"userDevices\",\"userScales\",\"deviceGuides\",\"hasGddConsoleFeature\",\"profileTabEnabled\"],[[23,[\"currentUser\"]],[23,[\"model\",\"deviceTypes\"]],[23,[\"model\",\"userDevices\",\"list\"]],[23,[\"model\",\"userScales\"]],[23,[\"model\",\"deviceGuides\",\"availableGuides\"]],[23,[\"model\",\"hasGddConsoleFeature\"]],[23,[\"model\",\"profileTabEnabled\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/index.hbs"
    }
  });

  _exports.default = _default;
});