define("fitbit-site-ui/templates/friends/friend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BPdq0e0L",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"user-profile\",null,[[\"user\"],[[23,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/friends/friend.hbs"
    }
  });

  _exports.default = _default;
});