define("fitbit-site-ui/helpers/i18n-url", ["exports", "snapdragon-common/helpers/i18n-url"], function (_exports, _i18nUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _i18nUrl.default;
    }
  });
});