define("fitbit-site-ui/utils/unit-converter", ["exports", "snapdragon-common/utils/unit-converter"], function (_exports, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _unitConverter.default;
    }
  });
});