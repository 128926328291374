define("fitbit-site-ui/services/device-guides", ["exports", "ember-ajax/services/ajax", "fitbit-site-ui/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    // Add a host here and it should be automatically prepended to the path.
    host: Ember.computed(function () {
      return _environment.default.guidesHost;
    }),
    contentType: 'text/html; charset=utf-8',
    getManifest: function getManifest() {
      if (HAS_101_GUIDES) {
        return this.request('/manifest.json').then(function (response) {
          return response;
        }).catch(function () {
          return undefined;
        });
      } else {
        return null;
      }
    },
    guideExists: function guideExists(deviceName) {
      return this.getManifest().then(function (manifest) {
        var found = false;
        manifest.availableGuides.forEach(function (guide) {
          if (guide.id === deviceName) {
            found = true;
          }
        });
        return found;
      });
    },
    loadGuide: function loadGuide(device, locale) {
      var path = "guides/".concat(device, "-101-no-layout.html");
      return this.raw(locale ? "/".concat(locale, "/").concat(path) : "/".concat(path), {
        method: 'GET',
        dataType: 'html'
      }).then(function (_ref) {
        var payload = _ref.payload;
        return payload;
      });
    }
  });

  _exports.default = _default;
});