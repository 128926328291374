define("fitbit-site-ui/adapters/cw-program-participant", ["exports", "fitbit-site-ui/adapters/corporate"], function (_exports, _corporate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _corporate.default.extend({
    /**
     * Override to return correct path.
     *
     * If the `modelName` return `user-programs` so that we don't need to create a specific model just for join/leave/update/decline operations.
     * It would be nice to have the API support operations on the same path.
     *
     * @param {String} modelName Model name to look up correct path.
     * @returns {string}
     */
    pathForType: function pathForType() {
      return 'users/self/program-participants';
    }
  });

  _exports.default = _default;
});