define("fitbit-site-ui/models/sub-locale", ["exports", "ember-data/model", "ember-data/attr", "ember-data"], function (_exports, _model, _attr, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    displayName: (0, _attr.default)('string'),
    subLocaleCode: (0, _attr.default)('string'),
    country: _emberData.default.belongsTo('country'),
    //Used to render in select-menu
    value: Ember.computed.alias('subLocaleCode')
  });

  _exports.default = _default;
});