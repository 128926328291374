define("fitbit-site-ui/models/journal-survey-question-multiple-choices", ["exports", "fitbit-site-ui/models/journal-survey-question"], function (_exports, _journalSurveyQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _journalSurveyQuestion.default.extend({
    /**
     * Multiple exclusive choices are supported
     */
    exclusiveResponses: Ember.computed('responses.@each.onSelect', function () {
      var responses = this.responses;
      return responses.filter(function (response) {
        return response.onSelect === 'clear-other';
      });
    }),
    setResponse: function setResponse(selectedResponse) {
      var exclusiveResponses = this.exclusiveResponses,
          responses = this.responses,
          clearOthers = this.clearOthers;

      if (selectedResponse.onSelect === 'clear-other') {
        clearOthers.call(this, selectedResponse.name);
      } else {
        if (exclusiveResponses.length) {
          // Make sure "None of the above" is set to false when selecting anything except it
          // Must handle multiple exclusive choices
          responses.forEach(function (response) {
            if (response.onSelect === 'clear-other') {
              Ember.set(response, 'selected', false);
            }
          });
        }
      }

      Ember.set(selectedResponse, 'selected', !selectedResponse.selected);
    }
  });

  _exports.default = _default;
});