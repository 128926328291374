define("fitbit-site-ui/components/profile-settings-change-image", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     *  3MB Max file size
     *  @type {number}
     */
    maxFileSize: 3000000,
    fileUploadElementId: null,
    fileUploadElement: Ember.computed('fileUploadElementId', function () {
      return document.getElementById(Ember.get(this, 'fileUploadElementId'));
    }),
    i18n: Ember.inject.service(),
    uploadedImage: '',
    errorMessage: '',
    fileName: '',
    selectedFile: null,
    //Used for Chrome bug that clears the previously selected file after canceling browse
    acceptedFileFormats: Ember.computed(function () {
      return ['image/jpeg', 'image/gif', 'image/png'];
    }),
    isLoading: false,
    actions: {
      /**
       * Performs validation on file to be uploaded
       *   sets source on the image to be uploaded (before crop)
       *
       * @returns {void}
       */
      uploadImage: function uploadImage() {
        var formatter = Ember.get(this, 'i18n');
        var maxFileSize = Ember.get(this, 'maxFileSize');
        var file = Ember.get(this, 'selectedFile');

        if (!file) {
          Ember.set(this, 'errorMessage', formatter.format('err_msg_file_upload'));
        } else if (file.size > maxFileSize) {
          Ember.set(this, 'errorMessage', formatter.format('err_msg_file_max_size'));
        } else if (!Ember.get(this, 'acceptedFileFormats').includes(file.type)) {
          Ember.set(this, 'errorMessage', formatter.format('err_msg_file_format'));
        } else {
          var src = _emberWindowMock.default.URL.createObjectURL(file);

          Ember.set(this, 'errorMessage', '');
          Ember.set(this, 'uploadedImage', src);
        }
      },

      /**
       * Sets the selected file after browse step in preparation for upload
       *   the user will see the file name appear in the input field
       *
       * @returns {void}
       */
      selectedFile: function selectedFile(files) {
        var file = files === null || files === void 0 ? void 0 : files[0];

        if (file) {
          Ember.set(this, 'fileName', file.name);
          Ember.set(this, 'selectedFile', file);
          Ember.set(this, 'errorMessage', '');
        }
      },

      /**
       * Triggered by click on browse for file button
       *   then, click triggered on fileUploadElement opens the file browse dialog
       *
       * @returns {void}
       */
      openFileBrowser: function openFileBrowser() {
        var fileUploadElement = Ember.get(this, 'fileUploadElement');
        fileUploadElement.click();
      },

      /**
       * Triggered by clicking cancel or closing the file browser modal
       *   values related to the image to be uploaded are cleared
       *
       * @returns {void}
       */
      canceledUpload: function canceledUpload() {
        var src = Ember.get(this, 'uploadedImage');

        if (src) {
          // Clean up step to tell the browser that a reference to the
          // file is no longer needed.
          _emberWindowMock.default.URL.revokeObjectURL(src);
        }

        Ember.setProperties(this, {
          uploadedImage: '',
          errorMessage: '',
          fileName: '',
          selectedFile: null
        });
      }
    }
  });

  _exports.default = _default;
});