define("fitbit-site-ui/controllers/onboarding/scale-consent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['senderId', 'serial', 'inviteeId'],
    senderId: null,
    serial: null,
    inviteeId: null,
    deviceName: Ember.computed.readOnly('model.device-name'),
    isBowie: Ember.computed.equal('deviceName', 'Aria Air'),
    acceptInviteUrl: Ember.computed('senderId', 'serial', 'inviteeId', function () {
      var inviteeId = this.inviteeId,
          senderId = this.senderId,
          serial = this.serial;

      if (!validateField(senderId) || !validateField(serial)) {
        return '/';
      } // eslint-disable-next-line no-undef


      var url = "/invitations/accept/scale?senderId=".concat(senderId, "&serial=").concat(serial);

      if (validateField(inviteeId)) {
        url += "&inviteeId=".concat(inviteeId);
      }

      return url;
    }),
    actions: {
      giveConsentAndAcceptInvite: function giveConsentAndAcceptInvite() {
        window.location.replace(this.get('acceptInviteUrl'));
      }
    }
  });

  _exports.default = _default;

  function validateField(field) {
    return field !== null && field !== undefined && field.length > 0;
  }
});