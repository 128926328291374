define("fitbit-site-ui/serializers/health-programs/membership", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'health-programs/membership';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'membership';
    },

    /**
     * For createRecord().save() requests post body should be { programId: _id_ }
     * @param adapterOptions
     * @returns {{programId: (string|number)}}
     */
    serialize: function serialize(_ref) {
      var adapterOptions = _ref.adapterOptions;

      if (adapterOptions) {
        var programId = Ember.get(adapterOptions, 'programId');
        var programVersion = Ember.get(adapterOptions, 'programVersion') || 'development';

        if (programId && programVersion) {
          var json = {
            programId: programId,
            programVersion: programVersion
          };
          return json;
        }
      }

      var payload = this._super.apply(this, arguments);

      delete payload.program;
      return payload;
    },

    /**
     * Conditionally serialze the data to have a root key or not. When provided a programId in
     * adaptorOptions we omit the root key for the object. For other requests we use the normal
     * serializer with the root key.
     *
     * For createRecord requests we want the request body to look like
     * { programId: _id_ } instead of { memberships: { programId: _id_ } }
     *
     * @override
     * @param {object} hash
     * @param {object} typeClass
     * @param {object} snapshot
     * @param {object} options
     */
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      if (snapshot.adapterOptions && snapshot.adapterOptions.programId) {
        return Object.assign(hash, this.serialize(snapshot, options));
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Response has form { deeplinkUrl: _url_, membership: { ... } }, set payload to be only membership
     */
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = Ember.get(payload, 'membership');
      var membershipId = Ember.get(newPayload, 'id');
      return this._super(store, primaryModelClass, {
        'health-programs/membership': payload.membership
      }, membershipId, requestType);
    }
  });

  _exports.default = _default;
});