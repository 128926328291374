define("fitbit-site-ui/components/device-inactivity-alerts-setting", ["exports", "moment", "fitbit-site-ui/components/panel-component", "fitbit-site-ui/mixins/device-time-format", "fitbit-site-ui/mixins/prevent-flash-duplicate", "ember-cp-validations"], function (_exports, _moment, _panelComponent, _deviceTimeFormat, _preventFlashDuplicate, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var timeValidations = function timeValidations(forClock12) {
    var isDisabled = Ember.computed('model.showErrors,model.isClock12', function () {
      var _this$get$getProperti = this.get('model').getProperties('showErrors', 'isClock12'),
          showErrors = _this$get$getProperti.showErrors,
          isClock12 = _this$get$getProperti.isClock12;

      var disabled = true;

      if (showErrors) {
        disabled = isClock12 !== forClock12;
      }

      return disabled;
    });
    return {
      description: 'The time',
      validators: [// Validate date following the timeFormat
      (0, _emberCpValidations.validator)('date', {
        format: Ember.computed.oneWay('model.timeValidationFormat'),
        message: Ember.computed(function () {
          return this.get('model.i18n').format('inactivity_validation_msg');
        }),
        disabled: isDisabled
      }), // Validate that
      (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: isDisabled
      })]
    };
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    startTime24: timeValidations(false),
    startTime12: timeValidations(true),
    endTime24: timeValidations(false),
    endTime12: timeValidations(true),
    weekDaySelection: (0, _emberCpValidations.validator)('length', {
      description: 'Weekday',
      min: 1,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('weekday_validation');
      })
    }),
    duration: (0, _emberCpValidations.validator)('number', {
      integer: true,
      gte: 5,
      lte: 14,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('duration_validation');
      })
    })
  }, {
    disabled: Ember.computed.not('model.showErrors')
  });

  var _default = _panelComponent.default.extend(_preventFlashDuplicate.default, _deviceTimeFormat.default, Validations, {
    moment: Ember.inject.service(),
    TWENTY_FOUR_HOUR_FORMAT: Ember.computed.readOnly('constants.TWENTY_FOUR_HOUR_FORMAT'),
    TWELVE_HOUR_FORMAT: Ember.computed.readOnly('constants.TWELVE_HOUR_FORMAT'),
    showErrors: true,
    startTime24: Ember.computed.oneWay('inactivityAlert.startTime'),
    startTime12: Ember.computed.oneWay('inactivityAlert.startTime12'),
    endTime24: Ember.computed.oneWay('inactivityAlert.endTime'),
    endTime12: Ember.computed.oneWay('inactivityAlert.endTime12'),
    endTimeAmPm: Ember.computed.oneWay('inactivityAlert.endTimeAmPm'),
    startTimeAmPm: Ember.computed.oneWay('inactivityAlert.startTimeAmPm'),
    timeValidationFormat: Ember.computed('timeFormat', function () {
      return this.get('timeFormat') === 'HH:mm' ? 'HH:00' : 'hh:00';
    }),
    duration: Ember.computed.oneWay('inactivityAlert.sedentaryTime.duration'),
    weekdayList: Ember.computed(function () {
      var momentService = this.get('moment');
      var localeData = momentService.moment().localeData();

      var weekdaysForValue = _moment.default.localeData('en').weekdays();

      return weekdaysForValue.map(function (day, index) {
        return {
          displayName: localeData.weekdaysMin()[index],
          value: day.toUpperCase()
        };
      });
    }),
    currentWeekdays: Ember.computed('inactivityAlert.inactivityAlerts.weekDays.[]', function () {
      var weekdayList = this.get('weekdayList');
      var weekDays = this.get('inactivityAlert.inactivityAlerts.weekDays');

      if (weekDays) {
        return weekdayList.filter(function (day) {
          return weekDays.includes(day.value);
        }).map(function (day) {
          return day.displayName;
        });
      } else {
        return [];
      }
    }),
    selectedWeekDays: Ember.computed('inactivityAlert.inactivityAlerts.weekDays.[]', function () {
      var weekDays = this.get('inactivityAlert.inactivityAlerts.weekDays');
      var momentService = this.get('moment');
      var localeData = momentService.moment().localeData();

      var weekdaysForValue = _moment.default.localeData('en').weekdays();

      return weekdaysForValue.map(function (day, index) {
        var displayName = localeData.weekdays()[index].capitalize();
        var value = day.toUpperCase();
        var isSelected = Ember.isPresent(weekDays) && weekDays.includes(value);
        return {
          displayName: displayName,
          value: value,
          isSelected: isSelected
        };
      });
    }),
    // Defined for validation purposes.
    // TODO: Fix this.
    weekDaySelection: Ember.computed.filterBy('selectedWeekDays', 'isSelected'),
    calculateDuration: function calculateDuration() {
      var TWELVE_HOUR_FORMAT = this.get('TWELVE_HOUR_FORMAT');
      var TWENTY_FOUR_HOUR_FORMAT = this.get('TWENTY_FOUR_HOUR_FORMAT');

      if (this.get('isClock12')) {
        return (0, _moment.default)("".concat(this.get('endTime12'), " ").concat(this.get('endTimeAmPm')), TWELVE_HOUR_FORMAT).diff((0, _moment.default)("".concat(this.get('startTime12'), " ").concat(this.get('startTimeAmPm')), TWELVE_HOUR_FORMAT), 'hours');
      } else {
        return (0, _moment.default)(this.get('endTime24'), TWENTY_FOUR_HOUR_FORMAT).diff((0, _moment.default)(this.get('startTime24'), TWENTY_FOUR_HOUR_FORMAT), 'hours');
      }
    },
    actions: {
      selectStartTimeAmPm: function selectStartTimeAmPm(value) {
        this.set('startTimeAmPm', value);
        this.set('duration', this.calculateDuration());
      },
      selectEndTimeAmPm: function selectEndTimeAmPm(value) {
        this.set('endTimeAmPm', value);
        this.set('duration', this.calculateDuration());
      },
      toggleInactivityAlerts: function toggleInactivityAlerts() {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.toggleProperty('hasInactivityAlertsEnabled');
        this.get('device.settings').then(function (model) {
          model.save().then(function () {
            //TODO: Better flash message strings
            var alertsEnabled = _this.get('hasInactivityAlertsEnabled') ? 'on_lower' : 'off_lower';
            var newActivityAlertMessage = formatter.format('new_inactivity_alert', {
              alertsEnabled: formatter.format(alertsEnabled)
            });

            _this._clearMessageFromQueue(newActivityAlertMessage);

            flashMessages.success(newActivityAlertMessage);
          }, function (err) {
            var error = err && err.errors[0],
                errorMessage = error.message || formatter.format('device_setting_err');

            this._clearMessageFromQueue(errorMessage);

            flashMessages.alert(errorMessage);
          });
        });
      },
      updateInactivityAlerts: function updateInactivityAlerts() {
        var _this2 = this;

        var formatter = this.get('i18n');
        var weekDaySelection = this.get('weekDaySelection'),
            settings = this.get('device.settings'),
            newStartTime,
            duration; // Validate when updating.

        this.set('showErrors', true);

        if (this.get('isClock12')) {
          var TWELVE_HOUR_FORMAT = this.get('TWELVE_HOUR_FORMAT');
          newStartTime = (0, _moment.default)("".concat(this.get('startTime12'), " ").concat(this.get('startTimeAmPm')), TWELVE_HOUR_FORMAT).format('HH:mm');
        } else {
          newStartTime = this.get('startTime24');
        }

        duration = this.calculateDuration();
        this.set('duration', duration);
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var flashMessages = _this2.get('flashMessages');

            settings.set('inactivityAlerts.sedentaryTime.duration', duration);
            settings.set('inactivityAlerts.sedentaryTime.startTime', newStartTime);
            settings.set('inactivityAlerts.sedentaryTime.duration', duration);

            _this2.set('inactivityAlert.inactivityAlerts.weekDays', weekDaySelection.map(function (day) {
              return day.value;
            }));

            settings.get('inactivityAlerts').then(function (model) {
              model.save().then(function () {
                _this2.set('alertsUpdated', true);

                var successMessage = formatter.format('device_setting_saved');

                _this2._clearMessageFromQueue(successMessage);

                flashMessages.success(successMessage);
              }, function (err) {
                var error = err && err.errors[0],
                    errorMessage = error.message || formatter.format('device_setting_err');

                this._clearMessageFromQueue(errorMessage);

                flashMessages.alert(errorMessage);
              });
            });
          }
        });
      },
      changeStartTime: function changeStartTime(value) {
        if (this.get('isClock12')) {
          this.set('startTime12', value);
        } else {
          this.set('startTime24', value);
        }

        this.set('duration', this.calculateDuration());
      },
      changeEndTime: function changeEndTime(value) {
        if (this.get('isClock12')) {
          this.set('endTime12', value);
        } else {
          this.set('endTime24', value);
        }

        this.set('duration', this.calculateDuration());
      }
    }
  });

  _exports.default = _default;
});