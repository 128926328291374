define("fitbit-site-ui/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    percentageStyle: Ember.computed('percentage', function () {
      var percentage = Ember.get(this, 'percentage');
      return Ember.String.htmlSafe("width: ".concat(percentage, "%"));
    })
  });

  _exports.default = _default;
});