define("fitbit-site-ui/models/water-log", ["exports", "ember-data", "snapdragon-common/utils/unit-converter"], function (_exports, _emberData, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    waterDate: _emberData.default.belongsTo('water-date'),
    amount: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    displayAmount: Ember.computed('amount', function () {
      var amount = Ember.get(this, 'amount');

      if (amount) {
        var unit = Ember.get(this, 'unit');
        var digits = unit === 'ml' ? 0 : 2;
        return _unitConverter.default.roundToDigits(amount, digits);
      }

      return 0;
    })
  });

  _exports.default = _default;
});