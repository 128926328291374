define("fitbit-site-ui/services/mobile-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super();

      this._mobileClient = window.MobileClient;
    },

    /**
     * Open passed link in an external system browser using MobileClient
     *
     * @param {String} url
     */
    openUrlExternally: function openUrlExternally(url) {
      if (this._mobileClient && typeof this._mobileClient.openUrlExternally === 'function') {
        this._mobileClient.openUrlExternally(url);
      }
    }
  });

  _exports.default = _default;
});