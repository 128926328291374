define("fitbit-site-ui/models/data-deletion-category", ["exports", "gdd-console-engine/models/data-deletion-category"], function (_exports, _dataDeletionCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dataDeletionCategory.default;
    }
  });
});