define("fitbit-site-ui/routes/settings/notifications-unsubscribe", ["exports", "fitbit-site-ui/routes/authenticated", "fitbit-site-ui/constants/settings/notifications"], function (_exports, _authenticated, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: {
      s: {
        refreshModel: true
      },
      u: {
        refreshModel: true
      }
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var userId = Ember.get(this, 'currentUser.id');
      return store.findRecord('notification-settings-list', userId);
    },
    afterModel: function afterModel(model, transition) {
      // check for updates being set in the queryParams
      var queryParams = Ember.get(transition, 'to.queryParams');
      var notificationName = this.parseNotificationUpdates(queryParams);

      if (!notificationName) {
        this.onError();
        return;
      }

      var notificationList = Ember.get(model, 'list');
      var notificationToUpdate = notificationList.find(function (notification) {
        return notification.id === notificationName;
      });

      if (!notificationToUpdate) {
        this.onError();
        return;
      }

      Ember.set(notificationToUpdate, 'emailState', _notifications.STATE_OFF);
      return model.save().then(this.onSuccess.bind(this)).catch(this.onError.bind(this));
    },
    onSuccess: function onSuccess() {
      var formatter = this.i18n,
          flashMessages = this.flashMessages;
      flashMessages.success(formatter.formatByNamespace('notification_settings_updated', 'components.notification-settings'));
      this.transitionTo('settings.notifications');
    },
    onError: function onError() {
      var formatter = this.i18n,
          flashMessages = this.flashMessages;
      flashMessages.alert(formatter.formatByNamespace('notification_settings_error', 'components.notification-settings'));
      this.transitionTo('settings.notifications');
    },
    parseNotificationUpdates: function parseNotificationUpdates(params) {
      var userId = Ember.get(this, 'currentUser.id');

      if (userId !== params.u) {
        // wrong user
        this.onError();
        return;
      }

      var notificationType = (Ember.get(params, 's') || '').trim();

      if (!notificationType || notificationType.length === 0) {
        // invalid parameter
        this.onError();
        return;
      }

      return notificationType;
    }
  });

  _exports.default = _default;
});