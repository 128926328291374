define("fitbit-site-ui/models/time-series/sleep", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    activitiesSleepIntraday: _emberData.default.attr(),
    activitiesSleep: _emberData.default.attr(),
    date: Ember.computed.alias('activitiesSleepIntraday.dateOfSleep'),
    summaryVal: Ember.computed.alias('activitiesSleepIntraday.minutesAsleep'),
    dataset: Ember.computed(function () {
      // The value of sleep is either a 1 or 2. With these values we can't
      // visually see well when the user is asleep (because the other graphs)
      // are on the order of 100s. Thus we'll multiply this value by a constant
      // so that we can see it in the graph.
      var SLEEP_GRAPH_FACTOR = 100;
      var minuteData = this.get('activitiesSleepIntraday').minuteData;
      minuteData.map(function (datum) {
        datum.time = datum.dateTime;
        datum.value = (3 - parseInt(datum.value)) * SLEEP_GRAPH_FACTOR;
      });
      return minuteData;
    })
  }); // Unknown possibly sensitive origin
  // {
  //   "sleep":  [
  //      {
  //       "awakeCount": 0,
  //       "awakeDuration": 0,
  //       "awakeningsCount": 22,
  //       "dateOfSleep": "2016-02-01",
  //       "duration": 28080000,
  //       "efficiency": 93,
  //       "isMainSleep": true,
  //       "logId": 10787588640,
  //       "minuteData":  [
  //          {
  //           "dateTime": "02:43:30",
  //           "value": "1"
  //         },
  //          {
  //           "dateTime": "02:44:30",
  //           "value": "1"
  //         },
  //          ......
  //          {
  //           "dateTime": "10:29:30",
  //           "value": "2"
  //         },
  //          {
  //           "dateTime": "10:30:30",
  //           "value": "1"
  //         }
  //       ],
  //       "minutesAfterWakeup": 0,
  //       "minutesAsleep": 437,
  //       "minutesAwake": 31,
  //       "minutesToFallAsleep": 0,
  //       "restlessCount": 22,
  //       "restlessDuration": 31,
  //       "startTime": "2016-02-01T02:43:30.000",
  //       "timeInBed": 468
  //     }
  //   ],
  //   "summary":  {
  //     "totalMinutesAsleep": 437,
  //     "totalSleepRecords": 1,
  //     "totalTimeInBed": 468
  //   }
  // }


  _exports.default = _default;
});