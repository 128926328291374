define("fitbit-site-ui/models/device/tracker/settings", ["exports", "fitbit-site-ui/models/device/settings", "ember-data/attr", "ember-data/relationships"], function (_exports, _settings, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _settings.default.extend({
    CUSTOM_DISPLAY_MAP: Ember.computed(function () {
      return {
        DISTANCE: 'displayDistance',
        FLOORS: 'displayElevation',
        CALORIES: 'displayCalories',
        TIME: 'displayClock',
        ACTIVE_MINUTES: 'displayActiveMinutes',
        STEPS: 'displaySteps',
        HEART_RATE: 'displayHeartRate',
        BATTERY: 'displayBattery',
        REMINDERS_TO_MOVE: 'displayRemindersToMove',
        FLOWER: 'displayEmote',
        RESTING_HEART_RATE: 'displayRestingHeartRate',
        UHG_FREQUENCY: 'displayUhgFrequency',
        UHG_INTENSITY: 'displayUhgIntensity',
        UHG_TENACITY: 'displayUhgTenacity'
      };
    }),
    DEVICE_SCREEN_ORDERS: Ember.computed(function () {
      return {
        zip: 'DISTANCE,CALORIES,TIME,FLOWER',
        ultra: 'DISTANCE,FLOORS,CALORIES,TIME,FLOWER',
        one: 'DISTANCE,FLOORS,CALORIES,TIME,FLOWER'
      };
    }),
    DEVICES_SCREEN_UNSORTABLE: Ember.computed(function () {
      return {
        zip: true,
        ultra: true,
        one: true
      };
    }),
    //In an ideal world this would be sent by the server
    DEVICE_MENU_SETTINGS: Ember.computed(function () {
      return ['HEARTRATE', 'EXERCISE', 'STOPWATCH', 'ALARMS', 'BREATHE'];
    }),
    fwUpdate: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    currentFirmware: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    latestFirmware: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    currentSettings: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    deviceType: (0, _relationships.belongsTo)('device-type', {
      async: false
    }),
    device: (0, _relationships.belongsTo)('user-device', {
      async: false
    }),
    options: (0, _relationships.belongsTo)('device/tracker/settings/options'),
    alarms: (0, _relationships.belongsTo)('device/tracker/alarm/list'),
    inactivityAlerts: (0, _relationships.belongsTo)('device/tracker/inactivity-alerts'),
    autoExerciseSettings: (0, _relationships.belongsTo)('device/tracker/auto-exercise-settings'),
    hasLiveDataSetting: Ember.computed('currentSettings.liveDataDisabled', function () {
      var currentSettings = Ember.get(this, 'currentSettings');
      return currentSettings.hasOwnProperty('liveDataDisabled');
    }),
    autoCueSettings: Ember.computed.filterBy('currentSettings.exerciseSettings', 'autoCue'),
    autoPauseSettings: Ember.computed.filter('currentSettings.exerciseSettings', function (setting) {
      return setting.hasOwnProperty('autoPauseOn');
    }),
    hasDominantHandSetting: Ember.computed('currentSettings', function () {
      return this.get('currentSettings').hasOwnProperty('onDominantHand');
    }),
    gpsSettings: Ember.computed.filter('currentSettings.exerciseSettings', function (setting) {
      return setting.hasOwnProperty('gpsOn');
    }),
    hasInactivityAlerts: Ember.computed('currentSettings', function () {
      return this.get('currentSettings').hasOwnProperty('enableInactivityAlerts');
    }),
    hasExerciseIntervalTraining: Ember.computed('currentSettings', function () {
      var currentSettings = Ember.get(this, 'currentSettings');
      var exerciseSettings = Ember.get(currentSettings, 'exerciseSettings');
      var hasIntervalSettings = currentSettings.hasOwnProperty('exerciseIntervalTimer');

      if (exerciseSettings && !hasIntervalSettings) {
        hasIntervalSettings = exerciseSettings.some(function (setting) {
          return setting.hasOwnProperty('intervalSettings');
        });
      }

      return hasIntervalSettings;
    }),
    intervalExerciseSettings: Ember.computed('currentSettings', function () {
      var currentSettings = Ember.get(this, 'currentSettings');
      var exerciseSettings = Ember.get(currentSettings, 'exerciseSettings');
      var intervalSettings = Ember.get(currentSettings, 'exerciseIntervalTimer');

      if (exerciseSettings && !intervalSettings) {
        intervalSettings = exerciseSettings.find(function (setting) {
          return setting.hasOwnProperty('intervalSettings');
        }).intervalSettings;
      }

      return intervalSettings;
    }),
    hasCustomizableMenu: Ember.computed('currentSettings', function () {
      return this.get('currentSettings').hasOwnProperty('enabledWidgets');
    }),
    hasTapGestures: Ember.computed('options.tapGestureOptions', function () {
      var tapGestureOptions = this.get('options.tapGestureOptions');
      return tapGestureOptions && tapGestureOptions.length > 0;
    }),
    quickView: Ember.computed('currentSettings', function () {
      var currentSettings = this.get('currentSettings');

      if (currentSettings.hasOwnProperty('watchCheckEnabled')) {
        return this.get('currentSettings.watchCheckEnabled') ? 'CLOCK' : 'DISABLED';
      }

      return this.get('currentSettings.watchCheck');
    }),
    customizableMenu: Ember.computed('options.availableWidgets.[]', function () {
      var customizableMenu = Ember.Object.create({
        model: this,
        device: Ember.computed.alias('model.device'),
        currentSettings: Ember.computed.alias('model.currentSettings')
      }),
          opts = [];
      var fullMenuItems = Ember.get(this, 'options.availableWidgets');

      if (fullMenuItems.length) {
        var fullMenuItemsIds = fullMenuItems.getEach('id'),
            //the choices the user has currently selected
        //disabled options do not show up in the response
        userMenuItems = Ember.get(this, 'currentSettings.enabledWidgets'),
            userMenuItemsArr = userMenuItems === '' ? [] : userMenuItems.split(','),
            userMenuOptions = userMenuItemsArr.concat(fullMenuItemsIds).uniq();
        userMenuOptions.forEach(function (type) {
          opts.push(Ember.Object.create({
            type: type,
            value: userMenuItemsArr.includes(type),
            setting: fullMenuItems.findBy('id', type).displayName
          }));
        });
        customizableMenu.menuItems = opts;
      }

      return customizableMenu;
    }),
    tapGesture: Ember.computed.alias('currentSettings.tapGesture'),
    customizableScreens: Ember.computed(function () {
      var _this$getProperties = this.getProperties('CUSTOM_DISPLAY_MAP', 'DEVICE_SCREEN_ORDERS', 'DEVICES_SCREEN_UNSORTABLE'),
          CUSTOM_DISPLAY_MAP = _this$getProperties.CUSTOM_DISPLAY_MAP,
          DEVICE_SCREEN_ORDERS = _this$getProperties.DEVICE_SCREEN_ORDERS,
          DEVICES_SCREEN_UNSORTABLE = _this$getProperties.DEVICES_SCREEN_UNSORTABLE;

      var customizableScreens = Ember.Object.extend({
        device: Ember.computed.alias('model.device'),
        currentSettings: Ember.computed.alias('model.currentSettings'),
        isSortable: Ember.computed('device.name', function () {
          var deviceName = (Ember.get(this, 'device.name') || '').toLowerCase();
          return !DEVICES_SCREEN_UNSORTABLE[deviceName];
        }),
        screens: Ember.computed('currentSettings.screenOrder', 'device.name', function () {
          var deviceName = (Ember.get(this, 'device.name') || '').toLowerCase();

          if (deviceName) {
            var currentSettings = Ember.get(this, 'currentSettings');
            var screenOrder = Ember.get(currentSettings, 'screenOrder') || DEVICE_SCREEN_ORDERS[deviceName];

            if (screenOrder) {
              return screenOrder.split(',').map(function (type) {
                var setting = CUSTOM_DISPLAY_MAP[type];
                var obj = Ember.Object.extend({
                  currentSettings: currentSettings,
                  type: type,
                  setting: setting,
                  value: Ember.computed.alias("currentSettings.".concat(setting)),
                  isPrimaryGoal: Ember.computed('currentSettings.primaryGoal', function () {
                    var primaryGoal = this.getWithDefault('currentSettings.primaryGoal', '').toUpperCase();
                    return primaryGoal === type;
                  })
                }).create();
                return obj;
              });
            }
          }
        })
      }).create({
        model: this
      });
      return customizableScreens;
    })
  });

  _exports.default = _default;
});