define("fitbit-site-ui/models/device/scale/settings", ["exports", "fitbit-site-ui/models/device/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _settings.default.extend({});

  _exports.default = _default;
});