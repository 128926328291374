define("fitbit-site-ui/templates/components/device-exercise-autopause-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/7fkJXAl",
    "block": "{\"symbols\":[\"autoPauseSetting\"],\"statements\":[[4,\"if\",[[23,[\"autoPauseSettings\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n        \"],[1,[27,\"format-by-namespace\",[\"exercise_autopause_detail\",\"templates.components.device-exercise-autopause\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"fieldset\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"autoPauseSettings\"]]],[[\"key\"],[\"exerciseId\"]],{\"statements\":[[0,\"            \"],[7,\"div\"],[9],[0,\"\\n                \"],[7,\"label\"],[11,\"class\",\"label-input\"],[9],[0,\"\\n                    \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"name\",\"change\",\"data-test-qa\"],[\"checkbox\",[22,1,[\"autoPauseOn\"]],[22,1,[\"exerciseId\"]],[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"exerciseAutoPauseControlChanged\",[22,1,[\"exerciseId\"]]],null]],null],[27,\"concat\",[\"exercise-autopause-control-\",[22,1,[\"exerciseId\"]]],null]]]],false],[0,\"\\n                    \"],[7,\"span\"],[9],[1,[22,1,[\"exerciseName\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n        \"],[1,[27,\"format-by-namespace\",[\"exercise_autopause_empty\",\"templates.components.device-exercise-autopause\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-exercise-autopause-control.hbs"
    }
  });

  _exports.default = _default;
});