define("fitbit-site-ui/initializers/setup-webview-comms", ["exports", "ember-fitbit-webview-comms/initializers/setup-webview-comms"], function (_exports, _setupWebviewComms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _setupWebviewComms.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _setupWebviewComms.initialize;
    }
  });
});