define("fitbit-site-ui/helpers/-inject-layout", ["exports", "@html-next/flexi-layouts/helpers/-inject-layout"], function (_exports, _injectLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _injectLayout.default;
    }
  });
  Object.defineProperty(_exports, "injectLayout", {
    enumerable: true,
    get: function get() {
      return _injectLayout.injectLayout;
    }
  });
});