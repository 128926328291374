define("fitbit-site-ui/components/user-messaging-form", ["exports", "fitbit-site-ui/constants/friends", "ember-cp-validations"], function (_exports, _friends, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserMessagingFormModel = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    _inputMessage: {
      description: Ember.computed(function () {
        var formatter = Ember.get(this, 'model.i18n');
        return formatter.formatByNamespace('message', 'common.messages');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: Ember.computed.readOnly('model.MAX_MESSAGE_LENGTH')
      })]
    }
  });
  var UserMessagingFormModel = Ember.Object.extend({
    message: '',
    avatar: '',
    name: ''
  });
  _exports.UserMessagingFormModel = UserMessagingFormModel;

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    tagName: '',

    /**
     * @type {UserMessagingFormModel|null}
     */
    model: null,

    /**
     * An image URL to display that belongs to the receiving user.
     *
     * @returns {String}
     */
    avatar: Ember.computed.readOnly('model.avatar'),

    /**
     * The name that belongs to the receiving user.
     *
     * @returns {String}
     */
    name: Ember.computed.readOnly('model.name'),

    /**
     * Max character length of a message (copied from weightsite)
     * @type {Number}
     */
    MAX_MESSAGE_LENGTH: _friends.MAX_MESSAGE_LENGTH,

    /**
     * Source message written in message modal
     * @returns {String}
     */
    message: Ember.computed.readOnly('model.message'),

    /**
     * One-way binding of `message` property.
     *
     * Not using `reads('model.message')` or `reads('message')` because the computed property should update when
     * either `model` or the `message` changes.
     *
     * @returns {String}
     */
    _inputMessage: Ember.computed('model', 'message', {
      get: function get() {
        return this.message;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Calculates remaining number of characters message can contain based on MAX_MESSAGE_LENGTH
     * @returns {Number}
     */
    charLeft: Ember.computed('{MAX_MESSAGE_LENGTH,_inputMessage}', function () {
      var MAX_MESSAGE_LENGTH = Ember.get(this, 'MAX_MESSAGE_LENGTH');
      var message = Ember.get(this, '_inputMessage');
      return MAX_MESSAGE_LENGTH - message.length;
    }),

    /**
     * Placeholder onSubmit function to be called by the `submit` action when validation is valid.
     * @param {String} message
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit: function onSubmit(message) {},

    /**
     * Placeholder onCancel function to be called by the `cancel` action
     */
    onCancel: function onCancel() {},
    actions: {
      submit: function submit() {
        var _this = this;

        return this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (Ember.get(validations, 'isValid')) {
            return _this.onSubmit(_this._inputMessage);
          }
        });
      },
      cancel: function cancel() {
        return this.onCancel();
      }
    }
  });

  _exports.default = _default;
});