define("fitbit-site-ui/models/device/settings", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import attr from 'ember-data/attr';
  var _default = _model.default.extend({
    device: (0, _relationships.belongsTo)('user-device', {
      async: false
    })
  });

  _exports.default = _default;
});