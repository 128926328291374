define("fitbit-site-ui/models/cw-program", ["exports", "ember-data", "snapdragon-common/mixins/models/non-rest-action", "fitbit-site-ui/constants/corporate-program-business-types"], function (_exports, _emberData, _nonRestAction, _corporateProgramBusinessTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_nonRestAction.default, {
    bulkFields: _emberData.default.attr(),
    dimensions: _emberData.default.attr(),
    features: _emberData.default.attr(),
    logo: _emberData.default.attr(),
    logo100: _emberData.default.attr(),
    logo160: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    period: _emberData.default.attr(),
    whitelistEnabled: _emberData.default.attr('boolean'),
    prepopulateEnabled: _emberData.default.attr('boolean'),
    fullCensusEnabled: _emberData.default.attr('boolean'),
    onboardingToken: _emberData.default.attr('string'),
    profileTabEnabled: _emberData.default.attr('boolean'),
    business: _emberData.default.attr('string'),
    isPartnerBusiness: Ember.computed.equal('business', _corporateProgramBusinessTypes.PARTNER),

    /**
     * Override to pass a different modelName and ID for decline/join/leave/update operations.
     * @param {String} action The url action.
     * @param {Object} adapter The adapter used for building the URL.
     * @returns {string}
     */
    getActionUrl: function getActionUrl(action, adapter) {
      var includeParam = action === 'leave' || action === 'join' ? '&include=user-features,program' : '';
      return "".concat(adapter.buildURL('cw-program', Ember.get(this, 'id')), "?action=").concat(action).concat(includeParam);
    },

    /**
     * Join the user program.
     * @returns {*|Object|undefined}
     */
    join: function join() {
      return this._operation.apply(this, ['join', 'post'].concat(Array.prototype.slice.call(arguments)));
    },

    /**
     * Decline to join the user program.
     * @returns {*|Object|undefined}
     */
    decline: function decline() {
      return this._operation('decline', 'post');
    },

    /**
     * Leave the user program.
     * @returns {*|Object|undefined}
     */
    leave: function leave() {
      return this._operation('leave', 'post');
    },

    /**
     *  Remove participation data.
     * @returns {*|Object|undefined}
     */
    remove: function remove() {
      return this._operation('remove', 'post');
    },
    _operation: function _operation(action) {
      var _this = this;

      return this.nonRestAction.apply(this, arguments).then(function (result) {
        if (Ember.isPresent(result) && action !== 'remove') {
          var store = Ember.get(_this, 'store');
          var modelName = _this.constructor.modelName || _this._internalModel.modelName;
          var cwFeaturesId = Ember.get(result, 'data.relationships.user-features.data.id'); // Push the payload first to make it safer to lookup cw-feature.

          store.pushPayload(modelName, result);

          if (Ember.isPresent(cwFeaturesId)) {
            store.peekRecord('cw-feature', cwFeaturesId);
          }
        }
      });
    }
  });

  _exports.default = _default;
});