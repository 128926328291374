define("fitbit-site-ui/models/friend/leaderboard/ranked-user", ["exports", "ember-data", "fitbit-site-ui/models/friend/leaderboard/base-user", "numbro"], function (_exports, _emberData, _baseUser, _numbro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUser.default.extend({
    stepRank: _emberData.default.attr('number'),
    stepSummary: _emberData.default.attr('number'),
    stepSummaryFormatted: Ember.computed('stepSummary', function () {
      var stepSummary = Ember.get(this, 'stepSummary') || 0;
      return (0, _numbro.default)(stepSummary).format();
    })
  });

  _exports.default = _default;
});