define("fitbit-site-ui/adapters/fhs-enrollment", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    namespace: '1/user/-/fhs-enrollment'
  });

  _exports.default = _default;
});