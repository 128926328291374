define("fitbit-site-ui/serializers/friend/leaderboard/person", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'person') {
        return this._super('friend/leaderboard/person');
      }

      return this._super(key);
    }
  });

  _exports.default = _default;
});