define("fitbit-site-ui/templates/components/device-sortable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MDqupjo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showDragHandle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"src\",\"/assets/images/drag-handle.png\"],[11,\"class\",\"drag-handle\"],[11,\"role\",\"presentation\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"device-sortable-item-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"device-sortable-item-body\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"disableTranslations\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,[\"display\",\"setting\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"format\",[[23,[\"display\",\"setting\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"span\"],[11,\"class\",\"device-sortable-item-meta\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isPrimaryGoal\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"main_goal_label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"switch-label\"],[9],[1,[27,\"if\",[[23,[\"display\",\"value\"]],[27,\"format\",[\"on\"],null],[27,\"format\",[\"off\"],null]],null],false],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"switch-container\"],[9],[0,\"\\n                \"],[1,[27,\"fit-switch\",null,[[\"class\",\"isEnabled\",\"onChange\",\"label\"],[\"small\",[23,[\"display\",\"value\"]],[23,[\"toggleAction\"]],[27,\"concat\",[\"Toggle \",[23,[\"display\",\"type\"]]],null]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-sortable-item.hbs"
    }
  });

  _exports.default = _default;
});