define("fitbit-site-ui/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kCJ/r4n8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-msg\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"missing-error-text-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"missing-error-centered\"],[9],[0,\"\\n            \"],[7,\"h1\"],[11,\"class\",\"error-header\"],[9],[1,[27,\"format-by-namespace\",[\"uh_oh\",\"templates.404\"],null],false],[10],[0,\"\\n            \"],[7,\"h1\"],[11,\"class\",\"error-header\"],[9],[1,[27,\"format-by-namespace\",[\"lost_you\",\"templates.404\"],null],false],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"error-text\"],[9],[1,[27,\"format-by-namespace\",[\"cant_find_page\",\"templates.404\"],null],false],[0,\" \"],[1,[27,\"format-by-namespace\",[\"typo\",\"templates.404\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"missing-error-image\"],[11,\"role\",\"presentation\"],[9],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/404.hbs"
    }
  });

  _exports.default = _default;
});