define("fitbit-site-ui/adapters/favorite-food", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user',
    disableRoot: true,
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var id = Ember.get(snapshot, 'record.id');
      return "".concat(this.buildURL(), "/-/foods/log/favorite/").concat(id, ".json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this.buildURL(), "/-/foods/log/favorite/").concat(id, ".json");
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.buildURL(), "/-/foods/log/favorite.json");
    }
  });

  _exports.default = _default;
});