define("fitbit-site-ui/controllers/friends/friend-accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['senderId', 'signup'],

    /**
     * The encoded user id of the inviting user.
     */
    senderId: null,

    /**
     * If the recipient of the invite doesn't have a fitbit account yet.
     */
    signup: null
  });

  _exports.default = _default;
});