define("fitbit-site-ui/templates/components/device-live-data-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uub/BGRK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row column panel gray\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column description\"],[11,\"data-test-qa\",\"live-data-description\"],[9],[0,\"\\n            \"],[1,[27,\"format-by-namespace\",[\"live_data_description\",\"templates.components.device-live-data-setting\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"shrink column switch-container\"],[11,\"data-test-qa\",\"live-data-switch-container\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"switch-label\"],[9],[0,\"\\n                \"],[1,[27,\"format-by-namespace\",[[27,\"if\",[[23,[\"value\"]],\"disabled\",\"enabled\"],null],\"common.messages\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[1,[27,\"fit-switch\",null,[[\"class\",\"isEnabled\",\"onChange\",\"label\"],[\"small\",[27,\"not\",[[23,[\"value\"]]],null],[27,\"action\",[[22,0,[]],\"changed\"],null],\"Toggle Live Data\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-live-data-setting.hbs"
    }
  });

  _exports.default = _default;
});