define("fitbit-site-ui/adapters/time-series/calorie", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    moment: Ember.inject.service(),
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName) {
      var snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var url = Ember.get(snapshot, 'adapterOptions.caloriesUrl');

      if (!url) {
        return "".concat(this.buildURL(id), "/activities/calories/date/").concat(id, "/1d/1min.json");
      }

      return url;
    },
    urlForQuery: function urlForQuery(query) {
      return "".concat(this._super(), "/activities/calories/date/").concat(query.startDate, "/").concat(query.endDate, ".json");
    }
  }); // GET /1/user/-/activities/calories/date/2016-05-07/1d/1min.json


  _exports.default = _default;
});