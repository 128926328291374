define("fitbit-site-ui/session-stores/fitbit-cookie", ["exports", "ember-simple-auth/session-stores/cookie", "ember-window-mock"], function (_exports, _cookie, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NEW_COOKIE_NAME = _exports.COOKIE_NAME = _exports.JWS_REGEX = void 0;
  var JWS_REGEX = /^[a-zA-Z0-9\-_]+?\.[a-zA-Z0-9\-_]+?\.([a-zA-Z0-9\-_]+)?$/;
  _exports.JWS_REGEX = JWS_REGEX;
  var COOKIE_NAME = 'oauth_access_token';
  _exports.COOKIE_NAME = COOKIE_NAME;
  var NEW_COOKIE_NAME = 's';
  /**
   * The Cookie store is specifically tailored to work with the oauth_access_token cookie that is created and stored by
   * weightsite login. It has been augmented to support delegate-api token exchange via webview-comms.
   */

  _exports.NEW_COOKIE_NAME = NEW_COOKIE_NAME;

  var _default = _cookie.default.extend({
    webviewAuth: Ember.inject.service(),
    cookies: Ember.inject.service(),
    cookieName: COOKIE_NAME,
    newCookieName: NEW_COOKIE_NAME,
    newCookieFieldSeparator: '|',

    /**
     * Overwriting _write() because login and logout occur in weightsite.
     * Once Snapdragon gets the oauth_access_token cookie, the app never
     * has to rewrite the cookie.
     */
    _write: function _write() {},
    restore: function restore() {
      var _this = this;

      if (this.webviewAuth.isSupported) {
        /**
         * Initiate delegate token exchange.
         * `webviewAuth.loadedToken` is a ember-concurrency Task instance,
         * so we just need to retrieve it because the task will exchange
         * and store the token as a cookie.
         */
        return this.webviewAuth.loadedToken.then(function () {
          /**
           * Restore from the cookie that should now contain the exchanged token.
           * Doing so to reduce complexity, as compared to writing logic to create and return
           * an auth object that `_restoreFromCookie` already does.
           */
          return _this._restoreFromCookie();
        }).catch(function () {
          /**
           * If there is a failure for some reason, then fallback to restore from cookie.
           */
          return _this._restoreFromCookie();
        });
      }

      return this._restoreFromCookie();
    },

    /**
     * Creates an auth object containing users auth credentials. Look up the
     * the data for the auth object in a new cookie, with fallback on the old one.
     * @return {Promise}
     * @private
     */
    _restoreFromCookie: function _restoreFromCookie() {
      var data = this._readFitbitOauthCookie(); //fallback to 'oauth_access_token' cookie


      if (Ember.isEmpty(data)) {
        data = this._read(this.cookieName);
      }

      var auth = {};

      if (!Ember.isEmpty(data) && data.match(JWS_REGEX)) {
        var authenticate = JSON.parse(_emberWindowMock.default.atob(data.split('.')[1]));
        var timeUntilExp = authenticate.exp - authenticate.iat;
        auth = {
          authenticated: {
            authenticator: 'authenticator:fitbit-password',
            access_token: data,
            expires_in: timeUntilExp,
            token_type: 'Bearer',
            user_id: authenticate.sub,
            expires_at: Date.now() + timeUntilExp
          }
        };
      }

      return Ember.RSVP.resolve(auth);
    },

    /**
     * Method to read auth token from a new `s` cookie, which will replace the `oauth_access_token` cookie.
     * The data looks like this: `.V3|auth_token`
     * @private
     * @returns {String|null}
     */
    _readFitbitOauthCookie: function _readFitbitOauthCookie() {
      var data = this._read(this.newCookieName);

      if (data) {
        var parts = data.split(this.newCookieFieldSeparator);

        if (parts.length >= 1) {
          data = parts[1];
        } else {
          data = null;
        }
      }

      return data;
    }
  });

  _exports.default = _default;
});