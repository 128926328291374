define("fitbit-site-ui/serializers/food-note-date", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/constants/food-logging"], function (_exports, _application, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var notes = Ember.get(payload, 'notes') || [];
      var noteIds = notes.map(function (note) {
        Ember.set(note, 'date', id);
        var mealType = Ember.get(note, 'mealType');
        Ember.set(note, 'mealType', _foodLogging.BE_TO_MEAL_TYPE[mealType]);
        return Ember.get(note, 'id');
      });
      var newPayload = {
        'food-note-date': {
          id: id,
          notes: noteIds,
          foodDateLog: id
        },
        'food-notes': notes
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});