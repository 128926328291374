define("fitbit-site-ui/routes/redirect-badges-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A redirect route to badges/all to provide flexibility in the future
   * for the general badges area
   */
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.replaceWith('badges');
    }
  });

  _exports.default = _default;
});