define("fitbit-site-ui/components/device-alarm-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    classNames: ['device-alarm-item'],
    timeFormat: null,
    timeFormatWithUTC: null,
    isClock12: null,
    UTCOffset: null,
    alarm: null,
    isEdit: false,
    // Read only so that only toggleEnabled action controls the change.
    isEnabled: Ember.computed.oneWay('alarm.enabled'),
    time: Ember.computed.oneWay('alarm.time'),
    formattedTime: Ember.computed('alarm.time', function () {
      var formatter = this.get('i18n');

      var _this$getProperties = this.getProperties('time', 'isClock12'),
          time = _this$getProperties.time,
          isClock12 = _this$getProperties.isClock12;

      var momentService = this.get('moment');
      var timeFormat = momentService.moment().localeData().longDateFormat('LT');
      var clockFormat = isClock12 ? formatter.formatTo12Hours(timeFormat, momentService.get('locale')) : formatter.formatTo24Hours(timeFormat);
      return momentService.moment(time, clockFormat).format(clockFormat);
    }),
    actions: {
      toggleEdit: function toggleEdit() {
        this.toggleProperty('isEdit');
      },
      toggleEnabled: function toggleEnabled(alarm) {
        alarm.toggleProperty('enabled');
        this.get('onEdit')(alarm);
      },
      // copy the updatedAlarmData onto the alarm and send the alarm off
      submit: function submit(alarm, alarmData) {
        var _this = this;

        this.get('onEdit')(alarm, alarmData).then(function () {
          _this.toggleProperty('isEdit');
        });
      },
      cancel: function cancel() {
        this.set('isEdit', false);
      },
      delete: function _delete(alarm) {
        this.get('onDelete')(alarm);
      }
    }
  });

  _exports.default = _default;
});