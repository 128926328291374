define("fitbit-site-ui/models/friend/inbox-invitation", ["exports", "fitbit-site-ui/models/friend/friend-invitation", "snapdragon-common/errors/too-many-connections-error"], function (_exports, _friendInvitation, _tooManyConnectionsError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _friendInvitation.default.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /**
     * Current user accepts this friend invitation
     * @returns {Promise}
     */
    acceptInvitation: function acceptInvitation() {
      return this.handleInvitation(true);
    },

    /**
     * Current user rejects this friend invitation
     * @returns {Promise}
     */
    declineInvitation: function declineInvitation() {
      return this.handleInvitation(false);
    },

    /**
     * Makes a call to the Accept/Reject friend request endpoint
     * @param accept {Boolean} - true if accept, false if reject
     * @returns {Promise}
     */
    handleInvitation: function handleInvitation(accept) {
      var _this = this;

      var currentUserId = Ember.get(this, 'currentUser.id') || '-';
      var sendId = Ember.get(this, 'user.id');
      var ajax = Ember.get(this, 'ajax');
      return ajax.post("/user/".concat(currentUserId, "/friends/invitations/").concat(sendId), {
        namespace: '1.1',
        dataType: 'text',
        data: {
          accept: accept
        }
      }).catch(function (err) {
        if (err instanceof _tooManyConnectionsError.default) {
          // Don't unload record, but display too many connections message
          throw err;
        } // In the case of any other error, pretend to show success


        return;
      }).then(function () {
        var store = Ember.get(_this, 'store'); // by responding (either accepting or declining) to a friend request the
        // request is no longer valid/exists.  Unload so it is no longer displayed.

        return store.unloadRecord(_this);
      });
    }
  });

  _exports.default = _default;
});