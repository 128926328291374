define("fitbit-site-ui/serializers/device/tracker/alarm", ["exports", "fitbit-site-ui/serializers/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;

  var _default = _application.default.extend({
    primaryKey: 'alarmId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'device/tracker/alarm';
    },
    _normalizeAlarm: function _normalizeAlarm(deviceID, alarm) {
      alarm.device = deviceID; // Associate the alarm to the alarm list.

      alarm.list = deviceID;
      return alarm;
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName);
      var pluralPayloadKey = inflector.pluralize(payloadKey);
      payload[pluralPayloadKey] = payload[pluralPayloadKey].map(function (alarm) {
        return _this._normalizeAlarm(id, alarm);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // API expects weekDays data to be comma separated.


      json.weekDays = json.weekDays.join(',');
      return json;
    }
  });

  _exports.default = _default;
});