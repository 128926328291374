define("fitbit-site-ui/templates/components/maintenance-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rA6pfkZN",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row align-center maintenance-error-container\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"column small-12\"],[11,\"data-test-qa\",\"maintenance-error-title\"],[9],[1,[27,\"format-by-namespace\",[\"is_unavailable\",\"templates.error\"],[[\"page\"],[[22,1,[]]]]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12 maintenance-header-image-conatiner\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/assets/images/error/maintenance.png\"],[11,\"class\",\"maintenance-header-image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column shrink\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"maintenance-error-logo\"],[11,\"role\",\"presentation\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"maintenance-error-text\"],[9],[1,[27,\"format-by-namespace\",[\"maintenance_error\",\"templates.error\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/maintenance-error.hbs"
    }
  });

  _exports.default = _default;
});