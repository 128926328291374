define("fitbit-site-ui/components/device-access", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorization: null,
    moment: Ember.inject.service(),
    ipAddress: Ember.computed.alias('authorization.sessionData.ip'),
    deviceModel: Ember.computed.alias('authorization.sessionData.device-model'),
    deviceName: Ember.computed.alias('authorization.sessionData.device-name'),
    operatingSystem: Ember.computed('authorization.sessionData', function () {
      var osName = Ember.get(this, 'authorization.sessionData.os-name');
      var osVersion = Ember.get(this, 'authorization.sessionData.os-version');
      return osName && osVersion && "".concat(osName, " ").concat(osVersion);
    }),
    browser: Ember.computed('authorization.sessionData', function () {
      var browserName = Ember.get(this, 'authorization.sessionData.browser-name');
      var browserVersion = Ember.get(this, 'authorization.sessionData.browser-version');
      return browserVersion ? "".concat(browserName, " ").concat(browserVersion) : browserName;
    }),
    browserIcon: Ember.computed.alias('authorization.sessionData.browser-icon'),
    deviceImage: Ember.computed.alias('authorization.sessionData.device-image'),
    isCurrentSession: Ember.computed.alias('authorization.isCurrentSession'),
    isRevocable: Ember.computed.alias('authorization.isRevocable'),
    location: Ember.computed('authorization.sessionData', function () {
      var city = Ember.get(this, 'authorization.sessionData.city');
      var country = Ember.get(this, 'authorization.sessionData.country');
      return city ? "".concat(city, ", ").concat(country) : country;
    }),
    formattedAuthorizationTime: Ember.computed('authorization.authorizedDate', function () {
      var authorizedDate = Ember.get(this, 'authorization.authorizedDate');
      var momentService = Ember.get(this, 'moment');
      var now = momentService.moment();
      var authorizedDateMoment = momentService.moment(authorizedDate);

      var duration = _moment.default.duration(now.diff(authorizedDateMoment));

      if (duration.asHours() < 1) {
        return "".concat(Math.floor(duration.asMinutes()), " minutes ago");
      } else if (duration.asHours() < 24) {
        return "".concat(Math.floor(duration.asHours()), " hours ago");
      } else {
        return authorizedDateMoment.format('LL');
      }
    })
  });

  _exports.default = _default;
});