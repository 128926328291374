define("fitbit-site-ui/models/privacy-settings-ui-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    settings: _emberData.default.hasMany('privacy-settings'),
    name: Ember.computed.alias('id')
  });

  _exports.default = _default;
});