define("fitbit-site-ui/serializers/cw-program-participant", ["exports", "fitbit-site-ui/serializers/application-jsonapi", "fitbit-site-ui/constants/corporate-program-states"], function (_exports, _applicationJsonapi, _corporateProgramStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    /**
     * Override to return the proper model name.
     * @param payloadKey
     * @returns {string}
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'program-participant':
          return 'cw-program-participant';

        case 'program':
          return 'cw-program';

        case 'corporate-user-features':
          return 'cw-feature';

        default:
          return this._super.apply(this, arguments);
      }
    },

    /**
     * Override `keyForAttribute` method to return the key, untouched, because JSONAPI serializer dasherizes attribute
     * keys by default.
     * @param {String} key
     * @returns {String}
     */
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      /**
       *  This clears the data for any program that is not current.
       *  It is needed so we don't auto fill fields in programs the user already left.
       */
      if (requestType === 'findAll') {
        var normalized = this._super.apply(this, arguments);

        var programs = Ember.get(normalized, 'data');
        programs.forEach(function (program) {
          var attributes = Ember.get(program, 'attributes');
          /**
           *  If it is not the current program, clear the attributes except for
           *     programState, status, records and onboardingToken.
           */

          if (Ember.get(attributes, 'programState') !== _corporateProgramStates.CURRENT_PROGRAM) {
            var records;

            if (Ember.get(attributes, 'records')) {
              records = Ember.get(attributes, 'records');
            }

            var state = Ember.get(attributes, 'programState');
            var status = Ember.get(attributes, 'status');
            var token = Ember.get(attributes, 'onboardingToken');
            program.attributes = {
              programState: state,
              records: records,
              onboardingToken: token,
              status: status
            };
          }
        });
        return normalized;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Overriding serialize because endpoint expects program fields for update method.
     * @returns {object}
     */
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      return json.data.attributes.fields;
    }
  });

  _exports.default = _default;
});