define("fitbit-site-ui/templates/components/linear-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1xwXPz9X",
    "block": "{\"symbols\":[\"v\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"linear-input\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"minimumLabel\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"input-label\"],[9],[1,[21,\"minimumLabel\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"input-choices\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"steps\"]]],null,{\"statements\":[[0,\"            \"],[7,\"label\"],[12,\"class\",[28,[\"linear-input-item \",[27,\"if\",[[27,\"eq\",[[23,[\"selectionIndex\"]],[22,2,[]]],null],\"active\"],null]]]],[9],[0,\"\\n                \"],[1,[27,\"get\",[[23,[\"formattedSteps\"]],[22,2,[]]],null],false],[0,\"\\n                \"],[7,\"input\"],[12,\"name\",[28,[\"radio-\",[21,\"elementId\"]]]],[12,\"value\",[28,[[27,\"get\",[[23,[\"formattedSteps\"]],[22,2,[]]],null]]]],[12,\"checked\",[28,[[27,\"if\",[[27,\"eq\",[[23,[\"selectionIndex\"]],[22,2,[]]],null],\"checked\"],null]]]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"valueChanged\",[22,2,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"maximumLabel\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"input-label\"],[9],[1,[21,\"maximumLabel\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/linear-input.hbs"
    }
  });

  _exports.default = _default;
});