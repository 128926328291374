define("fitbit-site-ui/adapters/legacy-food-date-log", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord(id) {
      var baseURL = this.buildURL();
      return "".concat(baseURL, "/-/foods/log/date/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});