define("fitbit-site-ui/models/achievement", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /**
     * Is true to indicate a trophy was achieved
     */
    achieved: _emberData.default.attr('boolean'),

    /**
     * These properties are always available
     * whether or not a trophy was earned
     */
    timesAchieved: _emberData.default.attr('number'),
    challengeName: _emberData.default.attr('string'),
    challengeType: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    achievementType: _emberData.default.attr('string'),

    /**
     * If trophy was achieved, these
     * properties are available
     */
    title: _emberData.default.attr('string'),
    lastEarned: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),

    /**
     * If trophy was NOT achieved, these
     * properties are available
     */
    achievementInstructions: _emberData.default.attr('string')
  });

  _exports.default = _default;
});