define("fitbit-site-ui/adapters/device/tracker/auto-exercise-settings", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    disableRoot: true,
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._super(null, null, snapshot) + '/exercises/settings.json';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._super(null, modelName, snapshot) + '/exercises/settings.json';
    }
  });

  _exports.default = _default;
});