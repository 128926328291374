define("fitbit-site-ui/models/privacy-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Created by mfeltz on 12/1/16.
   */
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    value: _emberData.default.attr('string'),
    options: _emberData.default.attr(),
    privacySettingsUiGroup: _emberData.default.belongsTo('privacy-settings-ui-group')
  });

  _exports.default = _default;
});