define("fitbit-site-ui/services/deezer-ajax", ["exports", "fitbit-site-ui/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    namespace: '/juno',
    svcid: 'fdfdda7d-0d3f-4923-b0fc-7cfe710f0562'
  });

  _exports.default = _default;
});