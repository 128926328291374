define("fitbit-site-ui/serializers/activity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    HR_CHART_PADDING: 20000,
    // milliseconds
    primaryKey: 'logId',
    attrs: {
      date: 'startTime'
    },

    /**
     * Convert root key to the correct model name.
     *
     * `activityLog` key will be replaced with `activity` model name.
     *
     * All others will be determined by the parent's method.
     *
     * @param {String} key
     * @returns {String}
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'activityLog') {
        return 'activity';
      }

      return this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.activityLog && payload.activityLog.heartRateLink) {
        payload.activityLog.heartRateLink = this._padHeartRateData(payload.activityLog);
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Due to the heart rate data being irregular in terms of bpm values every 5-10 secs on avg,
     * there are times when the start and end times do not have a bpm value. By adding padding
     * and extrapolating data to fill each data point (one per sec) we can generate the missing bpm
     * @param {Object} activityLog
     * @returns {String} - the api request url
     * @private
     */
    _padHeartRateData: function _padHeartRateData(activityLog) {
      var momentService = Ember.get(this, 'moment');
      var momentFormat = Ember.get(this, 'constants.NON_LOCALIZED_FORMAT');
      var padding = Ember.get(this, 'HR_CHART_PADDING');
      var durationPadding = activityLog.duration + padding;
      var heartRateLinkPrefix = activityLog.heartRateLink.split('date')[0];
      var startTime = momentService.moment(activityLog.startTime, momentFormat);
      var endTime = startTime.clone().add(durationPadding, 'ms');
      startTime = startTime.subtract(padding, 'ms');
      return "".concat(heartRateLinkPrefix, "date/").concat(startTime.format('YYYY-MM-DD'), "/").concat(endTime.format('YYYY-MM-DD'), "/1sec/time/").concat(startTime.format('HH:mm:ss'), "/").concat(endTime.format('HH:mm:ss'), ".json");
    }
  });

  _exports.default = _default;
});