define("fitbit-site-ui/adapters/friend", ["exports", "fitbit-site-ui/adapters/application", "snapdragon-common/errors/private-data-error"], function (_exports, _application, _privateDataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/user',

    /**
     * Checks if the API returned a 403, which is what happens when you view another user's profile
     *    and their achievements are private
     *
     * @return {*}
     */
    handleResponse: function handleResponse(status, headers, body) {
      return status === 403 ? new _privateDataError.default() : this._super(status, headers, body);
    },
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '/friends.json';
    }
  });

  _exports.default = _default;
});