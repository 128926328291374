define("fitbit-site-ui/models/user-notification", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    message: _emberData.default.attr('string'),
    notificationType: _emberData.default.attr('string'),
    isRead: _emberData.default.attr('boolean'),
    sentBy: (0, _relationships.belongsTo)('profile'),
    time: _emberData.default.attr('number'),
    timeRelative: _emberData.default.attr('string'),
    ajax: Ember.inject.service(),

    /**
     * updates read status on server
     * @returns {*}
     */
    markAsRead: function markAsRead() {
      var _this = this;

      var ajax = this.get('ajax');
      var notificationId = this.get('id');
      return ajax.put("/notifications-service/user/-/destination/NOTIFICATION_CENTER/notifications/".concat(notificationId), {
        namespace: '1',
        dataType: 'text',
        contentType: 'application/json',
        data: {
          isRead: true
        }
      }).then(function () {
        return _this.set('isRead', true);
      });
    }
  });

  _exports.default = _default;
});