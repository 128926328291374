define("fitbit-site-ui/adapters/application", ["exports", "ember-data", "snapdragon-common/mixins/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_application.default, {
    namespace: '1/user/-',
    methodPostForTypes: Ember.computed(function () {
      return ['PUT'];
    })
  });

  _exports.default = _default;
});