define("fitbit-site-ui/models/covid-survey", ["exports", "covid-survey-engine/models/covid-survey"], function (_exports, _covidSurvey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _covidSurvey.default;
    }
  });
});