define("fitbit-site-ui/adapters/corporate", ["exports", "ember-data", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _emberData, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = _applicationJsonapi.default.extend({
    namespace: '1/corporate',
    ajax: function ajax() {
      if (arguments[1] === 'PUT') {
        arguments[1] = 'POST';
      }

      return JSONAPIAdapter.prototype.ajax.apply(this, arguments);
    }
  });

  _exports.default = _default;
});