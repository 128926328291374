define("fitbit-site-ui/serializers/food-log", ["exports", "fitbit-site-ui/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    primaryKey: 'logId',
    attrs: {
      date: 'logDate'
    },
    normalize: function normalize(model, hash) {
      if (!hash.normalized) {
        hash = this._normalizeFoodLog(hash);
      }

      return this._super(model, hash);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.foodDay) {
        payload.foodDay.id = payload.foodDay.date;
      }

      var foodLog = payload.foodLog;
      var foodItem = foodLog.food && foodLog.food;
      foodLog = this._normalizeFoodLog(foodLog);
      var newPayload = {
        'food-date-logs': payload.foodDay,
        'food/food-item': foodItem ? [foodItem] : [],
        'food-log': [foodLog]
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },

    /**
     * Pass up the proper attributes for a food that was just created.
     * @param snapshot
     */
    serialize: function serialize(snapshot) {
      var json = {};

      if (snapshot.adapterOptions) {
        json = snapshot.adapterOptions;
        json.mealType = json.mealType.replace('_', ' ');
      }

      return json;
    },

    /**
     * Modify foodLog with the foodId to create relationships to the foodItem and loggedFood models
     * @param foodLog
     * @returns {Object}
     */
    _normalizeFoodLog: function _normalizeFoodLog(foodLog) {
      if (!foodLog.isQuickCaloriesAdd) {
        if (foodLog.food) {
          foodLog.foodItem = foodLog.food.foodId;
          foodLog.nutritionalValues = foodLog.food.nutritionalValues;
          foodLog.servings = foodLog.food.servings;
          delete foodLog.food;
        }
      }

      foodLog.normalized = true;
      return foodLog;
    }
  });

  _exports.default = _default;
});