define("fitbit-site-ui/templates/components/double-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rAoYzXTv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"columns shrink\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"label-input first-radio\"],[12,\"data-test-qa\",[28,[[21,\"propertyName\"],\"-\",[21,\"firstDescription\"]]]],[9],[0,\"\\n            \"],[1,[27,\"radio-button\",null,[[\"value\",\"groupValue\",\"radioClass\"],[[23,[\"firstValue\"]],[23,[\"toggleValue\"]],[23,[\"propertyName\"]]]]],false],[0,\"\\n            \"],[1,[21,\"firstDescription\"],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"columns shrink\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"label-input second-radio\"],[12,\"data-test-qa\",[28,[[21,\"propertyName\"],\"-\",[21,\"secondDescription\"]]]],[9],[0,\"\\n            \"],[1,[27,\"radio-button\",null,[[\"value\",\"groupValue\",\"radioClass\"],[[23,[\"secondValue\"]],[23,[\"toggleValue\"]],[23,[\"propertyName\"]]]]],false],[0,\"\\n            \"],[1,[21,\"secondDescription\"],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/double-radio-button.hbs"
    }
  });

  _exports.default = _default;
});