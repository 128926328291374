define("fitbit-site-ui/serializers/device/scale/invited-user-list", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var scaleUserIds = [];
      var payloadKey = primaryModelClass.modelName,
          scaleUsers = payload.scaleInvites,
          inviteArr = [];
      scaleUsers.forEach(function (scaleUser) {
        scaleUserIds.push(scaleUser.inviteId);
        inviteArr.push({
          id: scaleUser.inviteId,
          type: 'device/scale/invited-user',
          attributes: scaleUser
        });
      });

      var data = _defineProperty({}, payloadKey, {
        users: scaleUserIds,
        id: id
      });

      var ret = this._super(store, primaryModelClass, data, id, requestType);

      ret.included = inviteArr;
      return ret;
    }
  });

  _exports.default = _default;
});