define("fitbit-site-ui/templates/unauthenticated", ["exports", "snapdragon-common/templates/unauthenticated"], function (_exports, _unauthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _unauthenticated.default;
    }
  });
});