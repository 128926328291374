define("fitbit-site-ui/components/device-live-data-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    value: null,
    onChange: function onChange() {},
    actions: {
      changed: function changed() {
        var _this = this;

        var formatter = this.get('i18n');
        return Ember.RSVP.resolve(Ember.get(this, 'onChange')(!Ember.get(this, 'value'))).then(function () {
          _this.showFlashMessage('success', formatter.formatByNamespace('device_setting_saved', 'common.messages'));
        }).catch(function () {
          _this.showFlashMessage('alert', formatter.formatByNamespace('device_setting_err', 'common.messages'));
        });
      }
    }
  });

  _exports.default = _default;
});