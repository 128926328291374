define("fitbit-site-ui/serializers/custom-food", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/utils/unit-converter"], function (_exports, _application, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'foodId',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.isPresent(payload)) {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      var foods = payload.foods || [payload.food];
      foods.forEach(function (food) {
        if (!Ember.get(food, 'defaultServingSize')) {
          var defaultServingUnit = Ember.get(food, 'defaultServingUnit');
          var multiplier = Ember.get(food, "servings.".concat(defaultServingUnit, ".multiplier"));
          Ember.set(food, 'defaultServingSize', _unitConverter.default.roundToDigits(1 / multiplier, 2));
        }

        var foodId = Ember.get(food, 'foodId');
        Ember.set(food, 'foodItem', foodId);
        Ember.set(food, 'favorite', null);
      });
      var newPayload = {
        'food/food-item': foods,
        'custom-food': payload.food || payload.foods.map(function (food) {
          return {
            foodItem: food.foodId,
            foodId: food.foodId,
            servings: food.servings
          };
        })
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serialize: function serialize(snapshot) {
      var json = {};

      if (snapshot.adapterOptions) {
        json = snapshot.adapterOptions;
      }

      return json;
    }
  }); // adapterOptions to be passed to createRecord and updateRecord
  // Example of a food item
  // adapterOptions: {
  //     name: 'Marg\'s Cranberries',
  //         brand: 'Bananskis',
  //         defaultServingUnit: 'apricot',
  //         defaultServingSize: 10,
  //         calories: 200
  // }


  _exports.default = _default;
});