define("fitbit-site-ui/controllers/settings/corporate", ["exports", "snapdragon-common/constants/user-agent", "snapdragon-common/constants/app-store-urls"], function (_exports, _userAgent, _appStoreUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    firebase: Ember.inject.service(),
    isMobile: _userAgent.IS_MOBILE,
    dashboardUrl: null,
    appStoreUrl: _appStoreUrls.APPLE_APP_STORE_URL,
    playStoreUrl: _appStoreUrls.GOOGLE_PLAY_STORE_URL,
    init: function init() {
      this._super.apply(this, arguments);

      this.dashboardUrl = this.firebase.link({
        orStore: true
      });
    }
  });

  _exports.default = _default;
});