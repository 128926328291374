define("fitbit-site-ui/adapters/application-authorization", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.splitRecordId = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var splitRecordId = function splitRecordId(id) {
    var _id$split = id.split('-'),
        _id$split2 = _slicedToArray(_id$split, 2),
        applicationId = _id$split2[0],
        authorizationId = _id$split2[1];

    return {
      applicationId: applicationId,
      authorizationId: authorizationId
    };
  };

  _exports.splitRecordId = splitRecordId;

  var _default = _applicationJsonapi.default.extend({
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super.apply(this, arguments), ".json");
    },

    /**
     * Override urlForDeleteRecord to support two different API endpoints depending on whether the application-authorization is
     * a first party or third party authorization.
     *
     * First Party:
     * https://wiki.fitbit.com/display/API/Application+Authorization+APIs#ApplicationAuthorizationAPIs-RevokeApplicationAuthorization
     *
     * Third Party:
     * https://wiki.fitbit.com/display/API/Application+Authorization+APIs#ApplicationAuthorizationAPIs-RevokeAllAuthorizationsforApp+User
     *
     * @param id
     * @param modelName
     * @param snapshot
     * @return {String}
     */
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var isFirstPartyApp = Ember.get(snapshot, 'record.application.firstPartyApp');

      if (isFirstPartyApp) {
        return this._super.apply(this, arguments);
      } else {
        var _splitRecordId = splitRecordId(id),
            applicationId = _splitRecordId.applicationId;

        return "".concat(this._super(undefined, modelName, snapshot), "?filter[application.id]=").concat(applicationId);
      }
    }
  });

  _exports.default = _default;
});