define("fitbit-site-ui/components/device-main-goal-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    selectableMainGoalOptions: Ember.computed('mainGoalOptions.[]', function () {
      var mainGoalOptions = this.mainGoalOptions,
          formatter = this.i18n;
      return mainGoalOptions.filter(function (opt) {
        try {
          return formatter.formatByNamespace(opt, 'templates.components.device-main-goal-setting');
        } catch (e) {
          return undefined;
        }
      });
    }),
    actions: {
      selectMainGoalOption: function selectMainGoalOption(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.set('mainGoalSetting', value);
        this.get('settings').then(function (model) {
          return model.save().then(function () {
            var successMessage = formatter.format('device_setting_saved');

            _this._clearMessageFromQueue(successMessage);

            flashMessages.success(successMessage);
          });
        });
      }
    }
  });

  _exports.default = _default;
});