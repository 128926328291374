define("fitbit-site-ui/serializers/cw-program", ["exports", "fitbit-site-ui/serializers/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    /**
     * Override to return the proper model name.
     * @param payloadKey
     * @returns {string}
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'program-participant':
          return 'cw-program-participant';

        case 'program':
          return 'cw-program';

        case 'corporate-user-features':
          return 'feature';

        default:
          return this._super.apply(this, arguments);
      }
    },

    /**
     * Override `keyForAttribute` method to return the key, untouched, because JSONAPI serializer dasherizes attribute
     * keys by default.
     * @param {String} key
     * @returns {String}
     */
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },

    /**
     * Override `keyForRelationship` method to return the key, untouched, because JSONAPI serializer dasherizes
     * relationship keys by default.
     * @param {String} key
     * @returns {String}
     */
    keyForRelationship: function keyForRelationship(key) {
      return key;
    }
  });

  _exports.default = _default;
});