define("fitbit-site-ui/components/user-profile-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    coverPhotoDefault: '/assets/images/user-profile/profile-bg.svg'
  });

  _exports.default = _default;
});