define("fitbit-site-ui/services/i18n", ["exports", "ember-fitbit-i18n/services/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _i18n.default.extend({
    /**
     * Override to handle additional cases.
     * @param {String} locale
     */
    calculateLanguageLocale: function calculateLanguageLocale(locale) {
      var supportedLocales = Ember.get(this, 'supportedLocales');
      var languageLocale;

      switch (true) {
        // We have this added before the supported locales because we need to map ru_BY to return ru_RU
        case locale === 'ru_BY':
          languageLocale = 'ru_RU';
          break;
        // Check for supported locales first. This is a quicker check than following through the switch cases.
        // In cases where we have a locale that has the same language, this allows us to check for the right region before
        // following through to fallback on a language match.

        case supportedLocales.includes(locale):
          languageLocale = locale;
          break;

        case supportedLocales.includes('nb_NO') && /^nn|^nb|^no/i.test(locale):
          languageLocale = 'nb_NO';
          break;
        // Chinese locale could contain "script" subtags like Hans or HK

        case /(?=.*?(zh)+)(?=.*?(Hans)+)/i.test(locale):
          languageLocale = 'zh_CN';
          break;
        // Chinese locale could contain "script" subtags like Hant or TW

        case /(?=.*?(zh)+)(?=.*?(Hant|TW|HK)+)/i.test(locale):
          languageLocale = 'zh_TW';
          break;
        // Match only langages if we don't know what the region is.

        case /^zh/.test(locale):
          languageLocale = 'zh_TW';
          break;

        case /^ru/.test(locale):
          languageLocale = 'ru_RU';
          break;

        case /^de/.test(locale):
          languageLocale = 'de_DE';
          break;

        case /^nl/.test(locale):
          languageLocale = 'nl_NL';
          break;

        case /^sv/.test(locale):
          languageLocale = 'sv_SE';
          break;

        case /^ar/.test(locale):
          languageLocale = 'ar_SA';
          break;

        case /^cs/.test(locale):
          languageLocale = 'cs_CZ';
          break;

        case /^pl/.test(locale):
          languageLocale = 'pl_PL';
          break;

        case /^pt/.test(locale):
          languageLocale = 'pt_BR';
          break;

        case /^ro/.test(locale):
          languageLocale = 'ro_RO';
          break;

        case /^in/.test(locale):
          languageLocale = 'in_ID';
          break;

        case /^id/.test(locale):
          languageLocale = 'id_ID';
          break;
        // Singapore English needs to be mapped to British English.
        // Fallback to any language that isn't detected for Singapore is mapped to British English.

        case locale === 'en_SG' || /(?=.*?(SG)+)/.test(locale):
          languageLocale = 'en_GB';
          break;

        default:
          languageLocale = this._super(locale);
      }

      return languageLocale;
    },
    userLanguage: Ember.computed('userLanguageLocale', function () {
      var userLanguageLocale = this.userLanguageLocale;
      return userLanguageLocale.split('_')[0];
    })
  });

  _exports.default = _default;
});