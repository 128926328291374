define("fitbit-site-ui/models/my-application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    organization: _emberData.default.attr('string'),
    organizationUri: _emberData.default.attr('string'),
    permissions: _emberData.default.attr('string'),
    applicationAuthorizations: _emberData.default.hasMany('application-authorization'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    firstPartyApp: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});