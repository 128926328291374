define("fitbit-site-ui/adapters/device/scale/user", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    pathForType: function pathForType() {
      return 'devices/scale';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      //Because you update the scale user by providing the scale id rather than the user id (╯°□°）╯︵ ┻━┻
      var scaleId = snapshot.attr('scaleId');
      return this._super(scaleId, modelName, snapshot) + '/users.json';
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var scaleId = snapshot.adapterOptions.scaleId;
      return this._super(scaleId, modelName, snapshot) + "/users/".concat(id, ".json");
    }
  });

  _exports.default = _default;
});