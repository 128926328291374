define("fitbit-site-ui/adapters/journal-survey", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    methodPostForTypes: Ember.computed(function () {
      return [];
    }),
    namespace: Ember.computed('currentUser.id', function () {
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "1/user/".concat(currentUserId || '-', "/journal/surveys");
    }),
    urlForCreateRecord: function urlForCreateRecord(query) {
      var namespace = query.namespace,
          reference = query.reference;
      return "".concat(this._super(), "/").concat(namespace, "/").concat(reference);
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _snapshot$record = snapshot.record,
          reference = _snapshot$record.reference,
          namespace = _snapshot$record.referenceNamespace;

      if (!reference || !namespace) {
        throw new Error('reference or referenceNamespace not defined');
      }

      var url = this.urlForCreateRecord({
        reference: reference,
        namespace: namespace
      });
      return this.ajax(url, 'GET');
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var _snapshot$record2 = snapshot.record,
          reference = _snapshot$record2.reference,
          namespace = _snapshot$record2.referenceNamespace;
      return "".concat(this._super(), "/").concat(namespace, "/").concat(reference);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var _snapshot$record3 = snapshot.record,
          reference = _snapshot$record3.reference,
          namespace = _snapshot$record3.referenceNamespace;
      return "".concat(this._super(), "/").concat(namespace, "/").concat(reference);
    }
  });

  _exports.default = _default;
});