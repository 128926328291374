define("fitbit-site-ui/components/fitbit-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fitbit-radio-button'],
    actions: {
      valueChanged: function valueChanged(newValue) {
        var setting = this.get('setting');
        this.get('changeFnc')(setting, newValue);
      }
    }
  });

  _exports.default = _default;
});