define("fitbit-site-ui/services/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Identify the specific page type to group pages
     * @type {String|null}
     */
    pageType: null,

    /**
     * Identify the specific page name in a group of pages
     * @type {String|null}
     */
    pageName: null,

    /**
     * Reset the service properties
     */
    reset: function reset() {
      Ember.setProperties(this, {
        pageType: null,
        pageName: null
      });
    }
  });

  _exports.default = _default;
});