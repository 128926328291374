define("fitbit-site-ui/components/profile-settings-crop-image", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _imageCropper.default.extend({
    aspectRatio: Ember.computed.alias('cropAspectRatio'),
    //overrides imageCropper default of 16:9
    isLoading: false,
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    i18nKeyCropSuccess: null,
    i18nKeyCropError: null,
    endPoint: null,

    /**
     * Event handler triggered when "Cancel" button is clicked.
     */
    canceledUploadFnc: function canceledUploadFnc() {},
    // Borrowed from https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    // As a temporary patch for Safari and IE
    polyfillToBlob: function polyfillToBlob(croppedImage) {
      Object.defineProperty(croppedImage, 'toBlob', {
        value: function value(callback, type, quality) {
          var binStr = atob(this.toDataURL(type, quality).split(',')[1]);
          var len = binStr.length;
          var arr = new Uint8Array(len);

          for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], {
            type: type || 'image/png'
          }));
        }
      });
    },
    actions: {
      getCroppedImage: function getCroppedImage() {
        var scope = this; // eslint-disable-line consistent-this

        var endPoint = Ember.get(this, 'endPoint');
        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var successMessage = Ember.get(this, 'i18nKeyCropSuccess');
        var errorMessage = Ember.get(this, 'i18nKeyCropError');
        var container = this.$(Ember.get(this, 'cropperContainer'));
        var croppedImage = container.cropper('getCroppedCanvas');
        var buffer; // Safari and IE need this polyfill

        if (!croppedImage.toBlob) {
          scope.polyfillToBlob(croppedImage);
        }

        croppedImage.toBlob(function (blob) {
          var fileReader = new FileReader();

          fileReader.onload = function () {
            buffer = this.result;
            Ember.set(scope, 'isLoading', true);
            Ember.get(scope, 'ajax').post(endPoint, {
              data: buffer,
              processData: false,
              contentType: 'text/plain'
            }).then(function (images) {
              //Updates the profile model with new images
              Ember.get(scope, 'imageUpdatedFnc')(images);
              Ember.set(scope, 'uploadedImage', '');
              flashMessages.success(formatter.format(successMessage));
            }).catch(function (err) {
              flashMessages.alert(err.message || formatter.format(errorMessage));
            }).finally(function () {
              return Ember.set(scope, 'isLoading', false);
            });
          };

          fileReader.readAsArrayBuffer(blob);
        });
      }
    }
  });

  _exports.default = _default;
});