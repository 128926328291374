define("fitbit-site-ui/templates/settings/profile-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHZIu3O2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"app-loader \",[27,\"unless\",[[23,[\"isLoading\"]],\"hide\"],null]]]],[9],[1,[27,\"format\",[\"loading\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"profile-settings\"],[9],[0,\"\\n    \"],[1,[27,\"profile-settings\",null,[[\"profile\",\"onCitySearch\",\"onSaveEmailAddress\",\"onCancelEmailAddress\",\"onResendEmailAddress\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"citySearch\"],null],[27,\"action\",[[22,0,[]],\"saveEmailAddress\"],null],[27,\"action\",[[22,0,[]],\"cancelEmailAddress\"],null],[27,\"action\",[[22,0,[]],\"resendEmailAddress\"],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/profile-settings.hbs"
    }
  });

  _exports.default = _default;
});