define("fitbit-site-ui/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;

  function initialize()
  /* application */
  {
    inflector.irregular('settings', 'settings');
    inflector.irregular('options', 'options');
  }

  var _default = {
    name: 'custom-inflector-rules',
    initialize: initialize
  };
  _exports.default = _default;
});