define("fitbit-site-ui/adapters/fhs-enrollment-program-feature", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // api url: '1/user/-/fhs-enrollment/:programId/benefits.json',
  var _default = _applicationJsonapi.default.extend({
    namespace: '1/user/-/fhs-enrollment',
    // id should be program id
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var membershipId = snapshot.adapterOptions.membershipId;
      var baseUrl = this.buildURL();
      return "".concat(baseUrl, "/").concat(membershipId, "/benefits.json");
    }
  });

  _exports.default = _default;
});