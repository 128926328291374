define("fitbit-site-ui/controllers/friends/index", ["exports", "fitbit-site-ui/constants/friends"], function (_exports, _friends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    MAX_EMAIL_INVITES: _friends.MAX_EMAIL_INVITES,

    /**
     * DOMPurify option to return entire document including <html> tags.
     * This is needed for passing the whole <html> document to the Email Preview IFrame.
     * @type {Boolean}
     */
    DOMPURIFY_WHOLE_DOCUMENT: true,

    /**
     * DOMPurify option to leave all as it is but forbid identified attributes.
     * Forbidding `href` prevents links from working in the Email Preview IFrame.
     */
    DOMPURIFY_FORBID_ATTR: Object.freeze(['href']),
    isLoading: false,
    inviteByEmailModal: null,
    emailTags: Ember.computed(function () {
      return [];
    }),

    /**
     * Friends record.
     * @returns {Object}
     */
    friends: Ember.computed.readOnly('model.friends'),

    /**
     * Friends ordered by rank. Excludes inactive users and hidden users.
     * Includes the current user.
     * @returns {Array}
     */
    orderedRankedUsers: Ember.computed.readOnly('friends.orderedRankedUsers'),

    /**
     * Inactive friends. Filtered to exclude the current user.
     * @returns {Array}
     */
    inactiveUsers: Ember.computed.filter('friends.inactiveUsers', function (user) {
      return Ember.get(user, 'id') !== Ember.get(this, 'currentUser.id');
    }),

    /**
     * Hidden friends. Filtered to exclude the current user.
     * @returns {Array}
     */
    hiddenUsers: Ember.computed.filter('friends.hiddenUsers', function (user) {
      return Ember.get(user, 'id') !== Ember.get(this, 'currentUser.id');
    }),

    /**
     * Checks to see if *simple*, valid email format
     * @param email {String} - email to check if valid
     * @returns {Boolean} - true if email is valid
     */
    checkEmailValidity: function checkEmailValidity(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    /**
     * ember-tags-input required function
     * Called when adding a tag by the ember-tags-input component
     * @param tag
     */
    addEmailTag: function addEmailTag(tag) {
      var emailTags = Ember.get(this, 'emailTags');

      if (this.checkEmailValidity(tag)) {
        if (!emailTags.length) {
          this.getFriendInvitePreview(tag);
        }

        emailTags.addObject(tag);
      }
    },

    /**
     * ember-tags-input required function
     * Called when replacing a tag by the ember-tags-input component
     * @param tag
     * @param index
     */
    replaceEmailTagAtIndex: function replaceEmailTagAtIndex(tag, index) {
      var emailTags = Ember.get(this, 'emailTags');

      if (this.checkEmailValidity(tag)) {
        emailTags.replace(index, 1, [tag]);
      } else {
        this.removeEmailTagAtIndex(index);
      }

      this._updateFriendInvitePreview(index, emailTags);
    },

    /**
     * ember-tags-input required function
     * Called when replacing a set of tags by the ember-tags-input component
     * @param tags
     * @param index
     */
    replaceEmailTagWithTagsAtIndex: function replaceEmailTagWithTagsAtIndex(tags, index) {
      var _this = this;

      var emailTags = Ember.get(this, 'emailTags'); // Filter out any invalid emails

      tags.filter(function (tag) {
        return _this.checkEmailValidity(tag);
      });
      emailTags.replace(index, 1, tags);

      this._updateFriendInvitePreview(index, emailTags);
    },

    /**
     * ember-tags-input required function
     * Called when removing a tag by the ember-tags-input component
     * @param index
     */
    removeEmailTagAtIndex: function removeEmailTagAtIndex(index) {
      var emailTags = Ember.get(this, 'emailTags');
      emailTags.removeAt(index);

      this._updateFriendInvitePreview(index, emailTags);
    },
    _updateFriendInvitePreview: function _updateFriendInvitePreview(index, emailTags) {
      if (!emailTags.length) {
        Ember.set(this, 'friendInvitePreview', null);
      } else if (index === 0) {
        this.getFriendInvitePreview(Ember.get(emailTags, 'firstObject'));
      }
    },

    /**
     * Makes a request for the rendered invite preview displayed in the invite modal
     * and sets 'friendInivitePreview' with the resulting html
     * @param email
     * @returns {*}
     */
    getFriendInvitePreview: function getFriendInvitePreview(email) {
      var _this2 = this;

      var currentUserId = Ember.get(this, 'currentUser.id');
      var ajax = Ember.get(this, 'ajax');
      return ajax.request("/user/".concat(currentUserId, "/friends/invitations/preview.html"), {
        namespace: '1.1',
        dataType: 'html',
        data: {
          invitedUserEmail: email
        }
      }).then(function (html) {
        Ember.set(_this2, 'friendInvitePreview', html);
      });
    },
    friendInvitePreview: null,
    alreadyFriendsEmailErrors: Ember.computed(function () {
      return [];
    }),
    otherEmailErrors: Ember.computed(function () {
      return [];
    }),

    /**
     * Reset the properties used by the email invite.
     */
    resetEmailInvite: function resetEmailInvite() {
      Ember.get(this, 'emailTags').clear();
      Ember.set(this, 'friendInvitePreview', null);
    },
    actions: {
      openInviteByEmailModal: function openInviteByEmailModal() {
        var inviteByEmailModal = Ember.get(this, 'inviteByEmailModal');

        if (inviteByEmailModal) {
          // Reset the invite email modal before opening.
          this.resetEmailInvite();
          Ember.run.scheduleOnce('afterRender', function () {
            inviteByEmailModal.open();
          });
        }
      }
    }
  });

  _exports.default = _default;
});