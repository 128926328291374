define("fitbit-site-ui/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HnNimIDr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-msg\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"internal-error-text-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"internal-error-centered\"],[9],[0,\"\\n            \"],[7,\"h1\"],[11,\"class\",\"error-header\"],[9],[1,[27,\"format-by-namespace\",[\"not_right\",\"templates.error\"],null],false],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"error-text\"],[9],[1,[27,\"format-by-namespace\",[\"internal_error\",\"templates.error\"],null],false],[0,\" \"],[7,\"a\"],[11,\"class\",\"error-text\"],[11,\"href\",\"https://help.fitbit.com/?cu=1\"],[9],[1,[27,\"format-by-namespace\",[\"try_again\",\"templates.error\"],null],false],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"internal-error-image\"],[11,\"role\",\"presentation\"],[9],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/error.hbs"
    }
  });

  _exports.default = _default;
});