define("fitbit-site-ui/adapters/device/tracker/inactivity-alerts", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._super(null, null, snapshot) + '/sed/settings.json';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._super(null, null, snapshot) + '/sed/settings.json';
    }
  });

  _exports.default = _default;
});