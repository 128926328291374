define("fitbit-site-ui/adapters/device/tracker/alarm/list", ["exports", "fitbit-site-ui/adapters/device/tracker/alarm"], function (_exports, _alarm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _alarm.default.extend({});

  _exports.default = _default;
});