define("fitbit-site-ui/templates/components/friend-invitation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z1GHETLu",
    "block": "{\"symbols\":[\"invite\"],\"statements\":[[4,\"each\",[[23,[\"invitations\"]]],null,{\"statements\":[[4,\"link-to\",[\"profile\",[22,1,[\"user\",\"id\"]]],[[\"class\"],[\"leaderboard-profile-link\"]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"row friend-invite-row\"],[11,\"data-test-qa\",\"friend-invite\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column shrink friend-invitation-column\"],[9],[0,\"\\n                \"],[7,\"img\"],[11,\"class\",\"friend-invitation-avatar\"],[12,\"alt\",[28,[[27,\"format-by-namespace\",[\"user_avatar_alt\",\"route.friends\"],[[\"name\"],[[22,1,[\"user\",\"name\"]]]]]]]],[12,\"src\",[22,1,[\"user\",\"avatar\"]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"friend-invitation-name friend-invite-column column expand\"],[9],[1,[22,1,[\"user\",\"name\"]],false],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column shrink friend-invite-column\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"button friend-invite-button\"],[11,\"data-test-qa\",\"friend-invite-accept\"],[11,\"type\",\"button\"],[9],[1,[27,\"format-by-namespace\",[\"accept\",\"route.friends\"],null],false],[3,\"action\",[[22,0,[]],\"acceptInvite\",[22,1,[]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column shrink friend-invite-column decline-column\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"button decline-button friend-invite-button\"],[11,\"data-test-qa\",\"friend-invite-decline\"],[11,\"type\",\"button\"],[9],[1,[27,\"format-by-namespace\",[\"decline\",\"route.friends\"],null],false],[3,\"action\",[[22,0,[]],\"declineInvite\",[22,1,[]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/friend-invitation-list.hbs"
    }
  });

  _exports.default = _default;
});