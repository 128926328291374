define("fitbit-site-ui/serializers/fitbit-explore", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'categoryId',
    // Usage: const exploreQuery = store.queryRecord('fitbit-explore', {bundleId: 'programs' });
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var explore = Ember.get(payload, 'data.attributes');
      var items = Ember.get(explore, 'items');
      var itemsIds = items.reduce(function (acc, item) {
        acc.push(Ember.get(item, 'itemId'));
        return acc;
      }, []);
      Ember.set(explore, 'items', itemsIds);
      var newPayload = {
        'fitbit-explore': explore,
        'fitbit-explore-item': items
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});