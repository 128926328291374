define("fitbit-site-ui/adapters/health-programs/tag", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    namespace: '1/user/:userid/hp/admin',
    pathForType: function pathForType() {
      return 'tags';
    },
    buildURL: function buildURL() {
      var url = "".concat(this._super.apply(this, arguments), ".json");
      var userId = Ember.getWithDefault(this, 'currentUser.id', '-');
      return url.replace(':userid', userId);
    }
  });

  _exports.default = _default;
});