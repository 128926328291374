define("fitbit-site-ui/serializers/time-series/calorie", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    moment: Ember.inject.service(),
    primaryKey: 'dateTime',
    getPayloadKey: function getPayloadKey() {
      return 'time-series/calorie';
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _payload$activitiesC = payload['activities-calories'],
          activitiesCalories = _payload$activitiesC === void 0 ? [] : _payload$activitiesC;
      return this._super(store, primaryModelClass, {
        'time-series/calorie': activitiesCalories
      }, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var _payload$activitiesC2 = payload['activities-calories'],
          activitiesCalories = _payload$activitiesC2 === void 0 ? [] : _payload$activitiesC2,
          _payload$activitiesC3 = payload['activities-calories-intraday'],
          activitiesCaloriesIntraday = _payload$activitiesC3 === void 0 ? {} : _payload$activitiesC3;

      var _activitiesCalories = _slicedToArray(activitiesCalories, 1),
          _activitiesCalories$ = _activitiesCalories[0],
          record = _activitiesCalories$ === void 0 ? {
        dateTime: id
      } : _activitiesCalories$;

      if (Ember.isPresent(activitiesCaloriesIntraday)) {
        record.intraDay = activitiesCaloriesIntraday;
      }

      return this._super(store, primaryModelClass, {
        'time-series/calorie': record
      }, id, requestType);
    }
  });

  _exports.default = _default;
});