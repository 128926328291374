define("fitbit-site-ui/adapters/search-food-list", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/foods/',
    urlForQueryRecord: function urlForQueryRecord() {
      return "".concat(this._super(), "search.json");
    },

    /**
     * Overridden to have queryparams in serializer normalize response
     * https://github.com/emberjs/data/issues/3884
     * @param store
     * @param type
     * @param query
     */
    queryRecord: function queryRecord(store, type, query) {
      return this._super.apply(this, arguments).then(function (response) {
        response.queryParams = query;
        return response;
      });
    }
  });

  _exports.default = _default;
});