define("fitbit-site-ui/components/application-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['application-item'],
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),

    /**
     * Translated permissions given to 3rd party apps
     */
    formattedPermissions: Ember.computed('application.permissions', function () {
      var formatter = Ember.get(this, 'i18n');
      var permissions = Ember.get(this, 'application.permissions');
      var permissionsKeys = {
        'ACCESS AND WRITE': 'access_and_write',
        ACCESS: 'access'
      };
      return formatter.formatByNamespace("".concat(permissionsKeys[permissions]), 'templates.settings.application-settings');
    }),

    /**
     * Temporary - the oldest authorization date in the array because 1st party Fitbit apps have many auths
     * For 3rd party apps, there will only ever be one authorization.
     * TODO: update when the endpoint is updated with a filter for 3rd party apps vs 1st party
     */
    relevantAuth: Ember.computed('application.applicationAuthorizations', function () {
      var applicationAuthorizations = Ember.get(this, 'application.applicationAuthorizations');

      if (applicationAuthorizations) {
        var sortedAuths = applicationAuthorizations.sortBy('authorizedDate');
        return Ember.get(sortedAuths, 'firstObject');
      }
    }),

    /**
     * List of Fitbit data user has authorized the 3rd party app to use
     */
    translatedScopes: Ember.computed('relevantAuth.scopes', function () {
      var formatter = Ember.get(this, 'i18n');
      var scopes = Ember.get(this, 'relevantAuth.scopes');
      var translatedScopes = scopes.map(function (scope) {
        try {
          return formatter.formatByNamespace("scope_".concat(scope), 'templates.settings.application-settings');
        } catch (e) {
          return scope; // Should be translated, but to fail gracefully just return the untraslated scope
        }
      });
      return translatedScopes.join(', ');
    }),
    actions: {
      /**
       * Call action on the application-settings controller to
       * revoke access from app.
       */
      revokeAccess: function revokeAccess() {
        var relevantAuth = Ember.get(this, 'relevantAuth');
        var onConfirmRevokeAccess = Ember.get(this, 'onConfirmRevokeAccess');
        onConfirmRevokeAccess(relevantAuth);
      }
    }
  });

  _exports.default = _default;
});