define("fitbit-site-ui/adapters/body-log", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    namespace: '1.1/user/-',
    pathForType: function pathForType() {
      return '';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var type = this.get('body_type');
      return "".concat(this._super.apply(this, arguments), "/body/log/").concat(type, ".json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      var type = this.get('body_type');
      return "".concat(this._super(), "/body/log/").concat(type, "/").concat(id, ".json");
    },
    _urlForRecord: function _urlForRecord(url, id) {
      var type = this.get('body_type');
      url = url.substring(0, url.lastIndexOf('/'));
      return "".concat(url, "/body/log/").concat(type, "/").concat(id, ".json");
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var type = this.get('body_type');
      return "".concat(this._super(), "/body/log/").concat(type, ".json");
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      var type = this.get('body_type'); // To get both the logId and date of a record, the /list.json endpoint much
      // be hit.  isWeightList is set manually in createWeightLog in routes/weight.js
      // To force this to hit the /list.json endpoint instead fof weight/date endpoint.
      // As a result, the weight-log serializer will be invoked with a weight-list like response
      // which parses and returns the weight-log record to the store if it exists

      if (query.isWeightList) {
        delete query.isWeightList;
        return "".concat(this._super(), "/body/log/").concat(type, "/list.json");
      } else {
        return this._super();
      }
    }
  });

  _exports.default = _default;
});