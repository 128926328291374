define("fitbit-site-ui/components/device-heart-rate-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    setting: null,
    onSettingsChanged: null,
    actions: {
      settingChanged: function settingChanged(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(this.get('onSettingsChanged')(value)).then(function () {
          var successMessage = formatter.format('device_setting_saved');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        }).catch(function () {
          var failureMessage = formatter.format('something_went_wrong');

          _this._clearMessageFromQueue(failureMessage);

          flashMessages.alert(failureMessage);
        });
      }
    }
  });

  _exports.default = _default;
});