define("fitbit-site-ui/models/sleep-stat", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    stages: attr(),
    sleepDate: belongsTo('sleep-date'),
    classic: attr(),
    date: Ember.computed.alias('id'),
    minutesAsleep: Ember.computed('stages', 'classic', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'stages', 'classic'),
          stages = _EmberGetProperties.stages,
          classic = _EmberGetProperties.classic;

      var minutes = 0;

      if (stages) {
        minutes = this._collectMinutes(['rem', 'light', 'deep'], stages, minutes);
      }

      if (classic) {
        minutes = this._collectMinutes(['asleep', 'restless'], classic, minutes);
      }

      return minutes;
    }),

    /**
     * Return whether sleep goal was reached for this sleep stat
     */
    goalReached: Ember.computed('minutesAsleep', 'currentUser.sleepGoal.minDuration', function () {
      var sleepGoal = Ember.get(this, 'currentUser.sleepGoal.minDuration');
      var goalReached = false;

      if (sleepGoal) {
        goalReached = Ember.get(this, 'minutesAsleep') >= sleepGoal;
      }

      return goalReached;
    }),
    _collectMinutes: function _collectMinutes(stages, source, startingMinutes) {
      return stages.reduce(function (minutes, stage) {
        return minutes + (Ember.get(source, "".concat(stage, ".minutes")) || 0);
      }, startingMinutes);
    }
  });

  _exports.default = _default;
});