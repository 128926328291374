define("fitbit-site-ui/components/friend-invitation-list", ["exports", "snapdragon-common/errors/too-many-connections-error"], function (_exports, _tooManyConnectionsError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /**
     * Stub functions which handle accepting or declining friend invitations
     * expected to return promises
     */
    acceptFriendInvite: function acceptFriendInvite() {},
    declineFriendInvite: function declineFriendInvite() {},
    actions: {
      /**
       * Given an inbox-invitation (passed in by parent), call acceptFriendInvite
       * which calls passed in acceptFriendInvite
       * @param friendInvitation {object} - inbox-invitation model
       * @returns {Promise}
       */
      acceptInvite: function acceptInvite(friendInvitation) {
        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(Ember.get(this, 'acceptFriendInvite')(friendInvitation)).then(function () {
          return flashMessages.success(formatter.formatByNamespace('request_accept', 'route.friends'));
        }).catch(function (err) {
          if (err instanceof _tooManyConnectionsError.default) {
            return flashMessages.alert(formatter.formatByNamespace('too_many_friends_error', 'route.friends'));
          } else {
            return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
          }
        });
      },

      /**
       * Given an inbox-invitation (passed in by parent), call declineFriendInvite
       * which calls passed in declineFriendInvite
       * @param friendInvitation {object} - inbox-invitation model
       * @returns {Promise}
       */
      declineInvite: function declineInvite(friendInvitation) {
        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(Ember.get(this, 'declineFriendInvite')(friendInvitation)).then(function () {
          return flashMessages.success(formatter.formatByNamespace('request_decline', 'route.friends'));
        }).catch(function () {
          return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
        });
      }
    }
  });

  _exports.default = _default;
});