define("fitbit-site-ui/routes/messages/message-received", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deepLinkWithBackup: Ember.inject.service('deep-link-with-backup'),
    // for testing purposes
    _setWindowLocation: function _setWindowLocation(url) {
      window.location = url;
    },
    model: function model(params, transition) {
      var _this = this;

      var conversationId = params.conversationId ? params.conversationId.trim() : null;
      var deepLinkURL = conversationId ? "fitbit://conversation/".concat(conversationId) : 'fitbit://inbox/messages';
      var openInAppPromise = this.deepLinkWithBackup.openInApp(deepLinkURL, {
        timeout: 10
      });
      openInAppPromise.catch(function () {
        // if app doesnt load, go to notifications page
        transition.abort();

        _this._setWindowLocation('https://support.google.com/fitbit?p=dashboard-app');
      });
    }
  });

  _exports.default = _default;
});