define("fitbit-site-ui/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5trgxHSL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"success progress\"],[11,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[28,[[21,\"percentage\"]]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"progress-meter\"],[12,\"style\",[21,\"percentageStyle\"]],[9],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});