define("fitbit-site-ui/components/user-profile-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AchievementInterface = _exports.BadgeInterface = _exports.MAX_LIST_ITEMS = void 0;
  var MAX_LIST_ITEMS = 6;
  /**
   * Object that contains the needed badges properties
   */

  _exports.MAX_LIST_ITEMS = MAX_LIST_ITEMS;
  var BadgeInterface = Ember.Object.extend({
    source: null,
    image100px: Ember.computed.readOnly('source.image100px'),
    shortName: Ember.computed.readOnly('source.shortName'),
    dateTime: Ember.computed.readOnly('source.dateTime'),
    description: Ember.computed.readOnly('source.description'),
    timesAchieved: Ember.computed.readOnly('source.timesAchieved'),
    category: Ember.computed.readOnly('source.category')
  });
  /**
   * Object that maps the needed achievement properties to the equivalent badges properties
   */

  _exports.BadgeInterface = BadgeInterface;
  var AchievementInterface = Ember.Object.extend({
    source: null,
    image100px: Ember.computed.readOnly('source.imageUrl'),
    shortName: Ember.computed.readOnly('source.title'),
    dateTime: Ember.computed.readOnly('source.lastEarned'),
    description: Ember.computed.readOnly('source.description'),
    timesAchieved: Ember.computed.readOnly('source.timesAchieved'),
    category: Ember.computed.readOnly('source.category')
  });
  _exports.AchievementInterface = AchievementInterface;

  var _default = Ember.Component.extend({
    tagName: '',
    i18n: Ember.inject.service(),

    /**
     * Provides a filtered array of achieved achievements.
     * @returns {Array}
     */
    achievementsAchieved: Ember.computed('achievements.[]', function () {
      var achievements = Ember.get(this, 'achievements') || [];
      return achievements.filterBy('achieved', true);
    }),

    /**
     * Provides an array of badge or achievement interfaces, sorted by dateTime and reversed.
     *
     * @returns {BadgeInterface[]|AchievementInterface[]}
     */
    badgesAndAchievements: Ember.computed('badges.[]', 'achievementsAchieved.[]', function () {
      var badgeList = Ember.get(this, 'badges');
      var achievementsAchieved = Ember.get(this, 'achievementsAchieved');
      var badgesAndAchievements = [];

      if (badgeList) {
        var badgeInterfaces = badgeList.map(function (badge) {
          return BadgeInterface.create({
            source: badge
          });
        });
        badgesAndAchievements.addObjects(badgeInterfaces);
      }

      if (achievementsAchieved) {
        var achievementInterfaces = achievementsAchieved.map(function (achievement) {
          return AchievementInterface.create({
            source: achievement
          });
        });
        badgesAndAchievements.addObjects(achievementInterfaces);
      }

      return badgesAndAchievements.sortBy('dateTime').reverse();
    }),

    /**
     * This gets all of the users badges and returns an array of the top 6 most
     *  recent
     *
     *  @returns {Array}
     */
    recentBadgesAndAchievements: Ember.computed('badgesAndAchievements.[]', function () {
      var badgesAndAchievements = Ember.get(this, 'badgesAndAchievements');
      return badgesAndAchievements.slice(0, 6);
    }),

    /**
     * Returns whether the badges are equal to null
     *   If the badges are not private but just empty, this will be empty and not null
     *
     *   @returns {Boolean}
     */
    isBadgesPrivate: Ember.computed.equal('badges', null),

    /**
     * Determine if there are more badges and achievements to display.
     *
     * @returns {Boolean}
     */
    hasMoreBadgesAndAchievements: Ember.computed.gt('badgesAndAchievements.length', MAX_LIST_ITEMS),
    actions: {
      /**
       * Open the modal.
       * @param modal
       */
      openModal: function openModal(modal) {
        if (modal) {
          Ember.run.scheduleOnce('afterRender', modal, 'open');
        }
      }
    }
  });

  _exports.default = _default;
});