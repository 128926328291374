define("fitbit-site-ui/components/device-exercise-autocues", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _panelComponent.default.extend({
    /**
     * Helper to find an exercise's autocue settings.
     * @private
     */
    _getExerciseSetting: function _getExerciseSetting(exerciseIdString) {
      return Ember.get(this, 'settings').findBy('exerciseId', parseInt(exerciseIdString, 10));
    },

    /**
     * Helper to translate autocue states.
     * @private
     */
    _getAutoCueStateDisplayName: function _getAutoCueStateDisplayName(autoCueState) {
      if (autoCueState === 'auto') {
        return Ember.get(this, 'i18n').format('automatically');
      }

      if (autoCueState === 'manual') {
        return Ember.get(this, 'i18n').format('manually');
      }

      return Ember.get(this, 'i18n').format(autoCueState);
    },

    /**
     * Helper to generate a list of reusable autocue options.
     * @private
     */
    _autoCueOptions: Ember.computed(function () {
      var _EmberGetProperties = Ember.getProperties(this, 'options', 'i18n'),
          allOptions = _EmberGetProperties.options,
          formatter = _EmberGetProperties.i18n;

      var _this = this; // Some exercises don't have autocue options.


      var options = allOptions.filter(function (option) {
        return option.hasOwnProperty('autoCueStates') && option.hasOwnProperty('autoCueOptions');
      }); // The resulting list of objects is a combination of autocue options with user-editable values set to defaults.

      return options.map(function (option) {
        return {
          exerciseId: option.id,
          exerciseName: option.name,
          autoCueStates: option.autoCueStates.map(function (autoCueState) {
            return {
              displayName: _this._getAutoCueStateDisplayName(autoCueState),
              autoCueState: autoCueState
            };
          }),
          autoCues: option.autoCueOptions.map(function (autoCueOption) {
            return _objectSpread({}, autoCueOption, {
              selected: false,
              possibleValues: autoCueOption.possibleValues.map(function (value) {
                return {
                  displayValue: formatter.format(autoCueOption.unit + '_abbr', {
                    val: value
                  }),
                  value: value
                };
              })
            });
          }),
          autoCueState: 'off',
          autoCueEditable: false
        };
      });
    }),
    exerciseSettings: Ember.computed('settings', 'settings.@each', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, 'settings', '_autoCueOptions'),
          settings = _EmberGetProperties2.settings,
          options = _EmberGetProperties2._autoCueOptions; // For each exercise setting, find the appropriate autocue option and set the user's selected values.


      return settings.reduce(function (acc, setting) {
        var option = options.findBy('exerciseId', setting.exerciseId);

        if (option) {
          Ember.setProperties(option, {
            autoCueState: setting.autoCueState,
            autoCueEditable: setting.autoCueState !== 'off' && setting.autoCueState !== 'manual'
          }); // We only need to use the unit to uniquely identify the autocue. The autocue type classifies groups of
          // autocues (eg, distance based, etc) but the unit is more specific (km, miles, etc).

          option.autoCues.forEach(function (autoCue) {
            Ember.setProperties(autoCue, {
              selected: setting.autoCue.unit === autoCue.unit,
              value: setting.autoCue.unit === autoCue.unit ? setting.autoCue.value : autoCue.value
            });
          });
          acc.push(option);
        }

        return acc;
      }, []);
    }),
    actions: {
      editCueControl: function editCueControl(name, value) {
        var setting = this._getExerciseSetting(name);

        Ember.set(setting, 'autoCueState', value);
        this.updateSettings();
      },
      editCueType: function editCueType(name, value) {
        var options = Ember.get(this, 'options').findBy('id', parseInt(name, 10)),
            autoCueOption = options.autoCueOptions.findBy('unit', value),
            setting = this._getExerciseSetting(name);

        Ember.set(setting, 'autoCue', {
          unit: autoCueOption.unit,
          type: autoCueOption.type,
          value: autoCueOption.value
        });
        this.updateSettings();
      },
      editCueValue: function editCueValue(name, value) {
        var setting = this._getExerciseSetting(name);

        Ember.set(setting.autoCue, 'value', parseFloat(value));
        this.updateSettings();
      }
    }
  });

  _exports.default = _default;
});