define("fitbit-site-ui/routes/fhs-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      var store = this.store;
      var programMemberships = store.findAll('fhs-enrollment-program-membership');
      var programMembership = store.findRecord('fhs-enrollment-program-membership', '5DZPKGL');
      var programFeature = store.findAll('fhs-enrollment-program-feature', {
        adapterOptions: {
          membershipId: '5DZPKGL'
        }
      });
      return Ember.RSVP.hash({
        programMemberships: programMemberships,
        programMembership: programMembership,
        programFeature: programFeature
      });
    }
  });

  _exports.default = _default;
});