define("fitbit-site-ui/serializers/device/scale/user", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'device/scale/user';
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = primaryModelClass.modelName,
          scaleUser = payload.scaleUser;
      scaleUser.id = scaleUser.userId;

      var data = _defineProperty({}, payloadKey, scaleUser);

      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});