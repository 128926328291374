define("fitbit-site-ui/routes/friends", ["exports", "fitbit-site-ui/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({});

  _exports.default = _default;
});