define("fitbit-site-ui/serializers/weight-graph", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'dateTime',
    _getTimeForRecords: function _getTimeForRecords(dateTime, averagedWeights) {
      if (averagedWeights) {
        // Averages
        return "".concat(dateTime.substr(0, 10), " 23:59:59");
      } else {
        return dateTime.replace('T', ' ');
      }
    },
    _generateWeightGraph: function _generateWeightGraph(graphValues, averagedWeights) {
      var _this = this;

      return graphValues.map(function (g) {
        var dateTime = _this._getTimeForRecords(g.dateTime, averagedWeights);

        g.weightTime = dateTime;
        g.fatTime = dateTime;
        g.bmiTime = dateTime;
        g.dateTime = dateTime;
        g.weightTrend = dateTime;
        return g;
      });
    },
    _generateWeightTimes: function _generateWeightTimes(graphValues, averagedWeights) {
      var _this2 = this;

      return graphValues.map(function (g) {
        var dateTime = _this2._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          value: g.weight,
          dateTime: dateTime,
          weightGraph: dateTime
        };
      });
    },
    _generateFatTimes: function _generateFatTimes(graphValues, averagedWeights) {
      var _this3 = this;

      return graphValues.map(function (g) {
        var dateTime = _this3._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          value: g.fat,
          dateTime: dateTime,
          weight: dateTime,
          weightGraph: dateTime
        };
      });
    },
    _generateBmiTimes: function _generateBmiTimes(graphValues, averagedWeights) {
      var _this4 = this;

      return graphValues.map(function (g) {
        var dateTime = _this4._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          value: g.bmi,
          dateTime: dateTime,
          weightGraph: dateTime
        };
      });
    },
    _generateWeightTrend: function _generateWeightTrend(graphValues, averagedWeights) {
      var _this5 = this;

      return graphValues.map(function (g) {
        var dateTime = _this5._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          dateTime: dateTime,
          weightGraph: dateTime
        };
      });
    },

    /**
     * Returns true if the graphValues were requested with 3m, 12m, or all
     * @param graphValues - Array of graph values
     * @private
     */
    _spansMultipleMonths: function _spansMultipleMonths(queryParams) {
      switch (queryParams.durationType) {
        case '7d':
        case '1m':
          return false;

        default:
          return true;
      }
    },

    /**
     * Finds the union of graph and body fat graph values
     * @param graphValues
     * @param graphBodyFatValues
     * @private
     */
    _unionGraphValues: function _unionGraphValues(graphValues, graphBodyFatValues) {
      var uniqueRecords = [];
      graphBodyFatValues.forEach(function (graph) {
        var match = graphValues.find(function (g) {
          return Ember.get(g, 'dateTime') === Ember.get(graph, 'dateTime');
        });

        if (match === undefined) {
          uniqueRecords.push(graph);
        }
      });
      return graphValues.concat(uniqueRecords);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var graphValues = payload.graphValues,
          nextGraphValues = payload.nextGraphValues,
          prevGraphValues = payload.prevGraphValues,
          nextGraphBodyFatValues = payload.nextGraphBodyFatValues,
          prevGraphBodyFatValues = payload.prevGraphBodyFatValues;
      var queryParams = payload.queryParams; // Default to 3m if no queryParams

      if (!queryParams) {
        queryParams = {
          durationType: '3m'
        };
      }

      var unionNextGraphValues = this._unionGraphValues(nextGraphValues, nextGraphBodyFatValues);

      var unionPrevGraphValues = this._unionGraphValues(prevGraphValues, prevGraphBodyFatValues);

      var averagedWeights = this._spansMultipleMonths(queryParams);

      var combinedGraphValues = graphValues.concat(unionNextGraphValues, unionPrevGraphValues);
      var newPayload = {
        'weight-graph': this._generateWeightGraph(combinedGraphValues, averagedWeights),
        'weight-time': this._generateWeightTimes(combinedGraphValues, averagedWeights),
        'fat-time': this._generateFatTimes(combinedGraphValues, averagedWeights),
        'bmi-time': this._generateBmiTimes(combinedGraphValues, averagedWeights),
        'weight-graph-trendline': this._generateWeightTrend(combinedGraphValues, averagedWeights)
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});