define("fitbit-site-ui/routes/friends/friend-accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deepLinkWithBackup: Ember.inject.service('deep-link-with-backup'),
    // for testing purposes
    _setWindowLocation: function _setWindowLocation(url) {
      window.location = url;
    },
    model: function model(params, transition) {
      var _this = this;

      // deep link to app if hit from a mobile device
      var deepLinkURL = "fitbit://user/".concat(params.senderId);
      var openInAppPromise = this.deepLinkWithBackup.openInApp(deepLinkURL, {
        timeout: 10
      });
      openInAppPromise.catch(function () {
        // If the Fitbit app is not available on the device, then it is redirected to the Support URL
        transition.abort();

        _this._setWindowLocation('https://support.google.com/fitbit?p=dashboard-app');
      });
    }
  });

  _exports.default = _default;
});