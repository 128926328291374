define("fitbit-site-ui/components/device-alarm-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'parsedTime.time': {
      description: 'The alarm time',
      validators: [// Validate date following the timeFormat
      (0, _emberCpValidations.validator)('date', {
        format: Ember.computed.readOnly('model.timeFormat'),
        message: Ember.computed(function () {
          return this.get('model.i18n').format('desc_format_validation', {
            description: '{description}',
            format: '{format}'
          });
        })
      }), // Validate that
      (0, _emberCpValidations.validator)('presence', true)]
    },
    weekDaySelection: (0, _emberCpValidations.validator)('length', {
      description: 'Weekday',
      min: 1,
      // Disable this validation if recurring property is false.
      disabled: Ember.computed('model.showErrors,model.recurring', function () {
        var _this$get$getProperti = this.get('model').getProperties('showErrors', 'recurring'),
            showErrors = _this$get$getProperti.showErrors,
            recurring = _this$get$getProperti.recurring;

        var disabled = true;

        if (showErrors) {
          disabled = !recurring;
        }

        return disabled;
      }),
      message: 'At least one day must be selected.'
    })
  }, {
    disabled: Ember.computed.not('model.showErrors')
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    showErrors: false,
    timeFormat: null,
    timeFormatWithUTC: null,
    timeFormat24: null,
    meridiemFormat: null,
    isClock12: null,
    UTCOffset: null,
    recurring: false,

    /**
     * Time should always contain UTC
     * @property time
     */
    time: null,
    defaultMeridiem: 'AM',
    weekDays: null,
    weekDayOptions: null,
    weekDaysToSubmit: null,
    weekDayChoices: null,
    init: function init() {
      var momentService = this.get('moment');
      var weekdaysToSubmit = this.get('constants.WEEKDAYS');

      this._super.apply(this, arguments);

      var _this$getProperties = this.getProperties('weekDayOptions', 'weekDays'),
          weekDayOptions = _this$getProperties.weekDayOptions,
          weekDays = _this$getProperties.weekDays; // Set the default for weekDayOptions


      if (!weekDayOptions) {
        // pass `weekdays` method true to return locale specific list.
        this.set('weekDayOptions', Ember.A(momentService.moment().localeData().weekdays().map(function (day, index) {
          return {
            displayName: day.toUpperCase(),
            value: weekdaysToSubmit[index]
          };
        })));
      } else {
        (false && !(weekDayOptions.every(function (day) {
          return day === day.toUpperCase();
        })) && Ember.assert('weekDayOptions must be uppercase', weekDayOptions.every(function (day) {
          return day === day.toUpperCase();
        })));
      } // Set the default for weekDays


      if (!weekDays) {
        this.set('weekDays', Ember.A());
      }

      if (!this.get('weekDayChoices')) {
        this.setupWeekDayChoices();
      }
    },
    setupWeekDayChoices: function setupWeekDayChoices() {
      var _this$getProperties2 = this.getProperties('weekDayOptions', 'weekDays'),
          weekDayOptions = _this$getProperties2.weekDayOptions,
          weekDays = _this$getProperties2.weekDays;

      var weekDayChoices = this.processWeekDayOptions(weekDayOptions, weekDays);
      this.set('weekDayChoices', weekDayChoices);
    },
    processWeekDayOptions: function processWeekDayOptions(options, values) {
      (false && !(Ember.isArray(options) && Ember.isArray(values)) && Ember.assert('options and values must be an array ', Ember.isArray(options) && Ember.isArray(values)));
      return options.map(function (option) {
        return Ember.Object.create({
          value: option.value,
          label: option.displayName,
          isSelected: values.includes(option.value)
        });
      });
    },
    weekDaySelection: Ember.computed('weekDayChoices.@each.isSelected', function () {
      var weekDayChoices = this.get('weekDayChoices');
      return weekDayChoices.filterBy('isSelected');
    }),

    /**
     * Parse the `time` property to determine a proper time string. Provide the meridiem if `isClock12` is true.
     * `time` is expected to always contain UTC.
     * @return {Object}
     */
    parsedTime: Ember.computed(function () {
      var momentService = this.get('moment');

      var _this$getProperties3 = this.getProperties('time', 'timeFormat24', 'isClock12', 'timeFormat', 'defaultMeridiem'),
          time = _this$getProperties3.time,
          timeFormat24 = _this$getProperties3.timeFormat24,
          isClock12 = _this$getProperties3.isClock12,
          timeFormat = _this$getProperties3.timeFormat,
          defaultMeridiem = _this$getProperties3.defaultMeridiem;

      var meridiem = defaultMeridiem;
      var parsedTime;
      var timeString;

      if (time) {
        //time is stored as HH:mm format
        parsedTime = momentService.moment(time, timeFormat24);

        if (isClock12) {
          meridiem = parsedTime.format('A');
        }

        timeString = parsedTime.format(timeFormat);
      }

      return Ember.Object.create({
        time: timeString,
        meridiem: meridiem
      });
    }),
    formatParsedTime: function formatParsedTime(parsedTime) {
      var momentService = this.get('moment');

      var _this$getProperties4 = this.getProperties('UTCOffset', 'isClock12', 'timeFormatWithUTC'),
          UTCOffset = _this$getProperties4.UTCOffset,
          isClock12 = _this$getProperties4.isClock12,
          timeFormatWithUTC = _this$getProperties4.timeFormatWithUTC;

      var timeFormat = this.get('timeFormat');
      var time = parsedTime.time,
          meridiem = parsedTime.meridiem; // Include the meridiem format as part of timeFormat and time so momentjs will do the proper calculations
      // for converting 12hr clock to 24hr clock.

      if (isClock12) {
        timeFormat = "".concat(timeFormat, " A");
        time = "".concat(time, " ").concat(meridiem);
      } //True flag in utcOffset: sets offset but keep the "local" time so time doesn't get changed to the offset timezone


      return momentService.moment(time, timeFormat).utcOffset(UTCOffset, true).format(timeFormatWithUTC);
    },
    // No-op
    onCancel: function onCancel() {},
    onSubmit: function onSubmit() {},
    actions: {
      /**
       * Pass the values of time, recurring, and weekDays on to the onSubmit method.
       */
      submit: function submit() {
        var _this = this;

        this.set('showErrors', true);
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var _this$getProperties5 = _this.getProperties('parsedTime', 'recurring', 'weekDayChoices'),
                parsedTime = _this$getProperties5.parsedTime,
                recurring = _this$getProperties5.recurring,
                weekDayChoices = _this$getProperties5.weekDayChoices;

            var time = _this.formatParsedTime(parsedTime);

            var weekDays = weekDayChoices.filterBy('isSelected').map(function (choice) {
              return choice.get('value');
            });

            _this.get('onSubmit')({
              time: time,
              recurring: recurring,
              weekDays: weekDays
            });
          }
        });
      },

      /**
       * Trigger the onCancel method.
       */
      cancel: function cancel() {
        this.get('onCancel')();
      }
    }
  });

  _exports.default = _default;
});