define("fitbit-site-ui/mixins/models/non-rest-action", ["exports", "snapdragon-common/mixins/models/non-rest-action"], function (_exports, _nonRestAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nonRestAction.default;
    }
  });
});