define("fitbit-site-ui/serializers/onboarding-step-welcome", ["exports", "corporate-onboarding-engine/serializers/onboarding-step-welcome"], function (_exports, _onboardingStepWelcome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _onboardingStepWelcome.default;
    }
  });
});