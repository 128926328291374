define("fitbit-site-ui/serializers/device/tracker/exercise-preferences", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'device/tracker/exercise-preferences';
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    },
    serialize: function serialize(snapshot) {
      var swimSettings = snapshot.attr('swimSettings');
      var autoRunOn = snapshot.attr('autoRunOn');
      return {
        autoRunOn: autoRunOn || false,
        swimSettings: {
          poolSettings: {
            poolLength: swimSettings && swimSettings.poolLength || 10,
            poolLengthUnit: swimSettings && swimSettings.poolLengthUnit || 'Meter'
          }
        }
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName),
          data = {};
      data = _defineProperty({}, payloadKey, {
        autoRunOn: payload.autoRunOn,
        swimSettings: payload.swimSettings.poolSettings,
        id: id
      });
      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});