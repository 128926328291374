define("fitbit-site-ui/adapters/health-programs/admin/testing-data", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user/-/hp/admin',
    methodPostForTypes: Ember.computed(function () {
      return [];
    }),
    pathForType: function pathForType() {
      return 'testing';
    },
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments) + '.json';
      return url;
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments) + '.json';
      return url;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this._super(undefined, modelName, snapshot) + '.json';
      return url;
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var ids = id.split(':');
      var url = this._super(undefined, modelName, snapshot) + '.json' + '?programId=' + ids[0] + '&programVersion=' + ids[1];
      return url;
    }
  });

  _exports.default = _default;
});