define("fitbit-site-ui/models/sleep-list", ["exports", "ember-data", "snapdragon-common/mixins/models/non-rest-action"], function (_exports, _emberData, _nonRestAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_nonRestAction.default, {
    meta: _emberData.default.attr(),
    beforeDate: _emberData.default.attr(),
    list: _emberData.default.hasMany('sleep-log'),
    nextRecordUrl: _emberData.default.attr('string'),
    hasMoreSleep: Ember.computed.bool('nextRecordUrl'),

    /**
     * Sorted sleep list by descending order.
     * Mainly useful for inserting a manually logged record, so that
     * the new records position in the list doesn't need to be searched for.
     */
    descSleepList: Ember.computed.sort('list.[]', function (itemA, itemB) {
      var itemAStartTime = Ember.get(itemA, 'startTime');
      var itemBStartTime = Ember.get(itemB, 'startTime');
      return itemAStartTime > itemBStartTime ? -1 : itemBStartTime > itemAStartTime ? 1 : 0;
    }),
    fetchMoreSleep: function fetchMoreSleep() {
      var _this = this;

      var url = Ember.get(this, 'nextRecordUrl'); // Fetch more sleep using 'next' pagination url from Fitbit API

      return this.nonRestAction(url, 'GET').then(function (results) {
        var store = Ember.get(_this, 'store');
        var modelName = _this.constructor.modelName;
        var serializer = store.serializerFor(modelName);
        var model = store.modelFor(modelName);
        var recordId = Ember.get(_this, 'id'); // Use the serializer to normalize the payload as it would have with `findRecord`.

        var payload = serializer.normalizeResponse(store, model, results, recordId, 'findRecord');
        var newList = payload.data.relationships.list;
        var newListData = newList.data; // Generate data objects from the current list to combine with the new list.
        // This is needed otherwise the existing list will just be overwritten.

        var currentListData = Ember.get(_this, 'list').map(function (sleepLog) {
          return {
            id: Ember.get(sleepLog, 'id'),
            type: sleepLog.constructor.modelName
          };
        }); // Concat the two

        newList.data = currentListData.concat(newListData);
        store.push(payload);
      });
    },
    getActionUrl: function getActionUrl(url) {
      return url;
    }
  });

  _exports.default = _default;
});