define("fitbit-site-ui/components/header-nav", ["exports", "fitbit-site-ui/components/init-zf"], function (_exports, _initZf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _initZf.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    viewport: Ember.inject.service('device/layout'),
    ariaRole: 'banner',

    /**
     * Burger menu is only enabled for viewport.isMobile
     * @type {boolean}
     */
    isBurgerMenuEnabled: Ember.computed.bool('viewport.isMobile'),

    /**
     * Burger menu can only be visible if burger menu is enabled. By default visibility is false.
     */
    isBurgerMenuVisible: Ember.computed('isBurgerMenuEnabled', {
      /**
       * Default visibility is false when burger menu is enabled.
       * @returns {boolean}
       */
      get: function get() {
        return !Ember.get(this, 'isBurgerMenuEnabled');
      },

      /**
       * Cache the stored visibility when the value is set externally. The `get` will not refer to the cache
       * because the value needs to change when `isBurgerMenuEnabled` is false.
       * @param _
       * @param {boolean} value Value to be set
       * @returns {boolean}
       */
      set: function set(_, value) {
        return value;
      }
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        Ember.get(this, 'session').invalidate();
      },
      toggleHamburger: function toggleHamburger() {
        var isBurgerMenuEnabled = Ember.get(this, 'isBurgerMenuEnabled');

        if (isBurgerMenuEnabled) {
          this.toggleProperty('isBurgerMenuVisible');
        }
      },
      closeHamburger: function closeHamburger() {
        var isBurgerMenuEnabled = Ember.get(this, 'isBurgerMenuEnabled');

        if (isBurgerMenuEnabled) {
          Ember.set(this, 'isBurgerMenuVisible', false);
        }
      }
    }
  });

  _exports.default = _default;
});