define("fitbit-site-ui/models/health-programs/membership", ["exports", "ember-data", "snapdragon-common/utils/flash-errors", "snapdragon-common/constants/program-status"], function (_exports, _emberData, _flashErrors, _programStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    flashMessages: Ember.inject.service(),
    startDate: _emberData.default.attr('string'),
    viewedAt: _emberData.default.attr('string'),
    endedAt: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    programId: _emberData.default.attr('string'),
    programVersion: _emberData.default.attr('string'),
    versionReason: _emberData.default.attr('string'),
    program: _emberData.default.attr(),
    viewState: _emberData.default.attr(),
    isActive: Ember.computed.equal('status', _programStatus.ACTIVE),
    reasonString: Ember.computed('versionReason', 'programVersion', function () {
      if (this.versionReason) {
        return this.versionReason.toLowerCase();
      }

      return 'reason unavailable';
    }),
    sendAction: function sendAction(actionType, actionTime, actionReferencedObjectId, actionValue) {
      var _this = this;

      var userId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var adapter = Ember.getOwner(this).lookup('adapter:health-programs/membership');
      var adapterOptions = userId ? {
        userId: userId
      } : {};
      var url = "".concat(adapter.buildURL('health-programs/memberships', this.get('id'), {
        adapterOptions: adapterOptions
      })).replace('.json', '/action.json');
      var requestBodyObj = {};
      requestBodyObj.type = actionType;

      if (actionTime !== null && actionTime !== '') {
        requestBodyObj.actionTime = actionTime;
      }

      if (actionReferencedObjectId !== null && actionReferencedObjectId !== '') {
        requestBodyObj.referencedObjectId = actionReferencedObjectId;
      }

      if (actionValue !== null && actionValue !== '') {
        requestBodyObj.value = actionValue;
      }

      return adapter.ajax(url, 'POST', {
        data: requestBodyObj
      }).then(function (result) {
        _this.store.pushPayload('health-programs/membership', result);
      }).catch(function (e) {
        _this._handleErrors(e);

        throw e;
      });
    },
    _handleErrors: function _handleErrors(e) {
      (0, _flashErrors.default)(Ember.get(this, 'flashMessages'), e);
    }
  });

  _exports.default = _default;
});