define("fitbit-site-ui/controllers/family/parent-approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['approvalType', 'myChildId', 'otherUserId'],

    /**
     * The parent approval type: incoming or outgoing friendship.
     */
    approvalType: null,

    /**
     * The encoded user id of the child.
     */
    myChildId: null,

    /**
     * The encoded user id of other user that invites or is invited by child.
     */
    otherUserId: null
  });

  _exports.default = _default;
});