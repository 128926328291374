define("fitbit-site-ui/constants/settings/corporate-fsc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fitbitCareDesktopEvent = fitbitCareDesktopEvent;
  _exports.FITBIT_CARE_DESKTOP_PROFILE_TAB_VIEW = void 0;
  var FITBIT_CARE_DESKTOP_PROFILE_TAB_VIEW = 'Profile Tab';
  _exports.FITBIT_CARE_DESKTOP_PROFILE_TAB_VIEW = FITBIT_CARE_DESKTOP_PROFILE_TAB_VIEW;

  function fitbitCareDesktopEvent(_ref) {
    var view = _ref.view,
        element = _ref.element,
        action = _ref.action,
        programId = _ref.programId;
    return {
      pageName: view,
      eventType: action,
      programId: programId,
      fscOptions: {
        owner: 'corporate-wellness',
        feature: 'Fitbit Care Desktop',
        element: element
      }
    };
  }
});