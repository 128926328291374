define("fitbit-site-ui/session-stores/fitbit-adaptive", ["exports", "ember-simple-auth/session-stores/adaptive"], function (_exports, _adaptive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Custom to restore from webview-auth loaded token that should be written to localstorage.
   */
  var _default = _adaptive.default.extend({
    webviewAuth: Ember.inject.service(),
    restore: function restore() {
      var _this$_super;

      var _super = (_this$_super = this._super).bind.apply(_this$_super, [this].concat(Array.prototype.slice.call(arguments)));

      if (this.webviewAuth.isSupported) {
        /**
         * Initiate delegate token exchange.
         * `webviewAuth.loadedToken` is a ember-concurrency Task instance,
         * so we just need to retrieve it because the task will exchange
         * and store the token
         */
        return this.webviewAuth.loadedToken.then(function () {
          /**
           * Restore from localstorage that should now contain the exchanged token.
           * Doing so to reduce complexity.
           */
          return _super();
        }).catch(function () {
          /**
           * If there is a failure for some reason, then fallback to restore from localstorage.
           */
          return _super();
        });
      }

      return _super();
    }
  });

  _exports.default = _default;
});