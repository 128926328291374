define("fitbit-site-ui/routes/settings/profile-settings", ["exports", "fitbit-site-ui/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    titleToken: function titleToken() {
      var i18n = Ember.get(this, 'i18n');
      return i18n.formatByNamespace('route_title', 'route.settings.profile-settings');
    },
    model: function model() {
      var id = Ember.get(this, 'currentUser.id');
      return this.store.peekRecord('profile', id);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Triggers finding the cover photo record, because coverPhoto is a belongsTo relationship to a cover-photo model
      model.get('coverPhoto)');
      Ember.set(this, 'breadCrumb', {
        title: this.titleToken()
      });
      this.store.findAll('timezone').then(function (timezones) {
        model.set('timezones', timezones);
      });
      this.store.findAll('food-database').then(function (foodDbs) {
        model.set('foodDatabases', foodDbs);
      });
      this.store.findAll('country').then(function (countries) {
        model.set('countries', countries); // Find country record for US

        var US = _this.store.peekRecord('country', 'US'); // Find states in US


        var states = US.hasMany('subLocale').value();
        model.set('states', states);
      });
      this.store.findAll('locale').then(function (locales) {
        model.set('locales', locales);
      }); // GAIA account does not need to check pending email.

      if (Ember.get(this.currentUser, 'linkedGoogleAccountStatus')) {
        model.set('pendingEmail', '');
      } else {
        var id = Ember.get(this, 'currentUser.id');
        return Ember.get(this, 'ajax').request('/account/pending-email.json', {
          data: {
            id: id
          },
          namespace: '1'
        }).then(function (response) {
          var pendingEmail = Ember.get(response, 'pendingEmail');

          if (pendingEmail) {
            model.set('pendingEmail', pendingEmail);
          }
        }).catch(function () {
          model.set('pendingEmail', '');
        });
      }
    },
    deactivate: function deactivate() {
      Ember.get(this, 'currentModel').rollbackAttributes();
    },
    actions: {
      //We need this because Ember doesn't set customHeartRate fields as dirty when the object they store changes
      //Maybe look into data fragments: http://discuss.emberjs.com/t/hasdirtyattributes-always-false-for-nested-properties/8534
      saveHeartRate: function saveHeartRate(model) {
        var flashMessages = Ember.get(this, 'flashMessages');
        model.save().then(function () {
          flashMessages.success('Updated heart rate');
        }, function (e) {
          var errors = e.errors,
              errorMessage = errors.length ? errors[0].message : 'Error happened when saving profile';
          flashMessages.alert(errorMessage);
        });
      }
    }
  });

  _exports.default = _default;
});