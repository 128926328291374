define("fitbit-site-ui/serializers/cover-photo", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'encodedUserId',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {
        'cover-photo': payload.data
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});