define("fitbit-site-ui/serializers/fat-time", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'dateTime',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var modelName = 'fat-time';

      if (payloadKey !== 'body-fat') {
        modelName = this._super.apply(this, arguments);
      }

      return modelName;
    },

    /* eslint-disable no-unused-vars */
    keyForRelationship: function keyForRelationship(key, relationship) {
      return 'dateTime';
    }
  });

  _exports.default = _default;
});