define("fitbit-site-ui/controllers/profile/index", ["exports", "fitbit-site-ui/constants/profile-roles-permissions", "fitbit-site-ui/components/user-messaging-form"], function (_exports, _profileRolesPermissions, _userMessagingForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    VIEW_ROLE_OWNER: _profileRolesPermissions.VIEW_ROLE_OWNER,
    VIEW_ROLE_FRIENDS: _profileRolesPermissions.VIEW_ROLE_FRIENDS,
    VIEW_ROLE_PUBLIC: _profileRolesPermissions.VIEW_ROLE_PUBLIC,

    /**
     * UserId of currently viewed page
     */
    profileId: Ember.computed.readOnly('model.profile.id'),

    /**
     * Value of the logged in user's privacy setting for friends
     *
     * @returns Array
     */
    friendPrivacyValue: Ember.computed('model.privacySettings', function () {
      var privacyContent = Ember.get(this, 'model.privacySettings');
      return Ember.get(privacyContent.findBy('id', 'FRIENDS'), 'value') || 'PRIVATE';
    }),

    /**
     * Value of the logged in user's privacy setting for badges
     *
     * @returns Array
     */
    badgesPrivacyValue: Ember.computed('model.privacySettings', function () {
      var privacyContent = Ember.get(this, 'model.privacySettings');
      return Ember.get(privacyContent.findBy('id', 'BADGES'), 'value') || 'PRIVATE';
    }),

    /**
     * Boolean value representing whether the logged in user id and the url id is the same
     *
     * @returns {Boolean}
     */
    isLoggedInUser: Ember.computed('model.profile.id', function () {
      return Ember.get(this, 'model.profile.id') === Ember.get(this, 'currentUser.id');
    }),

    /**
     * Returns true if current user is friends with user of profile being viewed
     * also returns true if the current user is the user of profile being viewed
     * @returns {Boolean} - True if profile is current users or if current user is friends
     */
    isFriendsWithUser: Ember.computed('profileId', 'isLoggedInUser', 'model.currentUserFriendList.last.value.list.[]', function () {
      var profileId = Ember.get(this, 'profileId');
      var isLoggedInUser = Ember.get(this, 'isLoggedInUser');
      var friendsList = Ember.get(this, 'model.currentUserFriendList.last.value.list');

      if (!friendsList) {
        return isLoggedInUser;
      }

      var hasCurrentUserInFriendsList = friendsList.find(function (user) {
        return Ember.get(user, 'id') === profileId;
      });
      return isLoggedInUser || Boolean(hasCurrentUserInFriendsList);
    }),

    /**
     * True if the current user sent a friend request the viewed profile
     * @returns {Boolean}
     */
    hasSentInvitationToProfile: Ember.computed('profileId', 'isLoggedInUser', 'model.friendInvitations.last.value.sentInvitations.[]', function () {
      var profileId = Ember.get(this, 'profileId');
      var isLoggedInUser = Ember.get(this, 'isLoggedInUser');
      var sentInvitationsList = Ember.get(this, 'model.friendInvitations.last.value.sentInvitations');

      if (!sentInvitationsList) {
        return isLoggedInUser;
      }

      var hasCurrentUserInInvitationsList = sentInvitationsList.find(function (user) {
        return Ember.get(user, 'user.id') === profileId;
      });
      return isLoggedInUser || Boolean(hasCurrentUserInInvitationsList);
    }),

    /**
     * If the profile has sent the currentUser an invitation this will be that model or null
     * @returns {Object|null} - Either the inbox-invitation model or null if it does not exist
     */
    invitationFromProfile: Ember.computed('profileId', 'model.friendInvitations.last.value.inboxInvitations.[]', function () {
      var profileId = Ember.get(this, 'profileId');
      var receivedInvitationsList = Ember.get(this, 'model.friendInvitations.last.value.inboxInvitations');

      if (!receivedInvitationsList) {
        return {};
      }

      return receivedInvitationsList.find(function (user) {
        return Ember.get(user, 'user.id') === profileId;
      });
    }),

    /**
     * True if the current user has received an invitation from the viewed profile
     * @returns {Boolean}
     */
    hasReceivedInvitationFromProfile: Ember.computed('isLoggedInUser', 'invitationFromProfile', function () {
      var isLoggedInUser = Ember.get(this, 'isLoggedInUser');
      var invitationFromProfile = Ember.get(this, 'invitationFromProfile');
      return isLoggedInUser || Boolean(invitationFromProfile);
    }),

    /**
     * Determine if the owner of the profile is a friend of the current user by checking if the current user
     * friends list contains a relationship to the profile owner.
     *
     * @returns {Boolean}
     */
    isCurrentUserFriend: Ember.computed('model.{currentUserFriendList.last.value.list.[],profile}', function () {
      var friendsList = Ember.get(this, 'model.currentUserFriendList.last.value.list') || [];
      var profile = Ember.get(this, 'model.profile'); // currentUser service is a proxy object to the current user profile model so look up the profile from content

      return friendsList.findBy('id', profile.id);
    }),

    /**
     * Initial role value for when a profile owner first loads their own profile
     */
    profileViewRole: Ember.computed('isLoggedInUser', {
      /**
       * Provide the default value based on whether the logged in user (current user) is the owner of the profile.
       *
       * @returns {String|null}
       */
      get: function get() {
        return Ember.get(this, 'isLoggedInUser') ? _profileRolesPermissions.VIEW_ROLE_OWNER : null;
      },

      /**
       * Enables changing the value of `profileViewRole`.
       * The default value, as provided by `get`, will be returned when the `isLoggedInUser` changes.
       *
       * @param {String} key Key to be set.
       * @param {String} value Value to be set.
       * @returns {String}
       */
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Model for user-messaging-form component.
     *
     * The value is a placeholder that is replaced when the user message form appears in the modal.
     *
     * @returns {UserMessagingFormModel}
     */
    userMessagingFormModel: Ember.computed(function () {
      return this.createUserMessagingFormModel();
    }),

    /**
     * Reset data used for messaging form.
     * Is reset when the form is closed.
     *
     * @returns {*}
     */
    createUserMessagingFormModel: function createUserMessagingFormModel() {
      var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _EmberGetProperties = Ember.getProperties(source, 'avatar', 'name'),
          _EmberGetProperties$a = _EmberGetProperties.avatar,
          avatar = _EmberGetProperties$a === void 0 ? '' : _EmberGetProperties$a,
          _EmberGetProperties$n = _EmberGetProperties.name,
          name = _EmberGetProperties$n === void 0 ? '' : _EmberGetProperties$n,
          _EmberGetProperties$m = _EmberGetProperties.message,
          message = _EmberGetProperties$m === void 0 ? '' : _EmberGetProperties$m;

      return _userMessagingForm.UserMessagingFormModel.create({
        avatar: avatar,
        name: name,
        message: message
      });
    },
    actions: {
      /**
       * Open the message modal
       */
      openMessageModal: function openMessageModal() {
        var messageModal = Ember.get(this, 'messageModal');

        if (messageModal) {
          var profile = Ember.get(this, 'model.profile');

          var _EmberGetProperties2 = Ember.getProperties(profile, 'avatar', 'displayName'),
              avatar = _EmberGetProperties2.avatar,
              name = _EmberGetProperties2.displayName; // Update the `userMessagingFormModel` computed property a new object with populated avatar and name.


          Ember.set(this, 'userMessagingFormModel', this.createUserMessagingFormModel({
            avatar: avatar,
            name: name
          }));
          Ember.run.scheduleOnce('afterRender', messageModal, 'open');
        }
      },

      /**
       * Close the message modal
       */
      closeMessageModal: function closeMessageModal() {
        var messageModal = Ember.get(this, 'messageModal');

        if (messageModal) {
          Ember.run.scheduleOnce('afterRender', messageModal, 'close');
        }
      }
    }
  });

  _exports.default = _default;
});