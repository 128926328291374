define("fitbit-site-ui/components/profile-settings-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleHeight: function toggleHeight(heightUnit) {
        this.set('profile.heightUnit', heightUnit);
      },
      toggleWeight: function toggleWeight(weightUnit) {
        this.set('profile.weightUnit', weightUnit);
      },
      toggleWater: function toggleWater(waterUnit) {
        this.set('profile.waterUnit', waterUnit);
      }
    }
  });

  _exports.default = _default;
});