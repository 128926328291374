define("fitbit-site-ui/controllers/onboarding/scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    deviceName: Ember.computed('model.scale.version', function () {
      return this.get('model.scale.version').replace('_', ' ');
    }),
    actions: {
      onSave: function onSave(selectedIconId) {
        var currentId = this.get('currentUser.id');
        this.get('model.scale.userList.users').then(function (userList) {
          var currentUser = userList.findBy('id', currentId);
          currentUser.set('userIconId', selectedIconId);
          currentUser.save();
        });
        var routeTo = "/settings/scales/".concat(this.get('model.scale.id'));
        var deviceName = this.get('deviceName');
        this.transitionToRoute('onboarding.get-app', deviceName, {
          queryParams: {
            'forward-url': routeTo
          }
        });
      }
    }
  });

  _exports.default = _default;
});