define("fitbit-site-ui/serializers/journal-survey-question-response", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    attrs: {
      journalEntry: {
        key: 'journal-entry',
        serialize: true
      },
      name: {
        key: 'id'
      },
      onSelect: 'on-select'
    }
  });

  _exports.default = _default;
});