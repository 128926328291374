define("fitbit-site-ui/components/friend-invite-email-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'friend-invite-email-preview',

    /**
     * Stores the iFrame element that will contain the preview.
     *
     * @type {HTMLIFrameElement|null}
     */
    emailPreviewElement: null,

    /**
     * The email contents to be previewed.
     *
     * @returns {String|null}
     */
    emailContents: Ember.computed({
      get: function get() {
        return null;
      },
      set: function set(key, value) {
        var emailPreviewElement = Ember.get(this, 'emailPreviewElement');

        if (emailPreviewElement && value) {
          this.setEmailPreviewContents(emailPreviewElement, value);
        }

        return value;
      }
    }),

    /**
     * Override to retrieve and store the emailPreviewElement once the component has been inserted.
     *
     * Will also attempt to set the email contents on the `emailPreviewElement`.
     */
    didInsertElement: function didInsertElement() {
      var emailContents = Ember.get(this, 'emailContents');
      var emailPreviewElement = this.element.getElementsByClassName('friend-invite-email-preview-element')[0];
      Ember.set(this, 'emailPreviewElement', emailPreviewElement);

      if (emailPreviewElement && emailContents) {
        this.setEmailPreviewContents(emailPreviewElement, emailContents);
      }
    },

    /**
     *
     * @param {HTMLIFrameElement} emailPreviewElement An IFrame element to contain the email contents.
     * @param {String} emailContents Email contents as a string.
     */
    setEmailPreviewContents: function setEmailPreviewContents(emailPreviewElement, emailContents) {
      var iframedoc = emailPreviewElement.contentDocument || emailPreviewElement.contentWindow.document;
      iframedoc.body.innerHTML = emailContents;
    }
  });

  _exports.default = _default;
});