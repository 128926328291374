define("fitbit-site-ui/initializers/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // Ember < 2.1
    var application = arguments[1] || arguments[0];
    application.inject('route', 'session', 'service:session');
    application.inject('controller', 'session', 'service:session');
    application.inject('component', 'session', 'service:session');
  }

  var _default = {
    name: 'session',
    after: 'ember-simple-auth',
    initialize: initialize
  };
  _exports.default = _default;
});