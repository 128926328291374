define("fitbit-site-ui/adapters/time-series/activity/list", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    namespace: '1.1/user/-',
    pathForType: function pathForType(date) {
      return 'activities/date/' + date;
    }
  }); // GET /1.1/user/-/activities/date/2016-07-17.json


  _exports.default = _default;
});