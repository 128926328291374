define("fitbit-site-ui/components/double-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'double-radio-button',
    attributeBindings: ['tabindex'],
    tabindex: 0,
    ariaRole: 'radiogroup',
    change: function change(evt) {
      var value = evt.target.value;
      var classList = evt.target.classList[0];
      var key;
      var changeFnc = this.get('changeFnc');

      if (classList.length > 1) {
        key = evt.target.classList[0];
      }

      return changeFnc && changeFnc(value, key);
    }
  });

  _exports.default = _default;
});