define("fitbit-site-ui/models/application-authorization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    i18n: Ember.inject.service(),
    authorizedDate: _emberData.default.attr(),
    isCurrentSession: _emberData.default.attr('boolean'),
    isRevocable: _emberData.default.attr('boolean'),
    scopes: _emberData.default.attr(),
    application: _emberData.default.belongsTo('my-application', {
      async: false
    }),

    /**
     * {
     *   ip, city, country, os-name, os-version, browser-name, browser-version, device-name, device-model,
     *   device-manufacturer, device-image, browser-icon, json-version
     * }
     */
    sessionData: _emberData.default.attr(),

    /**
     * Formatted authorizedDate
     */
    displayDate: Ember.computed('authorizedDate', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'constants', 'moment'),
          constants = _EmberGetProperties.constants,
          moment = _EmberGetProperties.moment;

      var _EmberGetProperties2 = Ember.getProperties(constants, 'YEAR_MONTH_DAY', 'HOUR_MIN_SEC'),
          YEAR_MONTH_DAY = _EmberGetProperties2.YEAR_MONTH_DAY,
          HOUR_MIN_SEC = _EmberGetProperties2.HOUR_MIN_SEC;

      var authorizedDate = Ember.get(this, 'authorizedDate');
      return moment.moment(authorizedDate).format("".concat(YEAR_MONTH_DAY, " ").concat(HOUR_MIN_SEC));
    })
  });

  _exports.default = _default;
});