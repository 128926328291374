define("fitbit-site-ui/models/food-note-date", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    notes: _emberData.default.hasMany('food-note'),
    foodDateLog: _emberData.default.belongsTo('food-date-log')
  });

  _exports.default = _default;
});