define("fitbit-site-ui/serializers/device/tracker/settings/options", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName);
      var trackers = payload.trackers;
      trackers.forEach(function (tracker) {
        tracker.deviceType = tracker.name;
      });

      var data = _defineProperty({}, payloadKey, trackers);

      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});