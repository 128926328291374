define("fitbit-site-ui/components/journal-survey-question-slider", ["exports", "snapdragon-common/components/journal-survey-question-slider"], function (_exports, _journalSurveyQuestionSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _journalSurveyQuestionSlider.default;
    }
  });
});