define("fitbit-site-ui/adapters/water-log", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    namespace: '1/user',
    currentUser: Ember.inject.service(),
    headers: Ember.computed('i18n.userLocale', function () {
      var waterUnit = Ember.get(this, 'currentUser.waterUnit');
      var headers = this.createHeaders();
      return Object.assign(headers, {
        'Accept-Language': waterUnit === 'METRIC' ? 'en_GB' : 'en_US'
      });
    }),
    pathForType: function pathForType() {
      return '';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this._super.apply(this, arguments), "/-/foods/log/water.json");
    },
    createRecord: function createRecord(store, type, snapshot) {
      var date = Ember.get(snapshot, 'record.date');
      var unit = Ember.get(snapshot, 'record.unit');
      return this._super.apply(this, arguments).then(function (response) {
        response.date = date;
        response.passedUnit = unit;
        return response;
      });
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return "".concat(this._super(null, modelName, snapshot), "/-/foods/log/water/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});