define("fitbit-site-ui/components/join-cw-program", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onStartJoiningProgram: null,
    onLeave: function onLeave() {},

    /**
     * Remove participation data.
     */
    onParticipationRemove: function onParticipationRemove() {},
    actions: {
      startJoiningProgram: function startJoiningProgram(userProgram) {
        var onStartJoiningProgram = Ember.get(this, 'onStartJoiningProgram');

        if (onStartJoiningProgram) {
          return onStartJoiningProgram(userProgram);
        }
      }
    }
  });

  _exports.default = _default;
});