define("fitbit-site-ui/models/food-log", ["exports", "ember-data", "numbro", "snapdragon-common/utils/unit-converter"], function (_exports, _emberData, _numbro, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var roundToDigits = _unitConverter.default.roundToDigits;

  var _default = Model.extend({
    foodItem: belongsTo('food/food-item', {
      async: false
    }),

    /**
     * loggedFood is left as an object instead of creating a relationship with a food/logged-food because when
     * updating or logging a food at a different amount than the originally created loggedFood the logged-food is not
     * updated, and conflicting versions of the object are sent from logged food endpoints and food-date-log endpoints
     * where the food-date-log sends a logged-food object with the values associated with a food-log's properties
     */
    loggedFood: _emberData.default.attr(),
    loggedCalories: attr(),
    nutritionalValues: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    servings: attr(),
    date: belongsTo('food-date-log', {
      async: false
    }),
    isQuickCaloriesAdd: attr('boolean'),
    isGeneric: attr('boolean'),

    /**
     * Return the calories from either loggedFood or loggedCalories depending if the isQuickCaloriesAdd is true/false.
     *
     * @type {Number};
     */
    calories: Ember.computed('loggedFood.calories', 'loggedCalories.calories', 'isQuickCaloriesAdd', function () {
      var calories = Ember.get(this, 'isQuickCaloriesAdd') ? Ember.get(this, 'loggedCalories.calories') : Ember.get(this, 'loggedFood.calories');
      return calories || 0;
    }),

    /**
     * Calculate the servings from logged food calories.
     *
     * The `servings` attr provides a list of serving options.
     * @returns {Number}
     */
    loggedServings: Ember.computed('{foodItem,calories,isQuickCaloriesAdd}', function () {
      var isQuickCaloriesAdd = Ember.get(this, 'isQuickCaloriesAdd');

      if (isQuickCaloriesAdd) {
        return 1;
      } else {
        var logCalories = Ember.get(this, 'calories') || 0;
        var foodCalories = Ember.get(this, 'foodItem.calories') || Ember.get(this, 'loggedFood.calories') || Ember.get(this, 'loggedCalories.calories') || Ember.get(this, 'nutritionalValues.calories') || 0;
        /**
         * 0 is an acceptable serving size. Previously the default would be 1, which caused issues when comparing to the
         * total amount for a date log.
         */

        return foodCalories ? logCalories / foodCalories : 0;
      }
    }),

    /**
     * Nutritional values for the logged servings. Note this is not PER serving - this is for the total servings.
     *
     * @returns {void | Object}
     */
    nutritionalValuesOfLoggedServings: Ember.computed('nutritionalValues', 'loggedServings', 'isQuickCaloriesAdd', 'calories', function () {
      var nutritionalValuesSource;

      if (Ember.get(this, 'isQuickCaloriesAdd')) {
        nutritionalValuesSource = {
          calories: Ember.get(this, 'calories')
        };
      } else {
        nutritionalValuesSource = _objectSpread({}, Ember.get(this, 'nutritionalValues') || {});
      }
      /**
       * 0 is an acceptable serving size. Previously the default would be 1, which caused issues when comparing to the
       * total amount for a date log.
       */


      var loggedServings = Ember.get(this, 'loggedServings');

      var nutritionalValues = _objectSpread({}, nutritionalValuesSource);

      Object.keys(nutritionalValues).map(function (key) {
        nutritionalValues[key] = nutritionalValues[key] * loggedServings;
      });
      return nutritionalValues;
    }),

    /**
     * Formatted nutritional values for the logged servings.
     *
     * @returns {void | Object}
     */
    formattedNutritionalValuesOfLoggedServings: Ember.computed('nutritionalValuesOfLoggedServings', function () {
      var nutritionalValuesSource = Ember.get(this, 'nutritionalValuesOfLoggedServings') || {};

      var nutritionalValues = _objectSpread({}, nutritionalValuesSource);

      Object.keys(nutritionalValues).forEach(function (key) {
        var value = nutritionalValues[key];
        nutritionalValues[key] = (0, _numbro.default)(roundToDigits(value, 2)).format('0[.]00');
      });
      return nutritionalValues;
    })
  });

  _exports.default = _default;
});