define("fitbit-site-ui/components/scale-name", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    scaleName: (0, _emberCpValidations.validator)('length', {
      description: 'Scale name length',
      min: 1,
      max: 15,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('scale_name_msg', {
          min: '{min}',
          max: '{max}'
        });
      })
    })
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    onScaleNameChanged: function onScaleNameChanged() {},
    actions: {
      scaleNameChanged: function scaleNameChanged() {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            Ember.RSVP.resolve(_this.get('onScaleNameChanged')()).then(function () {
              return flashMessages.success(formatter.format('device_setting_saved'));
            });
          } else {
            flashMessages.alert(formatter.format('fix_errors'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});