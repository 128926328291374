define("fitbit-site-ui/serializers/health-programs/program-access", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'programId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'health-programs/program-access';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'programAccess';
    }
  });

  _exports.default = _default;
});