define("fitbit-site-ui/adapters/water-date", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user',
    currentUser: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(snapshot, 'adapterOptions.user.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/water/date/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});