define("fitbit-site-ui/instance-initializers/error-log", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var _appInstance$resolveR = appInstance.resolveRegistration('config:environment'),
        environment = _appInstance$resolveR.environment,
        APP = _appInstance$resolveR.APP;

    var build = APP === null || APP === void 0 ? void 0 : APP.version;
    var errorLoggerService = appInstance.lookup('service:error-logger');
    var oldOnError = Ember.onerror;

    function emberOnError(error) {
      // _from is used for testing purposes
      Ember.run.next(errorLoggerService, 'log', {
        error: error,
        build: build,
        _from: 'ember'
      });

      if (oldOnError) {
        oldOnError.apply(this, arguments);
      } // throw error while testing to not break test cases


      if (environment === 'test') {
        if (!errorLoggerService.mustIgnoreError(error)) {
          throw error;
        }
      }
    }

    function rsvpOnError(error) {
      // _from is used for testing purposes
      Ember.run.next(errorLoggerService, 'log', {
        error: error,
        build: build,
        _from: 'rsvp'
      }); // throw error while testing to not break test cases

      if (environment === 'test') {
        if (!errorLoggerService.mustIgnoreError(error)) {
          throw error;
        }
      }
    }

    function windowOnError(event) {
      var error = event.error;

      if (!error) {
        var message = event.message;
        error = new Error(message);
      } // _from is used for testing purposes


      Ember.run.next(errorLoggerService, 'log', {
        error: error,
        build: build,
        _from: 'window'
      }); // throw error while testing to not break test cases

      if (environment === 'test') {
        if (!errorLoggerService.mustIgnoreError(error)) {
          throw error;
        }
      }
    } // Set the new handler


    Ember.onerror = emberOnError; // Attach the event handler

    Ember.RSVP.on('error', rsvpOnError); // Attach event handler to window

    _emberWindowMock.default.addEventListener('error', windowOnError); // Reopen the app instance to add a willDestroy hook for cleaning up.


    appInstance.reopen({
      willDestroy: function willDestroy() {
        var ret = this._super.apply(this, arguments); // Restore the old handler


        Ember.onerror = oldOnError; // Remove the event handler

        Ember.RSVP.off('error', rsvpOnError); //  Remove event handler from window

        _emberWindowMock.default.removeEventListener('error', windowOnError);

        return ret;
      }
    });
  }

  var _default = {
    name: 'error-log',
    initialize: initialize
  };
  _exports.default = _default;
});