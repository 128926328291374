define("fitbit-site-ui/serializers/device/tracker/auto-exercise-settings", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'device/tracker/auto-exercise-settings';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'detectionThresholds';
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName),
          data = {};
      payload.id = id;
      data = _defineProperty({}, payloadKey, payload);
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    serialize: function serialize(snapshot) {
      return snapshot.attr('detectionThresholds');
    }
  });

  _exports.default = _default;
});