define("fitbit-site-ui/components/scale-user-icon-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSave: function onSave() {},
    selectedIconId: null,
    iconsSupported: null,
    users: null,
    actions: {
      iconSelect: function iconSelect(iconId) {
        this.set('selectedIconId', iconId);
      },
      submitIcon: function submitIcon() {
        var onSave = this.get('onSave');
        return onSave(this.get('selectedIconId'));
      }
    }
  });

  _exports.default = _default;
});