define("fitbit-site-ui/constants/email-resend-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAILURE = _exports.VERIFIED = _exports.SUCCESS = void 0;

  /**************************************************
   * Request Status
   *************************************************/

  /**
   * Email successfully resent
   * @type {String}
   */
  var SUCCESS = 'SUCCESS';
  /**
   * Email has already been verified, or does not need to be
   * @type {String}
   */

  _exports.SUCCESS = SUCCESS;
  var VERIFIED = 'VERIFIED';
  /**
   * Email failed to be resent (caused by server error)
   * @type {string}
   */

  _exports.VERIFIED = VERIFIED;
  var FAILURE = 'FAILURE';
  _exports.FAILURE = FAILURE;
});