define("fitbit-site-ui/models/weight-list", ["exports", "ember-data", "snapdragon-common/mixins/models/non-rest-action"], function (_exports, _emberData, _nonRestAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_nonRestAction.default, {
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    beforeDate: _emberData.default.attr(),
    list: _emberData.default.hasMany('weight-log'),
    nextRecordUrl: _emberData.default.attr('string'),
    hasMoreWeight: Ember.computed.bool('nextRecordUrl'),
    descWeightList: Ember.computed.sort('list.[]', function (itemA, itemB) {
      var itemATime = "".concat(Ember.get(itemA, 'date'), " ").concat(Ember.get(itemA, 'time'));
      var itemBTime = "".concat(Ember.get(itemB, 'date'), " ").concat(Ember.get(itemB, 'time'));
      return itemATime > itemBTime ? -1 : itemBTime > itemATime ? 1 : 0;
    }),

    /**
     * Time constants
     */
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    HOUR_MIN_SEC: Ember.computed.readOnly('constants.HOUR_MIN_SEC'),

    /**
     * Checks if there is a manually logged weight for today, returning the record if there is
     * @returns {null|weight-log} - If there is a manually logged weight for todays date
     *                              (log with time of 11:59:59 PM) return it, otherwise return null
     */
    logForToday: Ember.computed('descWeightList', function () {
      var moment = Ember.get(this, 'moment');
      var descWeightList = Ember.get(this, 'descWeightList');

      if (descWeightList.length === 0) {
        return null;
      }

      var mostRecentLog = descWeightList[0];
      var today = moment.moment();
      today.hour(23);
      today.minute(59);
      today.second(59);
      var dateFormat = "".concat(Ember.get(this, 'YEAR_MONTH_DAY'), " ").concat(Ember.get(this, 'HOUR_MIN_SEC'));
      var logDate = "".concat(Ember.get(mostRecentLog, 'date'), " ").concat(Ember.get(mostRecentLog, 'time'));
      return moment.moment(logDate).format(dateFormat) === today.format(dateFormat) ? mostRecentLog : null;
    }),

    /**
     * Uses a nonRestAction to fetch and serialize the next set of weight records
     * @returns { null }
     */
    fetchMoreWeight: function fetchMoreWeight() {
      var _this = this;

      var url = Ember.get(this, 'nextRecordUrl'); // Fetch more weight using 'next' pagination url from Fitbit API

      return this.nonRestAction(url, 'GET').then(function (results) {
        var store = Ember.get(_this, 'store');
        var modelName = _this.constructor.modelName || _this._internalModel.modelName;
        var serializer = store.serializerFor(modelName);
        var model = store.modelFor(modelName);
        var recordId = Ember.get(_this, 'id'); // Use the serializer to normalize the payload as it would have with `findRecord`.

        var payload = serializer.normalizeResponse(store, model, results, recordId, 'findRecord');
        var newList = payload.data.relationships.list;
        var newListData = newList.data; // Generate data objects from the current list to combine with the new list.
        // This is needed otherwise the existing list will just be overwritten.

        var currentListData = Ember.get(_this, 'list').map(function (weightLog) {
          return {
            id: Ember.get(weightLog, 'id'),
            type: weightLog.constructor.modelName
          };
        }); // Concat the two

        newList.data = currentListData.concat(newListData);
        store.push(payload);
      });
    },
    getActionUrl: function getActionUrl(url) {
      return url;
    }
  });

  _exports.default = _default;
});