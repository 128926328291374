define("fitbit-site-ui/templates/settings/privacy-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYmrll2J",
    "block": "{\"symbols\":[\"group\",\"setting\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row column\"],[11,\"id\",\"privacy-settings\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"format\",[\"privacy_settings_title\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h2\"],[9],[1,[27,\"format\",[[27,\"concat\",[[27,\"if\",[[27,\"eq\",[[22,1,[\"id\"]],\"personal-info\"],null],\"personal_info\",[22,1,[\"id\"]]],null],\"_subtitle\"],null]],null],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[22,1,[\"settings\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"privacy-setting\"],[9],[0,\"\\n                \"],[1,[27,\"privacy-setting-dropdown\",null,[[\"setting\",\"changeFnc\"],[[22,2,[]],[27,\"route-action\",[\"settingChanged\"],null]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/privacy-settings.hbs"
    }
  });

  _exports.default = _default;
});