define("fitbit-site-ui/models/user-property", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('string', {
      defaultValue: false
    }),
    space: _emberData.default.attr('string')
  });

  _exports.default = _default;
});