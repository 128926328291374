define("fitbit-site-ui/serializers/activity-summary", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var ps = payload.summary;
      var newPayload = {
        'activity-summary': {
          id: ps.date,
          stepsToday: ps.steps,
          caloriesToday: ps.caloriesOut,
          activeMinutesToday: ps.fairlyActiveMinutes + ps.veryActiveMinutes
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});