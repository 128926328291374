define("fitbit-site-ui/serializers/water-date", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/utils/unit-converter", "snapdragon-common/constants/food-logging"], function (_exports, _application, _unitConverter, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),

    /**
     * payload.amount ALWAYS received as ml.  Transform and set unt based on currentUser.waterUnit
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var water = payload.water;
      var waterUnit = Ember.get(this, 'currentUser.waterUnit');
      var logIds = water.map(function (log) {
        log.waterDate = id;
        log.unit = _foodLogging.WATER_UNIT_TO_SYMBOL[waterUnit] || 'fl oz';

        if (log.unit === 'cup') {
          log.amount = _unitConverter.default.mlToCups(log.amount, 2);
        } else if (log.unit === 'fl oz') {
          log.amount = _unitConverter.default.mlToOz(log.amount, 1);
        }

        return log.logId;
      });
      var newPayload = {
        'water-log': water,
        'water-date': {
          waterLogs: logIds,
          id: id
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});