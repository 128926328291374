define("fitbit-site-ui/adapters/weight-goal", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    namespace: '1.1/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/body/log/weight/goal.json");
    },
    urlForDeleteRecord: function urlForDeleteRecord() {
      return "".concat(this._super.apply(this, arguments), "/body/log/weight/goal.json");
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this._super.apply(this, arguments), "/-/body/log/weight/goal.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return "".concat(this._super.apply(this, arguments), "/body/log/weight/goal.json");
    }
  });

  _exports.default = _default;
});