define("fitbit-site-ui/controllers/settings/activity-goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function save(steps, distance, calories, activeMinutes, hourlyActiveStart, hourlyActiveDuration) {
        var activityGoals = Ember.get(this.model, 'activityGoals');
        activityGoals.setProperties({
          steps: steps,
          activeMinutes: activeMinutes,
          distance: distance,
          caloriesOut: calories
        });
        var activityPromise = activityGoals.save();
        var inactivityGoals = Ember.get(this.model, 'inactivityGoals');
        inactivityGoals.set('sedentaryTime.startTime', hourlyActiveStart);
        inactivityGoals.set('sedentaryTime.duration', hourlyActiveDuration);
        var inactivityPromise = inactivityGoals.save();
        return Ember.RSVP.all([activityPromise, inactivityPromise]);
      }
    }
  });

  _exports.default = _default;
});