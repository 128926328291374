define("fitbit-site-ui/validators/messages", ["exports", "ember-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * I18N Validation messages. The messages are from [ember-validators](https://github.com/offirgolan/ember-validators).
   */
  var _default = _messages.default.extend({
    i18n: Ember.inject.service(),

    /**
     * Helper function to get the i18n string by key in the `common.validators.messages` namespace with options.
     *
     * @param {String} key Key of the string to look up.
     * @param {Object|void} options Options for string interpolation.
     * @returns {String}
     * @private
     */
    _getI18nString: function _getI18nString(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var description = options.description;
      var i18n = Ember.get(this, 'i18n'); // Add description if it's missing

      if (!description) {
        options.description = '{description}';
      }

      return "".concat(i18n.formatByNamespace(key, 'common.validators.messages', options));
    },
    accepted: Ember.computed(function () {
      return this._getI18nString('accepted');
    }),
    after: Ember.computed(function () {
      return this._getI18nString('after', {
        after: '{after}'
      });
    }),
    before: Ember.computed(function () {
      return this._getI18nString('before', {
        before: '{before}'
      });
    }),
    blank: Ember.computed(function () {
      return this._getI18nString('blank');
    }),
    collection: Ember.computed(function () {
      return this._getI18nString('collection');
    }),
    confirmation: Ember.computed(function () {
      return this._getI18nString('confirmation', {
        on: '{on}'
      });
    }),
    date: Ember.computed(function () {
      return this._getI18nString('date');
    }),
    email: Ember.computed(function () {
      return this._getI18nString('email');
    }),
    empty: Ember.computed(function () {
      return this._getI18nString('empty');
    }),
    equalTo: Ember.computed(function () {
      return this._getI18nString('equal_to', {
        is: '{is}'
      });
    }),
    even: Ember.computed(function () {
      return this._getI18nString('even');
    }),
    exclusion: Ember.computed(function () {
      return this._getI18nString('exclusion');
    }),
    greaterThan: Ember.computed(function () {
      return this._getI18nString('greater_than', {
        gt: '{gt}'
      });
    }),
    greaterThanOrEqualTo: Ember.computed(function () {
      return this._getI18nString('greater_than_or_equal_to', {
        gte: '{gte}'
      });
    }),
    inclusion: Ember.computed(function () {
      return this._getI18nString('inclusion');
    }),
    invalid: Ember.computed(function () {
      return this._getI18nString('invalid');
    }),
    lessThan: Ember.computed(function () {
      return this._getI18nString('less_than', {
        lt: '{lt}'
      });
    }),
    lessThanOrEqualTo: Ember.computed(function () {
      return this._getI18nString('less_than_or_equal_to', {
        lte: '{lte}'
      });
    }),
    notAnInteger: Ember.computed(function () {
      return this._getI18nString('not_an_integer');
    }),
    notANumber: Ember.computed(function () {
      return this._getI18nString('not_a_number');
    }),
    odd: Ember.computed(function () {
      return this._getI18nString('odd');
    }),
    onOrAfter: Ember.computed(function () {
      return this._getI18nString('on_or_after', {
        onOrAfter: '{onOrAfter}'
      });
    }),
    onOrBefore: Ember.computed(function () {
      return this._getI18nString('on_or_before', {
        onOrBefore: '{onOrBefore}'
      });
    }),
    otherThan: Ember.computed(function () {
      return this._getI18nString('other_than', {
        value: '{value}'
      });
    }),
    phone: Ember.computed(function () {
      return this._getI18nString('phone');
    }),
    positive: Ember.computed(function () {
      return this._getI18nString('positive');
    }),
    multipleOf: Ember.computed(function () {
      return this._getI18nString('multiple_of', {
        multipleOf: '{mulitpleOf}'
      });
    }),
    present: Ember.computed(function () {
      return this._getI18nString('present');
    }),
    singular: Ember.computed(function () {
      return this._getI18nString('singular');
    }),
    tooLong: Ember.computed(function () {
      return this._getI18nString('too_long', {
        max: '{max}'
      });
    }),
    tooShort: Ember.computed(function () {
      return this._getI18nString('too_short', {
        min: '{min}'
      });
    }),
    between: Ember.computed(function () {
      return this._getI18nString('between', {
        min: '{min}',
        max: '{max}'
      });
    }),
    url: Ember.computed(function () {
      return this._getI18nString('url');
    }),
    wrongDateFormat: Ember.computed(function () {
      return this._getI18nString('wrong_date_format', {
        format: '{format}'
      });
    }),
    wrongLength: Ember.computed(function () {
      return this._getI18nString('wrong_length', {
        is: '{is}'
      });
    })
  });

  _exports.default = _default;
});