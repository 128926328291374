define("fitbit-site-ui/templates/components/scale-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MNKIkKv4",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"focus-out\"],[\"text\",[23,[\"scaleName\"]],\"scale-input\",[27,\"action\",[[22,0,[]],\"scaleNameChanged\"],null]]]],false],[0,\"\\n\"],[1,[27,\"validations-handler\",null,[[\"scope\",\"key\"],[[22,0,[]],\"scaleName\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/scale-name.hbs"
    }
  });

  _exports.default = _default;
});