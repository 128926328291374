define("fitbit-site-ui/models/weight-graph", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // For the 3m/12m/all requests, dateTime will be an average of each day at noon
    // For the 7d/1m requests, dateTime will be each log on each day separately
    primaryKey: 'dateTime',
    weightTime: _emberData.default.belongsTo('weight-time', {
      async: true
    }),
    fatTime: _emberData.default.belongsTo('fat-time', {
      async: true
    }),
    bmiTime: _emberData.default.belongsTo('bmi-time', {
      async: true
    }),
    weightTrend: _emberData.default.belongsTo('weight-graph-trendline', {
      async: true
    })
  });

  _exports.default = _default;
});