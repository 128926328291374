define("fitbit-site-ui/constants/corporate-program-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AGGREGATE_INDIVIDUAL_1P_IDENTITY = _exports.AGGREGATE_1P_IDENTITY = _exports.AGGREGATE_OPT_OUT = _exports.AGGREGATE_INDIVIDUAL = _exports.AGGREGATE = _exports.INDIVIDUAL_DATA_REPORTS_SLEEP = void 0;

  /**
   * The attribute to ask a user for their sleep data
   * @type {string}
   */
  var INDIVIDUAL_DATA_REPORTS_SLEEP = 'INDIVIDUAL_DATA_REPORTS_SLEEP';
  /**
   * Strings to create consent paragraph for invitation modal
   * @type {string}
   */

  _exports.INDIVIDUAL_DATA_REPORTS_SLEEP = INDIVIDUAL_DATA_REPORTS_SLEEP;
  var AGGREGATE = 'AGGREGATE';
  _exports.AGGREGATE = AGGREGATE;
  var AGGREGATE_INDIVIDUAL = 'AGGREGATE_INDIVIDUAL';
  _exports.AGGREGATE_INDIVIDUAL = AGGREGATE_INDIVIDUAL;
  var AGGREGATE_OPT_OUT = 'AGGREGATE_OPT_OUT';
  _exports.AGGREGATE_OPT_OUT = AGGREGATE_OPT_OUT;
  var AGGREGATE_1P_IDENTITY = 'AGGREGATE_1P_IDENTITY';
  _exports.AGGREGATE_1P_IDENTITY = AGGREGATE_1P_IDENTITY;
  var AGGREGATE_INDIVIDUAL_1P_IDENTITY = 'AGGREGATE_INDIVIDUAL_1P_IDENTITY';
  _exports.AGGREGATE_INDIVIDUAL_1P_IDENTITY = AGGREGATE_INDIVIDUAL_1P_IDENTITY;
});