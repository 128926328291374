define("fitbit-site-ui/components/www-settings-nav", ["exports", "fitbit-site-ui/components/settings-nav", "ember-get-config"], function (_exports, _settingsNav, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _settingsNav.default.extend({
    takeoutLink: Ember.computed(function () {
      return _emberGetConfig.default.takeoutLink;
    })
  });

  _exports.default = _default;
});