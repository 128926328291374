define("fitbit-site-ui/adapters/fitbit-food", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    namespace: '1.1',
    pathForType: function pathForType() {
      return 'foods';
    },
    headers: Ember.computed(function () {
      var headers = this.createHeaders();
      return Object.assign(headers, {
        'Accept-Locale': Ember.get(this, 'currentUser.foodsLocale')
      });
    }),
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), ".json");
    }
  });

  _exports.default = _default;
});