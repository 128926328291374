define("fitbit-site-ui/models/user-device", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    ajax: Ember.inject.service(),
    name: (0, _attr.default)('string'),
    battery: (0, _attr.default)('string'),
    deviceType: (0, _relationships.belongsTo)('device-type', {
      async: false
    }),
    encrypted: (0, _attr.default)('boolean'),
    features: (0, _attr.default)(),
    lastSyncTime: (0, _attr.default)('string'),
    mac: (0, _attr.default)('string'),
    productType: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('profile', {
      async: false
    }),
    settings: (0, _relationships.belongsTo)('device/settings', {
      polymorphic: true
    }),
    isLegacy: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    wireId: (0, _attr.default)('string'),

    /**
     * Generate a route name by using the productType. Used for links.
     */
    routeName: Ember.computed('productType', function () {
      var productType = this.get('productType');
      return "settings.".concat(productType, "s");
    }),
    deviceList: (0, _relationships.belongsTo)('user-device-list', {
      inverse: 'list'
    }),
    displayLastSyncTime: Ember.computed('lastSyncTime', function () {
      var formatter = this.get('i18n');
      var moment = this.get('moment');
      var isClock12 = this.get('user.isClock12');
      var timeFormat = moment.moment().localeData().longDateFormat('LT');
      var clockFormat = isClock12 ? formatter.formatTo12Hours(timeFormat, moment.get('locale')) : formatter.formatTo24Hours(timeFormat);
      return "".concat(moment.moment(this.get('lastSyncTime')).format('ll'), " ").concat(formatter.formatByNamespace('at', 'common.messages'), " ").concat(moment.moment(this.get('lastSyncTime')).format(clockFormat));
    }),
    displayName: Ember.computed('name', function () {
      return this.get('name').toUpperCase();
    }),
    isScale: Ember.computed.equal('productType', 'scale'),
    scale: (0, _relationships.belongsTo)('device/scale/user-scale'),
    firmwareVersion: Ember.computed.alias('settings.currentFirmware.app'),
    supportsSwim: Ember.computed('deviceType.deviceFeatureSupported', function () {
      var deviceFeatures = this.get('deviceType.deviceFeatureSupported');
      return deviceFeatures && deviceFeatures.includes('SWIM');
    }),
    supportsAutoRun: Ember.computed('deviceType.deviceFeatureSupported', function () {
      var deviceFeatures = this.get('deviceType.deviceFeatureSupported');
      return deviceFeatures && deviceFeatures.includes('AUTORUN');
    }),

    /**
     * Answers the question: Does this device have the payment feature?
     * @function
     * @param list of supported features from device endpoint
     * @returns {boolean} - is feature supported
     */
    supportsPayments: Ember.computed('deviceType.deviceFeatureSupported', function () {
      var deviceFeatures = this.get('deviceType.deviceFeatureSupported');
      return deviceFeatures && deviceFeatures.includes('PAYMENTS');
    }),
    supports: Ember.computed('deviceType.deviceFeatureSupported', function () {
      var deviceFeatureSupported = this.get('deviceType.deviceFeatureSupported') || [];
      var deviceFeatures = {};
      deviceFeatureSupported.forEach(function (feature) {
        deviceFeatures[Ember.String.camelize(feature.toLowerCase())] = true;
      });
      return deviceFeatures;
    }),
    navId: Ember.computed('wireId', function () {
      var key = 'id'; // The key should be wireId if this is a weightsite build and the device is not a scale
      // Not a scale because scales use the id for navigation instead of wireId.

      if (IS_WEIGHTSITE) {
        if (!this.get('isScale')) {
          key = 'wireId';
        }
      }

      return this.get(key);
    }),
    deactivatePayment: function deactivatePayment() {
      var wireId = this.get('wireId');
      return this.get('ajax').raw('payments/devices?wire_id=' + wireId, {
        method: 'DELETE'
      });
    },
    receiptsRequest: function receiptsRequest() {
      var wireId = this.get('wireId');
      return this.get('ajax').raw( // Project code name
      'felica/export-receipts?wire_id=' + wireId, {
        method: 'POST',
        headers: {
          'Accept-Language': Ember.String.dasherize(this.get('i18n.userLocale'))
        }
      });
    }
  });

  _exports.default = _default;
});