define("fitbit-site-ui/components/device-clock-face-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    label: Ember.computed('clockFace.{displayName,name}', function () {
      var formatter = this.get('i18n');
      var displayName = this.get('clockFace.displayName');
      var name = this.get('clockFace.name');
      return displayName || formatter.formatByNamespace(name, 'templates.components.device-clock-face-setting');
    })
  });

  _exports.default = _default;
});