define("fitbit-site-ui/adapters/friend/sent-invitation", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/user',
    currentUser: Ember.inject.service(),
    pathForType: function pathForType() {
      return '';
    },

    /**
     * Action for RESCINDING a sent friend request
     * @param id
     * @returns {string}
     */
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "".concat(this._super(), "/").concat(currentUserId, "/friends/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});