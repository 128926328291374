define("fitbit-site-ui/routes/login", ["exports", "fitbit-site-ui/routes/unauthenticated", "ember-fitbit-pwd-auth/mixins/fitbit-pwd-auth-route"], function (_exports, _unauthenticated, _fitbitPwdAuthRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unauthenticated.default.extend(_fitbitPwdAuthRoute.default, {
    actions: {
      fitbitPasswordOauth2Login: function fitbitPasswordOauth2Login() {
        var controller = this.controllerFor('login');

        var _this$controller$getP = this.controller.getProperties('identification', 'password'),
            identification = _this$controller$getP.identification,
            password = _this$controller$getP.password;

        this.fitbitPasswordOauth2Login(identification, password).catch(function (reason) {
          var errors = reason.errors;

          if (errors) {
            errors = errors.map(function (error) {
              return error.message;
            });
          }

          controller.set('errorMessage', errors || reason);
        });
      }
    }
  });

  _exports.default = _default;
});