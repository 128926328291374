define("fitbit-site-ui/models/journal-survey-question-response", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    onSelect: _emberData.default.attr('string'),
    journalEntry: (0, _attributes.fragment)('journal-survey-question-response-entry')
  });

  _exports.default = _default;
});