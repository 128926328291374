define("fitbit-site-ui/components/g-autocomplete", ["exports", "ember-cli-g-maps/components/g-autocomplete"], function (_exports, _gAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _gAutocomplete.default;
    }
  });
});