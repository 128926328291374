define("fitbit-site-ui/components/user-notifications/user-notification-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewport: Ember.inject.service('device/layout'),

    /**
     * tells the controller that the notification has been interacted with
     * @private
     */
    _readInteraction: function _readInteraction() {
      var notification = this.get('notification');
      var handler = this.get('markAsRead');

      if (handler && !notification.get('isRead')) {
        handler(notification);
      }
    },
    mouseEnter: function mouseEnter() {
      this._readInteraction();
    },
    touchStart: function touchStart() {
      this._readInteraction();
    }
  });

  _exports.default = _default;
});