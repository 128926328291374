define("fitbit-site-ui/components/profile-friend-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /**
     * Stub for removeFriend action passed by parent
     */
    removeFriend: function removeFriend() {},

    /**
     * Stub for acceptInvitation action passed by parent
     */
    acceptInvitation: function acceptInvitation() {},

    /**
     * Stub for declineInvitation action passed by parent
     */
    declineInvitation: function declineInvitation() {},

    /**
     * Stub for addFriend action passed by parent
     */
    addFriend: function addFriend() {},
    actions: {
      /**
       * Given friend id, removes friend and destroys friend record
       * @returns {Promise}
       */
      onRemoveFriend: function onRemoveFriend(friendId) {
        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(Ember.get(this, 'removeFriend')(friendId)).then(function () {
          return flashMessages.success(formatter.formatByNamespace('friend_removed', 'templates.components.user-profile'));
        }).catch(function () {
          return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
        });
      },

      /**
       * Given inbox-invitation accepts invitation
       * @returns {Promise}
       */
      onAcceptInvite: function onAcceptInvite(invitationFromProfile) {
        var _this = this;

        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(Ember.get(this, 'acceptInvitation')(invitationFromProfile)).then(function () {
          // Push new friends profile into currentUser's profile so the button has the correct state from isFriendsWithProfile
          var friendProfile = Ember.get(_this, 'profile');
          var currentUserProfileFriends = Ember.get(_this, 'currentUser.friends.list');
          return currentUserProfileFriends.pushObject(friendProfile);
        }).then(function () {
          return flashMessages.success(formatter.formatByNamespace('request_accept', 'route.friends'));
        }).catch(function () {
          return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
        });
      },

      /**
       * Given inbox-invitation declines invitation
       * @returns {Promise}
       */
      onDeclineInvite: function onDeclineInvite(invitiationFromProfile) {
        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        return Ember.RSVP.resolve(Ember.get(this, 'declineInvitation')(invitiationFromProfile)).then(function () {
          return flashMessages.success(formatter.formatByNamespace('request_decline', 'route.friends'));
        }).catch(function () {
          return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
        });
      },

      /**
       * Given friend id, sends friend invitation
       * @returns {Promise}
       */
      onAddFriend: function onAddFriend(profile) {
        var formatter = Ember.get(this, 'i18n');
        var flashMessages = Ember.get(this, 'flashMessages');
        var id = profile.id,
            email = profile.email,
            name = profile.name;
        return Ember.RSVP.resolve(Ember.get(this, 'addFriend')({
          id: id,
          email: email,
          name: name
        })).then(function () {
          return flashMessages.success(formatter.formatByNamespace('friend_add', 'templates.components.user-profile'));
        }).catch(function () {
          return flashMessages.alert(formatter.formatByNamespace('something_went_wrong', 'common.messages'));
        });
      }
    }
  });

  _exports.default = _default;
});