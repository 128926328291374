define("fitbit-site-ui/helpers/checkbox-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkboxHelper = checkboxHelper;
  _exports.default = void 0;

  function checkboxHelper(params
  /*, hash*/
  ) {
    var array = params[0],
        value = params[1];
    return array ? array.includes(value) : false;
  }

  var _default = Ember.Helper.helper(checkboxHelper);

  _exports.default = _default;
});