define("fitbit-site-ui/models/fitbit-explore-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    itemId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    priority: 1000,
    requiredGrants: _emberData.default.attr(),
    previewImageUrl: _emberData.default.attr('string'),
    previewImageStyle: _emberData.default.attr('string'),
    discoverTileDetail: _emberData.default.attr('string'),
    primaryImageUrl: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    legalText: _emberData.default.attr('string')
  });

  _exports.default = _default;
});