define("fitbit-site-ui/adapters/activity-summary", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    namespace: '1/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      var id = query.id,
          date = query.date;
      delete query.id;
      delete query.date;
      return "".concat(this._super.apply(this, arguments), "/").concat(id, "/activities/date/").concat(date, ".json");
    },

    /**
     * Override queryRecord to add date query to the payload to more easily identify the summary record.
     * @param store
     * @param type
     * @param query
     */
    queryRecord: function queryRecord(store, type, query) {
      var date = query.date;
      return this._super.apply(this, arguments).then(function (payload) {
        var summary = payload.summary;

        if (summary && !summary.date) {
          summary.date = date;
        }

        return payload;
      });
    }
  });

  _exports.default = _default;
});