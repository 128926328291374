define("fitbit-site-ui/templates/components/scale-user-icon-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSdJYwZQ",
    "block": "{\"symbols\":[\"icon\"],\"statements\":[[7,\"div\"],[11,\"class\",\"icon-wrapper\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"icons\"]]],null,{\"statements\":[[0,\"        \"],[4,\"scale-user-icon\",null,[[\"icon\",\"users\",\"selected\",\"clickAction\"],[[22,1,[]],[23,[\"users\"]],[27,\"eq\",[[23,[\"selectedIconId\"]],[22,1,[\"userIconId\"]]],null],[27,\"action\",[[22,0,[]],\"iconSelect\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"form\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"cta-button icon-select-button\"],[11,\"data-test-qa\",\"icon-select-submit-button\"],[11,\"type\",\"submit\"],[9],[1,[27,\"format-by-namespace\",[\"save_icon\",\"templates.onboarding\"],null],false],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"submitIcon\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/scale-user-icon-select.hbs"
    }
  });

  _exports.default = _default;
});