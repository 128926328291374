define("fitbit-site-ui/services/deep-link-with-backup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userAgent: Ember.inject.service('user-agent'),
    constants: Ember.inject.service(),
    openInApp: function openInApp(deepLinkURL) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var constants = Ember.get(this, 'constants');
      var os;

      if (Ember.get(this, 'userAgent.os.isIOS')) {
        os = constants.OS.IOS;
      } else if (Ember.get(this, 'userAgent.os.isAndroid')) {
        os = constants.OS.ANDROID;
      } else if (Ember.get(this, 'userAgent.os.isWindows')) {
        os = constants.OS.WINDOWS_MOBILE;
      } else {
        return Ember.RSVP.reject();
      }

      return Ember.RSVP.resolve(this._deepLinkOrStorePage(deepLinkURL, constants.getStoreURL(os), options));
    },
    _deepLinkOrStorePage: function _deepLinkOrStorePage(deepLinkURL, storeURL, options) {
      var _this = this;

      var timeout = options.timeout || 500;
      setTimeout(function () {
        _this._setWindowLocation(storeURL);
      }, timeout);

      this._setWindowLocation(deepLinkURL);
    },
    _setWindowLocation: function _setWindowLocation(url) {
      window.location = url;
    }
  });

  _exports.default = _default;
});