define("fitbit-site-ui/mixins/prevent-flash-duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    _clearMessageFromQueue: function _clearMessageFromQueue(message) {
      var flashMessages = Ember.get(this, 'flashMessages');
      var queue = Ember.get(flashMessages, 'queue');
      var queuedMessage = queue.find(function (queueMessage) {
        return Ember.get(queueMessage, 'message') === message;
      }); // If a user clicks quickly enough, the previous success message lives in the queue and flashMessages
      // will throw an warning about adding duplicate messages

      if (queuedMessage) {
        flashMessages.clearMessages(queuedMessage);
      }
    }
  });

  _exports.default = _default;
});