define("fitbit-site-ui/components/delete-user-account", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    verifyPasswordForSend: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('must_enter_password', 'templates.components.profile-settings');
      }),
      disabled: Ember.computed.bool('model.disableSendVerifyPassword')
    }),
    verifyPasswordForResend: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('must_enter_password', 'templates.components.profile-settings');
      }),
      disabled: Ember.computed.bool('model.disableResendVerifyPassword')
    })
  });

  var _default = Ember.Component.extend(Validations, {
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),

    /**
     * If pendingDeletion is true, allow user to RESEND cancellation request
     * @returns {boolean}
     */
    pendingDeletion: Ember.computed.notEmpty('profile.pendingDeletion'),

    /**
     * Values used to catch the passwords from input to then be used
     * in the sendDeleteConfirmation action
     */
    verifyPasswordForSend: null,
    verifyPasswordForResend: null,
    disableSendVerifyPassword: true,
    disableResendVerifyPassword: true,
    deleteUserModal: null,

    /**
     * No-ops, email actions passed from controller
     * called from sendEmailVerification and passed into change-email
     */
    onSaveEmailAddress: function onSaveEmailAddress() {},
    onResendEmailAddress: function onResendEmailAddress() {},
    onCancelEmailAddress: function onCancelEmailAddress() {},
    actions: {
      /**
       * Will hit endpoint to send user deletion confirmation email
       * Email will contain button to confirm deletion
       */
      sendDeleteConfirmation: function sendDeleteConfirmation(verifyPasswordForSend) {
        var _this = this;

        // check for correct password and send email conf
        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var deleteUserModal = Ember.get(this, 'deleteUserModal');
        var profile = Ember.get(this, 'profile');

        if (deleteUserModal && !Ember.get(this, 'isLoading')) {
          Ember.set(this, 'isLoading', true);
          return profile.deleteAccount(verifyPasswordForSend).then(function () {
            Ember.set(_this, 'deleteConfirmationSent', true);
            flashMessages.success(formatter.formatByNamespace('confirmation_sent', 'templates.components.account-deletion'));
            deleteUserModal.close();
          }).catch(function (err) {
            var errorMessage = Ember.get(err, 'payload.errors.0.message') || formatter.formatByNamespace('confirmation_err', 'templates.components.account-deletion');
            flashMessages.alert(errorMessage, {
              isModal: true
            });
          }).finally(function () {
            Ember.set(_this, 'isLoading', false);
          });
        }
      },
      enableSendVerifyPasswordValidation: function enableSendVerifyPasswordValidation() {
        Ember.set(this, 'disableSendVerifyPassword', false);
      },
      enableResendVerifyPasswordValidation: function enableResendVerifyPasswordValidation() {
        Ember.set(this, 'disableResendVerifyPassword', false);
      }
    }
  });

  _exports.default = _default;
});