define("fitbit-site-ui/models/notification-settings-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    list: _emberData.default.hasMany('notification-setting', {
      async: false
    })
  });

  _exports.default = _default;
});