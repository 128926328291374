define("fitbit-site-ui/templates/components/user-profile-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QeeX6FUJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"profile-image\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"data-test-qa\",\"avatar-image\"],[12,\"src\",[28,[[23,[\"user\",\"profile\",\"avatar150\"]]]]],[12,\"alt\",[28,[[27,\"format-by-namespace\",[\"prof_pic_alt\",\"templates.components.profile-settings\"],null]]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"profileViewRole\"]],\"owner\"],null]],null,{\"statements\":[[4,\"link-to\",[\"settings.profile-settings\"],[[\"tagName\",\"class\"],[\"span\",\"upload-image-circle\"]],{\"statements\":[[0,\"                \"],[7,\"span\"],[11,\"class\",\"show-for-sr\"],[9],[0,\"\\n                    \"],[1,[27,\"format-by-namespace\",[\"change_cover_screen_reader\",\"templates.components.profile-settings-change-picture\"],null],false],[0,\"\\n                \"],[10],[0,\"\\n                \"],[1,[27,\"fit-glyph\",null,[[\"type\",\"class\"],[\"camera\",\"upload-profile-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-profile-heading.hbs"
    }
  });

  _exports.default = _default;
});