define("fitbit-site-ui/components/user-profile-friends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_LIST_ITEMS = void 0;
  var MAX_LIST_ITEMS = 6;
  _exports.MAX_LIST_ITEMS = MAX_LIST_ITEMS;

  var _default = Ember.Component.extend({
    tagName: '',
    i18n: Ember.inject.service(),

    /**
     * Reference to modal that displays list of friends, which is set by the fit-modal component in the template of
     * this component.
     * @type {Object}
     */
    modal: null,

    /**
     * Array of all the users friends.
     * Sort by case-insensitive ascending order.
     */
    // listOfFriends: alias('friendsList.list'),
    listOfFriends: Ember.computed('friendsList.list', function () {
      var list = Ember.get(this, 'friendsList.list') || [];
      return list.slice().sort(function (a, b) {
        var aDisplayName = Ember.getWithDefault(a, 'displayName', '').toLowerCase();
        var bDisplayName = Ember.getWithDefault(b, 'displayName', '').toLowerCase();

        if (aDisplayName < bDisplayName) {
          return -1;
        }

        if (aDisplayName > bDisplayName) {
          return 1;
        }

        return 0;
      });
    }),

    /**
     * An array of the first 6 friends in user's friends list.
     *
     * @returns {Array}
     */
    topFriends: Ember.computed('listOfFriends', function () {
      var listOfFriends = Ember.get(this, 'listOfFriends');
      return Ember.isPresent(listOfFriends) ? listOfFriends.slice(0, 6) : [];
    }),

    /**
     * Returns whether the friends are equal to null
     *   If the friends are not private but just empty, this will be empty and not null
     *
     *   @returns {Boolean}
     */
    isUserFriendsPrivate: Ember.computed.equal('friendsList', null),

    /**
     * Determine if there are more friends to display.
     * @return {Boolean}
     */
    hasMoreFriends: Ember.computed.gt('listOfFriends.length', MAX_LIST_ITEMS),
    actions: {
      /**
       * Open the modal.
       * @param modal
       */
      openModal: function openModal(modal) {
        if (modal) {
          Ember.run.scheduleOnce('afterRender', modal, 'open');
        }
      }
    }
  });

  _exports.default = _default;
});