define("fitbit-site-ui/adapters/time-series", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id) {
      return this._buildURL(id) + '.json';
    }
  });

  _exports.default = _default;
});