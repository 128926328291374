define("fitbit-site-ui/adapters/time-series/step", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    pathForType: function pathForType(modelName) {
      return 'activities/steps/date/' + modelName + '/1d/1min';
    }
  }); // GET /1/user/-/activities/steps/date/2016-06-05/1d/15min.json


  _exports.default = _default;
});