define("fitbit-site-ui/models/health-programs/program", ["exports", "ember-data", "snapdragon-common/constants/program-type"], function (_exports, _emberData, _programType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    detail: _emberData.default.attr('string'),
    abandonDurationSeconds: _emberData.default.attr('number'),
    programType: _emberData.default.attr('string'),
    tileImageUrl: _emberData.default.attr('string'),
    fullscreenImageUrl: _emberData.default.attr('string'),
    imageSubtitle: _emberData.default.attr('string'),
    primaryColor: _emberData.default.attr('string'),
    secondaryColor: _emberData.default.attr('string'),
    visibility: _emberData.default.attr('string'),
    defaultVersion: _emberData.default.attr('string'),
    programVersions: _emberData.default.attr(),
    consentIds: _emberData.default.attr(),
    grantIds: _emberData.default.attr(),
    experimentIds: _emberData.default.attr(),
    legalDisclaimer: _emberData.default.attr(),
    userDataExported: _emberData.default.attr('boolean'),
    customDeepLinkUrl: _emberData.default.attr('string'),
    tagIds: _emberData.default.attr(),
    allowsMembershipUpgrade: _emberData.default.attr('boolean'),
    defaultVersionByViewVersion: _emberData.default.attr(),

    /**
     *  Set of Strings that define which bundles the program will show up for when requested by Fitbit Discover.
     *  Strings correspond with the BundleType enum defined in FibitExplore.thrift https://source.fitbit.com/projects/SITE/repos/weightsite/browse/fitbit-explore/thrifts/src/main/thrift/FitbitExplore.thrift#97.
     *  If no values are provided, the default is for the item to be displayed in the HEALTH_PROGRAMS bundle only.
     */
    visibleDiscoverBundles: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return new Set();
      }
    }),

    /**
     * boolean describing the behavior of the secondary button on the item's splash page after a user has completed the program once.
     * If true will cause the secondary button to link into the user's history list of completed programs
     * If false will link into the last completed instance of the program.
     */
    useHistoryListDeeplink: _emberData.default.attr('boolean'),

    /**
     * String that lines up with a service identifier/client registered in the ThriftClientsModule of the Program Service.
     * https://source.fitbit.com/projects/SITE/repos/weightsite/browse/healthprograms/program-service/src/main/java/com/fitbit/healthprograms/service/modules/ThriftClientsModule.java#76.
     * Setting this will cause the client to call the external service when determining if the user is eligible to see the item.
     * DO NOT SET THIS UNLESS YOU KNOW WHAT YOU'RE DOING
     */
    externalEligibilityServiceIdentifier: _emberData.default.attr('string'),
    programId: Ember.computed({
      get: function get() {
        return Ember.get(this, 'id');
      },
      set: function set(key, value) {
        var programId = value.replace(/[^0-9a-zA-Z-]/g, '-').toLowerCase();
        Ember.set(this, 'id', programId);
        return programId;
      }
    }),
    consentIdsForDisplay: Ember.computed('consentIds.[]', {
      get: function get() {
        var consentIds = Ember.get(this, 'consentIds');
        return consentIds ? consentIds.join(', ') : '';
      },
      set: function set(key, value) {
        Ember.set(this, 'consentIds', value.split(/[ ,]+/).filter(Boolean));
        return value;
      }
    }),
    grantIdsForDisplay: Ember.computed('grantIds.[]', {
      get: function get() {
        var grantIds = Ember.get(this, 'grantIds');
        return grantIds ? grantIds.join(', ') : '';
      },
      set: function set(key, value) {
        Ember.set(this, 'grantIds', value.split(/[ ,]+/).filter(Boolean));
        return value;
      }
    }),
    serviceRole: Ember.computed(function () {
      if (this.programType === _programType.GAME) {
        return 'games';
      }

      return 'health-programs';
    })
  });

  _exports.default = _default;
});