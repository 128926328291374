define("fitbit-site-ui/services/user-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendTaunt = sendTaunt;
  _exports.sendCheer = sendCheer;
  _exports.sendMessage = sendMessage;
  _exports.default = _exports.MESSAGE = _exports.CHEER = _exports.TAUNT = void 0;
  var TAUNT = 'taunt';
  _exports.TAUNT = TAUNT;
  var CHEER = 'cheer';
  _exports.CHEER = CHEER;
  var MESSAGE = 'message';
  _exports.MESSAGE = MESSAGE;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * Sends a cheer or taunt to id based on actionType
     * @param id {String} - user id to send cheer or taunt to
     * @param actionType {String} - 'cheer' or 'taunt'
     * @returns {Promise}
     * @private
     */
    sendActionToId: function sendActionToId(id, actionType) {
      var ajax = Ember.get(this, 'ajax');
      return ajax.post("/user/".concat(id, "/leaderboard/").concat(actionType), {
        namespace: '1.1',
        dataType: 'text',
        contentType: 'text/plain'
      });
    },

    /**
     * Sends a 'taunt' action to user
     * @param user {String} - userId of user to send taunt to
     * @returns {Promise|null}
     */
    sendTauntToId: function sendTauntToId(id) {
      return this.sendActionToId(id, TAUNT);
    },

    /**
     * Sends a 'cheer' action to user
     * @param id {String} - userId of user to send cheer to
     * @returns {Promise|null}
     */
    sendCheerToId: function sendCheerToId(id) {
      return this.sendActionToId(id, CHEER);
    },

    /**,
     * Sends a message with content to user
     * @param id {String} - userId of user to send message to
     * @param message {String} - message content to send to a user
     * @returns {Promise|null}
     */
    sendMessageToId: function sendMessageToId(id, message) {
      var ajax = Ember.get(this, 'ajax');
      return ajax.post("/conversations/".concat(id, "/messages.json"), {
        namespace: '1',
        data: {
          userId: id,
          content: message
        }
      });
    }
  });

  _exports.default = _default;

  function sendTaunt(id, UserActionService) {
    return UserActionService.sendTauntToId(id);
  }

  function sendCheer(id, UserActionService) {
    return UserActionService.sendCheerToId(id);
  }

  function sendMessage(id, message, UserActionService) {
    return UserActionService.sendMessageToId(id, message);
  }
});