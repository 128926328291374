define("fitbit-site-ui/templates/components/device-quick-view-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5adow2BB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"format\",[\"viewing_position\"],[[\"deviceName\"],[[23,[\"deviceName\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row column handedness-radio-group\"],[9],[0,\"\\n    \"],[1,[27,\"double-radio-button\",null,[[\"toggleValue\",\"firstValue\",\"firstDescription\",\"secondValue\",\"secondDescription\",\"propertyName\",\"changeFnc\"],[[23,[\"quickViewSetting\"]],\"DISABLED\",[27,\"format\",[\"off\"],null],\"CLOCK\",[27,\"format\",[\"quick_view_on\"],null],\"quick-view\",[27,\"action\",[[22,0,[]],\"quickViewChanged\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-quick-view-setting.hbs"
    }
  });

  _exports.default = _default;
});