define("fitbit-site-ui/controllers/settings/scales", ["exports", "fitbit-site-ui/mixins/panel-mixin", "ember-cp-validations"], function (_exports, _panelMixin, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    emailList: (0, _emberCpValidations.validator)('scale-invite', {
      invitesLeft: Ember.computed.readOnly('model.scale.invitesLeft')
    })
  });

  var _default = Ember.Controller.extend(Validations, _panelMixin.default, {
    i18n: Ember.inject.service(),
    PANEL_LIST: Ember.computed(function () {
      return ['scaleName', 'scaleUnits'];
    }),
    scaleUsers: Ember.computed('model.scale.userList.users.@each.scaleUserName', function () {
      var formatter = Ember.get(this, 'i18n');
      var users = Ember.get(this, 'model.scale.userList.users') || [];

      if (Ember.get(users, 'length')) {
        var scaleUsers = users.map(function (user) {
          return {
            scaleUserName: Ember.get(user, 'scaleUserName'),
            id: Ember.get(user, 'id')
          };
        });
        scaleUsers.push({
          scaleUserName: "".concat(formatter.format('guest')),
          id: 'GUEST'
        });
        return scaleUsers;
      }
    }),
    invitesLeft: Ember.computed('model.scale.userList.users.[],model.scale.invitedUserList.users.[]', function () {
      return 8 - this.get('model.scale.userList.users.length') - this.get('model.scale.invitedUserList.users.length');
    }),
    //Format the scale weight depending on the user's setting
    scaleUnit: Ember.computed.alias('currentUser.weightUnit'),
    emailList: '',
    emailText: Ember.computed('model.scale.deviceType.name', function () {
      var formatter = this.get('i18n');
      return formatter.formatByNamespace('email_text', 'controllers.settings.scales', {
        scaleDeviceTypeName: this.get('model.scale.deviceType.name')
      });
    }),
    actions: {
      scaleNameChanged: function scaleNameChanged() {
        var _this = this;

        return this.get('model.scale').save().then(function () {
          /*
          Once the scale's name has changed, the page title needs to update. Unfortunately the route is not
          observing the model since the model is just a method that returns the model. ember-cli-document-title
          adds a `updateTitle` method onto the Route class which updates the page title.
          The controller has a reference to the route, via `target` property.
          */
          _this.get('target').updateTitle();
        });
      },
      triggerRemoveModal: function triggerRemoveModal(user, isCurrentUser) {
        this.setProperties({
          userToRemove: user,
          isCurrentUser: isCurrentUser
        });
        Ember.$('#openModal').click();
      },
      sendInvites: function sendInvites() {
        var _this2 = this;

        var formatter = this.get('i18n');
        var emails = this.get('emailList'),
            emailText = this.get('emailText'); //Don't panic I didn't make this regex myself, I shamelessly stole it from
        //http://stackoverflow.com/questions/14440444/extract-all-email-addresses-from-bulk-text-using-jquery
        //This lets the app be a bit more forgiving about how the user enters a list of emails

        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g,
            scaleId = this.get('model.scale.id'),
            model = this.get('model.scale'),
            flashMessages = this.get('flashMessages');
        emails = emails.match(re).join(','); //endpoint expects a single string of emails joined by commas

        model.get('invitedUserList').then(function (list) {
          list.inviteUser(emails, emailText, scaleId, model).then(function () {
            _this2.set('emailList', '');

            flashMessages.success(formatter.formatByNamespace('invites_sent', 'controllers.settings.scales'));
          }, function () {
            return flashMessages.alert(formatter.formatByNamespace('invite_error', 'controllers.settings.scales'));
          });
        });
      },
      deleteUser: function deleteUser(scale, user) {
        var _this3 = this;

        var formatter = this.get('i18n');
        var scaleId = this.get('model.scale.id'),
            currentUser = this.get('currentUser.id'),
            flashMessages = this.get('flashMessages'),
            userName = user.get('fullName'),
            userId = user.get('id');
        user.destroyRecord({
          adapterOptions: {
            scaleId: scaleId
          }
        }).then(function () {
          if (currentUser === userId) {
            var userDevice = _this3.get('store').peekRecord('user-device', scaleId),
                userDeviceList = _this3.get('store').peekRecord('user-device-list', userId).get('list');

            if (userDevice && userDeviceList) {
              userDeviceList.removeObjects(userDeviceList.filter(function (device) {
                return device.isScale;
              }));
              userDevice.deleteRecord();
            }

            scale.deleteRecord();

            _this3.replaceRoute('settings.devices');
          } else {
            flashMessages.success(formatter.format('user_removed', {
              userName: userName
            }));
          }

          _this3.setProperties({
            userToRemove: null,
            isCurrentUser: null
          });
        }, function () {
          return flashMessages.alert(formatter.format('remove_error', {
            userName: userName
          }));
        });
      },
      deleteInvite: function deleteInvite(invitedUser) {
        var formatter = this.get('i18n');
        var scaleId = this.get('model.scale.id'),
            flashMessages = this.get('flashMessages'),
            invitedUserName = invitedUser.get('fullName');
        invitedUser.destroyRecord({
          adapterOptions: {
            scaleId: scaleId
          }
        }).then(function () {
          return flashMessages.success(formatter.format('revoked_invite', {
            invitedUserName: invitedUserName
          }));
        }, function () {
          return flashMessages.alert(formatter.format('revoke_error', {
            invitedUserName: invitedUserName
          }));
        });
      }
    }
  });

  _exports.default = _default;
});