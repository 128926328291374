define("fitbit-site-ui/controllers/launch", ["exports", "snapdragon-common/constants/user-agent"], function (_exports, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    IS_MOBILE: _userAgent.IS_MOBILE
  });

  _exports.default = _default;
});