define("fitbit-site-ui/models/device/tracker/auto-exercise-settings", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    autoDetection: (0, _attr.default)(),
    //can be "on" or "off"
    detectionThresholds: (0, _attr.default)(),
    isEnabled: Ember.computed.equal('autoDetection', 'on')
    /*
    dectionThreshold is an array of
    {
        activityName: "Walk",
        activityTypeId: 90013,
        enabled: true,
        minDuration: 900000
    }
    */

  });

  _exports.default = _default;
});