define("fitbit-site-ui/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3kPUMbp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"format\",[\"look_around\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"login\"],null,{\"statements\":[[0,\"                    \"],[1,[27,\"format\",[\"please_login\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/index.hbs"
    }
  });

  _exports.default = _default;
});