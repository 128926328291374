define("fitbit-site-ui/adapters/food-note", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: false,
    namespace: '1.1/user/-',

    /**
     * Note, that Find Record will NOT set up the relationship with food-note-date
     * because there is no date returned when serializing the record
     * @param id
     * @returns {string}
     */
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this._super(), "/foods/note/").concat(id);
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this._super(), "/foods/note");
    },

    /**
     * Date not returned by backend, so save when creating for referencing in the serializer
     * @param store
     * @param type
     * @param snapshot
     * @returns {*}
     */
    createRecord: function createRecord(store, type, snapshot) {
      var date = Ember.get(snapshot, 'record.date');
      return this._super.apply(this, arguments).then(function (response) {
        response.date = date;
        return response;
      });
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this._super(), "/foods/note/").concat(id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this._super(), "/foods/note/").concat(id);
    }
  });

  _exports.default = _default;
});