define("fitbit-site-ui/routes/redirect-invitation-accept-corporate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A redirect route to handle legacy URL invitation/accept/corporate and redirect to settings/corporate.
   * Please, visit router.js for more details.
   */
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.replaceWith('settings.corporate');
    }
  });

  _exports.default = _default;
});