define("fitbit-site-ui/app", ["exports", "fitbit-site-ui/resolver", "ember-load-initializers", "fitbit-site-ui/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    engines: {
      activitiesEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'ajax', 'device/layout', 'constants', 'current-user'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      badgesEngine: {
        dependencies: {
          services: ['store', 'navigation', 'i18n', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      corporateLandingPageEngine: {
        dependencies: {
          services: ['i18n', 'constants', 'head-data', 'current-user', 'mobile-view', 'metrics'],
          externalRoutes: {
            404: '/404',
            fhsOnboarding: 'fhs-onboarding'
          }
        }
      },
      corporateOnboardingEngine: {
        dependencies: {
          services: ['store', 'i18n', 'ajax', 'navigation', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'mobile-view', 'metrics', 'user-agent', 'mobile-client', 'deep-link-with-backup', 'webview-comms', 'optimizely', 'optimizely-ajax'],
          externalRoutes: {
            404: '/404',
            login: '/login',
            settingsCorporate: '/settings/corporate'
          }
        }
      },
      corporateMembershipSettingsEngine: {
        dependencies: {
          services: ['i18n', 'session', 'webview-comms', 'optimizely', 'store', 'moment', 'flash-messages', 'mobile-view', 'metrics'],
          externalRoutes: {
            404: '/404',
            login: '/login',
            fhsOnboarding: 'fhs-onboarding'
          }
        }
      },
      covidSurveyEngine: {
        dependencies: {
          services: ['ajax', 'store', 'i18n', 'navigation', 'metrics', 'mobile-view', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizely-ajax', 'webview-comms', 'user-agent'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      deezerEngine: {
        dependencies: {
          services: ['store', 'navigation', 'i18n', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'deezer-ajax'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      experimentationPlatformEngine: {
        dependencies: {
          services: ['ajax', 'store', 'i18n', 'navigation', 'metrics', 'mobile-view', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizely-ajax'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      fitbitLabsEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      foodEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      gddConsoleEngine: {
        dependencies: {
          services: ['ajax', 'store', 'i18n', 'navigation', 'metrics', 'mobile-view', 'session', 'flash-messages', 'moment', 'no-auth-ajax', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizely-ajax', 'webview-comms', 'router', 'user-agent'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      udeConsoleEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'ajax', 'device/layout', 'constants', 'current-user', 'router'],
          externalRoutes: {
            404: '/404',
            gddConsole: 'settings.data.delete'
          }
        }
      },
      healthProgramsEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'metrics', 'mobile-view', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizelyAjax', 'hp-ajax', 'ajax'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      healthUpdatesEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'metrics', 'mobile-view', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizelyAjax'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      legacyDeviceDownloadEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'router'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      sleepEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'ajax', 'device/layout', 'constants', 'current-user'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      snoreDetectionEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'metrics', 'mobile-view', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizely-ajax', 'webview-comms'],
          externalRoutes: {
            404: '/404'
          }
        }
      },
      symptomLoggingEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'metrics', 'session', 'flash-messages', 'moment', 'device/layout', 'constants', 'current-user', 'optimizely', 'optimizely-ajax', 'webview-comms', 'user-agent', 'mobile-view'],
          externalRoutes: {
            404: '/404',
            covid: 'covid-19',
            covidStudy: 'labs.covid-study.landing-page'
          }
        }
      },
      weightEngine: {
        dependencies: {
          services: ['store', 'i18n', 'navigation', 'session', 'flash-messages', 'moment', 'ajax', 'device/layout', 'constants', 'current-user'],
          externalRoutes: {
            404: '/404',
            badges: 'badges'
          }
        }
      }
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});