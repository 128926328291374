define("fitbit-site-ui/instance-initializers/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // const service = Ember.ObjectProxy.create({ isServiceFactory: true });
    // application.register('service:i18n-translator', service, { instantiate: false, singleton: true });
    application.inject('component', 'i18n', 'service:i18n');
    application.inject('controller', 'i18n', 'service:i18n');
    application.inject('model', 'i18n', 'service:i18n');
    application.inject('adapter', 'i18n', 'service:i18n');
  }

  var _default = {
    name: 'formatter',
    initialize: initialize
  };
  _exports.default = _default;
});