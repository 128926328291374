define("fitbit-site-ui/models/data-export-request", ["exports", "ude-console-engine/models/data-export-request"], function (_exports, _dataExportRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dataExportRequest.default;
    }
  });
});