define("fitbit-site-ui/models/user-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    message: _emberData.default.attr('string'),
    notificationType: _emberData.default.attr('string'),
    isRead: _emberData.default.attr('boolean'),
    sentBy: _emberData.default.attr(),
    // message end point returns just enough profile information: avatar150, encodedId, displayName
    time: _emberData.default.attr('number'),
    timeRelative: _emberData.default.attr('string'),
    ajax: Ember.inject.service(),

    /**
     * updates read status on server
     * @returns {*}
     */
    markAsRead: function markAsRead() {
      var _this = this;

      var ajax = this.get('ajax');
      var messageId = this.get('id');
      return ajax.post("/conversations/-/messages/".concat(messageId, "/read"), {
        namespace: '1',
        dataType: 'text'
      }).then(function () {
        return _this.set('isRead', true);
      });
    }
  });

  _exports.default = _default;
});