define("fitbit-site-ui/components/profile-settings", ["exports", "ember-cp-validations", "fitbit-site-ui/mixins/weight-unit", "snapdragon-common/mixins/validations/date-of-birth", "snapdragon-common/utils/unit-converter", "snapdragon-common/constants/user-agent", "fitbit-site-ui/config/environment"], function (_exports, _emberCpValidations, _weightUnit, _dateOfBirth, _unitConverter, _userAgent, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var roundToDigits = _unitConverter.default.roundToDigits;
  var Validations = (0, _emberCpValidations.buildValidations)(Ember.assign({
    fullName: [(0, _emberCpValidations.validator)('length', {
      max: 32,
      min: 1,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('length_validation_msg', 'components.profile-settings', {
          value: '{max}'
        });
      }),
      disabled: Ember.computed.bool('model.shouldShowFirstAndLast')
    })],
    firstName: (0, _emberCpValidations.validator)('length', {
      max: 50,
      min: 1,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('length_validation_msg', 'components.profile-settings', {
          value: '{max}'
        });
      }),
      disabled: Ember.computed.not('model.shouldShowFirstAndLast')
    }),
    lastName: (0, _emberCpValidations.validator)('length', {
      max: 50,
      min: 1,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('length_validation_msg', 'components.profile-settings', {
          value: '{max}'
        });
      }),
      disabled: Ember.computed.not('model.shouldShowFirstAndLast')
    }),
    heightInCm: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lte: 300,
      message: Ember.computed(function () {
        return 'Height';
      })
    }),
    pounds: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 5,
      lt: 1500,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_US', 'units');
      }),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'components.profile-settings', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      }),
      disabled: Ember.computed.not('model.profile.isUSWeight')
    }),
    kilograms: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 2.5,
      lt: 680,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_metric', 'units');
      }),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'components.profile-settings', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      }),
      disabled: Ember.computed.not('model.profile.isMetricWeight')
    }),
    stones: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      lt: 107,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_GB', 'units');
      }),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'components.profile-settings', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      }),
      disabled: Ember.computed.not('model.profile.isUKWeight')
    }),
    stonePounds: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: Ember.computed('model.{stones,stonePounds}', function () {
        var stones = Ember.get(this, 'model.stones');
        return stones <= 0 ? 5 : 0;
      }),
      lt: 14,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_US', 'units');
      }),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'components.profile-settings', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      }),
      disabled: Ember.computed.not('model.profile.isUKWeight')
    }),
    strideLengthWalkingCm: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lte: 600,
      message: Ember.computed(function () {
        return 'Walking Stride Length';
      })
    }),
    strideLengthRunningCm: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lte: 600,
      message: Ember.computed(function () {
        return 'Running Stride Length';
      })
    }),
    city: (0, _emberCpValidations.validator)('length', {
      max: 32,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('length_validation_msg', 'components.profile-settings', {
          value: '{max}'
        });
      })
    }),
    aboutMe: (0, _emberCpValidations.validator)('length', {
      max: 500,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('length_validation_msg', 'components.profile-settings', {
          value: '{max}'
        });
      })
    }),
    dateOfBirth: _userAgent.IS_MOBILE ? [(0, _emberCpValidations.validator)('alias', 'mobileDateOfBirth')] : [(0, _emberCpValidations.validator)('alias', 'year'), (0, _emberCpValidations.validator)('alias', 'birthdate')],
    mobileDateOfBirth: {
      validators: [_dateOfBirth.birthdate],
      disabled: !_userAgent.IS_MOBILE
    }
  }, _dateOfBirth.default));

  var _default = Ember.Component.extend(Validations, _weightUnit.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    IS_MOBILE: _userAgent.IS_MOBILE,
    isLoading: false,
    isEmberTesting: Ember.computed(function () {
      return _environment.default.environment === 'test';
    }),

    /**
     * Default values to disable change email validations on open
     */
    disableNewEmailValidation: true,
    disableConfirmValidation: true,
    disablePasswordValidation: true,
    changeEmailModal: null,
    deleteUserModal: null,
    emailAddress: Ember.computed.oneWay('profile.email'),
    fullName: Ember.computed.oneWay('profile.fullName'),
    firstName: Ember.computed.oneWay('profile.firstName'),
    lastName: Ember.computed.oneWay('profile.lastName'),
    aboutMe: Ember.computed.oneWay('profile.aboutMe'),
    city: Ember.computed.oneWay('profile.city'),
    stateIsChosen: Ember.computed.notEmpty('profile.state'),
    genders: Ember.computed(function () {
      return [{
        value: 'MALE',
        displayName: 'male'
      }, {
        value: 'FEMALE',
        displayName: 'female'
      }];
    }),
    year: Ember.computed('birthdate', function () {
      var birthday = Ember.get(this, 'birthdate');
      return birthday && birthday.split('-')[0];
    }),

    /**
     * Determines birthday from user input
     * @return {string} - date representation of inputted birthday
     */
    birthdate: Ember.computed.readOnly('profile.dateOfBirth'),
    weight: Ember.computed.oneWay('profile.weight'),
    kilograms: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');
        return this.gramsToKilograms(grams);
      },
      set: function set(key, value) {
        var grams = this.kilogramsToGrams(value);
        Ember.set(this, 'weight', grams);
        return value;
      }
    }),
    pounds: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');
        return this.gramsToPounds(grams);
      },
      set: function set(key, value) {
        var grams = this.poundsToGrams(value);
        Ember.set(this, 'weight', grams);
        return value;
      }
    }),
    _stonesAndPounds: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');
        return this.gramsToStonesAndPounds(grams);
      },
      set: function set(key, value) {
        var grams = this.stonesAndPoundsToGrams(value.stones, value.pounds);
        Ember.set(this, 'weight', grams);
        return value;
      }
    }),
    stones: Ember.computed('_stonesAndPounds', {
      get: function get() {
        return Ember.get(this, '_stonesAndPounds.stones');
      },
      set: function set(key, value) {
        var stonesAndPounds = Ember.get(this, '_stonesAndPounds');
        Ember.set(stonesAndPounds, 'stones', value);
        Ember.set(this, '_stonesAndPounds', stonesAndPounds);
        return value;
      }
    }),
    stonePounds: Ember.computed('_stonesAndPounds', {
      get: function get() {
        return Ember.get(this, '_stonesAndPounds.pounds');
      },
      set: function set(key, value) {
        var stonesAndPounds = Ember.get(this, '_stonesAndPounds');
        Ember.set(stonesAndPounds, 'pounds', value);
        Ember.set(this, '_stonesAndPounds', stonesAndPounds);
        return value;
      }
    }),
    heightInCm: Ember.computed.oneWay('profile.height'),
    strideLengthRunningCmFormatted: Ember.computed('profile.strideLengthRunning', function () {
      return roundToDigits(this.get('profile.strideLengthRunning'), 1);
    }),
    strideLengthWalkingCmFormatted: Ember.computed('profile.strideLengthWalking', function () {
      return roundToDigits(this.get('profile.strideLengthWalking'), 1);
    }),
    strideLengthRunningCm: Ember.computed.oneWay('strideLengthRunningCmFormatted'),
    strideLengthWalkingCm: Ember.computed.oneWay('strideLengthWalkingCmFormatted'),
    // Only those who are in the correct locale AND have a real name already set should see the real name fields
    shouldShowFirstAndLast: Ember.computed.readOnly('profile.shouldShowFirstAndLast'),
    heartRateUpdated: false,
    changePersonalInfoLink: Ember.computed(function () {
      return _environment.default.changePersonalInfoLink;
    }),
    fitbitServiceDeletionLink: Ember.computed(function () {
      return _environment.default.fitbitServiceDeletionLink;
    }),

    /**
     * no-ops for email validation and change email
     */
    onSaveEmailAddress: function onSaveEmailAddress() {},
    onResendEmailAddress: function onResendEmailAddress() {},
    onCancelEmailAddress: function onCancelEmailAddress() {},
    actions: {
      selectGender: function selectGender(gender) {
        Ember.set(this, 'profile.gender', gender);
      },
      changeModel: function changeModel() {
        var _this = this;

        var formatter = Ember.get(this, 'i18n');
        var changedAttrs = Ember.get(this, 'profile').changedAttributes();
        var flashMessages = Ember.get(this, 'flashMessages');
        Ember.get(this, 'profile').save().then(function () {
          flashMessages.success(formatter.format('updated_profile')); // refresh page upon locale change to update language

          if (changedAttrs.locale && !Ember.testing) {
            window.location.reload();
          }
        }).catch(function () {
          var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var errorMessage = Ember.get(e, 'errors.0.message') || formatter.formatByNamespace('err_saving', 'components.profile-settings');
          flashMessages.alert(errorMessage);
        }).finally(function () {
          return Ember.set(_this, 'isLoading', false);
        });
      },
      resetPassword: function resetPassword() {
        var formatter = Ember.get(this, 'i18n');
        var email = Ember.get(this, 'profile.email'),
            flashMessages = Ember.get(this, 'flashMessages');

        if (email) {
          Ember.get(this, 'ajax').post('/account/resetPassword.json', {
            data: {
              email: email
            }
          }).then(function () {
            flashMessages.success(formatter.format('pwd_reset_email', {
              email: email
            }));
          }).catch(function () {
            var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var errorMessage = Ember.get(e, 'errors.0.message') || formatter.formatByNamespace('pwd_reset_email_err', 'components.profile-settings');
            flashMessages.alert(errorMessage);
          });
        } else {
          flashMessages.alert(formatter.formatByNamespace('no_user_email', 'components.profile-settings'));
        }
      },
      dobChanged: function dobChanged(year, month, day) {
        Ember.setProperties(this, {
          year: year,
          month: month,
          day: day
        });
        Ember.set(this, 'profile.dateOfBirth', "".concat(year, "-").concat(month, "-").concat(day));
      },
      heightChanged: function heightChanged() {
        var height = this.get('heightInCm');
        this.set('profile.height', height);
      },
      strideLengthChanged: function strideLengthChanged(isValid) {
        if (isValid) {
          var strideLengthRunning = this.get('strideLengthRunningCm');
          var strideLengthWalking = this.get('strideLengthWalkingCm');
          this.setProperties({
            'profile.strideLengthRunning': strideLengthRunning,
            'profile.strideLengthWalking': strideLengthWalking
          });
        }
      },
      selectCountry: function selectCountry(locale) {
        Ember.set(this, 'profile.country', locale);
      },
      selectState: function selectState(state) {
        Ember.set(this, 'profile.state', state); // Clear out the city field when a new state is chosen

        Ember.set(this, 'profile.city', '');
      },
      selectCity: function selectCity(city) {
        Ember.set(this, 'profile.city', city);
      },
      updateProfile: function updateProfile() {
        var _this2 = this;

        var formatter = Ember.get(this, 'i18n');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var fullName = Ember.get(_this2, 'fullName');
            var firstName = Ember.get(_this2, 'firstName');
            var lastName = Ember.get(_this2, 'lastName');
            var aboutMe = Ember.get(_this2, 'aboutMe');
            var weight = Ember.get(_this2, 'weight');
            var profile = Ember.get(_this2, 'profile'); // Set the values on profile to determine the dirty attributes.

            profile.set('weight', weight);
            profile.set('fullName', fullName);
            profile.set('firstName', firstName);
            profile.set('lastName', lastName);
            profile.set('aboutMe', aboutMe); // State and city are not valid for non-US countries, so clear them out if the country is changed

            if (profile.get('country') !== 'US') {
              profile.set('state', '');
              profile.set('city', '');
            } else if (!profile.get('state')) {
              // Can't have a city without a state!
              profile.set('city', '');
            }

            var dirtyAttributes = Object.keys(profile.changedAttributes());
            var hasDirtyAttributes = dirtyAttributes.length || Ember.get(_this2, 'heartRateUpdated');

            if (hasDirtyAttributes) {
              Ember.set(_this2, 'isLoading', true);
              Ember.RSVP.resolve().then(function () {
                if (hasDirtyAttributes) {
                  // Need to set heartRateUpdated to false in case it wasn't already.
                  Ember.set(_this2, 'heartRateUpdated', false);

                  _this2.send('changeModel');
                }
              }).catch(function () {
                return Ember.set(_this2, 'isLoading', false);
              });
            }
          } else {
            Ember.get(_this2, 'flashMessages').alert(formatter.formatByNamespace('fix_errors', 'common.messages'));
          }
        });
      },
      avatarUpdated: function avatarUpdated(_ref2) {
        var photos = _ref2.photos;
        var profile = Ember.get(this, 'profile');
        var store = Ember.get(profile, 'store');
        var id = Ember.get(profile, 'id');
        var avatar = photos.findBy('size', 100).url,
            avatar150 = photos.findBy('size', 150).url; // Directly setting the avatar and avatar150 properties on the model would mark it as dirty.
        // Push the data into the store updates the profile without making the model "dirty".
        // This way will make the submit button do nothing when the avatars are updated.

        store.push({
          data: {
            attributes: {
              avatar: avatar,
              avatar150: avatar150
            },
            id: id,
            type: 'profile'
          }
        });
      },
      coverUpdated: function coverUpdated(images) {
        var profile = Ember.get(this, 'profile');
        var store = Ember.get(profile, 'store');
        var id = Ember.get(profile, 'id');
        var _images$data = images.data,
            largeUrl = _images$data.largeUrl,
            mediumUrl = _images$data.mediumUrl,
            smallUrl = _images$data.smallUrl,
            url = _images$data.url;
        store.push({
          data: {
            attributes: {
              largeUrl: largeUrl,
              mediumUrl: mediumUrl,
              smallUrl: smallUrl,
              url: url
            },
            id: id,
            type: 'cover-photo'
          }
        });
      },
      heartRateUpdated: function heartRateUpdated(isUpdated) {
        Ember.set(this, 'heartRateUpdated', isUpdated);
      },

      /**
       * Resets values for the change email address component
       */
      resetValuesForChangeEmail: function resetValuesForChangeEmail() {
        Ember.setProperties(this, {
          disableNewEmailValidation: true,
          disableConfirmValidation: true,
          disablePasswordValidation: true,
          newEmail: '',
          confirmNewEmail: '',
          verifyPassword: ''
        });
      }
    }
  });

  _exports.default = _default;
});