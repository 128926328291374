define("fitbit-site-ui/components/device-exercise-gps-control", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    areAnyExerciseGpsControlEnabled: Ember.computed('settings', function () {
      var settings = Ember.get(this, 'settings');
      return settings && settings.some(function (setting) {
        return setting.gpsOn === true;
      });
    }),
    gpsSettings: Ember.computed('settings', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'settings', 'options'),
          settings = _EmberGetProperties.settings,
          options = _EmberGetProperties.options;

      return settings.map(function (setting) {
        var currentOption = options.findBy('id', setting.exerciseId);
        var exerciseName = currentOption.name,
            exerciseId = currentOption.id;
        return {
          exerciseName: exerciseName,
          exerciseId: exerciseId,
          gpsOn: setting.gpsOn
        };
      });
    }),
    actions: {
      exerciseGpsControlChanged: function exerciseGpsControlChanged(exerciseId, value) {
        var setting = Ember.get(this, 'settings').findBy('exerciseId', exerciseId);
        setting.gpsOn = value.target.checked;
        this.updateSettings();
      },
      exerciseGpsControlSwitch: function exerciseGpsControlSwitch(value) {
        var savedValues = Ember.get(this, 'savedValues');
        var beforeSaveGps = Ember.get(this, 'beforeSaveGps');
        var settings = Ember.get(this, 'settings'); // When turning GPS off, we want to remember the current value for each setting before turning it off. We
        // do this so that if the user flips the setting back on (given that the user doesn't navigate away) then we
        // can set the values to what they were previously.

        settings.forEach(function (setting) {
          if (value === 'false') {
            beforeSaveGps(setting.exerciseId, setting.gpsOn);
            setting.gpsOn = false;
          } else {
            setting.gpsOn = savedValues[setting.exerciseId];
          }
        });
        this.updateSettings();
      }
    }
  });

  _exports.default = _default;
});