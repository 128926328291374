define("fitbit-site-ui/models/device/scale/invited-user-list", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ajax: Ember.inject.service(),
    users: _emberData.default.hasMany('device/scale/invited-user'),
    inviteUser: function inviteUser(emails, emailText, scaleId, model) {
      var ajax = this.get('ajax'),
          store = this.get('store');
      return ajax.post("/user/-/devices/scale/".concat(scaleId, "/users/invitations.json"), {
        data: {
          invitedUserEmails: emails,
          message: emailText
        }
      }).then(function (result) {
        result.scaleInviteSendingResults.forEach(function (_ref) {
          var scaleInvite = _ref.scaleInvite;

          if (model) {
            //If the model is passed in it means it's a new invite and we need to add to the store
            scaleInvite.id = scaleInvite.inviteId;
            scaleInvite.scaleUserList = scaleId;
            var data = {
              id: scaleInvite.inviteId,
              type: 'device/scale/invited-user',
              attributes: scaleInvite
            };
            store.push({
              data: data
            });
            var invitedUser = store.peekRecord('device/scale/invited-user', data.id);
            model.get('invitedUserList.users').pushObject(invitedUser);
          }
        });
      });
    }
  });

  _exports.default = _default;
});