define("fitbit-site-ui/components/device-menu-setting", ["exports", "fitbit-site-ui/components/panel-component", "fitbit-site-ui/mixins/prevent-flash-duplicate"], function (_exports, _panelComponent, _preventFlashDuplicate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend(_preventFlashDuplicate.default, {
    updateMenuItems: function updateMenuItems(menuItems) {
      var _this = this;

      var sortedMenuItems = menuItems.filterBy('value', true).mapBy('type'),
          formatter = this.get('i18n'),
          flashMessages = this.get('flashMessages');
      this.set('device.settings.currentSettings.enabledWidgets', sortedMenuItems.join(','));
      this.set('menuItems.menuItems', menuItems);
      this.get('device.settings').then(function (model) {
        return model.save().then(function () {
          var successMessage = formatter.format('device_setting_saved');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        });
      });
    },
    actions: {
      toggleMenu: function toggleMenu(menuItem) {
        var menuItems = this.get('menuItems.menuItems');
        menuItem.toggleProperty('value');
        this.updateMenuItems(menuItems);
      },
      reorderItems: function reorderItems(menuItems) {
        this.updateMenuItems(menuItems);
      }
    }
  });

  _exports.default = _default;
});