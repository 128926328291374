define("fitbit-site-ui/components/accept-consent", ["exports", "snapdragon-common/mixins/animations-mixin"], function (_exports, _animationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_animationsMixin.default, {
    consentModel: null,
    consentContent: null,

    /**
     *  Value is toggeled to trigger the animation
     */
    animateButton: false,

    /**
     *  Triggers the bounceAnimation from the AnimationsMixin
     *  on the accept button when the animate property is changed
     *  @param none
     *  @returns null
     */
    triggerBounceAnimation: Ember.observer('animateButton', function () {
      this.actions.bounceAnimation(document.getElementById('accept-button'));
    }),

    /**
     *  accept is passed in as (route-action "acceptConsent") from settings.hbs
     *  @param none
     */
    accept: function accept() {}
  });

  _exports.default = _default;
});