define("fitbit-site-ui/mixins/panel-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    PANEL_LIST: Ember.computed(function () {
      return [];
    }),

    /**
     * Create panel states for each setting component. As a performacne optimization, components are rerendered
     * if the route isn't completely exited. E.g. same route, different model.
     * To reset the components, panelStates provides a mechanism for resetting them.
     */
    initPanelStates: Ember.on('init', function () {
      Ember.set(this, 'panelStates', Ember.Object.create({}));
      this.resetPanelStates();
    }),

    /**
     * Reset the panel states back to the defaults for each setting.
     */
    resetPanelStates: function resetPanelStates() {
      var _EmberGetProperties = Ember.getProperties(this, 'PANEL_LIST', 'panelStates'),
          PANEL_LIST = _EmberGetProperties.PANEL_LIST,
          panelStates = _EmberGetProperties.panelStates;

      PANEL_LIST.forEach(function (setting) {
        panelStates.set(setting, {
          panelOpened: false,
          editPanelOpened: false
        });
      });
    }
  });

  _exports.default = _default;
});