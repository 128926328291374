define("fitbit-site-ui/serializers/notification-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    serialize: function serialize(rec) {
      var _EmberGetProperties = Ember.getProperties(rec, 'record', 'id'),
          record = _EmberGetProperties.record,
          id = _EmberGetProperties.id;

      var _EmberGetProperties2 = Ember.getProperties(record, 'emailState', 'pushState'),
          emailState = _EmberGetProperties2.emailState,
          pushState = _EmberGetProperties2.pushState;

      return {
        data: [{
          attributes: {
            'push-state': pushState,
            'email-state': emailState
          },
          id: id,
          type: 'notification-settings'
        }]
      };
    },

    /**
     * Overwritten because the endpoint returns all the settings
     * instead of just the one we updated
     */
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload.data,
          updatedRecord = data && data.findBy('id', id);
      var newPayload = {
        data: updatedRecord
      };
      Ember.set(newPayload, 'data.type', 'notification-setting');
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});