define("fitbit-site-ui/serializers/user-props", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return key;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var properties = payload.reduce(function (acc, prop) {
        acc[prop.name] = prop.value;
        return acc;
      }, {});
      var newPayload = {
        'user-props': {
          id: id,
          properties: properties,
          userProperties: payload
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  }); // Example response data. Unknown possibly sensitive origin
  //[{"name":"age_range","value":"RANGE_25_30"},{"name":"gender","value":"MALE"},{"name":"expUserId","value":"01NE1LM0dNAAAAAAAAqZr3ReY6NChUYe2pVVJg7Xdcm+KtjU28"},{"name":"is_fitbit_employee","value":true},{"name":"server_locale_country","value":"US"},{"name":"server_locale_language","value":"en"},{"name":"is_child","value":false}]


  _exports.default = _default;
});