define("fitbit-site-ui/models/journal-survey", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reference: _emberData.default.attr('string'),
    referenceNamespace: _emberData.default.attr('string'),
    version: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    questions: _emberData.default.hasMany('journal-survey-question', {
      polymorphic: true,
      async: false
    }),
    completed: Ember.computed('questions.@each.answered', function () {
      var questions = this.questions;
      return !questions.find(function (question) {
        return !question.answered;
      });
    }),
    sortQuestions: Object.freeze(['order']),
    orderedQuestions: Ember.computed.sort('questions', 'sortQuestions')
  });

  _exports.default = _default;
});