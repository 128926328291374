define("fitbit-site-ui/adapters/friend/friend-invitation", ["exports", "fitbit-site-ui/adapters/application", "snapdragon-common/errors/too-many-connections-error"], function (_exports, _application, _tooManyConnectionsError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/user',
    currentUser: Ember.inject.service(),

    /**
     * Checks to see if the error response has the title of 'tooManyConnections'
     * indicating that a user already has 2000 connections and cannot make another
     * @param status - Http response code
     * @param headers - Http headers
     * @param body - Response boday
     * @returns {TooManyConnectionsError,Promise>}
     */
    handleResponse: function handleResponse(status, headers, body) {
      var payloadErrors = Ember.get(body, 'errors') || [];
      var tooManyConnectionsError = payloadErrors.find(function (error) {
        return Ember.get(error, 'title') === 'tooManyConnections';
      });

      if (tooManyConnectionsError) {
        return new _tooManyConnectionsError.default();
      } else {
        return this._super(status, headers, body);
      }
    },
    pathForType: function pathForType() {
      return '';
    },
    urlForFindAll: function urlForFindAll() {
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "".concat(this._super(), "/").concat(currentUserId, "/friends/invitations.json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      var currentUserId = Ember.get(this, 'currentUser.id');
      return "".concat(this._super(), "/").concat(currentUserId, "/friends/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});