define("fitbit-site-ui/components/footer-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),

    /**
     * Properties for social media links
     */
    facebookURL: Ember.computed(function () {
      var _this$get = this.get('constants.socialMediaContexts.FACEBOOK'),
          locale = _this$get.locale,
          baseURL = _this$get.baseURL,
          URLContext = _this$get.URLContext;

      var key;

      switch (true) {
        case URLContext.hasOwnProperty(locale):
          key = locale;
          break;

        default:
          key = 'other';
      }

      return "".concat(baseURL).concat(URLContext[key]);
    }),
    twitterURL: Ember.computed(function () {
      var _this$get2 = this.get('constants.socialMediaContexts.TWITTER'),
          locale = _this$get2.locale,
          baseURL = _this$get2.baseURL,
          URLContext = _this$get2.URLContext;

      var key;

      switch (true) {
        case URLContext.hasOwnProperty(locale):
          key = locale;
          break;

        default:
          key = 'other';
      }

      return "".concat(baseURL).concat(URLContext[key]);
    }),
    instagramURL: Ember.computed(function () {
      var _this$get3 = this.get('constants.socialMediaContexts.INSTAGRAM'),
          locale = _this$get3.locale,
          baseURL = _this$get3.baseURL,
          URLContext = _this$get3.URLContext;

      var key;

      switch (true) {
        case URLContext.hasOwnProperty(locale):
          key = locale;
          break;

        default:
          key = 'other';
      }

      return "".concat(baseURL).concat(URLContext[key]);
    }),
    youtubeURL: Ember.computed(function () {
      var _this$get4 = this.get('constants.socialMediaContexts.YOUTUBE'),
          locale = _this$get4.locale,
          baseURL = _this$get4.baseURL,
          URLContext = _this$get4.URLContext;

      var key = locale === 'zh_TW' ? locale : 'other';
      return "".concat(baseURL).concat(URLContext[key]);
    }),
    browserLocaleChina: Ember.computed.match('i18n.browserLocale', /zh_CN/i),
    browserLocaleUS: Ember.computed.match('i18n.browserLocale', /en_US/i),
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    })
  });

  _exports.default = _default;
});