define("fitbit-site-ui/serializers/time-series/sleep/list", ["exports", "fitbit-site-ui/serializers/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    getPayloadKey: function getPayloadKey() {
      return 'time-series/sleep/list';
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var sleepList = payload.sleep; // We'll be adding the payloadkey in the timeserisserializer class.

      var data = {
        id: id,
        list: sleepList.map(function (sleepList) {
          return sleepList.startTime;
        })
      };

      var ret = this._super(store, primaryModelClass, data, id, requestType); // Construct the array of the list


      var newPayload = sleepList.map(function (list) {
        return {
          activitiesSleepIntraday: list,
          activitiesSleep: payload.summary,
          _id: list.startTime
        };
      });

      var resSleepDetails = this._normalizeSleepDetails(store, newPayload, id).data;

      ret.included = resSleepDetails;
      return ret;
    },
    _normalizeSleepDetails: function _normalizeSleepDetails(store, payload, id) {
      var sleepDetailsModel = store.modelFor('time-series/sleep');
      var sleepDetailsSerializer = store.serializerFor(sleepDetailsModel.modelName);
      return sleepDetailsSerializer.normalizeArrayResponse(store, sleepDetailsModel, {
        'time-series/sleep': payload
      }, id);
    }
  });

  _exports.default = _default;
});