define("fitbit-site-ui/models/friend/sent-invitation", ["exports", "fitbit-site-ui/models/friend/friend-invitation", "ember-data"], function (_exports, _friendInvitation, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _friendInvitation.default.extend({
    email: _emberData.default.attr('string')
  });

  _exports.default = _default;
});