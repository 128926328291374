define("fitbit-site-ui/adapters/badge", ["exports", "fitbit-site-ui/adapters/application-jsonapi", "snapdragon-common/errors/private-data-error"], function (_exports, _applicationJsonapi, _privateDataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    /**
     * Checks if the API returned a 403, which is what happens when you view another user's profile
     *    and their achievements are private
     *
     * @return {*}
     */
    handleResponse: function handleResponse(status, headers, body) {
      return status === 403 ? new _privateDataError.default() : this._super(status, headers, body);
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var urlId = Ember.get(snapshot, 'adapterOptions.urlId');

      var url = this._super(modelName, snapshot);

      if (urlId) {
        url = url.replace('/-/', '/' + urlId + '/');
      }

      return "".concat(url, ".json");
    }
  });

  _exports.default = _default;
});