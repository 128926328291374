define("fitbit-site-ui/adapters/food-log", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    namespace: '1.1/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this._super.apply(this, arguments), "/-/foods/log.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this._super(), "/-/foods/log/").concat(id, ".json");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this._super(), "/-/foods/log/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});