define("fitbit-site-ui/components/device-dominant-hand-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    actions: {
      dominantHandChanged: function dominantHandChanged(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.set('dominantHand', value);
        this.get('settings').then(function (model) {
          return model.save();
        }).then(function () {
          var successMessage = formatter.format('device_setting_saved');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        }).catch(function () {
          var failureMessage = formatter.format('something_went_wrong');

          _this._clearMessageFromQueue(failureMessage);

          flashMessages.alert(failureMessage);
        });
      }
    }
  });

  _exports.default = _default;
});