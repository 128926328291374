define("fitbit-site-ui/serializers/user-device-list", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    _normalizeUserDevices: function _normalizeUserDevices(store, payload, id) {
      var userDeviceModel = store.modelFor('user-device');
      var userDeviceSerializer = store.serializerFor(userDeviceModel.modelName);
      return userDeviceSerializer.normalizeArrayResponse(store, userDeviceModel, {
        'user-devices': payload
      }, id);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // API returns an array in the payload. Change it to match the devices model.
      var devices = payload;
      var data = {
        'user-device-list': {
          id: id,
          list: devices.map(function (device) {
            return device.id;
          })
        }
      };

      var ret = this._super(store, primaryModelClass, data, id, requestType);

      var userDevices = this._normalizeUserDevices(store, devices, id).data;

      ret.included = userDevices;
      return ret;
    }
  });

  _exports.default = _default;
});