define("fitbit-site-ui/models/water-date", ["exports", "ember-data", "snapdragon-common/utils/unit-converter"], function (_exports, _emberData, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    waterLogs: _emberData.default.hasMany('water-log'),
    currentUser: Ember.inject.service(),

    /**
     * Total of all water logs.  Unit based on currentUser.waterUnit
     */
    total: Ember.computed('waterLogs.[]', function () {
      var waterLogs = Ember.get(this, 'waterLogs');
      var waterLogTotal = waterLogs.reduce(function (sum, log) {
        var logAmount = Ember.get(log, 'amount');
        return logAmount + sum;
      }, 0);
      var currentUnit = Ember.get(this, 'currentUser.waterUnit');
      var digits = currentUnit === 'METRIC' ? 0 : 2;
      return _unitConverter.default.roundToDigits(waterLogTotal, digits);
    }),
    totalMl: Ember.computed('total', function () {
      var total = Ember.get(this, 'total') || 0;
      var currentUnit = Ember.get(this, 'currentUser.waterUnit');

      if (currentUnit !== 'METRIC') {
        return currentUnit === 'en_US' ? _unitConverter.default.ozToMl(total) : _unitConverter.default.cupsToMl(total);
      }

      return _unitConverter.default.roundToDigits(total, 0);
    }),
    totalOz: Ember.computed('total', function () {
      var total = Ember.get(this, 'total') || 0;
      var currentUnit = Ember.get(this, 'currentUser.waterUnit');

      if (currentUnit !== 'en_US') {
        return currentUnit === 'METRIC' ? _unitConverter.default.mlToOz(total, 1) : _unitConverter.default.cupsToOz(total);
      }

      return _unitConverter.default.roundToDigits(total, 2);
    }),
    totalCup: Ember.computed('total', function () {
      var total = Ember.get(this, 'total') || 0;
      var currentUnit = Ember.get(this, 'currentUser.waterUnit');

      if (currentUnit !== 'en_US_cup') {
        return currentUnit === 'en_US' ? _unitConverter.default.ozToCups(total, 2) : _unitConverter.default.mlToCups(total, 2);
      }

      return _unitConverter.default.roundToDigits(total, 2);
    })
  });

  _exports.default = _default;
});