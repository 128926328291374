define("fitbit-site-ui/components/scale-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    scaleUnits: Ember.computed(function () {
      return [{
        displayName: 'scale_unit_US',
        unit: 'en_US'
      }, {
        displayName: 'scale_unit_GB',
        unit: 'en_GB'
      }, {
        displayName: 'scale_unit_metric',
        unit: 'METRIC'
      }];
    }),
    actions: {
      selectScaleUnit: function selectScaleUnit(value) {
        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.set('userScaleUnit', value);
        this.get('scaleSettings').save().then(function () {
          return flashMessages.success(formatter.format('device_setting_saved'));
        });
      }
    }
  });

  _exports.default = _default;
});