define("fitbit-site-ui/controllers/settings/trackers", ["exports", "fitbit-site-ui/mixins/panel-mixin"], function (_exports, _panelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend(_panelMixin.default, {
    constants: Ember.inject.service(),
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentSettings: Ember.computed.alias('model.settings.currentSettings'),

    /**
     * Constant for collection of settings for trackers. Used to generate the panel states.
     */
    PANEL_LIST: Ember.computed(function () {
      return ['alarms', 'inactivityAlerts', 'primaryGoal', 'quickView', 'dominantHand', 'wristOrientation', 'handedness', 'sleep', 'grok', 'clockFace', 'heartRate', 'autoLap', 'exerciseShortcuts', 'exerciseAutoCues', 'exerciseAutoPause', 'exerciseGpsControl', 'customizeDisplay', 'swimSetting', 'exerciseInterval', 'autoRunSetting', 'fitbitPaySetting', 'customizeMenu', 'tapGesture', 'liveDataSetting'];
    }),

    /**
     * Helper method to reset the controller.
     */
    reset: function reset() {
      this.set('model', null);
      this.resetPanelStates();
    },
    autoLapIntervalDisplay: Ember.computed('model.settings.options.autoLapInterval', 'currentSettings.autoLapInterval', function () {
      var formatter = this.get('i18n');
      var distanceUnit = this.get('currentUser.distanceUnit');
      var autoLapInterval = this.get('currentSettings.autoLapInterval');
      var options = this.get('model.settings.options.autoLapInterval');
      var str;

      if (options) {
        if (autoLapInterval === 'OFF') {
          str = formatter.format('off');
        } else {
          // Determine whether the interval is by TIME or DISTANCE
          var _autoLapInterval$spli = autoLapInterval.split('_'),
              _autoLapInterval$spli2 = _slicedToArray(_autoLapInterval$spli, 1),
              type = _autoLapInterval$spli2[0];

          var lookupKey = 'time';
          var suffix = formatter.format('time_unit_minutes');
          var option; // If the type is Distance, determine whether to look up the value in distanceMile or distanceKm

          if (type === 'DISTANCE') {
            if (distanceUnit === 'en_US') {
              lookupKey = 'distanceMile';
              suffix = formatter.format('dist_symbol_US');
            } else {
              lookupKey = 'distanceKm';
              suffix = formatter.format('dist_symbol_metric');
            }
          }

          option = options[lookupKey].findBy('autoLapInterval', autoLapInterval);
          str = "".concat(option.value, " ").concat(suffix);
        }
      }

      return str;
    }),
    inactivityDurationDisplay: Ember.computed('model.settings.inactivityAlerts.sedentaryTime.duration', function () {
      var formatter = this.get('i18n');
      var duration = this.get('model.settings.inactivityAlerts.sedentaryTime.duration');

      if (duration) {
        return formatter.format('inactivity_duration', {
          duration: duration
        });
      }
    }),
    clockFaceDisplay: Ember.computed('currentSettings.clockFace', 'model.settings.options.clockFacesSupported', function () {
      var formatter = this.get('i18n');
      var clockFaceID = this.get('currentSettings.clockFace.name');
      var options = this.get('model.settings.options.clockFacesSupported');

      if (options) {
        var selectedClockFace = Ember.A(options).findBy('name', clockFaceID),
            label;

        if (selectedClockFace) {
          // Lookup the clockface label.
          label = selectedClockFace && selectedClockFace.displayName || formatter.format(selectedClockFace.name);
        } else {
          //Should only happen for devices paired over magic data
          label = formatter.format('clock_face_not_set');
        }

        (false && !(label) && Ember.assert(formatter.format('define_clock_label'), label));
        return label;
      }
    }),
    dominantHandDisplay: Ember.computed('currentSettings.onDominantHand', function () {
      var formatter = this.get('i18n');
      var key = this.get('currentSettings.onDominantHand') ? 'dominant' : 'non_dominant';
      return formatter.format(key);
    }),
    mainGoalDisplay: Ember.computed('currentSettings.primaryGoal', function () {
      var mainGoalSettingDisplay = this.get('currentSettings.primaryGoal');
      return mainGoalSettingDisplay && mainGoalSettingDisplay;
    }),
    quickViewDisplay: Ember.computed('model.settings.quickView', function () {
      var formatter = this.get('i18n');
      var quickViewSetting = this.get('model.settings.quickView');
      var key = quickViewSetting === 'DISABLED' ? 'off' : 'show_clock';
      return formatter.format(key);
    }),
    wristOrientationDisplay: Ember.computed('currentSettings.watchCheck', function () {
      var formatter = this.get('i18n');
      var wristOrientation = this.get('currentSettings.wearWrist');
      return wristOrientation && formatter.format(wristOrientation);
    }),
    handednessDisplay: Ember.computed('currentSettings.handedness', function () {
      var formatter = this.get('i18n');
      var handedness = this.get('currentSettings.handedness');
      return handedness && formatter.format(handedness);
    }),
    sleepSensitivityDisplay: Ember.computed.alias('currentUser.sleepTracking'),
    silentAlarmDisplay: Ember.computed('model.settings.alarms.list.[]', function () {
      var formatter = this.get('i18n');
      var count = this.get('model.settings.alarms.list.length');
      var str = formatter.format('no_alarms_set');

      if (count) {
        str = formatter.format('alarms_set', {
          count: count
        });
      }

      return str;
    }),
    heartRateDisplay: Ember.computed('currentSettings.heartRateTracking', function () {
      var formatter = this.get('i18n');
      var heartRateSetting = this.get('currentSettings.heartRateTracking');
      var heartRateSettingKey = heartRateSetting === 'ON' ? 'on' : heartRateSetting === 'OFF' ? 'off' : 'auto';
      return heartRateSetting && formatter.format(heartRateSettingKey);
    }),
    autoExerciseRecognitionDisplay: Ember.computed('model.settings.autoExerciseSettings.autoDetection', function () {
      //TODO: Missing designs for setting label
      var formatter = this.get('i18n');
      var autoDetection = this.get('model.settings.autoExerciseSettings.autoDetection');
      return autoDetection && formatter.format(autoDetection);
    }),
    exerciseIntervalDisplay: Ember.computed('currentSettings.exerciseIntervalTimer', function () {
      var intervals = this.get('currentSettings.exerciseIntervalTimer.intervals'),
          duration = intervals && intervals[0].durationSeconds;
      return duration;
    }),
    tapGestureDisplay: Ember.computed('model.settings.{options.tapGestureOptions,tapGesture}', function () {
      var tapGestureOptions = this.get('model.settings.options.tapGestureOptions'),
          tapGesture = this.get('model.settings.tapGesture');
      return tapGesture && tapGestureOptions && tapGestureOptions.length ? tapGestureOptions.findBy('name', tapGesture).displayName : '';
    }),
    exerciseGpsControlDisplay: Ember.computed('currentSettings.exerciseSettings', function () {
      var settings = this.get('currentSettings.exerciseSettings');
      var formatter = this.get('i18n');
      var gpsOn = settings && settings.some(function (setting) {
        return setting.gpsOn === true;
      });
      return gpsOn ? formatter.formatByNamespace('on', 'common.messages') : formatter.formatByNamespace('off', 'common.messages');
    }),
    displayNames: Ember.computed('model.deviceType.deviceFeatureSupported', 'dominantHandDisplay', 'mainGoalDisplay', 'quickViewDisplay', 'handednessDisplay', 'wristOrientationDisplay', 'autoExerciseRecognitionDisplay', 'silentAlarmDisplay', 'heartRateDisplay', 'clockFaceDisplay', 'inactivityDurationDisplay', 'sleepSensitivityDisplay', 'autoLapIntervalDisplay', 'exerciseGpsControlDisplay', function () {
      var displayNames = {
        dominantHandDisplay: this.get('dominantHandDisplay'),
        mainGoalDisplay: this.get('mainGoalDisplay'),
        quickViewDisplay: this.get('quickViewDisplay'),
        handednessDisplay: this.get('handednessDisplay'),
        wristOrientationDisplay: this.get('wristOrientationDisplay'),
        sleepSensitivityDisplay: this.get('sleepSensitivityDisplay'),
        autoExerciseRecognitionDisplay: this.get('autoExerciseRecognitionDisplay'),
        heartRateDisplay: this.get('heartRateDisplay'),
        clockFaceDisplay: this.get('clockFaceDisplay'),
        inactivityDurationDisplay: this.get('inactivityDurationDisplay'),
        autoLapIntervalDisplay: this.get('autoLapIntervalDisplay'),
        exerciseIntervalDisplay: this.get('exerciseIntervalDisplay'),
        tapGestureDisplay: this.get('tapGestureDisplay'),
        exerciseGpsControlDisplay: this.get('exerciseGpsControlDisplay')
      }; // Only retrieve silentAlarmDisplay if device supports it, otherwise requests to alarms endpoint will be triggered
      // and respond with a 400

      var supportedFeatures = this.get('model.deviceType.deviceFeatureSupported');

      if (supportedFeatures && supportedFeatures.includes('ALARMS')) {
        displayNames.silentAlarmDisplay = this.get('silentAlarmDisplay');
      }

      return displayNames;
    }),

    /**
     * Displays a success flash message when deactivation endpoint succeeds
     * @function
     * @returns {undefined}
     */
    deactivationSuccess: function deactivationSuccess() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.success(formatter.formatByNamespace('deactivation_success', 'templates.payment-killswitch'));
    },

    /**
     * Displays an alert flash message when deactivation endpoint returns the no payment method found error
     * @function
     * @returns {undefined}
     */
    noPaymentMethodFound: function noPaymentMethodFound() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.alert(formatter.formatByNamespace('device_not_registered', 'templates.payment-killswitch'));
    },

    /**
     * Displays an alert flash message when deactivation endpoint returns an unknown/unexpected error
     * @function
     * @returns {undefined}
     */
    deactivationError: function deactivationError() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.alert(formatter.formatByNamespace('failure_contact_cs', 'templates.payment-killswitch'));
    },

    /**
     * Displays a success flash message when receipts request endpoint succeeds
     * @function
     * @returns {undefined}
     */
    receiptsRequestSuccess: function receiptsRequestSuccess() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.success(formatter.formatByNamespace('receipts_request_success', 'templates.payment-receipts-request'));
    },

    /**
     * Displays an alert flash message when receipts request endpoint returns the no transactions found error
     * @function
     * @returns {undefined}
     */
    noReceiptsRequestMethodFound: function noReceiptsRequestMethodFound() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.alert(formatter.formatByNamespace('no_transactions_found', 'templates.payment-receipts-request'));
    },

    /**
     * Displays an alert flash message when receipts request endpoint returns an unknown/unexpected error
     * @function
     * @returns {undefined}
     */
    receiptsRequestError: function receiptsRequestError() {
      var formatter = Ember.get(this, 'i18n');
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.alert(formatter.formatByNamespace('failure_contact_cs', 'templates.payment-killswitch'));
    }
  });

  _exports.default = _default;
});