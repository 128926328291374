define("fitbit-site-ui/routes/onboarding/scales", ["exports", "fitbit-site-ui/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    model: function model(params) {
      var deviceId = params.id;
      return Ember.RSVP.hash({
        scale: this.store.findRecord('device/scale/user-scale', deviceId)
      });
    },
    afterModel: function afterModel(model) {
      return Ember.get(model, 'scale.userList');
    },
    actions: {
      error: function error(_error) {
        var errors = _error.errors; // Handle 400 status by transitioning to 404 page.

        if (errors && errors.length) {
          var _errors$ = errors[0],
              errorType = _errors$.errorType,
              fieldName = _errors$.fieldName;

          if (errorType === 'validation' && fieldName === 'serialNumber') {
            // Incorrect device ID.
            this.transitionTo('/404');
          }
        }

        Ember.Logger.error(_error);
      }
    }
  });

  _exports.default = _default;
});