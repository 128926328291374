define("fitbit-site-ui/serializers/journal-survey-question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'questionId',
    attrs: {
      responses: {
        serialize: true,
        embedded: 'always'
      },
      categoryTitle: 'category-title',
      name: 'id' // Map id to name to maintain original non-UUID

    },
    keyForAttribute: function keyForAttribute(attr, method) {
      if (method === 'serialize') {
        return Ember.String.dasherize(attr);
      } else {
        return Ember.String.camelize(attr);
      }
    },

    /**
     * Remove properties added to model during normalization step and convert changed properties
     * to backed-expected format (Remove appended "journal-survey-question" from question.type)
     * @returns {*}
     */
    serialize: function serialize() {
      var question = this._super.apply(this, arguments); // Convert journal-survey-question types to backend expected


      var type = question.type;
      question.type = type.replace('journal-survey-question-', '');
      delete question['question-id'];
      delete question.order;
      delete question.survey;
      return question;
    }
  });

  _exports.default = _default;
});