define("fitbit-site-ui/templates/components/device-handedness-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g7IesqOu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"format\",[\"handedness_setting_detail\"],null],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row column radio-group\"],[9],[0,\"\\n    \"],[1,[27,\"double-radio-button\",null,[[\"toggleValue\",\"firstValue\",\"firstDescription\",\"secondValue\",\"secondDescription\",\"propertyName\",\"changeFnc\"],[[23,[\"handedness\"]],\"LEFT\",[27,\"format\",[\"left\"],null],\"RIGHT\",[27,\"format\",[\"right\"],null],\"handedness\",[27,\"action\",[[22,0,[]],\"handednessChanged\"],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-handedness-setting.hbs"
    }
  });

  _exports.default = _default;
});