define("fitbit-site-ui/session-stores/application", ["exports", "fitbit-site-ui/session-stores/fitbit-cookie", "fitbit-site-ui/session-stores/fitbit-adaptive", "ember-get-config"], function (_exports, _fitbitCookie, _fitbitAdaptive, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var environment = _emberGetConfig.default.environment;
  /**
   * Adaptive is the default session store. If the build is a weightsite build AND it's a production build, then use the
   * Cookie store.
   *
   * The Cookie store is specifically tailored to work with the oauth_access_token cookie that is created and stored by
   * weightsite login.
   */

  var SessionStore = _fitbitAdaptive.default;

  if (IS_WEIGHTSITE) {
    /*
     Need to have nested if statement because conditional-compile addon relies on uglify dead code removal.
     If the condition is `IS_WEIGHTSITE && environment === 'production'`, then the if statement would not be considered
     dead code.
     */
    if (environment === 'production') {
      SessionStore = _fitbitCookie.default;
    }
  }

  var _default = SessionStore;
  _exports.default = _default;
});