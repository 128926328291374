define("fitbit-site-ui/adapters/activity-list", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    moment: Ember.inject.service(),
    namespace: '1.1/user',
    _buildActivityLogURLParams: function _buildActivityLogURLParams() {
      var moment = this.get('moment');
      return "?limit=10&sort=desc&offset=0&beforeDate=".concat(moment.moment().format('YYYY-MM-DDTHH:mm:ss'));
    },
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/activities/list.json").concat(this._buildActivityLogURLParams());
    }
  });

  _exports.default = _default;
});