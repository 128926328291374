define("fitbit-site-ui/adapters/privacy-settings-ui-group", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    urlForFindAll: function urlForFindAll() {
      return this._super.apply(this, arguments) + '.json';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._super(null, modelName, snapshot) + '.json';
    }
  });

  _exports.default = _default;
});