define("fitbit-site-ui/adapters/time-series/sleep/list", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    pathForType: function pathForType(modelName) {
      return 'sleep/date/' + modelName;
    }
  });

  _exports.default = _default;
});