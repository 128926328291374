define("fitbit-site-ui/controllers/dashboard", ["exports", "snapdragon-common/constants/user-agent", "snapdragon-common/constants/app-store-urls", "snapdragon-common/constants/support-article"], function (_exports, _userAgent, _appStoreUrls, _supportArticle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.dashboardUrl = this.firebase.link({
        orStore: true
      });
    },
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebase: Ember.inject.service(),
    isMobile: _userAgent.IS_MOBILE,
    router: Ember.inject.service(),
    dashboardUrl: null,
    appStoreUrl: _appStoreUrls.APPLE_APP_STORE_URL,
    playStoreUrl: _appStoreUrls.GOOGLE_PLAY_STORE_URL,
    legacyDashboardArticleUrl: _supportArticle.LEGACY_DASHBOARD_ARTICLE_URL
  });

  _exports.default = _default;
});