define("fitbit-site-ui/templates/components/user-action-notification-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3C8KglHf",
    "block": "{\"symbols\":[\"@user\",\"@actionType\"],\"statements\":[[7,\"div\"],[12,\"id\",[28,[\"user-action-notification-\",[22,1,[\"id\"]]]]],[11,\"class\",\"user-action-notification-container row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column shrink\"],[9],[0,\"\\n        \"],[1,[27,\"fit-glyph\",null,[[\"type\",\"id\",\"class\"],[[22,2,[]],[27,\"concat\",[\"user-action-notification-icon-\",[22,1,[\"id\"]]],null],\"user-action-notification-fit-glyph\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column expand user-action-notification-text-column align-self-middle\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"user-action-notification-text\"],[11,\"data-test-qa\",\"user-action-notification-text\"],[9],[0,\"\\n            \"],[1,[27,\"format-by-namespace\",[[27,\"concat\",[\"you_\",[22,2,[]]],null],\"route.friends\"],[[\"name\"],[[22,1,[\"name\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-action-notification-card.hbs"
    }
  });

  _exports.default = _default;
});