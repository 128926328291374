define("fitbit-site-ui/components/device-exercise-autopause-control", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    autoPauseSettings: Ember.computed('settings', 'settings.@each', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'settings', 'options'),
          settings = _EmberGetProperties.settings,
          options = _EmberGetProperties.options;

      return settings.map(function (setting) {
        var option = options.findBy('id', setting.exerciseId);
        var exerciseName = option.name,
            exerciseId = option.id;
        return {
          exerciseId: exerciseId,
          exerciseName: exerciseName,
          autoPauseOn: setting.autoPauseOn
        };
      });
    }),
    actions: {
      exerciseAutoPauseControlChanged: function exerciseAutoPauseControlChanged(exerciseId, value) {
        var setting = Ember.get(this, 'settings').findBy('exerciseId', exerciseId);
        Ember.set(setting, 'autoPauseOn', value.target.checked);
        this.updateSettings();
      }
    }
  });

  _exports.default = _default;
});