define("fitbit-site-ui/templates/components/device-swim-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q2poNMEp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[1,[27,\"format\",[\"swim_setting_detail\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row radio-group\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"small-2 columns offset-2\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"focus-out\"],[\"text\",[23,[\"exercisePreferences\",\"swimSettings\",\"poolLength\"]],[27,\"action\",[[22,0,[]],\"swimSettingChanged\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"small-6 columns\"],[9],[0,\"\\n        \"],[1,[27,\"double-radio-button\",null,[[\"toggleValue\",\"firstValue\",\"firstDescription\",\"secondValue\",\"secondDescription\",\"propertyName\",\"changeFnc\"],[[23,[\"exercisePreferences\",\"swimSettings\",\"poolLengthUnit\"]],\"Meter\",[27,\"format\",[\"meters\"],null],\"Yard\",[27,\"format\",[\"yards\"],null],\"poolLengthUnit\",[27,\"action\",[[22,0,[]],\"swimSettingChanged\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"validations-handler\",null,[[\"scope\",\"key\"],[[22,0,[]],\"exercisePreferences.swimSettings.poolLength\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-swim-setting.hbs"
    }
  });

  _exports.default = _default;
});