define("fitbit-site-ui/models/friend/leaderboard", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    rankedUsers: _emberData.default.hasMany('friend/leaderboard/ranked-user'),
    inactiveUsers: _emberData.default.hasMany('friend/leaderboard/inactive-user'),
    hiddenUsers: _emberData.default.hasMany('friend/leaderboard/hidden-user'),
    rankedSort: Object.freeze(['stepRank']),
    orderedRankedUsers: Ember.computed.sort('rankedUsers', 'rankedSort')
  });

  _exports.default = _default;
});