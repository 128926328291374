define("fitbit-site-ui/templates/components/profile-settings-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8Du7tbT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"input-header unit\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"unit_type_length\"],null],false],[0,\"\\n    \"],[1,[27,\"fit-select-menu\",null,[[\"list\",\"selectedValue\",\"selectAction\",\"class\",\"data-test-qa\"],[[23,[\"units\",\"height\"]],[23,[\"profile\",\"heightUnit\"]],[27,\"action\",[[22,0,[]],\"toggleHeight\"],null],\"select-units\",\"height-unit\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"input-header unit\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"unit_type_weight\"],null],false],[0,\"\\n    \"],[1,[27,\"fit-select-menu\",null,[[\"list\",\"selectedValue\",\"selectAction\",\"class\",\"data-test-qa\"],[[23,[\"units\",\"weight\"]],[23,[\"profile\",\"weightUnit\"]],[27,\"action\",[[22,0,[]],\"toggleWeight\"],null],\"select-units\",\"weight-unit\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"input-header unit\"],[11,\"data-test-qa\",\"water-units\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"unit_type_water\"],null],false],[0,\"\\n    \"],[1,[27,\"fit-select-menu\",null,[[\"list\",\"selectedValue\",\"selectAction\",\"class\",\"data-test-qa\"],[[23,[\"units\",\"water\"]],[23,[\"profile\",\"waterUnit\"]],[27,\"action\",[[22,0,[]],\"toggleWater\"],null],\"select-units\",\"water-unit\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/profile-settings-units.hbs"
    }
  });

  _exports.default = _default;
});