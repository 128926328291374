define("fitbit-site-ui/adapters/user-props", ["exports", "fitbit-site-ui/adapters/application", "fitbit-site-ui/constants/user-props"], function (_exports, _application, _userProps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: _userProps.USER_PROPS_NAMESPACE,
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var isAdmin = snapshot && Ember.get(snapshot, 'adapterOptions.admin');

      if (isAdmin) {
        return "".concat(this._super(), "/admin/user/").concat(id, "/props");
      }

      return "".concat(this._super(), "/user/").concat(id, "/props");
    },
    // Admin namespace
    urlForUpdateRecord: function urlForUpdateRecord(id, modelname, snapshot) {
      var email = Ember.get(snapshot, 'adapterOptions.email');
      var urlArg = email || id;
      return "".concat(this._super(), "/admin/user/").concat(urlArg, "/props");
    }
  });

  _exports.default = _default;
});