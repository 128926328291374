define("fitbit-site-ui/adapters/device/tracker/settings", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    pathForType: function pathForType() {
      return 'devices/tracker';
    },

    /**
     * Custom buildURL to return a string with .json appended.
     */
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '.json';
    }
  });

  _exports.default = _default;
});