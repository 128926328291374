define("fitbit-site-ui/controllers/user-notifications", ["exports", "fitbit-site-ui/components/user-messaging-form", "fitbit-site-ui/constants/user-notifications"], function (_exports, _userMessagingForm, _userNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    emptyStateMessage: Ember.computed(function () {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('empty_state_notifications', 'route.notifications');
    }),
    // list of all notifications/messages
    sortedNotificationsKey: Object.freeze(['time:desc']),
    sortedNotifications: Ember.computed.sort('model.allNotifications', 'sortedNotificationsKey'),
    // limit sorted notifications
    filteredNotifications: Ember.computed.filter('sortedNotifications', function (notification, index) {
      return index < _userNotifications.MAX_NOTIFICATIONS_DISPLAYED;
    }),
    // messaging modal
    messageRecipient: null,
    // the user receiving the message

    /**
     * Model for user-messaging-form component.
     *
     * The value is a placeholder that is replaced when the user message form appears in the modal.
     *
     * @returns {UserMessagingFormModel}
     */
    userMessagingFormModel: Ember.computed(function () {
      return this.createUserMessagingFormModel();
    }),

    /**
     * Reset data used for messaging form.
     * Is reset when the form is closed.
     *
     * @returns {*}
     */
    createUserMessagingFormModel: function createUserMessagingFormModel() {
      var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _EmberGetProperties = Ember.getProperties(source, 'avatar', 'name'),
          _EmberGetProperties$a = _EmberGetProperties.avatar,
          avatar = _EmberGetProperties$a === void 0 ? '' : _EmberGetProperties$a,
          _EmberGetProperties$n = _EmberGetProperties.name,
          name = _EmberGetProperties$n === void 0 ? '' : _EmberGetProperties$n,
          _EmberGetProperties$m = _EmberGetProperties.message,
          message = _EmberGetProperties$m === void 0 ? '' : _EmberGetProperties$m;

      return _userMessagingForm.UserMessagingFormModel.create({
        avatar: avatar,
        name: name,
        message: message
      });
    },
    actions: {
      /**
       * Open the message modal
       */
      openMessageModal: function openMessageModal(user) {
        var messageModal = Ember.get(this, 'messageModal');

        if (messageModal) {
          var _EmberGetProperties2 = Ember.getProperties(user, 'avatar150', 'displayName'),
              avatar = _EmberGetProperties2.avatar150,
              name = _EmberGetProperties2.displayName; // Update the `userMessagingFormModel` computed property a new object with populated avatar and name.


          Ember.set(this, 'userMessagingFormModel', this.createUserMessagingFormModel({
            avatar: avatar,
            name: name
          }));
          Ember.set(this, 'messageRecipient', user);
          Ember.run.scheduleOnce('afterRender', messageModal, 'open');
        }
      },

      /**
       * Close the message modal
       */
      closeMessageModal: function closeMessageModal() {
        var messageModal = Ember.get(this, 'messageModal');

        if (messageModal) {
          Ember.run.scheduleOnce('afterRender', messageModal, 'close');
        }

        Ember.set(this, 'messageRecipient', null);
      },

      /**
       * requests for a notification to be marked as read after user interaction
       * @param notification
       */
      markAsRead: function markAsRead(notification) {
        notification.markAsRead();
      }
    }
  });

  _exports.default = _default;
});