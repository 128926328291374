define("fitbit-site-ui/templates/components/device-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iFWik8f9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"columns status-bar\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"float-left status-col\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fitglyph fitglyph-sync\"],[11,\"role\",\"presentation\"],[9],[10],[0,\"\\n            \"],[1,[23,[\"device\",\"displayLastSyncTime\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"float-left status-col\"],[9],[0,\"\\n            \"],[1,[27,\"device-battery-icon\",null,[[\"batteryStatus\"],[[23,[\"device\",\"battery\"]]]]],false],[0,\"\\n            \"],[1,[27,\"format-by-namespace\",[[27,\"lowercase\",[[23,[\"device\",\"battery\"]]],null],\"templates.components.device-overview\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"device\",\"firmwareVersion\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"float-left status-col\"],[9],[0,\"\\n                \"],[1,[27,\"format\",[\"firmware_version\"],[[\"version\"],[[23,[\"device\",\"firmwareVersion\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-overview.hbs"
    }
  });

  _exports.default = _default;
});