define("fitbit-site-ui/serializers/user-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    moment: Ember.inject.service(),
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var moment = Ember.get(this, 'moment');

      if (typeof payload === 'undefined' || payload === null) {
        payload = [];
      } else {
        // flatten included users for easier reference
        var includedUsers = {};
        var users = payload.included || [];
        users.forEach(function (user) {
          includedUsers[user.id] = {
            encodedId: user.id,
            type: user.type,
            displayName: Ember.get(user, 'attributes.name'),
            avatar150: Ember.get(user, 'attributes.avatar')
          };
        }); // flatten messages

        var messages = payload.data || [];
        payload = messages.map(function (message) {
          var _message$attributes = message.attributes,
              isRead = _message$attributes.read,
              content = _message$attributes.content,
              time = _message$attributes.time;
          var momentTime = moment.moment(time);
          return {
            id: message.id,
            notificationType: 'message',
            sentBy: Ember.get(includedUsers, Ember.get(message, 'relationships.sender.data.id')),
            message: content,
            isRead: isRead,
            time: momentTime.valueOf(),
            timeRelative: momentTime.fromNow()
          };
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});