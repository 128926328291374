define("fitbit-site-ui/adapters/time-series/calories-in", ["exports", "fitbit-site-ui/adapters/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeSeries.default.extend({
    pathForType: function pathForType() {
      return '';
    },
    urlForQuery: function urlForQuery(query) {
      return "".concat(this._super.apply(this, arguments), "/foods/log/caloriesIn/date/").concat(query.startDate, "/").concat(query.endDate, ".json");
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      return "".concat(this._super.apply(this, arguments), "/foods/log/caloriesIn/date/").concat(query.startDate, "/").concat(query.endDate, ".json");
    }
  }); // GET /1/user/-/foods/log/caloriesIn/date/2010-08-11/2010-08-15.json


  _exports.default = _default;
});