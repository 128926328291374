define("fitbit-site-ui/routes/logout", ["exports", "fitbit-site-ui/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    beforeModel: function beforeModel(transition) {
      var session = Ember.get(this, 'session');
      /**
       * If the session is authenticated, invalidate. Otherwise transition to login.
       * IS_WEIGHTSITE build should load /login page with no redirects.
       */

      if (Ember.get(session, 'isAuthenticated')) {
        if (IS_WEIGHTSITE) {
          transition.abort();
          window.location.href = '/logout';
        } else {
          return session.invalidate();
        }
      } else {
        if (IS_WEIGHTSITE) {
          transition.abort();
          window.location.href = '/login';
          return;
        }

        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});