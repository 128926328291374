define("fitbit-site-ui/serializers/feature", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {
        flags: payload.features,
        id: id
      };
      return this._super(store, primaryModelClass, {
        feature: newPayload
      }, id, requestType);
    }
  });

  _exports.default = _default;
});