define("fitbit-site-ui/serializers/activity-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _emberData.default.RESTSerializer.extend(EmbeddedRecordsMixin, {
    attrs: {
      list: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var nextRecordUrl = payload.pagination.next;
      var newPayload = {
        // Create new activity-set based on new activities
        'activity-list': {
          id: id,
          user: id,
          list: payload.activities,
          nextRecordUrl: nextRecordUrl,
          hasMoreActivities: payload.activities.length >= 10 // If 10 activities are fetched

        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});