define("fitbit-site-ui/serializers/food-note", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/constants/food-logging"], function (_exports, _application, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // Remove properties not necessary for back end


      delete json.foodNoteDate; // Translate front end meal type to back end meal type

      json.mealType = _foodLogging.MEAL_TYPE_TO_BE[json.mealType];
      return json;
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      Object.assign(hash, this.serialize(snapshot, options));
    },
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, recordId, requestType) {
      var id = Ember.get(payload, 'note.id');
      var date = Ember.get(payload, 'date');
      var mealType = Ember.get(payload, 'note.mealType');
      Ember.set(payload, 'note.mealType', _foodLogging.BE_TO_MEAL_TYPE[mealType]);
      var newPayload = {
        'food-note-date': {
          id: date,
          notes: [id]
        },
        'food-note': Ember.get(payload, 'note')
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, recordId, requestType) {
      var id = Ember.get(payload, 'note.id');
      var mealType = Ember.get(payload, 'note.mealType');
      Ember.set(payload, 'note.mealType', _foodLogging.BE_TO_MEAL_TYPE[mealType]);
      var newPayload = {
        'food-note': Ember.get(payload, 'note')
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});