define("fitbit-site-ui/serializers/food-date-log", ["exports", "fitbit-site-ui/serializers/application", "ember-data", "snapdragon-common/constants/food-logging"], function (_exports, _application, _emberData, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    currentUser: Ember.inject.service(),
    attrs: {
      foodLogs: {
        key: 'foods',
        embedded: 'always'
      }
    },

    /**
     * Given an array of notes for a date, set their foodNoteDate to passed in date
     * and return an array of each note's id
     * @param notes - array of notes
     * @param date - YYYY-MM-DD date string corresponding to associated foodNoteDate id
     * @returns {Array} - note ids
     * @private
     */
    _getNoteIdsAndSetNoteDate: function _getNoteIdsAndSetNoteDate(notes, date) {
      var noteIds = notes.map(function (note) {
        Ember.set(note, 'date', date);
        Ember.set(note, 'foodNoteDate', date);
        var mealType = Ember.get(note, 'mealType');
        Ember.set(note, 'mealType', _foodLogging.BE_TO_MEAL_TYPE[mealType]);
        return Ember.get(note, 'id');
      });
      return noteIds;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var userId = Ember.get(this, 'currentUser.id');
      var notes = Ember.get(payload, 'notes') || [];

      var noteIds = this._getNoteIdsAndSetNoteDate(notes, id);

      var foods = Ember.get(payload, 'foods') || [];
      var foodItems = [];
      var loggedFoods = [];
      /**
       * Serialize foods for food-log model.
       * Each item in the foods array are references to items in food date logs, which will be included in the store
       * because food-date-log serializer marks `foods` attr as always embedded.
       */

      foods.forEach(function (food) {
        if (!food.isQuickCaloriesAdd) {
          if (food.food) {
            foodItems.push(food.food);
          }
          /**
           * Fix for 3rd party integration where foodId === 0
           */


          if (!food.loggedFood.foodId) {
            food.loggedFood.foodId = "".concat(food.logDate, "-").concat(food.loggedFood.mealType);
          }

          loggedFoods.push(food.loggedFood);
        }
      });
      var newPayload = {
        'food-date-log': _objectSpread({
          id: id,
          date: id,
          foodGoal: userId,
          caloriesOut: id,
          foodNoteDate: id
        }, payload),
        'food-note-date': {
          id: id,
          notes: noteIds
        },
        'food-notes': notes,

        /**
         * Removed the neeed for adding food-logs here, otherwise it will be processed and "included" twice because
         * the food logs are always embedded in the food-date-logs.
         */
        // 'food-logs': [
        //     ...foods
        // ],
        'food/food-items': foodItems.concat(),
        'food/logged-foods': loggedFoods.concat()
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },

    /**
     * Normalizes response of listByDay for food-date-log and food-log models
     * @param store
     * @param primaryModelClass
     * @param payload
     * @param id
     * @param requestType
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var userId = Ember.get(this, 'currentUser.id');
      var _payload$days = payload.days,
          days = _payload$days === void 0 ? [] : _payload$days,
          pagination = payload.pagination;
      var foods = [];
      var foodItems = [];
      var loggedFoods = [];
      var caloriesOutIds = []; // Maps date to array of { date, notes: [ array of associated food note ids ] }

      var foodNoteDates = []; // Array of all food notes

      var foodNotes = [];

      if (Ember.isPresent(days)) {
        foodNoteDates = days.map(function (day) {
          /**
           * Push the day's `foods` items into the `foods` array s othey can be serialized.
           */
          foods.push.apply(foods, _toConsumableArray(day.foods));
          day.id = day.date;
          day.caloriesOut = day.id;
          day.foodGoal = userId;
          caloriesOutIds.push({
            dateTime: day.id
          }); // Update each note with date and get array of each note id

          var notes = Ember.get(day, 'notes') || [];

          var noteIds = _this._getNoteIdsAndSetNoteDate(notes, day.id); // Add day.notes to array of all notes


          foodNotes = foodNotes.concat(notes);
          return {
            id: day.id,
            notes: noteIds,
            foodDateLog: day.id
          };
        });
      }
      /**
       * Serialize foods for food-log model.
       * Each item in the foods array are references to items in food date logs, which will be included in the store
       * because food-date-log serializer marks `foods` attr as always embedded.
       */


      foods.forEach(function (food) {
        if (!food.isQuickCaloriesAdd) {
          if (food.food) {
            foodItems.push(food.food);
          }
          /**
           * Fix for 3rd party integration where foodId === 0
           */


          if (!food.loggedFood.foodId) {
            food.loggedFood.foodId = "".concat(food.logDate, "-").concat(food.loggedFood.mealType);
          }

          loggedFoods.push(food.loggedFood);
        }
      });
      var newPayload = {
        /**
         * Removed the neeed for adding food-logs here, otherwise it will be processed and "included" twice because
         * the food logs are always embedded in the food-date-logs.
         */
        // 'food-logs': [
        //     ...foods
        // ],
        'food-date-logs': _toConsumableArray(days),
        'food/food-items': foodItems.concat(),
        'food/logged-foods': loggedFoods.concat(),
        'time-series/calorie': caloriesOutIds,
        'food-note': foodNotes,
        'food-note-date': foodNoteDates,
        meta: {
          pagination: pagination
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});