define("fitbit-site-ui/validators/scale-invite", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ScaleInvite = _base.default.extend({
    store: Ember.inject.service(),
    validate: function validate(value, options) {
      var store = this.get('store'),
          //taken from stack overflow
      re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g,
          emailList = value.match(re),
          invitesLeft = Ember.get(options, 'invitesLeft');

      if (emailList) {
        if (invitesLeft - emailList.length < 0) {
          return "You can't add more than 8 users to a scale";
        }

        var invitedUsers = store.peekAll('device/scale/invited-user'); //TODO: Also check emails of scale users
        //Currently the endpoint does not send the user's email in the response so we have no way to check

        var matchingEmails = invitedUsers.reduce(function (listOfMatchingEmails, invitedUser) {
          if (emailList.includes(invitedUser.get('email'))) {
            listOfMatchingEmails.push(invitedUser.get('email'));
          }

          return listOfMatchingEmails;
        }, []);
        return matchingEmails.length ? "Invites have already been sent to: ".concat(matchingEmails.join(', ')) : true;
      }

      return 'Emails must be properly formatted and separated by commas or spaces';
    }
  });

  ScaleInvite.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return [];
    }
  });
  var _default = ScaleInvite;
  _exports.default = _default;
});