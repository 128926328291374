define("fitbit-site-ui/serializers/device/tracker/settings", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    attrs: {
      deviceType: 'version',
      currentSettings: 'settings'
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName);
      var tracker = payload.tracker;
      Ember.assign(tracker, {
        // Setting the device by ID to reference the user-device model.
        device: tracker.id,
        // Lookup options by tracker version.
        options: tracker.id,
        // Lookup alarms by tracker id.
        alarms: tracker.id,
        inactivityAlerts: tracker.id,
        autoExerciseSettings: tracker.id,
        deviceType: tracker.version.replace('_', ' ')
      });

      var data = _defineProperty({}, payloadKey, tracker);

      return this._super(store, primaryModelClass, data, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var _json$settings = json.settings,
          displayDistance = _json$settings.displayDistance,
          displayElevation = _json$settings.displayElevation,
          displayCalories = _json$settings.displayCalories,
          displayClock = _json$settings.displayClock,
          displayEmote = _json$settings.displayEmote,
          displayActiveMinutes = _json$settings.displayActiveMinutes,
          displayGreeting = _json$settings.displayGreeting,
          displaySteps = _json$settings.displaySteps,
          displayHeartRate = _json$settings.displayHeartRate,
          displayBattery = _json$settings.displayBattery,
          displayRemindersToMove = _json$settings.displayRemindersToMove,
          displayRestingHeartRate = _json$settings.displayRestingHeartRate,
          displayUhgFrequency = _json$settings.displayUhgFrequency,
          displayUhgIntensity = _json$settings.displayUhgIntensity,
          displayUhgTenacity = _json$settings.displayUhgTenacity,
          greeting = _json$settings.greeting,
          displayChatter = _json$settings.displayChatter,
          displayTapGesture = _json$settings.displayTapGesture,
          watchCheck = _json$settings.watchCheck,
          watchCheckEnabled = _json$settings.watchCheckEnabled,
          enableAncs = _json$settings.enableAncs,
          handedness = _json$settings.handedness,
          onDominantHand = _json$settings.onDominantHand,
          wearWrist = _json$settings.wearWrist,
          enableSleepAnnotations = _json$settings.enableSleepAnnotations,
          sleepSensitivity = _json$settings.sleepSensitivity,
          primaryGoal = _json$settings.primaryGoal,
          clockFace = _json$settings.clockFace,
          clockApplication = _json$settings.clockApplication,
          screenOrder = _json$settings.screenOrder,
          heartRateTracking = _json$settings.heartRateTracking,
          exercises = _json$settings.exercises,
          exerciseSettings = _json$settings.exerciseSettings,
          enabledNotificationTypes = _json$settings.enabledNotificationTypes,
          autoLapInterval = _json$settings.autoLapInterval,
          enableInactivityAlerts = _json$settings.enableInactivityAlerts,
          smsPrivateFormat = _json$settings.smsPrivateFormat,
          exerciseIntervalTimer = _json$settings.exerciseIntervalTimer,
          enabledWidgets = _json$settings.enabledWidgets,
          tapGesture = _json$settings.tapGesture,
          liveDataDisabled = _json$settings.liveDataDisabled; // Send the clockface name.

      if (clockFace) {
        clockFace = clockFace.name;
      }

      if (exerciseIntervalTimer) {
        exerciseIntervalTimer = JSON.stringify(exerciseIntervalTimer);
      }

      if (exerciseSettings) {
        exerciseSettings = JSON.stringify(exerciseSettings);
      }

      return {
        displayDistance: displayDistance,
        displayElevation: displayElevation,
        displayCalories: displayCalories,
        displayClock: displayClock,
        displayEmote: displayEmote,
        displayActiveMinutes: displayActiveMinutes,
        displayGreeting: displayGreeting,
        displaySteps: displaySteps,
        displayHeartRate: displayHeartRate,
        displayBattery: displayBattery,
        displayRemindersToMove: displayRemindersToMove,
        displayRestingHeartRate: displayRestingHeartRate,
        displayUhgFrequency: displayUhgFrequency,
        displayUhgIntensity: displayUhgIntensity,
        displayUhgTenacity: displayUhgTenacity,
        greeting: greeting,
        displayChatter: displayChatter,
        displayTapGesture: displayTapGesture,
        watchCheck: watchCheck,
        watchCheckEnabled: watchCheckEnabled,
        enableAncs: enableAncs,
        handedness: handedness,
        onDominantHand: onDominantHand,
        wearWrist: wearWrist,
        enableSleepAnnotations: enableSleepAnnotations,
        sleepSensitivity: sleepSensitivity,
        primaryGoal: primaryGoal,
        clockFace: clockFace,
        clockApplication: clockApplication,
        screenOrder: screenOrder,
        heartRateTracking: heartRateTracking,
        exercises: exercises,
        exerciseSettings: exerciseSettings,
        enabledNotificationTypes: enabledNotificationTypes,
        autoLapInterval: autoLapInterval,
        enableInactivityAlerts: enableInactivityAlerts,
        smsPrivateFormat: smsPrivateFormat,
        exerciseIntervalTimer: exerciseIntervalTimer,
        enabledWidgets: enabledWidgets,
        tapGesture: tapGesture,
        liveDataDisabled: liveDataDisabled
      };
    }
  });

  _exports.default = _default;
});