define("fitbit-site-ui/utils/load-google-maps", ["exports", "ember-cli-g-maps/utils/load-google-maps"], function (_exports, _loadGoogleMaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loadGoogleMaps.default;
    }
  });
});