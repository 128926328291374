define("fitbit-site-ui/templates/settings/activity-goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XArmMx3J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"activity-goals\"],[9],[0,\"\\n    \"],[1,[27,\"activity-goals\",null,[[\"save\",\"activityGoals\",\"inactivityGoals\",\"distanceUnit\"],[[27,\"action\",[[22,0,[]],\"save\"],null],[23,[\"model\",\"activityGoals\"]],[23,[\"model\",\"inactivityGoals\"]],[23,[\"currentUser\",\"distanceUnit\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/activity-goals.hbs"
    }
  });

  _exports.default = _default;
});