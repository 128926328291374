define("fitbit-site-ui/models/weight-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    bmi: attr('number'),
    date: attr(),
    fat: attr('number', {
      defaultValue: null
    }),
    logId: attr(),
    time: attr(),
    weight: attr('number'),
    source: attr(),

    /**
     * Returns whether weight record is today.
     */
    isToday: Ember.computed('date', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'moment', 'YEAR_MONTH_DAY', 'date'),
          moment = _EmberGetProperties.moment,
          YEAR_MONTH_DAY = _EmberGetProperties.YEAR_MONTH_DAY,
          date = _EmberGetProperties.date;

      return moment.moment(date, YEAR_MONTH_DAY).isSame(new Date(), 'days');
    })
  });

  _exports.default = _default;
});