define("fitbit-site-ui/components/profile-settings-heart-rate", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    customHrMin: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 30,
      lt: Ember.computed.readOnly('model.customHrMax'),
      message: Ember.computed(function () {
        return this.get('model.i18n').format('custom_hr_zone_min_validation', {
          min: '{gte}',
          max: '220'
        });
      })
    }),
    customHrMax: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: Ember.computed.readOnly('model.customHrMin'),
      lte: 220,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('custom_hr_zone_max_validation', {
          min: '{gt}',
          max: '{lte}'
        });
      })
    }),
    customMaxHr: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 30,
      lte: 220,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('max_hr_validation', {
          min: '{gte}',
          max: '{lte}'
        });
      })
    })
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    customMaxHr: Ember.computed.oneWay('profile.customMaxHeartRate.maxHeartRate'),
    customHrMax: Ember.computed.oneWay('profile.customHeartRateZone.max'),
    customHrMin: Ember.computed.oneWay('profile.customHeartRateZone.min'),
    keyDown: function keyDown(e) {
      // If it's the enter key, do a validation check before bubbling up.
      if (e.keyCode === 13) {
        var _this$validateSync = this.validateSync(),
            validations = _this$validateSync.validations;

        if (!validations.get('isValid')) {
          e.preventDefault();
        }
      }
    },
    actions: {
      heartRateChanged: function heartRateChanged() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.set('profile.customMaxHeartRate.maxHeartRate', _this.get('customMaxHr'));

            _this.set('profile.customHeartRateZone.max', _this.get('customHrMax'));

            _this.set('profile.customHeartRateZone.min', _this.get('customHrMin'));

            _this.get('heartRateUpdatedFnc')(true);
          } else {
            //Means that we don't have valid input in all the heart rate fields
            //Need to postpone the request
            _this.get('heartRateUpdatedFnc')(false);
          }
        });
      },
      heartRateToggled: function heartRateToggled() {
        var heartRateEnabled = this.get('profile.customHeartRateZone.enabled'),
            heartRateZonesEnabled = this.get('profile.customMaxHeartRate.enabled'); //Reset these properties in case there was some bad input before the user toggled to Default

        if (!heartRateEnabled) {
          this.setProperties({
            customHrMax: 31,
            customHrMin: 30
          });
        }

        if (!heartRateZonesEnabled) {
          this.set('customMaxHr', 30);
        }

        this.send('heartRateChanged');
      }
    }
  });

  _exports.default = _default;
});