define("fitbit-site-ui/models/user-props", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    userProperties: (0, _attributes.fragmentArray)('user-property'),
    properties: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    prettyProperties: Ember.computed('properties', function () {
      var properties = Ember.get(this, 'properties');
      return JSON.stringify(properties, null, ' ');
    })
  });

  _exports.default = _default;
});