define("fitbit-site-ui/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ProxyMixin = Ember._ProxyMixin;
  /**
   * Current User service for accessing the current user that is logged in.
   * It's risky to use `_ProxyMixin` because it's private. Currently using it until the usage of currentUser
   * is updated so that the profile is accessed as a property of `currentUser`.
   */

  var _default = Ember.Service.extend(_ProxyMixin, {
    isServiceFactory: true,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    load: function load() {
      var _this = this;

      var _EmberGet = Ember.get(this, 'session.data.authenticated'),
          userID = _EmberGet.user_id;

      return Ember.get(this, 'store').findRecord('profile', userID).then(function (user) {
        Ember.set(_this, 'content', user);
        return user;
      });
    }
  });

  _exports.default = _default;
});