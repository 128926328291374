define("fitbit-site-ui/constants/settings/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE_EMAIL = _exports.TYPE_PUSH = _exports.STATE_READ_ONLY = _exports.STATE_OFF = _exports.STATE_ON = void 0;
  var STATE_ON = 'on';
  _exports.STATE_ON = STATE_ON;
  var STATE_OFF = 'off';
  _exports.STATE_OFF = STATE_OFF;
  var STATE_READ_ONLY = 'readOnlyOff';
  _exports.STATE_READ_ONLY = STATE_READ_ONLY;
  var TYPE_PUSH = 'push';
  _exports.TYPE_PUSH = TYPE_PUSH;
  var TYPE_EMAIL = 'email';
  _exports.TYPE_EMAIL = TYPE_EMAIL;
});