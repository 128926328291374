define("fitbit-site-ui/controllers/settings/profile-settings", ["exports", "snapdragon-common/mixins/email-actions"], function (_exports, _emailActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_emailActions.default, {
    actions: {
      calloutDismissed: function calloutDismissed() {
        this.set('updatedProperties', null);
      },
      errorsDismissed: function errorsDismissed() {
        this.set('errors', null);
      },
      runCitySearch: function runCitySearch(query, asyncResults) {
        this.model.store.query('profile-location', {
          filter: {
            query: query
          }
        }).then(function (results) {
          var cities = [];
          results.forEach(function (result) {
            cities.push(result.get('city'));
          });
          asyncResults(cities);
        });
      },
      citySearch: function citySearch(query, syncResults, asyncResults) {
        // Create a specific query string like "San Francisco, CA" (NOTE: State should/must always be present)
        var state = this.model.get('state');
        var queryString = query + ', ' + state;
        Ember.run.debounce(this, this.actions.runCitySearch, queryString, asyncResults, 250);
      },
      saveEmailAddress: function saveEmailAddress(data) {
        return this._saveEmailAddress(data);
      },
      resendEmailAddress: function resendEmailAddress() {
        return this._resendEmailAddress();
      },
      cancelEmailAddress: function cancelEmailAddress() {
        return this._cancelEmailAddress();
      }
    }
  });

  _exports.default = _default;
});