define("fitbit-site-ui/routes/settings/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    titleToken: Ember.computed(function () {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('route_title', 'route.settings.devices');
    }),
    model: function model() {
      return this.modelFor('settings');
    }
  });

  _exports.default = _default;
});