define("fitbit-site-ui/routes/settings/application-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    titleToken: function titleToken() {
      var formatter = Ember.get(this, 'i18n');
      return formatter.formatByNamespace('route_title', 'route.settings.application-settings');
    },
    model: function model() {
      var _this = this;

      return this.store.findAll('application-authorization').then(function () {
        return _this.store.peekAll('my-application');
      });
    },
    afterModel: function afterModel() {
      Ember.set(this, 'breadCrumb', {
        title: this.titleToken()
      });
    }
  });

  _exports.default = _default;
});