define("fitbit-site-ui/models/journal-survey-question-single-choice", ["exports", "fitbit-site-ui/models/journal-survey-question"], function (_exports, _journalSurveyQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _journalSurveyQuestion.default.extend({
    currentlySelectedResponse: null,
    setResponse: function setResponse(newSelectedResponse) {
      var currentlySelectedResponse = this.currentlySelectedResponse;

      if (currentlySelectedResponse && currentlySelectedResponse !== newSelectedResponse) {
        Ember.set(currentlySelectedResponse, 'selected', false);
      }

      Ember.set(newSelectedResponse, 'selected', !newSelectedResponse.selected);
      Ember.set(this, 'currentlySelectedResponse', newSelectedResponse);
    }
  });

  _exports.default = _default;
});