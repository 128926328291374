define("fitbit-site-ui/serializers/water-goal", ["exports", "fitbit-site-ui/serializers/application", "snapdragon-common/utils/unit-converter", "snapdragon-common/constants/food-logging"], function (_exports, _application, _unitConverter, _foodLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var goal = payload.goal;
      var waterUnit = Ember.get(this, 'currentUser.waterUnit');
      var currentUserId = Ember.get(this, 'currentUser.id'); // Goal ML

      var goalMl = Ember.get(goal, 'goal') || _foodLogging.DEFAULT_WATER_GOAL_ML; // Goal OZ


      var goalOz = _unitConverter.default.mlToOz(goalMl, 1) || _foodLogging.DEFAULT_WATER_GOAL_OZ; // Goal Cup


      var goalCup = _unitConverter.default.mlToCups(goalMl, 2) || _foodLogging.DEFAULT_WATER_GOAL_CUP;

      var goalAmount;

      switch (waterUnit) {
        case 'METRIC':
          goalAmount = goalMl;
          break;

        case 'en_US':
          goalAmount = goalOz;
          break;

        case 'en_US_cup':
          goalAmount = goalCup;
          break;
      }

      var newPayload = {
        'water-goal': {
          startDate: Ember.get(goal, 'startDate'),
          goalAmount: goalAmount,
          goalMl: goalMl,
          goalOz: goalOz,
          goalCup: goalCup,
          id: currentUserId
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },

    /**
     * Based on Headers, alwasys end Ml value
     * @returns {Object}
     */
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var goalValue = Ember.get(json, 'goalMl');
      Ember.set(json, 'waterGoal', goalValue);
      Ember.set(json, 'goal', goalValue);
      delete json.goalMl;
      delete json.goalOz;
      delete json.startDate;
      return goalValue;
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var goalValue = this.serialize(snapshot, options); // Validation for waterGoal throws errors from backend

      Ember.set(hash, 'goal', goalValue);
      Ember.set(hash, 'waterGoal', {
        waterGoal: goalValue,
        goal: goalValue,
        'water-goal': goalValue
      });
      Ember.set(hash, 'water-goal', goalValue);
    }
  });

  _exports.default = _default;
});