define("fitbit-site-ui/models/friend/leaderboard/inactive-user", ["exports", "fitbit-site-ui/models/friend/leaderboard/base-user"], function (_exports, _baseUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUser.default.extend({});

  _exports.default = _default;
});