define("fitbit-site-ui/components/accordion-list", ["exports", "ember-accordion/components/accordion-list"], function (_exports, _accordionList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _accordionList.default;
    }
  });
});