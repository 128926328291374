define("fitbit-site-ui/serializers/search-food", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // search-food has a different set of properties than food-item,
  // so a summary of data is displayed, get-food endpoint is required to get the corresponding food-item
  var _default = _application.default.extend({
    primaryKey: 'foodId'
  });

  _exports.default = _default;
});