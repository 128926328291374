define("fitbit-site-ui/models/activity-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // id is date
    //-- summary of running totals for the day so far --//
    stepsToday: _emberData.default.attr('number'),
    caloriesToday: _emberData.default.attr('number'),
    activeMinutesToday: _emberData.default.attr('number') // value is in actual minutes

  });

  _exports.default = _default;
});