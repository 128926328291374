define("fitbit-site-ui/services/mobile-view", ["exports", "ember-window-mock", "snapdragon-common/constants/user-agent"], function (_exports, _emberWindowMock, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Names of tags that need keyboard.
   *
   * **Note**. Although `select` does not need keyboard on Android, iOS still requires it.
   */
  var TAG_NAMES_FOR_KEYBOARD = {
    input: true,
    select: true,
    textarea: true
  };
  /**
   * Approximate size of device-specific panels (typically displayed at the top or bottom of the screen).
   */

  var DOCK_SIZE = 150;
  /**
   * This function checks if currently active element needs keyboard.
   */

  function needsKeyboardForActiveElement() {
    var activeEl = document.activeElement;

    if (!activeEl) {
      return false;
    }

    var tagName = activeEl.tagName.toLowerCase();
    return TAG_NAMES_FOR_KEYBOARD[tagName] || false;
  }
  /**
   * Checks if height of window was shrinked.
   * If height was shrinked we can suppose that keyboard is shown.
   *
   * **Note.** Currently this function can be used only for Android.
   *
   * It is not clear how to determine screen height on iOS in both portrait and landscape modes:
   * - regardless device orientation `screen.width` and `screen.height` are not modified
   * - `window.orientation` is deprecated way to determine device orientation
   * - `screen.orientation` is not supported on iOS
   * - it seems `window.matchMedia("(orientation: portrait)").matches` could be used for iOS devices,
   *   but `window.innerWidth`/`window.innerHeight` may change depending on scale level.
   */


  function isViewportHeightShrinked() {
    return _emberWindowMock.default.innerHeight < screen.height - DOCK_SIZE;
  }

  var _default = Ember.Service.extend({
    userAgent: Ember.inject.service('user-agent'),
    _isKeyboardOpened: null,

    /**
     * This flag identifies if keyboard is shown on Mobile.
     *
     * There is no reliable way to determine whether keyboard is shown or do not.
     * The logic underlying this flag relies on some heuristic and should work in the most cases.
     *
     * Thereby, developer should not heavily rely on this flag
     * and should consider it like the way to **improve UX**, no more.
     */
    isKeyboardOpened: Ember.computed({
      get: function get() {
        // Start to track isKeyboardOpened if this value was requested at least once
        if (this._isKeyboardOpened != null) {
          return this._isKeyboardOpened;
        } else {
          this._trackKeyboardOnMobile();

          this._isKeyboardOpened = this._checkKeyboardOpened();
          return this._isKeyboardOpened;
        }
      },
      set: function set(_, value) {
        this._isKeyboardOpened = value;
        return value;
      }
    }),
    init: function init() {
      var webStorage = _emberWindowMock.default.sessionStorage;
      /**
       * Wrapper for window.sessionStorage, providing a simple interface
       * @type {Object}
       * @private
       */

      this._webStorage = {
        isAvailable: !!webStorage,
        getValue: function getValue(key) {
          return webStorage.getItem(key);
        },
        setValue: function setValue(key, value) {
          webStorage.setItem(key, value);
        }
      };

      this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      this._stopTrackingKeyboardOnMobile();

      this._super.apply(this, arguments);
    },

    /**
     * Inner value of isWebView computed property
     * @type {Boolean}
     * @private
     */
    _innerValue: false,

    /**
     * Indicates whether application is used inside mobile's WebView
     * Saves value to _innervalue
     * Persists its state to webStorage if it is available
     * @type {Boolean}
     */
    isWebView: Ember.computed({
      get: function get(key) {
        return this._webStorage.isAvailable ? this._webStorage.getValue(key) === 'true' : this._innerValue;
      },
      set: function set(key, value) {
        this._innerValue = value;

        if (this._webStorage.isAvailable) {
          this._webStorage.setValue(key, value);
        }

        return value;
      }
    }),

    /**
     * Returns true if application is opened on mobile screen, otherwise false
     * @type {Boolean}
     */
    isMobile: Ember.computed('isWebView', function () {
      return this.isWebView || _userAgent.IS_MOBILE;
    }),
    _trackKeyboardOnMobile: function _trackKeyboardOnMobile() {
      var _this = this;

      if (!this.isMobile) {
        return;
      }

      var listener = function listener() {
        return _this.set('isKeyboardOpened', _this._checkKeyboardOpened());
      };

      this.set('_keyboardListener', listener);
      /**
       * On Android devices viewport is resized when keyboard is opened.
       */

      _emberWindowMock.default.addEventListener('resize', listener);
      /**
       * WebKit engine on iOS devices does not resize viewport when keyboard is opened.
       * But we can catch the moment when keyboard should be shown/hidden
       * by listening to focus/blur events respectively.
       */


      _emberWindowMock.default.document.addEventListener('focus', listener, true);

      _emberWindowMock.default.document.addEventListener('blur', listener, true);
    },
    _stopTrackingKeyboardOnMobile: function _stopTrackingKeyboardOnMobile() {
      var _keyboardListener = this._keyboardListener;

      if (_keyboardListener) {
        _emberWindowMock.default.removeEventListener('resize', _keyboardListener);

        _emberWindowMock.default.document.removeEventListener('focus', _keyboardListener, true);

        _emberWindowMock.default.document.removeEventListener('blur', _keyboardListener, true);

        this.set('_keyboardListener', null);
      }
    },

    /**
     * This method does its best to determine if keyboard is shown on mobile.
     */
    _checkKeyboardOpened: function _checkKeyboardOpened() {
      if (this.userAgent.os.isIOS) {
        /**
         * If input/select/textarea is focused on iOS device => keyboard should be shown
         */
        return needsKeyboardForActiveElement();
      } else if (this.userAgent.os.isAndroid) {
        /**
         * On Android device input/select/textarea can be focused, but keyboard may be not displayed.
         *
         * For example, when keyboard is opened and user presses Back button,
         * keyboard disappears, but focus remains in the field.
         *
         * Thereby, we need additional condition to determine if viewport was shrinked by keyboard.
         */
        return needsKeyboardForActiveElement() && isViewportHeightShrinked();
      } else {
        return false;
      }
    }
  });

  _exports.default = _default;
});