define("fitbit-site-ui/routes/settings/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.i18n.formatByNamespace('route_title', 'route.settings.data');
    },
    breadCrumb: Ember.computed(function () {
      return {
        title: this.titleToken()
      };
    })
  });

  _exports.default = _default;
});