define("fitbit-site-ui/services/no-auth-ajax", ["exports", "ember-ajax/services/ajax", "fitbit-site-ui/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extension of Ember AJAX service, similar to ./ajax, but does not provide auth headers.
   * Clients of this service are responsible for providing their own auth headers if needed.
   *
   * @extends AjaxService
   */
  var _default = _ajax.default.extend({
    host: _environment.default.host,
    namespace: '/1'
  });

  _exports.default = _default;
});