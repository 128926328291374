define("fitbit-site-ui/templates/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXaiZjm1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"onboarding-page\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"onboarding-panel\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/onboarding.hbs"
    }
  });

  _exports.default = _default;
});