define("fitbit-site-ui/serializers/fitbit-food", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'foodId',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.isPresent(payload)) {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      var food = payload.food;
      var newPayload = {
        'food/food-item': food,
        'fitbit-food': {
          foodItem: food.foodId,
          foodId: food.foodId,
          servings: food.servings,
          nutritionalValues: food.nutritionalValues
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});