define("fitbit-site-ui/helpers/get-belongs-to-id", ["exports", "snapdragon-common/helpers/get-belongs-to-id"], function (_exports, _getBelongsToId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getBelongsToId.default;
    }
  });
  Object.defineProperty(_exports, "getBelongsToId", {
    enumerable: true,
    get: function get() {
      return _getBelongsToId.getBelongsToId;
    }
  });
});