define("fitbit-site-ui/models/device/tracker/alarm", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    deleted: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    enabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    recurring: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    snoozeCount: (0, _attr.default)('number'),
    snoozeLength: (0, _attr.default)('number'),
    syncedToDevice: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    time: (0, _attr.default)('string'),
    // "21:30-07:00"
    vibe: (0, _attr.default)('string', {
      defaultValue: 'DEFAULT'
    }),
    weekDays: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    device: (0, _relationships.belongsTo)('user-device'),
    list: (0, _relationships.belongsTo)('device/tracker/alarm/list')
  });

  _exports.default = _default;
});