define("fitbit-site-ui/components/device-customize-display-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    isEditingGreeting: false,
    greetingMaxLength: 8,
    hasChatter: Ember.computed('displayChatter', function () {
      var displayChatter = Ember.get(this, 'displayChatter');
      return displayChatter !== undefined;
    }),
    hasGreeting: Ember.computed('displayGreeting', function () {
      var displayGreeting = Ember.get(this, 'displayGreeting');
      return displayGreeting !== undefined;
    }),
    greetingText: Ember.computed.oneWay('greeting'),
    greetingCharCount: Ember.computed('greetingText', 'greetingMaxLength', function () {
      var greeting = Ember.get(this, 'greetingText');
      var greetingMax = Ember.get(this, 'greetingMaxLength');
      var greetingLength = greeting.length;
      return greetingMax - greetingLength;
    }),
    onSettingsChanged: function onSettingsChanged() {},
    saveAction: function saveAction() {
      var _this = this;

      var formatter = this.get('i18n');
      return Ember.RSVP.resolve(Ember.get(this, 'onSettingsChanged')()).then(function () {
        _this.showFlashMessage('success', formatter.format('updated_settings'));
      }).catch(function (err) {
        var error = err && err.errors[0];
        var errorMessage = error.message || formatter.format('device_setting_saved');

        _this.showFlashMessage('alert', errorMessage);

        return Ember.RSVP.reject(err);
      });
    },
    _generateOrderByType: function _generateOrderByType(items) {
      return items.mapBy('type').join(',');
    },
    actions: {
      reorderItems: function reorderItems(items) {
        var _this2 = this;

        var prevItems = this.get('customizableScreens.screens');
        var currentSettings = this.get('device.settings.currentSettings');
        Ember.set(currentSettings, 'screenOrder', this._generateOrderByType(items)); // Save the changes. Revert screen order if there is a saving error.

        this.saveAction().catch(function () {
          Ember.set(currentSettings, 'screenOrder', _this2._generateOrderByType(prevItems));
        });
      },
      toggleChatter: function toggleChatter() {
        this.toggleProperty('displayChatter');
        this.saveAction();
      },
      toggleGreeting: function toggleGreeting() {
        this.toggleProperty('displayGreeting');
        this.saveAction();
      },
      toggleDisplay: function toggleDisplay(display) {
        display.toggleProperty('value');
        this.saveAction();
      },
      toggleEditGreeting: function toggleEditGreeting() {
        this.toggleProperty('isEditingGreeting');
      },
      saveGreeting: function saveGreeting(value) {
        Ember.set(this, 'greeting', value.toUpperCase());
        this.saveAction();
      },
      cancelGreetingEdit: function cancelGreetingEdit() {
        // Toggle the edit greeting form to close.
        this.toggleProperty('isEditingGreeting'); // Reset the oneWay greeting

        Ember.set(this, 'greetingText', Ember.get(this, 'greeting'));
      }
    }
  });

  _exports.default = _default;
});