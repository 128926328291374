define("fitbit-site-ui/templates/fhs-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBvLftml",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[1,[23,[\"model\",\"programMembership\"]],false],[0,\"\\n\"],[1,[23,[\"model\",\"programMemberships\"]],false],[0,\"\\n\"],[1,[23,[\"model\",\"programFeature\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/fhs-test.hbs"
    }
  });

  _exports.default = _default;
});