define("fitbit-site-ui/serializers/weight-time", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'dateTime',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var modelName = 'weight-time';

      if (payloadKey !== 'body-weight') {
        modelName = this._super.apply(this, arguments);
      }

      return modelName;
    }
  });

  _exports.default = _default;
});