define("fitbit-site-ui/models/food-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    foodNoteDate: _emberData.default.belongsTo('food-note-date'),
    date: _emberData.default.attr('string'),
    mealType: _emberData.default.attr('string'),
    // ANYTIME, BREAKFAST, etc
    message: _emberData.default.attr('string'),
    updatedTime: _emberData.default.attr()
  });

  _exports.default = _default;
});