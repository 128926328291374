define("fitbit-site-ui/components/friends-list-item", ["exports", "snapdragon-common/mixins/animations-mixin", "fitbit-site-ui/constants/friends"], function (_exports, _animationsMixin, _friends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_animationsMixin.default, {
    tagName: '',
    viewport: Ember.inject.service('device/layout'),
    currentUser: Ember.inject.service(),
    userId: Ember.computed.readOnly('user.user.id'),
    isCurrentUser: Ember.computed('currentUser.id', 'user.user.id', function () {
      var currentUserId = Ember.get(this, 'currentUser.id');
      var userId = Ember.get(this, 'user.user.id');
      return currentUserId.trim() === userId.trim();
    }),

    /**
     * Either 'cheered' or 'taunted'
     * Consumed by user-action-notification-card to determine
     * Taunted or Cheered fitglphy and text
     * Set to 'cheered' by default because of translation
     */
    actionType: _friends.ACTION_TYPE_CHEERED,

    /**
     * References to taunt, cheer, and expand button elements to animate
     * with failure state when taunt or cheer throws error
     */
    cheerButton: null,
    tauntButton: null,
    expandButton: null,

    /**
     * References to user-action-notification elements to animate
     * when a taunt or cheer button is pressed
     */
    cardElement: null,
    iconElement: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var userId = Ember.get(this, 'userId');
      var cheerButton = document.getElementById("cheer-button-".concat(userId)) || null;
      Ember.set(this, 'cheerButton', cheerButton);
      var tauntButton = document.getElementById("taunt-button-".concat(userId)) || null;
      Ember.set(this, 'tauntButton', tauntButton);
      var expandButton = document.getElementById("expand-button-".concat(userId)) || null;
      Ember.set(this, 'expandButton', expandButton);
      var cardElement = document.getElementById("user-action-notification-".concat(userId)) || null;
      Ember.set(this, 'cardElement', cardElement);
      var iconElement = document.getElementById("user-action-notification-icon-".concat(userId)) || null;
      Ember.set(this, 'iconElement', iconElement);
    },

    /**
     * Function stubs passed from parent
     * Called by onSendCheerToId and onSendTauntToId
     * when clicking cheer to taunt buttons
     */
    sendCheerToId: function sendCheerToId() {},
    sendTauntToId: function sendTauntToId() {},
    actions: {
      onSendCheerToId: function onSendCheerToId() {
        var _this = this;

        Ember.set(this, 'actionType', _friends.ACTION_TYPE_CHEERED);
        return Ember.RSVP.resolve(this.sendCheerToId.apply(this, arguments)).then(function () {
          var cardElement = Ember.get(_this, 'cardElement');
          var iconElement = Ember.get(_this, 'iconElement'); // If the card element exists, cause it to pop out from the right hand side

          if (cardElement) {
            _this.actions.notificationCardPopout(cardElement);

            if (iconElement) {
              _this.actions.spinAndGrow(iconElement);
            }
          }
        }).catch(function () {
          // On small screens, animate the expand button, on desktop expand the cheer button
          var viewport = Ember.get(_this, 'viewport');
          var buttonToAnimate = Ember.get(viewport, 'isAtLeastTablet') ? Ember.get(_this, 'cheerButton') : Ember.get(_this, 'expandButton');

          if (buttonToAnimate) {
            _this.actions.pulseAnimation(buttonToAnimate, true);
          }
        });
      },
      onSendTauntToId: function onSendTauntToId() {
        var _this2 = this;

        Ember.set(this, 'actionType', _friends.ACTION_TYPE_TAUNTED);
        return Ember.RSVP.resolve(this.sendTauntToId.apply(this, arguments)).then(function () {
          var cardElement = Ember.get(_this2, 'cardElement');
          var iconElement = Ember.get(_this2, 'iconElement'); // If the card element exists, cause it to pop out from the right hand side

          if (cardElement) {
            _this2.actions.notificationCardPopout(cardElement);

            if (iconElement) {
              _this2.actions.spinAndGrow(iconElement);
            }
          }
        }).catch(function () {
          // On small screens, animate the expand button, on desktop expand the taunt button
          var viewport = Ember.get(_this2, 'viewport');
          var buttonToAnimate = Ember.get(viewport, 'isAtLeastTablet') ? Ember.get(_this2, 'tauntButton') : Ember.get(_this2, 'expandButton');

          if (buttonToAnimate) {
            _this2.actions.pulseAnimation(buttonToAnimate, true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});