define("fitbit-site-ui/helpers/profile-role-view", ["exports", "fitbit-site-ui/constants/profile-roles-permissions"], function (_exports, _profileRolesPermissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.profileViewRoleHelper = profileViewRoleHelper;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Used to determine whether to display a profile area based on privacy roles
   * when a profile owner is switching between friend, public and self roles
   *
   * @param profileViewRole {string}
   * @param privacySetting {string}
   *
   * @returns {boolean}
   */
  function profileViewRoleHelper(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        profileViewRole = _ref2[0],
        privacySetting = _ref2[1];

    // non-owners don't get any view roles
    var notTheProfileOwner = !profileViewRole; // the owner sees all fields

    var isOwnerRole = profileViewRole === _profileRolesPermissions.VIEW_ROLE_OWNER; // friend role gets to see both friend & public permissions

    var friendPermissions = privacySetting === _profileRolesPermissions.PRIVACY_SET_TO_PUBLIC || privacySetting === _profileRolesPermissions.PRIVACY_SET_TO_FRIENDS;
    var isFriendRole = profileViewRole === _profileRolesPermissions.VIEW_ROLE_FRIENDS && friendPermissions; // public role only sees public permissions

    var isPublicRole = profileViewRole === _profileRolesPermissions.VIEW_ROLE_PUBLIC && privacySetting === _profileRolesPermissions.PRIVACY_SET_TO_PUBLIC;
    return notTheProfileOwner || isOwnerRole || isFriendRole || isPublicRole;
  }

  var _default = Ember.Helper.helper(profileViewRoleHelper);

  _exports.default = _default;
});