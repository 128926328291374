define("fitbit-site-ui/components/init-zf", ["exports", "foundation-sites"], function (_exports, _foundationSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component just to trigger foundation when the element is inserted.
   */
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$().foundation();
    }
  });

  _exports.default = _default;
});