define("fitbit-site-ui/routes/settings/activity-goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var userID = Ember.get(this, 'currentUser.id');
      var activityGoals = this.store.findRecord('goal/daily', userID);
      var inactivityGoals = this.store.findRecord('device/tracker/inactivity-alerts', userID);
      return Ember.RSVP.hash({
        activityGoals: activityGoals,
        inactivityGoals: inactivityGoals
      });
    }
  });

  _exports.default = _default;
});