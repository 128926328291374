define("fitbit-site-ui/serializers/time-series", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // This class gets inherited by the files in time-series/*.js serializers
  var _default = _application.default.extend({
    getPayloadKey: function getPayloadKey() {},
    attrs: {},
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.getPayloadKey();

      var newPayload = _defineProperty({}, payloadKey, payload);

      var ret = this._super(store, primaryModelClass, newPayload, id, requestType);

      ret.data.id = id;
      return ret;
    }
  });

  _exports.default = _default;
});