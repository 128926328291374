define("fitbit-site-ui/templates/components/scale-user-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wVCf8m+s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"class\",\"scale-icon\"],[12,\"src\",[28,[[23,[\"icon\",\"imageUrl\"]]]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"iconOwned\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[1,[23,[\"iconOwner\",\"userInfo\",\"displayName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/scale-user-icon.hbs"
    }
  });

  _exports.default = _default;
});