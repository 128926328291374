define("fitbit-site-ui/components/activity-goals", ["exports", "ember-cp-validations", "snapdragon-common/utils/unit-converter"], function (_exports, _emberCpValidations, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cmToMiles = _unitConverter.default.cmToMiles,
      cmToKm = _unitConverter.default.cmToKm,
      milesToCm = _unitConverter.default.milesToCm,
      kmToCm = _unitConverter.default.kmToCm;
  var Validations = (0, _emberCpValidations.buildValidations)({
    steps: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      integer: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('steps_validation_msg', 'templates.settings.goals');
      })
    }),
    distance: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('distance_validation_msg', 'templates.settings.goals');
      })
    }),
    calories: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      integer: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('calories_validator_msg', 'templates.settings.goals');
      })
    }),
    activeMinutes: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      integer: true,
      lte: 24 * 60,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('active_minutes_validation_msg', 'templates.settings.goals');
      })
    }),
    hourlyActiveStart: (0, _emberCpValidations.validator)('date', {
      format: 'HH:mm'
    }),
    hourlyActiveDuration: [(0, _emberCpValidations.validator)('dependent', {
      on: ['hourlyActiveStart']
    }), (0, _emberCpValidations.validator)('number', {
      integer: true,
      gte: 5,
      lte: 14,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('hourly_active_duration_validation_msg', 'templates.settings.goals', {
          start: 5,
          end: 14
        });
      })
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    constants: Ember.inject.service(),
    isLoading: false,
    save: function save() {
      return Ember.RSVP.resolve();
    },
    steps: Ember.computed.oneWay('activityGoals.steps'),
    distance: Ember.computed.oneWay('activityGoals.distance'),
    calories: Ember.computed.oneWay('activityGoals.caloriesOut'),
    activeMinutes: Ember.computed.oneWay('activityGoals.activeMinutes'),
    hourlyActiveDuration: Ember.computed.oneWay('inactivityGoals.duration'),
    hourlyActiveStart: Ember.computed.oneWay('inactivityGoals.startTime'),
    distanceUnitFormated: Ember.computed('activitySet.user', function () {
      var formatter = Ember.get(this, 'i18n');
      var distanceUnit = Ember.get(this, 'distanceUnit');

      if (distanceUnit === 'en_US') {
        return formatter.format('distance_US');
      } else {
        return formatter.format('distance_symbol_GB');
      }
    }),
    distanceConverted: Ember.computed('activityGoals.distance', {
      get: function get() {
        // Convert Centimeters to Miles or Kilomters
        var distanceUnit = Ember.get(this, 'distanceUnit');
        var cm = Ember.get(this, 'activityGoals.distance');

        if (distanceUnit === 'en_US') {
          // Convert Centimeters to Miles
          return cmToMiles(cm, 2);
        } else {
          // Convert Centimters to Kilomters
          return cmToKm(cm, 2);
        }
      },
      set: function set(key, value) {
        var distanceUnit = Ember.get(this, 'distanceUnit');

        if (distanceUnit === 'en_US') {
          // Convert Miles to Centimeters
          Ember.set(this, 'distance', milesToCm(value));
        } else {
          // Convert Kilomters to Centimeters
          Ember.set(this, 'distance', kmToCm(value));
        }

        return value;
      }
    }),
    hours: Ember.computed({
      get: function get() {
        var moment = Ember.get(this, 'moment');
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d = moment.moment(d);
        var hours = [];
        var maxHours = 24;
        var format = 'HH:mm';

        if (Ember.get(this, 'using12HourClock')) {
          maxHours = 12;
          format = 'hh:mm';
        }

        for (var i = 0; i < maxHours; i++) {
          hours.push(d.format(format));
          d.add(1, 'hours');
        }

        return hours;
      }
    }),
    hourlyActiveEnd: Ember.computed('inactivityGoals.{duration,startTime}', function () {
      return Ember.get(this, 'inactivityGoals.endTime');
    }),
    hourlyActiveStartHr: Ember.computed('inactivityGoals.{duration,startTime}', function () {
      var key = Ember.get(this, 'using12HourClock') ? 'startTime12' : 'startTimeDisplay24';
      return Ember.get(this, "inactivityGoals.".concat(key));
    }),
    hourlyActiveEndHr: Ember.computed('inactivityGoals.{duration,startTime}', function () {
      var key = Ember.get(this, 'using12HourClock') ? 'endTime12' : 'endTimeDisplay24';
      return Ember.get(this, "inactivityGoals.".concat(key));
    }),
    hourlyActiveStartMeridiem: Ember.computed('inactivityGoals.startTime', function () {
      return Ember.get(this, 'inactivityGoals.startTimeAmPm');
    }),
    hourlyActiveEndMeridiem: Ember.computed('inactivityGoals.{duration,startTime}', function () {
      return Ember.get(this, 'inactivityGoals.endTimeAmPm');
    }),
    using12HourClock: Ember.computed('currentUser.isClock12', function () {
      return Ember.get(this, 'currentUser.isClock12');
    }),
    get24HrTimeForHrAndMeridiem: function get24HrTimeForHrAndMeridiem(time, meridiem) {
      var momentService = Ember.get(this, 'moment'); // If in 12 hour time, convert val to 24 hour time

      if (Ember.get(this, 'using12HourClock')) {
        var frmt12 = "".concat(Ember.get(this, 'constants.CLOCK_12_TIME_FORMAT'), " ").concat(Ember.get(this, 'constants.MERIDIEM_FORMAT'));
        var frmt24 = Ember.get(this, 'constants.CLOCK_24_TIME_FORMAT');
        time = momentService.moment("".concat(time, " ").concat(meridiem), frmt12).format(frmt24);
      }

      return time;
    },
    setDuration: function setDuration() {
      var momentService = Ember.get(this, 'moment');
      var frmt24 = Ember.get(this, 'constants.CLOCK_24_TIME_FORMAT');
      var startMoment = momentService.moment(Ember.get(this, 'hourlyActiveStart'), frmt24);
      var endMoment = momentService.moment(Ember.get(this, 'hourlyActiveEnd'), frmt24);
      var duration = endMoment.diff(startMoment, 'hours');
      Ember.set(this, 'hourlyActiveDuration', duration);
    },
    actions: {
      startTimeChanged: function startTimeChanged(val) {
        var time = this.get24HrTimeForHrAndMeridiem(val, Ember.get(this, 'hourlyActiveStartMeridiem'));
        Ember.set(this, 'hourlyActiveStart', time);
        this.setDuration();
      },
      startMeridiemChanged: function startMeridiemChanged(val) {
        var momentService = Ember.get(this, 'moment');
        var frmt24 = Ember.get(this, 'constants.CLOCK_24_TIME_FORMAT');
        var m = momentService.moment(Ember.get(this, 'hourlyActiveStart'), frmt24);

        if (val === 'PM') {
          m.add(12, 'hours');
        } else {
          m.subtract(12, 'hours');
        }

        this.setProperties({
          hourlyActiveStartMeridiem: val,
          hourlyActiveStart: m.format(frmt24)
        });
        this.setDuration();
      },
      endTimeChanged: function endTimeChanged(val) {
        var time = this.get24HrTimeForHrAndMeridiem(val, Ember.get(this, 'hourlyActiveEndMeridiem'));
        Ember.set(this, 'hourlyActiveEnd', time);
        this.setDuration();
      },
      endMeridiemChanged: function endMeridiemChanged(val) {
        var momentService = Ember.get(this, 'moment');
        var frmt24 = Ember.get(this, 'constants.CLOCK_24_TIME_FORMAT');
        var m = momentService.moment(Ember.get(this, 'hourlyActiveEnd'), frmt24);

        if (val === 'PM') {
          m.add(12, 'hours');
        } else {
          m.subtract(12, 'hours');
        }

        this.setProperties({
          hourlyActiveEndMeridiem: val,
          hourlyActiveEnd: m.format(frmt24)
        });
        this.setDuration();
      },
      updateGoals: function updateGoals() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;
          var formatter = Ember.get(_this, 'i18n');

          if (!Ember.get(_this, 'isLoading') && validations.get('isValid')) {
            var _this$getProperties = _this.getProperties('steps', 'distance', 'calories', 'activeMinutes', 'hourlyActiveStart', 'hourlyActiveDuration'),
                steps = _this$getProperties.steps,
                distance = _this$getProperties.distance,
                calories = _this$getProperties.calories,
                activeMinutes = _this$getProperties.activeMinutes,
                hourlyActiveStart = _this$getProperties.hourlyActiveStart,
                hourlyActiveDuration = _this$getProperties.hourlyActiveDuration;

            Ember.set(_this, 'isLoading', true);
            Ember.get(_this, 'save')(steps, distance, calories, activeMinutes, hourlyActiveStart, hourlyActiveDuration).then(function () {
              Ember.get(_this, 'flashMessages').success(formatter.format('goals_updated'));
            }).catch(function () {
              Ember.get(_this, 'flashMessages').alert(formatter.formatByNamespace('fix_errors', 'common.messages'));
            }).finally(function () {
              Ember.set(_this, 'isLoading', false);
            });
          } else {
            Ember.get(_this, 'flashMessages').alert(formatter.formatByNamespace('fix_errors', 'common.messages'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});