define("fitbit-site-ui/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZ43u72i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"siteName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"meta\"],[11,\"property\",\"og:site_name\"],[12,\"content\",[23,[\"model\",\"siteName\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"meta\"],[11,\"property\",\"og:title\"],[12,\"content\",[23,[\"model\",\"title\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"description\"]]],null,{\"statements\":[[0,\"    \"],[7,\"meta\"],[11,\"property\",\"og:description\"],[12,\"content\",[23,[\"model\",\"description\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"image\"]]],null,{\"statements\":[[0,\"    \"],[7,\"meta\"],[11,\"property\",\"og:image\"],[12,\"content\",[23,[\"model\",\"image\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/head.hbs"
    }
  });

  _exports.default = _default;
});