define("fitbit-site-ui/models/time-series/activity", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    activityIntraday: _emberData.default.attr(),
    activityGoal: _emberData.default.attr(),
    activitySummary: _emberData.default.attr(),
    date: Ember.computed(function () {
      return (0, _moment.default)(this.get('activityIntraday').startTime).format('YYYY-MM-DD');
    }),
    summaryVal: Ember.computed(function () {
      var activityType = this.get('activityIntraday').activityName;

      var duration = _moment.default.duration(this.get('activityIntraday').activeDuration).asMinutes();

      return "".concat(duration, " minutes ").concat(activityType);
    }),
    dataset: Ember.computed(function () {
      // We have this constant as when we graph this, we can't see a val of 1
      var someConstant = 100; // Best method would be to get the startTime, get the start date &
      // minutes. Then add the number of activeDuration, as calculated above.
      // We'll map that into the following array
      // [{time: HHMMXX, value: 1}]; where value is always 1;

      var timeFormat = 'HH:mm:ss';
      var timeSeries = this.get('activityIntraday');
      var acitvityData = [];
      var momentStartTime = (0, _moment.default)(timeSeries.startTime);

      for (var min = 0; min < _moment.default.duration(timeSeries.activeDuration).asMinutes(); min++) {
        acitvityData.push({
          value: someConstant,
          time: momentStartTime.format(timeFormat)
        });
        momentStartTime.add(1, 'minute');
      }

      return acitvityData;
    })
  }); // Unknown possibly sensitive origin
  // {
  //   "activities":  [
  //      {
  //       "activeDuration": 2725000,
  //       "activityLevel":  [
  //          {
  //           "minutes": 0,
  //           "name": "sedentary"
  //         },
  //          {
  //           "minutes": 0,
  //           "name": "lightly"
  //         },
  //          {
  //           "minutes": 0,
  //           "name": "fairly"
  //         },
  //          {
  //           "minutes": 46,
  //           "name": "very"
  //         }
  //       ],
  //       "activityName": "Run",
  //       "activityTypeId": 90009,
  //       "averageHeartRate": 172,
  //       "calories": 761,
  //       "caloriesLink": "https://api.fitbit.com/1.1/user/-/activities/calories/date/2016-07-17/2016-07-17/1min/time/17:38/18:24.json",
  //       "detailsLink": "https://api.fitbit.com/1.1/user/-/activities/3322248259.json",
  //       "distance": 8.407279,
  //       "distanceUnit": "Kilometer",
  //       "duration": 2725000,
  //       "heartRateLink": "https://api.fitbit.com/1.1/user/-/activities/heart/date/2016-07-17/2016-07-17/1sec/time/17:38:51/18:24:16.json",
  //       "heartRateZones":  [
  //          {
  //           "max": 99,
  //           "min": 30,
  //           "minutes": 0,
  //           "name": "Out of Range"
  //         },
  //          {
  //           "max": 139,
  //           "min": 99,
  //           "minutes": 2,
  //           "name": "Fat Burn"
  //         },
  //          {
  //           "max": 169,
  //           "min": 139,
  //           "minutes": 13,
  //           "name": "Cardio"
  //         },
  //          {
  //           "max": 220,
  //           "min": 169,
  //           "minutes": 29,
  //           "name": "Peak"
  //         }
  //       ],
  //       "lastModified": "2016-07-18T01:24:21.000Z",
  //       "logId": 3322248259,
  //       "logType": "mobile_run",
  //       "manualValuesSpecified":  {
  //         "calories": false,
  //         "distance": false,
  //         "steps": false
  //       },
  //       "pace": 324.123893116905,
  //       "source":  {
  //         "id": "228TSL",
  //         "name": "Fitbit for iPhone",
  //         "type": "app",
  //         "url": "https://www.fitbit.com/iphone"
  //       },
  //       "speed": 11.106864000000002,
  //       "startTime": "2016-07-17T17:38:51.000-07:00",
  //       "steps": 6196
  //     }
  //   ],
  //   "goals":  {
  //     "activeMinutes": 45,
  //     "calories": 3019,
  //     "distance": 8,
  //     "distanceUnit": "Kilometer",
  //     "floors": 10,
  //     "steps": 10000
  //   },
  //   "summary":  {
  //     "activityLevels":  [
  //        {
  //         "distance": 0,
  //         "minutes": 656,
  //         "name": "sedentary"
  //       },
  //        {
  //         "distance": 5.23,
  //         "minutes": 254,
  //         "name": "lightly"
  //       },
  //        {
  //         "distance": 0,
  //         "minutes": 1,
  //         "name": "moderately"
  //       },
  //        {
  //         "distance": 8.62,
  //         "minutes": 51,
  //         "name": "very"
  //       }
  //     ],
  //     "calories":  {
  //       "bmr": 1856,
  //       "total": 3590
  //     },
  //     "customHeartRateZones":  [],
  //     "distance": 13.85,
  //     "elevation": 207.26,
  //     "floors": 68,
  //     "heartRateZones":  [
  //        {
  //         "caloriesOut": 2426.84939,
  //         "max": 99,
  //         "min": 30,
  //         "minutes": 1191,
  //         "name": "Out of Range"
  //       },
  //        {
  //         "caloriesOut": 174.70015,
  //         "max": 139,
  //         "min": 99,
  //         "minutes": 19,
  //         "name": "Fat Burn"
  //       },
  //        {
  //         "caloriesOut": 194.29751,
  //         "max": 169,
  //         "min": 139,
  //         "minutes": 13,
  //         "name": "Cardio"
  //       },
  //        {
  //         "caloriesOut": 550.5311,
  //         "max": 220,
  //         "min": 169,
  //         "minutes": 31,
  //         "name": "Peak"
  //       }
  //     ],
  //     "steps": 14487
  //   }
  // }


  _exports.default = _default;
});