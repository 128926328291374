define("fitbit-site-ui/models/health-programs/admin/program", ["exports", "snapdragon-common/constants/program-visibility", "snapdragon-common/constants/program-type", "fitbit-site-ui/models/health-programs/program", "ember-data"], function (_exports, _programVisibility, _programType, _program, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _program.default.extend({
    programType: _emberData.default.attr('string', {
      defaultValue: _programType.PROGRAM
    }),
    canDelete: Ember.computed('canArchive', 'visibility', function () {
      return this.canArchive && this.visibility == _programVisibility.ARCHIVED;
    }),
    canArchive: Ember.computed('programVersions.[]', function () {
      return !this.programVersions || !this.programVersions.length;
    }),
    canSave: Ember.computed('hasDirtyAttributes', function () {
      return this.hasDirtyAttributes;
    })
  });

  _exports.default = _default;
});