define("fitbit-site-ui/components/device-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    supports: Ember.computed.alias('device.supports'),
    deviceTypeName: Ember.computed('device.deviceType.name', function () {
      return Ember.String.dasherize((this.get('device.deviceType.name') || '').toLowerCase());
    }),
    user: null,
    device: null,
    settings: null,
    options: null,
    newAlarm: null,
    displayNames: null,
    panelStates: null,
    onCreateAlarm: null,
    onEditAlarm: null,
    onSettingsChanged: null,
    UTCOffset: Ember.computed.readOnly('user.offsetFromUTCHours'),
    init: function init() {
      this._super.apply(this, arguments);

      var device = Ember.get(this, 'device');
      var hasSavedGpsValues = Ember.get(device, 'savedGpsValues');

      if (!hasSavedGpsValues) {
        Ember.set(device, 'savedGpsValues', {});
      }
    },
    savedGpsValues: Ember.computed.alias('device.savedGpsValues'),
    actions: {
      saveGpsValues: function saveGpsValues(id, gpsOn) {
        var device = Ember.get(this, 'device'),
            oldValues = Ember.get(device, 'savedGpsValues');
        oldValues[id] = gpsOn;
        Ember.set(device, 'savedGpsValues', oldValues);
      }
    }
  });

  _exports.default = _default;
});