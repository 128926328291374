define("fitbit-site-ui/models/food/food-item", ["exports", "ember-data", "fitbit-site-ui/models/food/base-food", "snapdragon-common/mixins/food-nutrition"], function (_exports, _emberData, _baseFood, _foodNutrition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseFood.default.extend(_foodNutrition.default, {
    creatorEncodedId: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    defaultServingSize: _emberData.default.attr('number'),
    defaultServingUnit: _emberData.default.attr('string'),
    isGeneric: _emberData.default.attr('boolean'),
    isQuickCaloriesAdd: _emberData.default.attr('boolean'),
    isRaw: _emberData.default.attr('boolean'),
    nutritionalValues: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    accessLevel: _emberData.default.attr('string'),

    /**
     * Favorite is a boolean set by the favorite-food serializer to be true if a corresponding favorite-food records exists
     * The id will match the food-item and can be retrieved through peekRecord.  This is done instead of a relationship
     * due to issues in order of serialization between a recent/frequent/custom and favorite foods
     *
     */
    favorite: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    /**
     * Iterates through nutritionalValues and creates object that contains an array of vitaminMineral objects and
     *  and object with the mainNutrients
     *
     *  We need an object for mainNutrients because order matters, and an array for vitaminMinerals so we can just iterate over it
     * @return {mainNutrients, vitaminMinerals: Array}
     * @private
     */
    nutritionalValuesStructs: Ember.computed('nutritionalValues', function () {
      var nutritionalValues = Ember.get(this, 'nutritionalValues');
      return this.createMacroStructs(nutritionalValues);
    })
  });

  _exports.default = _default;
});