define("fitbit-site-ui/serializers/device/tracker/inactivity-alerts", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'device/tracker/inactivity-alerts';
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadKey = this.payloadKeyFromModelName(primaryModelClass.modelName),
          data = {}; //It should really be the encoded user id since this setting is shared across trackers but for now we
      //will assign it the tracker id

      payload.id = id;
      data = _defineProperty({}, payloadKey, payload);
      return this._super(store, primaryModelClass, data, id, requestType);
    },
    serialize: function serialize(snapshot) {
      //TODO: Unsure what the behavior should be if a user tries to unselect all the weekdays
      //Should we take that as a sign to turn off the feature?
      //Currently we cannot send an empty weekDays array -- the server ignores it
      var weekDays = snapshot.attr('inactivityAlerts').weekDays || [];
      var json = {
        sedStartTime: snapshot.attr('sedentaryTime').startTime,
        sedDuration: snapshot.attr('sedentaryTime').duration,
        inactivityWeekDays: weekDays.join(',')
      };
      return json;
    }
  });

  _exports.default = _default;
});