define("fitbit-site-ui/initializers/ember-cli-conditional-compile-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializer = {
    name: 'ember-cli-conditional-compile-features',
    initialize: function initialize() {}
  };
  var feature_flags = {"IS_WEIGHTSITE":true,"HAS_101_GUIDES":true,"GDPR_ENABLED":false,"DUT_ENABLED":true,"RECEIPTS_REQUEST_ENABLED":true,"GDD_CONSOLE_ALL_TIME_ENABLED":true,"GDD_CONSOLE_ALL_TIME_BY_DEFAULT":false};
  Object.keys(feature_flags).map(function (flag) {
    window[flag] = feature_flags[flag];
  });
  var _default = initializer;
  _exports.default = _default;
});