define("fitbit-site-ui/constants/friends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_EMAIL_INVITES = _exports.ACTION_TYPE_TAUNTED = _exports.ACTION_TYPE_CHEERED = _exports.MAX_MESSAGE_LENGTH = void 0;

  /**
   * Max character length of a message (based on back end validation limit)
   */
  var MAX_MESSAGE_LENGTH = 512;
  /**
   * Used in user-action-notification cards
   */

  _exports.MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;
  var ACTION_TYPE_CHEERED = 'cheered';
  _exports.ACTION_TYPE_CHEERED = ACTION_TYPE_CHEERED;
  var ACTION_TYPE_TAUNTED = 'taunted';
  /**
   * Max number of email invites that can be sent at a time.
   * @type {number}
   */

  _exports.ACTION_TYPE_TAUNTED = ACTION_TYPE_TAUNTED;
  var MAX_EMAIL_INVITES = 5;
  _exports.MAX_EMAIL_INVITES = MAX_EMAIL_INVITES;
});