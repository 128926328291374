define("fitbit-site-ui/serializers/badge", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'encodedId',
    normalizeResponse: function normalizeResponse() {
      arguments[3] = null; // Needed when badges API gets called with specific user ID

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});