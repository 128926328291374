define("fitbit-site-ui/models/sleep-goal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('profile', {
      inverse: 'sleepGoal'
    }),
    minDuration: _emberData.default.attr({
      defaultValue: 0
    })
  });

  _exports.default = _default;
});