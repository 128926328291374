define("fitbit-site-ui/models/water-goal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    startDate: _emberData.default.attr('date'),

    /**
     * Goal Amount based on current user water unit
     */
    goalAmount: _emberData.default.attr('number'),

    /**
     * Goal Amounts for each unit
     */
    goalCup: _emberData.default.attr('number'),
    goalMl: _emberData.default.attr('number'),
    goalOz: _emberData.default.attr('number')
  });

  _exports.default = _default;
});