define("fitbit-site-ui/serializers/frequent-food", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'foodId',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.isPresent(payload)) {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      var newPayload = {
        'food/logged-food': payload,
        'frequent-food': payload.map(function (food) {
          return {
            loggedFood: food.foodId,
            foodId: food.foodId
          };
        })
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});