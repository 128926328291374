define("fitbit-site-ui/adapters/cw-consent", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _applicationJsonapi.default.extend({
    namespace: "1/health-solutions-onboarding",
    urlForFindRecord: function urlForFindRecord(id, modelName, _ref) {
      var _ref$adapterOptions = _ref.adapterOptions,
          userId = _ref$adapterOptions.userId,
          programId = _ref$adapterOptions.programId;
      return "".concat(this.buildURL(), "/programs/").concat(programId, "/users/").concat(userId, "/consents");
    },
    findRecord: function findRecord(store, type, id, _ref2) {
      var modelName = _ref2.modelName,
          _ref2$adapterOptions = _ref2.adapterOptions,
          userId = _ref2$adapterOptions.userId,
          programId = _ref2$adapterOptions.programId;
      return this._super.apply(this, arguments).then(function (payload) {
        var html = null;
        var json = null;

        try {
          json = JSON.parse(payload.data);
        } catch (e) {
          html = payload.data;
        }

        return _defineProperty({}, modelName, {
          id: id,
          userId: userId,
          programId: programId,
          html: html,
          json: json
        });
      });
    }
  });

  _exports.default = _default;
});