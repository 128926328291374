define("fitbit-site-ui/routes/settings/guides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    deviceGuides: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var device = params.device;
      var deviceGuides = Ember.get(this, 'deviceGuides');
      return deviceGuides.guideExists(device).then(function (exists) {
        var i18n = Ember.get(_this, 'i18n');

        if (!exists) {
          _this.transitionTo('/404');
        } else {
          var locale = Ember.get(i18n, 'locale');
          return deviceGuides.loadGuide(device, locale).catch(function () {
            locale = i18n.calculateLanguageLocale(locale);
            return deviceGuides.loadGuide(device, locale);
          });
        }
      });
    }
  });

  _exports.default = _default;
});