define("fitbit-site-ui/models/fhs-enrollment-program-feature", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    icon: _emberData.default.attr('string'),
    deeplink: _emberData.default.attr('string'),
    programFeatureDetails: _emberData.default.hasMany('fhs-enrollment-program-feature-detail')
  });

  _exports.default = _default;
});