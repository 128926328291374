define("fitbit-site-ui/services/device/layout", ["exports", "fitbit-site-ui/config/environment", "@html-next/flexi-layouts/services/device/layout", "ember-window-mock"], function (_exports, _environment, _layout, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Extending the device layout service to override _currentHeight and _currentWidth computation logic because
   * they both look up height and widths from window.screen, which doesn't update upon orientation change. This causes
   * the breakpoint booleans to be incorrect.
   */
  var _default = _layout.default.extend({
    breakpoints: _environment.default.flexi.breakpoints,

    /**
     * Remove orientation change event.
     *
     * @override
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (_typeof(_emberWindowMock.default) === 'object' && (typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
        _emberWindowMock.default.removeEventListener('orientationchange', this._resizeHandler, true);
      }
    },

    /**
     * Add orientation change event.
     *
     * @override
     */
    setupResize: function setupResize() {
      this._super.apply(this, arguments);

      if (_typeof(_emberWindowMock.default) === 'object' && (typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object') {
        _emberWindowMock.default.addEventListener('orientationchange', this._resizeHandler, true);
      }
    },

    /**
     * Retrieve the width based on the smallest value.
     *
     * Overide to exclude window.screen.width from logic because the value does not change on orientation change.
     *
     * @override
     * @returns {number}
     * @private
     */
    _currentWidth: function _currentWidth() {
      var widths = [_emberWindowMock.default.document.documentElement.clientWidth, _emberWindowMock.default.innerWidth];
      return Math.min.apply(Math, _toConsumableArray(widths.filter(function (width) {
        return width;
      })));
    },

    /**
     * Retrieve the height based on the smallest value.
     *
     * Overide to exclude window.screen.height from logic because the value does not change on orientation change.
     *
     * @override
     * @returns {number}
     * @private
     */
    _currentHeight: function _currentHeight() {
      var heights = [_emberWindowMock.default.document.documentElement.clientHeight, _emberWindowMock.default.innerHeight];
      return Math.min.apply(Math, _toConsumableArray(heights.filter(function (height) {
        return height;
      })));
    }
  });

  _exports.default = _default;
});