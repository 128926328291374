define("fitbit-site-ui/services/dumpster-ajax", ["exports", "fitbit-site-ui/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    apiBase: 'research/storage'
  });

  _exports.default = _default;
});