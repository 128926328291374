define("fitbit-site-ui/adapters/food-goal", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    namespace: '1/user',
    disableRoot: true,
    pathForType: function pathForType() {
      return '';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/foods/log/goal.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return "".concat(this._super.apply(this, arguments), "/foods/log/goal.json");
    }
  });

  _exports.default = _default;
});