define("fitbit-site-ui/models/timezone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //id is a string of the timezone name
    //see documentation on Fitbit Private API
    name: _emberData.default.attr('string'),
    offset: _emberData.default.attr(),
    //Used to render in select-menu
    value: Ember.computed.alias('id'),
    displayName: Ember.computed.alias('name')
  });

  _exports.default = _default;
});