define("fitbit-site-ui/models/onboarding-step-type", ["exports", "corporate-onboarding-engine/models/onboarding-step-type"], function (_exports, _onboardingStepType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _onboardingStepType.default;
    }
  });
});