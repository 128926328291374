define("fitbit-site-ui/serializers/family-account", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      addedById: 'added_by_id',
      familyOwnerId: 'family_owner_id',
      displayName: 'display_name',
      memberId: 'member_id'
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }
  });

  _exports.default = _default;
});