define("fitbit-site-ui/models/user-device-list", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    list: (0, _relationships.hasMany)('user-device', {
      async: false
    }),
    noDevicesPaired: Ember.computed('list', function () {
      return Ember.get(this, 'list.length') === 0;
    }),

    /**
     * Determine if Sleep is supported by any of the users devices.
     * @returns {boolean}
     */
    isSleepSupported: Ember.computed('list.{[],@each.features}', function () {
      var deviceList = Ember.get(this, 'list');
      var sleepDeviceFound = deviceList.find(function (device) {
        var features = device.get('features');
        return features.indexOf('SLEEP') > -1;
      });
      return !!sleepDeviceFound;
    })
  });

  _exports.default = _default;
});