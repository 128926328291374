define("fitbit-site-ui/services/ajax", ["exports", "ember-ajax/services/ajax", "fitbit-site-ui/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.host,
    namespace: '/1',
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      var headers = {};
      var authToken = this.get('session.data.authenticated.access_token');

      if (authToken) {
        headers.Authorization = "Bearer ".concat(authToken);
      }

      return headers;
    })
  });

  _exports.default = _default;
});