define("fitbit-site-ui/constants/corporate-program-business-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARTNER = _exports.SMALL_MEDIUM = _exports.ENTERPRISE = void 0;

  /**
   * Corporate program business types
   * @type {string}
   */
  var ENTERPRISE = 'ENTERPRISE';
  _exports.ENTERPRISE = ENTERPRISE;
  var SMALL_MEDIUM = 'SMALL_MEDIUM';
  _exports.SMALL_MEDIUM = SMALL_MEDIUM;
  var PARTNER = 'PARTNER';
  _exports.PARTNER = PARTNER;
});