define("fitbit-site-ui/components/device-sleep-sensitivity-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    actions: {
      sleepSensitivityChanged: function sleepSensitivityChanged(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.get('user').set('sleepTracking', value);
        this.get('user').content.save().then(function () {
          var successMessage = formatter.format('device_setting_saved');

          _this._clearMessageFromQueue(successMessage);

          flashMessages.success(successMessage);
        }).catch(function () {
          var failureMessage = formatter.format('something_went_wrong');

          _this._clearMessageFromQueue(failureMessage);

          flashMessages.alert(failureMessage);
        });
      }
    }
  });

  _exports.default = _default;
});