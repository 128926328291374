define("fitbit-site-ui/helpers/google-account-chooser-link", ["exports", "snapdragon-common/helpers/google-account-chooser-link"], function (_exports, _googleAccountChooserLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleAccountChooserLink.default;
    }
  });
  Object.defineProperty(_exports, "missingEmailAddressParamError", {
    enumerable: true,
    get: function get() {
      return _googleAccountChooserLink.missingEmailAddressParamError;
    }
  });
  Object.defineProperty(_exports, "missingContinueUrlParamError", {
    enumerable: true,
    get: function get() {
      return _googleAccountChooserLink.missingContinueUrlParamError;
    }
  });
  Object.defineProperty(_exports, "googleAccountChooserLink", {
    enumerable: true,
    get: function get() {
      return _googleAccountChooserLink.googleAccountChooserLink;
    }
  });
});