define("fitbit-site-ui/serializers/fhs-enrollment-program-membership", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    // Map payload key name to ember model name (the filename in the models/ directory)
    // API returns `{ type: 'program-tag'|'program-field }` which maps to `fhs-enrollment-program-tag|field`
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return "fhs-enrollment-".concat(key);
    }
  });

  _exports.default = _default;
});