define("fitbit-site-ui/constants/corporate-program-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.POST_PROGRAM_STATUS_AND_STATE = _exports.CONSENT_REQUIRED_PROGRAM_STATUS = _exports.REMOVED_PROGRAM_STATUS = _exports.COMPLETED_PROGRAM_STATUS = _exports.DECLINED_PROGRAM_STATUS = _exports.INVITED_PROGRAM_STATUS = _exports.LEFT_PROGRAM_STATUS = _exports.UNIT_CHANGE_STATUS = _exports.INACTIVE_PROGRAM_STATUS = _exports.ACTIVE_PROGRAM_STATUS = _exports.OTHER_PROGRAM = _exports.ENDED_PROGRAM = _exports.COMPLETED_PROGRAM = _exports.AVAILABLE_PROGRAM = _exports.CURRENT_PROGRAM = void 0;

  /**************************************************
   * PROGRAM STATES
   *************************************************/

  /**
   * The current program a user has joined.
   * @type {String}
   */
  var CURRENT_PROGRAM = 'CURRENT_PROGRAM';
  /**
   * An available program a user can join.
   * @type {String}
   */

  _exports.CURRENT_PROGRAM = CURRENT_PROGRAM;
  var AVAILABLE_PROGRAM = 'AVAILABLE_PROGRAM';
  /**
   * A completed program
   * @type {string}
   */

  _exports.AVAILABLE_PROGRAM = AVAILABLE_PROGRAM;
  var COMPLETED_PROGRAM = 'COMPLETED_PROGRAM';
  /**
   * An ended program a user can no longer join
   * @type {string}
   */

  _exports.COMPLETED_PROGRAM = COMPLETED_PROGRAM;
  var ENDED_PROGRAM = 'ENDED_PROGRAM';
  /**
   * A program that a user has been removed from
   * @type {string}
   */

  _exports.ENDED_PROGRAM = ENDED_PROGRAM;
  var OTHER_PROGRAM = 'OTHER_PROGRAM';
  /**************************************************
   * PROGRAM STATUSES
   *************************************************/

  /**
   * The status of a joined program
   * @type {string}
   */

  _exports.OTHER_PROGRAM = OTHER_PROGRAM;
  var ACTIVE_PROGRAM_STATUS = 'ACTIVE';
  /**
   * The status of an inactive program
   * @type {string}
   */

  _exports.ACTIVE_PROGRAM_STATUS = ACTIVE_PROGRAM_STATUS;
  var INACTIVE_PROGRAM_STATUS = 'INACTIVE';
  /**
   * Record unit_change status
   * @type {string}
   */

  _exports.INACTIVE_PROGRAM_STATUS = INACTIVE_PROGRAM_STATUS;
  var UNIT_CHANGE_STATUS = 'UNIT_CHANGE';
  /**
   * The status of a left program
   * @type {string}
   */

  _exports.UNIT_CHANGE_STATUS = UNIT_CHANGE_STATUS;
  var LEFT_PROGRAM_STATUS = 'LEFT';
  /**
   * The status of an unjoined program
   * @type {string}
   */

  _exports.LEFT_PROGRAM_STATUS = LEFT_PROGRAM_STATUS;
  var INVITED_PROGRAM_STATUS = 'INVITED';
  /**
   * The status of a declined program
   * @type {string}
   */

  _exports.INVITED_PROGRAM_STATUS = INVITED_PROGRAM_STATUS;
  var DECLINED_PROGRAM_STATUS = 'DECLINED';
  /**
   * The status of a program that has ended and was active or inactive
   * @type {string}
   */

  _exports.DECLINED_PROGRAM_STATUS = DECLINED_PROGRAM_STATUS;
  var COMPLETED_PROGRAM_STATUS = 'COMPLETED';
  /**
   * The status of a program that the user was removed from
   * @type {string}
   */

  _exports.COMPLETED_PROGRAM_STATUS = COMPLETED_PROGRAM_STATUS;
  var REMOVED_PROGRAM_STATUS = 'REMOVED';
  /**
   * Record consent_required status
   * @type {string}
   */

  _exports.REMOVED_PROGRAM_STATUS = REMOVED_PROGRAM_STATUS;
  var CONSENT_REQUIRED_PROGRAM_STATUS = 'CONSENT_REQUIRED';
  /**************************************************
   * OTHER
   *************************************************/

  /**
   * Mapping from cw-program action to status and state
   * Used for mirage config in testing.
   * @type {{leave: {status: string, state: String}, decline: {status: string, state: String}, join: {status: string, state: String}}}
   */

  _exports.CONSENT_REQUIRED_PROGRAM_STATUS = CONSENT_REQUIRED_PROGRAM_STATUS;
  var POST_PROGRAM_STATUS_AND_STATE = {
    leave: {
      status: LEFT_PROGRAM_STATUS,
      state: AVAILABLE_PROGRAM
    },
    decline: {
      status: DECLINED_PROGRAM_STATUS,
      state: AVAILABLE_PROGRAM
    },
    join: {
      status: ACTIVE_PROGRAM_STATUS,
      state: CURRENT_PROGRAM
    }
  };
  _exports.POST_PROGRAM_STATUS_AND_STATE = POST_PROGRAM_STATUS_AND_STATE;
});