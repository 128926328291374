define("fitbit-site-ui/controllers/family/family-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['familyId', 'inviterId'],

    /**
     * The encoded user id of the family.
     */
    familyId: null,

    /**
     * The encoded user id of the inviter.
     */
    inviterId: null
  });

  _exports.default = _default;
});