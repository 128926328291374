define("fitbit-site-ui/controllers/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    mobileView: Ember.inject.service(),
    viewport: Ember.inject.service('device/layout'),
    displayNavigation: Ember.computed.not('mobileView.isWebView'),
    pageFlashMessagesQueue: Ember.computed('flashMessages.{queue,queue.[]}', function () {
      var queue = Ember.get(this, 'flashMessages.queue');

      if (Ember.isPresent(queue)) {
        return queue.filter(function (flashMessage) {
          return !flashMessage.isModal;
        });
      }
    })
  });

  _exports.default = _default;
});