define("fitbit-site-ui/services/error-logger", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to log errors.
   */
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    _config: Ember.computed(function () {
      var _EmberGetOwner$resol;

      return (_EmberGetOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment')) === null || _EmberGetOwner$resol === void 0 ? void 0 : _EmberGetOwner$resol['fitbit-web-metrics'];
    }),
    mustIgnoreError: function mustIgnoreError(error) {
      /* Ember 2.X seems to not catch `TransitionAborted` errors caused by
      regular redirects. We don't want these errors to show up
      so we have to filter them ourselves.
      See https://github.com/emberjs/ember.js/issues/12505
      */
      if (Ember.isNone(error)) {
        return true;
      }

      return error.name === 'TransitionAborted';
    },
    formatMsg: function formatMsg(msg, url, stack) {
      var _window$navigator;

      var shortErrorMessage = msg;

      if (url) {
        shortErrorMessage += " @ ".concat(url);

        if (stack) {
          shortErrorMessage += " : ".concat(stack);
        }
      }

      var fullErrorMessage = "".concat(_emberWindowMock.default.document.title, " => ").concat(_emberWindowMock.default.location.href, " | ").concat(shortErrorMessage);
      var userAgent = _emberWindowMock.default === null || _emberWindowMock.default === void 0 ? void 0 : (_window$navigator = _emberWindowMock.default.navigator) === null || _window$navigator === void 0 ? void 0 : _window$navigator.userAgent;

      if (userAgent) {
        fullErrorMessage += " | ".concat(userAgent);
      }

      return {
        fullErrorMessage: fullErrorMessage,
        shortErrorMessage: shortErrorMessage
      };
    },
    // Copied most from Weightsite/site/web/WEB-INF/jsp/com/fitbit/app/lab/galileo/base.jsp
    handleErrorMsg: function handleErrorMsg(msg, url, stack) {
      var _this$formatMsg = this.formatMsg(msg, url, stack),
          fullErrorMessage = _this$formatMsg.fullErrorMessage,
          shortErrorMessage = _this$formatMsg.shortErrorMessage;

      try {
        var xmlhttp;

        if (_emberWindowMock.default.XMLHttpRequest) {
          xmlhttp = new _emberWindowMock.default.XMLHttpRequest();
        } else {
          xmlhttp = new _emberWindowMock.default.ActiveXObject('Microsoft.XMLHTTP');
        }

        xmlhttp.open('POST', '/error/log', true);
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xmlhttp.send("error=".concat(encodeURIComponent(shortErrorMessage), "&fullMessage=").concat(encodeURIComponent(fullErrorMessage)));
      } catch (e) {
        // creating an request failed, nothing to surface but at least print the error
        console.error(e); // eslint-disable-line  no-console
      }
    },
    handleErrorMsgV2: function handleErrorMsgV2() {
      var _this$_config, _router$currentRoute;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          error = _ref.error,
          _ref$severity = _ref.severity,
          severity = _ref$severity === void 0 ? 'error' : _ref$severity,
          feature = _ref.feature,
          _ref$details = _ref.details,
          details = _ref$details === void 0 ? {} : _ref$details,
          build = _ref.build;

      // Prevent logging if there is no URL to log to or there is no error.
      if (!(this === null || this === void 0 ? void 0 : (_this$_config = this._config) === null || _this$_config === void 0 ? void 0 : _this$_config.url) || !error) {
        return;
      }

      if (typeof error === 'string') {
        error = new Error(error);
      }

      var ajax = this.ajax,
          router = this.router,
          _config = this._config;
      var _error = error,
          name = _error.name,
          message = _error.message,
          stack = _error.stack; // Incrememnt the session sequence number

      var sessionSeqNo = this._sessionSeqNo += 1;
      var url = _emberWindowMock.default.location.href;
      var data = {
        name: name,
        message: message,
        stack: stack,
        url: url,
        route: (router === null || router === void 0 ? void 0 : (_router$currentRoute = router.currentRoute) === null || _router$currentRoute === void 0 ? void 0 : _router$currentRoute.name) || 'unknown',
        pageTitle: _emberWindowMock.default.document.title,
        project: 'snapdragon',
        feature: feature,
        severity: severity,
        details: details,
        sessionSeqNo: sessionSeqNo,
        build: build
      };
      ajax.post("".concat(_config.url, "/v3/web-errors/log"), {
        dataType: 'form',
        data: data
      }).catch(function (e) {
        // Catch the rejected request, otherwise an endless loop will occur here.
        // Nothing to surface but at least print the error
        console.error(e); // eslint-disable-line  no-console
      });
    },

    /**
     * Count the number of errors. Sent along to web-errors/log API.
     * @type {Number}
     */
    _sessionSeqNo: 0,
    log: function log() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          error = _ref2.error;

      if (this.mustIgnoreError(error)) {
        return;
      }

      var url = location.href;
      var msg = '';
      var stack = '';

      if (typeof error === 'string') {
        // The stack trace here would be wrong because the error was a string.
        error = new Error(error);
        msg = error.message;
      } else {
        msg = error.message;
        stack = error.stack;
      }

      this.handleErrorMsg(msg, url, stack);
      this.handleErrorMsgV2.apply(this, arguments);
      console.error(error); // eslint-disable-line  no-console
    }
  });

  _exports.default = _default;
});