define("fitbit-site-ui/serializers/sleep-list", ["exports", "fitbit-site-ui/serializers/application", "fitbit-site-ui/mixins/sleep-date-mixin"], function (_exports, _application, _sleepDateMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend(_sleepDateMixin.default, {
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var moment = this.get('moment');
      var YEAR_MONTH_DAY_FORMAT = this.get('constants.YEAR_MONTH_DAY');
      var sleepArr = payload.sleep,
          pagination = payload.pagination,
          _payload$meta = payload.meta,
          meta = _payload$meta === void 0 ? {} : _payload$meta;
      var beforeDate = pagination.beforeDate;
      var lengthOfSleeps = sleepArr.length;

      var _sleepArr = _slicedToArray(sleepArr, 1),
          firstSleepItem = _sleepArr[0];

      var lastSleepItem = _toConsumableArray(sleepArr).pop();

      var mostRecent, leastRecent;

      if (firstSleepItem) {
        mostRecent = moment.moment(firstSleepItem.dateOfSleep, YEAR_MONTH_DAY_FORMAT);
      }

      if (lastSleepItem) {
        leastRecent = moment.moment(lastSleepItem.dateOfSleep, YEAR_MONTH_DAY_FORMAT);
      }

      var nextRecordUrl = pagination.next;
      var newPayload = {
        // Array of sleep objects to be saved
        'sleep-log': sleepArr,
        // Create new sleep-set for history list
        'sleep-list': {
          id: id,
          user: id,
          beforeDate: beforeDate,
          meta: meta,
          list: sleepArr.map(function (log) {
            return log.logId;
          }),
          nextRecordUrl: nextRecordUrl
        },
        // Array of sleep-date objects
        'sleep-date': lengthOfSleeps ? this.sleepByDate(sleepArr, leastRecent, mostRecent) : []
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});