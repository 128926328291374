define("fitbit-site-ui/models/fat-time", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    /**
     * The fat percentage as a string
     */
    value: attr(),

    /**
     * The corresponding weight-time log
     */
    weight: belongsTo('weight-time'),

    /**
     * Corresponding weight-graph log
     */
    weightGraph: belongsTo('weight-graph'),
    date: Ember.computed.oneWay('id'),
    leanMass: Ember.computed('value', 'weight.value', function () {
      var value = parseFloat(Ember.get(this, 'value'));
      var weight = Ember.get(this, 'weight.value');
      var fatMass = value * weight / 100;
      return weight - fatMass;
    }),
    notLogged: false
  });

  _exports.default = _default;
});