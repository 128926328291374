define("fitbit-site-ui/models/badge", ["exports", "ember-data", "snapdragon-common/constants/badge-types"], function (_exports, _emberData, BADGE_TYPES) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CUSTOM = BADGE_TYPES.CUSTOM,
      OTHER = BADGE_TYPES.OTHER;

  var _default = _emberData.default.Model.extend({
    /**
     * If a badge is earned all the following attributes are
     * returned. If a badge is not earned, nothing is returned.
     */

    /**
     * The type of badge eg. "DAILY_STEPS"
     */
    badgeType: _emberData.default.attr('string'),

    /**
     * The category of badge within a type eg. "CUSTOM.Challenge"
     */
    category: _emberData.default.attr('string'),

    /**
     * The number of times a specific badge was achieved
     */
    timesAchieved: _emberData.default.attr('number'),

    /**
     * The number of steps, floors, miles, etc
     */
    value: _emberData.default.attr('number'),

    /**
     * The badge's name eg. "Blue Suede Shoes"
     */
    shortName: _emberData.default.attr('string'),

    /**
     * The phrase which combines the value and description for
     * a particular bagde eg. "60,000 steps in a day"
     */
    description: _emberData.default.attr('string'),

    /**
     * Badge image as a path to a png file. Offered in
     * multiple sizes (50,75,100,125,300)
     */
    image125px: _emberData.default.attr('string'),
    image100px: _emberData.default.attr('string'),

    /**
     * Date when the badge was last earned
     */
    dateTime: _emberData.default.attr('string'),
    group: Ember.computed('badgeType', function () {
      var badgeType = Ember.get(this, 'badgeType');
      var BADGE_TYPE = Ember.get(BADGE_TYPES, badgeType);

      if (BADGE_TYPE === CUSTOM) {
        var category = Ember.get(this, 'category');
        BADGE_TYPE = Ember.get(BADGE_TYPES, Ember.String.underscore(category).toUpperCase());
      }

      if (!BADGE_TYPE) {
        BADGE_TYPE = OTHER;
      }

      return BADGE_TYPE;
    })
  });

  _exports.default = _default;
});