define("fitbit-site-ui/serializers/user-prop-info", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _application.default.extend({
    primaryKey: 'key',
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var spaceIds = payload.map(function (propInfo) {
        return Ember.get(propInfo, 'spaceName');
      });
      var newPayload = {
        'user-space': _toConsumableArray(new Set(spaceIds)).map(function (space) {
          return {
            name: space
          };
        }),
        'user-prop-info': payload
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      Object.assign(hash, this.serialize(snapshot, options));
    }
  });

  _exports.default = _default;
});