define("fitbit-site-ui/adapters/weight-graph-trendline", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    disableRoot: true,
    namespace: '1.1/user/-',
    urlForQuery: function urlForQuery(query) {
      var startDateFormatted = query.startDateFormatted,
          durationType = query.durationType;

      if (durationType === 'all') {
        return "".concat(this._super(), "/body/log/weight/graph/weighttrend/all.json");
      } else {
        return "".concat(this._super(), "/body/log/weight/graph/weighttrend/").concat(durationType, "/").concat(startDateFormatted, ".json");
      }
    },

    /**
     * Overridden to have queryparams in serializer normalize response
     * https://github.com/emberjs/data/issues/3884
     * @param store
     * @param type
     * @param query
     */
    query: function query(store, type, _query) {
      return this._super.apply(this, arguments).then(function (response) {
        response.meta = _query;
        return response;
      });
    }
  });

  _exports.default = _default;
});