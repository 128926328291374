define("fitbit-site-ui/adapters/consent", ["exports", "consent-data/adapters/consent", "snapdragon-common/mixins/adapters/application"], function (_exports, _consent, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _consent.default.extend(_application.default);

  _exports.default = _default;
});