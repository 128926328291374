define("fitbit-site-ui/templates/components/device-heart-rate-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "arB4JEOr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row column description\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"hr_setting_detail\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row column panel\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"data-test-qa\",\"label-input-off\"],[9],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"classNames\",\"groupValue\",\"changed\"],[\"OFF\",\"label-input\",[23,[\"setting\"]],[27,\"action\",[[22,0,[]],\"settingChanged\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"off\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"span\"],[11,\"data-test-qa\",\"label-input-on\"],[9],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"classNames\",\"groupValue\",\"changed\"],[\"ON\",\"label-input\",[23,[\"setting\"]],[27,\"action\",[[22,0,[]],\"settingChanged\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"on\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"span\"],[11,\"data-test-qa\",\"label-input-auto\"],[9],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"classNames\",\"groupValue\",\"changed\"],[\"AUTO\",\"label-input\",[23,[\"setting\"]],[27,\"action\",[[22,0,[]],\"settingChanged\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"format\",[\"auto\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-heart-rate-setting.hbs"
    }
  });

  _exports.default = _default;
});