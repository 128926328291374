define("fitbit-site-ui/models/journal-survey-question", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    survey: _emberData.default.belongsTo('journal-survey', {
      inverse: 'questions'
    }),
    questionId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    categoryTitle: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    responses: (0, _attributes.fragmentArray)('journal-survey-question-response'),
    order: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    // id
    answered: Ember.computed('responses.@each.selected', function () {
      var responses = this.responses;
      return !!responses.find(function (response) {
        return response.selected;
      });
    }),

    /**
     * Sets selected to false for all responses with names that do not
     * match passed in name and sets record with matching name true
     * NOTE: Does not change passed is response's name's selected value
     * @param name
     */
    clearOthers: function clearOthers(name) {
      var responses = this.responses;
      responses.forEach(function (response) {
        var responseName = response.name;

        if (name === responseName) {
          return;
        }

        Ember.set(response, 'selected', false);
      });
    }
  });

  _exports.default = _default;
});