define("fitbit-site-ui/templates/components/device-auto-run-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wB0simin",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"autoRunAvailable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n        \"],[1,[27,\"format-by-namespace\",[\"auto-run-detail\",\"templates.components.device-auto-run\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"double-radio-button\",null,[[\"toggleValue\",\"firstValue\",\"firstDescription\",\"secondValue\",\"secondDescription\",\"propertyName\",\"changeFnc\"],[[23,[\"exercisePreferences\",\"autoRunOn\"]],false,[27,\"format-by-namespace\",[\"off\",\"common.messages\"],null],true,[27,\"format-by-namespace\",[\"on\",\"common.messages\"],null],\"autoRunControl\",[27,\"action\",[[22,0,[]],\"autoRunSettingChanged\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n        \"],[1,[27,\"format-by-namespace\",[\"auto-run-unavailable\",\"templates.components.device-auto-run\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-auto-run-setting.hbs"
    }
  });

  _exports.default = _default;
});