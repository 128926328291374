define("fitbit-site-ui/templates/components/scale-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qrw0kst",
    "block": "{\"symbols\":[\"scaleUnit\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row column\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"scale_unit_detail\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row column\"],[9],[0,\"\\n    \"],[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectScaleUnit\"],[[\"value\"],[\"target.value\"]]]],[11,\"class\",\"scale-input\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"scaleUnits\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"value\",[22,1,[\"unit\"]]],[12,\"selected\",[27,\"eq\",[[23,[\"userScaleUnit\"]],[22,1,[\"unit\"]]],null]],[9],[1,[27,\"format\",[[22,1,[\"displayName\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/scale-unit.hbs"
    }
  });

  _exports.default = _default;
});