define("fitbit-site-ui/models/sleep-log", ["exports", "ember-data", "snapdragon-common/utils/unit-converter", "fitbit-site-ui/models/sleep-log/segment"], function (_exports, _emberData, _unitConverter, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  var minutesToHoursAndMinutes = _unitConverter.default.minutesToHoursAndMinutes;

  var _default = Model.extend({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    dateOfSleep: attr(),
    duration: attr(),
    efficiency: attr(),
    levels: attr(),
    logId: attr(),
    minutesAfterWakeup: attr(),
    minutesAsleep: attr(),
    minutesAwake: attr(),
    minutesToFallAsleep: attr(),
    sleepDateRecord: belongsTo('sleep-date', {
      inverse: 'sleepLogs'
    }),
    startTime: attr(),
    timeInBed: attr('number'),
    type: attr(),
    date: Ember.computed.readOnly('dateOfSleep'),
    displayTimeAsleep: Ember.computed('minutesAsleep', function () {
      var minutesAsleep = Ember.get(this, 'minutesAsleep');
      return minutesToHoursAndMinutes(minutesAsleep);
    }),
    levelsData: Ember.computed('levels', function () {
      var moment = Ember.get(this, 'moment');
      return Ember.get(this, 'levels.data').map(function (level) {
        return _segment.default.create({
          seconds: level.seconds,
          dateTime: moment.moment(level.dateTime),
          level: level.level
        });
      });
    }),
    shortLevelsData: Ember.computed('levels', function () {
      var moment = Ember.get(this, 'moment');
      return Ember.get(this, 'levels.shortData').map(function (level) {
        return _segment.default.create({
          seconds: level.seconds,
          dateTime: moment.moment(level.dateTime),
          level: level.level
        });
      });
    }),

    /**
     * Returns true if sleep log type is stage.
     */
    isStagesLog: Ember.computed.equal('type', 'stages'),

    /**
     * Returns true if sleep log is greater than EDIT_SLEEP_MAX_DAYS old
     */
    isOldSleep: Ember.computed('startTime', function () {
      var EDIT_SLEEP_MAX_DAYS = Ember.get(this, 'constants.EDIT_SLEEP_MAX_DAYS');
      var moment = Ember.get(this, 'moment');
      var today = moment.moment();
      var sleepStartDay = moment.moment(Ember.get(this, 'startTime')).startOf('day');
      return today.diff(sleepStartDay, 'days') > EDIT_SLEEP_MAX_DAYS;
    }),

    /**
     * Return whether sleep goal was reached for this sleep log
     */
    goalReached: Ember.computed('minutesAsleep', 'currentUser.sleepGoal.minDuration', function () {
      var sleepGoal = Ember.get(this, 'currentUser.sleepGoal.minDuration');
      var goalReached = false;

      if (sleepGoal) {
        goalReached = Ember.get(this, 'minutesAsleep') >= sleepGoal;
      }

      return goalReached;
    }),
    isToday: Ember.computed('dateOfSleep', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'moment', 'YEAR_MONTH_DAY', 'dateOfSleep'),
          moment = _EmberGetProperties.moment,
          YEAR_MONTH_DAY = _EmberGetProperties.YEAR_MONTH_DAY,
          dateOfSleep = _EmberGetProperties.dateOfSleep;

      return moment.moment(dateOfSleep, YEAR_MONTH_DAY).isSame(new Date(), 'days');
    })
  });

  _exports.default = _default;
});