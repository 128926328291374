define("fitbit-site-ui/components/device-interval-workout-setting", ["exports", "moment", "fitbit-site-ui/components/panel-component", "ember-cp-validations"], function (_exports, _moment, _panelComponent, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var Validations = (0, _emberCpValidations.buildValidations)({
    moveIntervalTime: (0, _emberCpValidations.validator)('number', {
      gte: 10,
      lt: 1200,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('interval_time_validation');
      })
    }),
    restIntervalTime: (0, _emberCpValidations.validator)('number', {
      gte: 10,
      lt: 1200,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('interval_time_validation');
      })
    }),
    moveIntervalDisplayTime: (0, _emberCpValidations.validator)('format', {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('interval_time_format_validation');
      })
    }),
    restIntervalDisplayTime: (0, _emberCpValidations.validator)('format', {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('interval_time_format_validation');
      })
    })
  });

  var _default = _panelComponent.default.extend(Validations, {
    moment: Ember.inject.service(),
    numIntervalsRange: Ember.computed(function () {
      //Max number of intervals we can have is 49
      var rangeT = _toConsumableArray(Array(49).keys()).map(function (i) {
        var index = i + 1;
        return {
          value: index,
          displayName: index
        };
      });

      return rangeT;
    }),
    numRepeats: Ember.computed('setting.numRepeats', function () {
      return this.get('setting.numRepeats').toString();
    }),
    moveIntervalDisplay: Ember.computed('setting.intervals', function () {
      var momentService = this.get('moment');

      var durationInSec = this.get('setting.intervals.0.durationSeconds') || 0,
          duration = _moment.default.duration(durationInSec, 'seconds');

      return momentService.moment("".concat(duration.minutes(), ":").concat(duration.seconds()), 'mm:s').format('mm:ss');
    }),
    restIntervalDisplay: Ember.computed('setting.intervals', function () {
      var momentService = this.get('moment');
      var intervals = this.get('setting.intervals'),
          durationInSec = intervals[1] && intervals[1].durationSeconds,
          formatter = this.get('i18n');

      if (durationInSec) {
        var duration = _moment.default.duration(durationInSec, 'seconds');

        return momentService.moment("".concat(duration.minutes(), ":").concat(duration.seconds()), 'mm:s').format('mm:ss');
      }

      return formatter.format('minutes_and_seconds');
    }),
    //Used for display -- these is the value the user modifies -- we only save once we validate
    moveIntervalDisplayTime: Ember.computed.oneWay('moveIntervalDisplay'),
    restIntervalDisplayTime: Ember.computed.oneWay('restIntervalDisplay'),
    //Used for validation purposes -- calculates how many seconds the user enter on each keypress
    moveIntervalTime: Ember.computed('moveIntervalDisplayTime', function () {
      var moveInterval = this.get('moveIntervalDisplayTime'),
          moveIntervalDuration = moveInterval && moveInterval.split(':');
      var duration = 0;

      if (moveIntervalDuration.length === 2) {
        duration = _moment.default.duration({
          seconds: parseInt(moveIntervalDuration[1]),
          minutes: parseInt(moveIntervalDuration[0])
        });
        duration = duration ? duration.asSeconds() : 0;
      }

      return duration;
    }),
    restIntervalTime: Ember.computed('restIntervalDisplayTime', function () {
      var restInterval = this.get('restIntervalDisplayTime'),
          restIntervalDuration = restInterval && restInterval.split(':');
      var duration = 0;

      if (restIntervalDuration.length === 2) {
        duration = _moment.default.duration({
          seconds: parseInt(restIntervalDuration[1]),
          minutes: parseInt(restIntervalDuration[0])
        });
        duration = duration ? duration.asSeconds() : 10;
      }

      return duration;
    }),
    restIntervalDisabled: Ember.computed('setting.intervals.[]', function () {
      var intervals = this.get('setting.intervals');
      return intervals.length === 1;
    }),
    restIntervalEnabled: Ember.computed.not('restIntervalDisabled'),
    actions: {
      changeNumIntervals: function changeNumIntervals(newNumber) {
        this.set('setting.numRepeats', newNumber);
        this.send('updateIntervalSettings');
      },
      restIntervalToggled: function restIntervalToggled(evt) {
        var isEnabled = evt.target.checked,
            intervals = this.get('setting.intervals');
        this.set('restIntervalDisabled', !isEnabled);

        if (!isEnabled) {
          intervals.pop();
          this.set('setting.intervals', intervals);
          this.send('updateIntervalSettings');
        }
      },
      updateIntervalSettings: function updateIntervalSettings() {
        var _this = this;

        var restIntervalDisabled = this.get('restIntervalDisabled'),
            moveIntervalTime = this.get('moveIntervalTime'),
            restIntervalTime = this.get('restIntervalTime'),
            restInterval = this.get('setting.intervals.1');
        this.validate().then(function (_ref) {
          var model = _ref.model,
              validations = _ref.validations;
          var validationAttrs = model.get('validations.attrs');

          if (validations.get('isValid') || restIntervalDisabled && !validationAttrs.get('restIntervalTime.isValid') && !validationAttrs.get('restIntervalDisplayTime.isValid') && validationAttrs.get('moveIntervalDisplayTime.isValid')) {
            _this.set('setting.intervals.0.durationSeconds', moveIntervalTime);

            if (!restIntervalDisabled && !restInterval) {
              _this.set('setting.intervals.1', {
                id: 1,
                durationSeconds: restIntervalTime
              });
            } else if (restInterval) {
              _this.set('setting.intervals.1.durationSeconds', restIntervalTime);
            }

            _this.updateSettings();
          }
        });
      }
    }
  });

  _exports.default = _default;
});