define("fitbit-site-ui/models/friend", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('profile'),
    list: _emberData.default.hasMany('friend/leaderboard/person')
  });

  _exports.default = _default;
});