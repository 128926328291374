define("fitbit-site-ui/services/gdpr", ["exports", "consent-data/services/gdpr"], function (_exports, _gdpr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var GDPR = _gdpr.default.extend({
    errorLogger: Ember.inject.service(),

    /**
     * Snapdragon currently has not replaced the index page and must redirect using `location.href`
     * otherwise users will see an empty index page.
     *
     * @override
     * @param route
     * @returns {*}
     */
    replaceWithRoute: function replaceWithRoute(route) {
      var _EmberGet = Ember.get(this, '_config'),
          environment = _EmberGet.environment; // During testing, soft-navigating to the index is fine.


      if (environment === 'test') {
        return this._super.apply(this, arguments);
      } else {
        if (route === 'index') {
          return this.locationHref('/');
        } else {
          return this._super.apply(this, arguments);
        }
      }
    },

    /**
     * Override creating a URL instance to catch errors and report them to new relic.
     * @override
     * @returns {object}
     */
    createURL: function createURL() {
      try {
        return this._super.apply(this, arguments);
      } catch (err) {
        // Log the error if it can't successfuly create a URL.
        this.errorLogger.log(err);
        throw err;
      }
    },

    /**
     * Override to allow certain routes to be transitioned to.
     * @override
     * @param route
     */
    validateTransitionRoute: function validateTransitionRoute(route) {
      /**
       * Allow transition to logout route - users should be able to logout without giving consent
       */
      if (route === 'logout') {
        return true;
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  if (!GDPR_ENABLED) {
    /**
     * When GDPR_ENABLED is disabled, set `isRequired` to false so no further checks
     * are required. This flag can have /consent page handle redirection immediately
     * in case there are pages out in the wild that still direct to the /consent page.
     */
    GDPR.reopen({
      isRequired: false
    });
  }

  var _default = GDPR;
  _exports.default = _default;
});