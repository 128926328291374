define("fitbit-site-ui/models/activity", ["exports", "ember-data", "snapdragon-common/utils/unit-converter"], function (_exports, _emberData, _unitConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var Logger = Ember.Logger;
  var roundToDigits = _unitConverter.default.roundToDigits,
      kilometersToMeters = _unitConverter.default.kilometersToMeters,
      kilometersToYards = _unitConverter.default.kilometersToYards,
      milesToYards = _unitConverter.default.milesToYards,
      milesToMeters = _unitConverter.default.milesToMeters,
      secondsToMinutesAndSeconds = _unitConverter.default.secondsToMinutesAndSeconds;
  /**
   * @typedef {Object} Pace
   * @property {Number} minutes The minutes for the pace
   * @property {Number} seconds The seconds for the pace
   */

  /**
   * Activity model
   * @module app/models/activity
   */

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * Pace smoothing constant to generate _movingAverage Function
     */
    PACE_SMOOTHING_PERIOD: 3,
    //---------------- Summary -----------------//
    date: _emberData.default.attr(),
    // startTime
    activityName: _emberData.default.attr('string'),
    // activityName
    steps: _emberData.default.attr('number'),

    /**
     * Distance returned is in the unit defined by distanceUnit property.
     * Could be miles or kilometers depending on user distanceUnit passed in request header.
     */
    distance: _emberData.default.attr('number', {
      defaultValue: null
    }),

    /**
     * Duration of activity in milliseconds
     */
    duration: _emberData.default.attr('number'),
    calories: _emberData.default.attr('number'),
    //----------------- Metadata ---------------//
    activityTypeId: _emberData.default.attr('number'),
    distanceUnit: _emberData.default.attr('string', {
      defaultValue: null
    }),
    lastModified: _emberData.default.attr('date'),

    /**
     * Identifies log from `manual` entry or created by `tracker`
     */
    logType: _emberData.default.attr('string'),
    manualValuesSpecified: _emberData.default.attr(),
    // Object array
    source: _emberData.default.attr(),
    // Object array
    //------------- Always Appears -------------//
    activeDuration: _emberData.default.attr('number'),
    activityLevel: _emberData.default.attr(),
    // Object array
    caloriesLink: _emberData.default.attr('string'),
    //------------ Sometimes Appears -----------//
    averageHeartRate: _emberData.default.attr('number', {
      defaultValue: null
    }),
    heartRateLink: _emberData.default.attr('string', {
      defaultValue: null
    }),
    heartRateZones: _emberData.default.attr({
      defaultValue: null
    }),
    // Object array
    speed: _emberData.default.attr('number', {
      defaultValue: null
    }),

    /**
     * poolLength and poolLengthUnit notes:
     * [4:45 PM] Vafa Mottahedin: note that we get the poolLength and poolUnit from the exercise object itself, not from the user prefs or something
     * [4:46 PM] Vafa Mottahedin: this was a product requirement to 'remember' the poolLength and poolUnit across each individual swim - the idea being, you swim at a pool at home that is 25m, but then you go to the YMCA which is 25 yards - we want to show total distance in the unit for each individual pool
     */
    poolLength: _emberData.default.attr('number', {
      defaultValue: null
    }),
    poolLengthUnit: _emberData.default.attr('string', {
      defaultValue: null
    }),
    swimLengths: _emberData.default.attr('number', {
      defaultValue: null
    }),

    /**
     * Pace in seconds
     */
    pace: _emberData.default.attr('number', {
      defaultValue: null
    }),
    details: _emberData.default.attr({
      defaultValue: null
    }),
    elevationGain: _emberData.default.attr('number', {
      defaultValue: null
    }),
    useManualDistance: Ember.computed.bool('manualValuesSpecified.distance'),

    /**
     * Link for downloading exported activity.
     * Currently generated here because Activity API does not provide the link.
     * Filed IPD-64485 for Activity API to provide the link.
     */
    tcxLink: Ember.computed('hasGPSData', 'hasHeartRateEnabled', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'hasGPSData', 'hasHeartRateEnabled'),
          hasGPSData = _EmberGetProperties.hasGPSData,
          hasHeartRateEnabled = _EmberGetProperties.hasHeartRateEnabled;

      var link = '';

      if (hasGPSData || hasHeartRateEnabled) {
        var id = Ember.get(this, 'id'); // Due to https://github.com/emberjs/data/issues/4923 in 2.12, we need to check internalModel for modelName as a fallback.

        var modelName = this.constructor.modelName || this._internalModel.modelName;
        var adapter = Ember.get(this, 'store').adapterFor(modelName);
        var url = adapter.urlForFindRecord(id, modelName);
        link = url.replace('.json', '.tcx');
      }

      return link;
    }),
    percentTimeActive: Ember.computed('duration', 'activeDuration', function () {
      return Ember.get(this, 'activeDuration') / Ember.get(this, 'duration') * 100;
    }),
    momentDate: Ember.computed('date', function () {
      var momentService = Ember.get(this, 'moment');
      var NON_LOCALIZED_FORMAT = Ember.get(this, 'constants.NON_LOCALIZED_FORMAT');
      return momentService.moment(Ember.get(this, 'date'), NON_LOCALIZED_FORMAT);
    }),

    /**
     * Formatted display date.
     * @type {String}
     */
    displayDate: Ember.computed('momentDate', function () {
      var date = Ember.get(this, 'momentDate');
      return date.format('LLL');
    }),

    /**
     * Distance computed from activity type, owner user's distance unit, and pool unit.
     *
     * When activity is a swim and distance is manually entered, distance is converted from pool length unit.
     *
     * When activity is a swim, distance is computed from pool length and swing lengths if data is available.
     *
     * When activity is a swim, distance is converted to pool length unit, if unit is available.
     *
     * Defaults to returning distance.
     *
     * @returns {Number}
     */
    distanceByActivity: Ember.computed('distance', 'isSwimActivity', 'isUserDistanceMetric', 'poolLengthUnit', 'useManualDistance', 'poolLength', 'swimLengths', function () {
      var isSwimActivity = Ember.get(this, 'isSwimActivity');
      var distance = Ember.get(this, 'distance');

      if (isSwimActivity) {
        var _EmberGetProperties2 = Ember.getProperties(this, 'isUserDistanceMetric', 'useManualDistance', 'poolLength', 'swimLengths'),
            isUserDistanceMetric = _EmberGetProperties2.isUserDistanceMetric,
            useManualDistance = _EmberGetProperties2.useManualDistance,
            poolLength = _EmberGetProperties2.poolLength,
            swimLengths = _EmberGetProperties2.swimLengths;

        var poolLengthUnit = Ember.get(this, 'poolLengthUnit');
        var shouldComputeFromSwimLengths = Ember.isPresent(poolLength) && Ember.isPresent(swimLengths); // If poolLengthUnit is missing, use the user's distance unit to determine the poolLengthUnit.

        if (!Ember.isPresent(poolLengthUnit)) {
          if (isUserDistanceMetric) {
            poolLengthUnit = 'Meter';
          } else {
            poolLengthUnit = 'Yard';
          }
        } // Compute swim distance if distance is manually entered or both pool length swim length is not present.
        // Otherwise, compute distance from pool and swim length.


        if (useManualDistance || !shouldComputeFromSwimLengths) {
          // set poolLengthUnit if it's undefined.
          if (isUserDistanceMetric) {
            distance = this._convertDistanceFromKilometers(distance, poolLengthUnit);
          } else {
            distance = this._convertDistanceFromMiles(distance, poolLengthUnit);
          }
        } else if (shouldComputeFromSwimLengths) {
          // poolLength is already in the expected unit, so no need to convert to yards or meters, refer to poolLength and poolLengthUnit jsdoc comments
          distance = poolLength * swimLengths;
        }
      }

      return distance;
    }),

    /**
     * Duration displayed in hh:mm:ss
     * @type {String}
     */
    displayDuration: Ember.computed('activeDuration', function () {
      // Annoyingly, moment.duration doesn't have hh:mm:ss output display
      var millisDuration = Ember.get(this, 'activeDuration');
      var hours = Math.floor(millisDuration / 3600000);
      millisDuration = millisDuration % 3600000;
      var minutes = Math.floor(millisDuration / 60000);
      millisDuration = millisDuration % 60000;
      var seconds = Math.floor(millisDuration / 1000);
      var displayDuration = '';

      if (hours) {
        if (hours < 10) {
          displayDuration += '0';
        }

        displayDuration += hours + ':';
      }

      if (minutes < 10) {
        displayDuration += '0';
      }

      displayDuration += minutes + ':';

      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      displayDuration += seconds;
      return displayDuration;
    }),
    isUserDistanceMetric: Ember.computed.equal('currentUser.distanceUnit', 'METRIC'),

    /**
     * Distance rounded to 2 decimal places.
     * @type {Number}
     */
    displayDistance: Ember.computed('distanceByActivity', function () {
      var distance = Ember.get(this, 'distanceByActivity');
      var roundedDistance = null;

      if (Ember.isPresent(distance) && distance > 0) {
        roundedDistance = roundToDigits(distance, 2);
      }

      return roundedDistance;
    }),

    /**
     * Determine if the activity is a bike by checking activityTypeId.
     * @returns {Boolean}
     */
    isBikeActivity: Ember.computed('activityTypeId', function () {
      var activityType = Ember.get(this, 'activityTypeId');
      var bikeTypes = [90001
      /*bike*/
      , 1071
      /*outdoor bike*/
      , 20048
      /*mountain bike*/
      ];
      return bikeTypes.includes(activityType);
    }),

    /**
     * Determine if the activity is a swim by checking activityTypeId.
     * @returns {Boolean}
     */
    isSwimActivity: Ember.computed('activityTypeId', function () {
      var activityType = Ember.get(this, 'activityTypeId');
      var swimTypes = [90024
      /*swim*/
      ];
      return swimTypes.includes(activityType);
    }),

    /**
     * Display pace as minutes and seconds
     * @returns {Pace} Seconds converted to an object containing minutes and seconds
     */
    displayPace: Ember.computed('isSwimActivity', 'pace', 'duration', 'distanceByActivity', function () {
      var isSwimActivity = Ember.get(this, 'isSwimActivity');
      var pace = null; // Swim needs to calculate pace as time per 100 meters

      if (isSwimActivity) {
        var distance = Ember.get(this, 'distanceByActivity');

        if (Ember.isPresent(distance) && distance > 0) {
          // Milliseconds
          var duration = Ember.get(this, 'duration');
          pace = duration / (distance / 100) / 1000;
        }
      } else {
        pace = Ember.get(this, 'pace');
      }

      return this._formatPace(pace);
    }),
    hasHeartRateEnabled: Ember.computed.or('averageHeartRate', 'heartRateLink', 'heartRateZones'),
    heartRateValues: _emberData.default.belongsTo('time-series/heart'),
    calorieValues: _emberData.default.belongsTo('time-series/calorie'),
    dailySummary: _emberData.default.belongsTo('activity-summary'),

    /**
     * Calculates total time spent in heart rate zone from this.heartRateZones
     * @returns {Number} - minutes in hr zone
     */
    timeInHeartRateZone: Ember.computed('heartRateZones', function () {
      var heartRateZones = Ember.get(this, 'heartRateZones');
      (false && !(heartRateZones) && Ember.assert('Cannot calculate time in heart rate zones because data is missing', heartRateZones));
      return heartRateZones.reduce(function (prev, item, i) {
        if (i !== 3) {
          return prev + item.minutes;
        }

        return prev;
      }, 0);
    }),

    /**
     * Calculates total time spent out of heart rate zone from this.heartRateZones
     * @returns {Number} - minutes out of hr zone
     */
    timeOutOfHeartRateZone: Ember.computed('heartRateZones', function () {
      var heartRateZones = Ember.get(this, 'heartRateZones');
      (false && !(heartRateZones) && Ember.assert('Cannot calculate time out of heart rate zones because data is missing', heartRateZones));
      return heartRateZones[3].minutes;
    }),
    hasGPSData: Ember.computed('details', function () {
      return Ember.get(this, 'details.trackpoints');
    }),
    hasElevationFeature: Ember.computed('source.trackerFeatures', function () {
      var trackerFeatures = Ember.get(this, 'source.trackerFeatures');
      return trackerFeatures && trackerFeatures.includes('ELEVATION');
    }),
    splits: Ember.computed('details.splits', function () {
      return Ember.getWithDefault(this, 'details.splits', null);
    }),

    /**
     * Given an array of split values (either laps or splits)
     * @param splits - array of split values
     * @returns {Array | null} - array of percentage values or null
     * @private
     */
    _calculateSplitPercent: function _calculateSplitPercent(splits) {
      // Ember.isEmpty will check if the value is falsey or if it is an empty array.
      if (!Ember.isEmpty(splits)) {
        var splitDurationList = splits.map(function (split) {
          if (split.distance <= 0) {
            return null;
          }

          return split.duration / split.distance;
        });
        var filteredSplitDurationList = splitDurationList.filter(function (split) {
          return split > 0;
        });
        var max = Math.max.apply(Math, _toConsumableArray(filteredSplitDurationList));
        var min = Math.min.apply(Math, _toConsumableArray(filteredSplitDurationList));
        var splitListPercent = [];

        if (splitDurationList.length === 1 && max === min) {
          splitListPercent.push(100); // one single split gets full width
        } else {
          splitListPercent = splitDurationList.map(function (pace) {
            if (pace) {
              return Math.round((pace - min) * 100 / (max - min));
            }

            return null;
          });
        }

        return splitListPercent;
      } else {
        return null;
      }
    },

    /**
     * Returns a list of relative percent values based on split duration
     * The slowest time is 100 and fastest is 0 (used for CSS chart display)
     * @return {Array}
     */
    splitListPercent: Ember.computed('splits', function () {
      var splits = Ember.get(this, 'splits');
      return this._calculateSplitPercent(splits);
    }),

    /**
     * If the activity has laps, details.manualSplits within the activities object
     * will contain the user's splits.
     * @returns {Array|null} - if manualSplits appears, return the splits, else null
     */
    laps: Ember.computed('details.manualSplits', function () {
      var manualSplits = Ember.get(this, 'details.manualSplits');
      return manualSplits && manualSplits;
    }),

    /**
     * Returns a list of relative percent values based on lap split duration
     * The slowest time is 100 and fastest is 0 (used for CSS chart display)
     * @return {Array}
     */
    lapListPercent: Ember.computed('laps', function () {
      var laps = Ember.get(this, 'laps');

      if (Ember.isEmpty(laps)) {
        return null;
      }

      return this._calculateSplitPercent(laps);
    }),

    /**
     * Calculates total exercise time
     * @returns {Object} - {start: miliseconds of start date, end: milliseconds of end date}
     */
    exerciseTime: Ember.computed('date', 'duration', function () {
      var moment = Ember.get(this, 'moment');
      var date = Ember.get(this, 'date');
      var duration = Ember.get(this, 'duration');

      var newDate = this._removeTmz(date);

      var start = moment.moment(newDate);
      var end = start.clone().add(duration, 'milliseconds').valueOf();
      return {
        start: start.valueOf(),
        end: end
      };
    }),
    paceField: Ember.computed('isBikeActivity', function () {
      return Ember.get(this, 'isBikeActivity') ? 'speed' : 'pace';
    }),
    totalPaceOrSpeed: Ember.computed('pace', 'speed', 'paceField', function () {
      var fieldName = Ember.get(this, 'paceField');
      return Ember.get(this, fieldName);
    }),
    paceOrSpeedUnits: Ember.computed('paceField', 'details.units.{pace,speed}', function () {
      var fieldName = Ember.get(this, 'paceField');
      return Ember.get(this, "details.units.".concat(fieldName));
    }),

    /**
     * Filters trackpoints to display on pace chart
     * @returns {Object} - { time: (in milliseconds), distance:, value: }
     */
    paceDatasetForChart: Ember.computed('details', 'paceField', 'isBikeActivity', function () {
      var moment = Ember.get(this, 'moment');
      var NON_LOCALIZED_FORMAT = Ember.get(this, 'constants.NON_LOCALIZED_FORMAT');
      var paceField = Ember.get(this, 'paceField');
      var trackpoints = Ember.get(this, 'details.trackpoints');
      var isBikeActivity = Ember.get(this, 'isBikeActivity');
      trackpoints = trackpoints.filter(function (point) {
        return !point.paused;
      });
      trackpoints = trackpoints.map(function (point) {
        var time = moment.moment(point.date, NON_LOCALIZED_FORMAT).valueOf();
        var val = point[paceField];

        if (!isBikeActivity) {
          val = val / 60;
        }

        return {
          time: time,
          distance: point.distance,
          value: val
        };
      });
      return trackpoints.filter(function (point) {
        return point.value !== 'undefined' && point.value !== null;
      });
    }),

    /**
     * Calculated based on pace dataset for display on pace-chart
     * uses _movingAverage to calculate smoothed datapoint
     * @returns {Array} - array of cloned trackpoints with smoothed .value values
     */
    smoothedPaceDatasetForChart: Ember.computed('paceDatasetForChart', function () {
      var paceDatasetForChart = Ember.get(this, 'paceDatasetForChart'),
          PACE_SMOOTHING_PERIOD = Ember.get(this, 'PACE_SMOOTHING_PERIOD');

      var paceSmoother = this._movingAverager(PACE_SMOOTHING_PERIOD);
      /**
       * The first pace value is always 0 and since we're using a moving average
       * we apply the third point to the first two to avoid skewing
       * presentation.
       */


      if (paceDatasetForChart.length > 2) {
        paceDatasetForChart[0].value = paceDatasetForChart[2].value;
        paceDatasetForChart[1].value = paceDatasetForChart[2].value;
      }

      var paceDatasetForChartCopy = paceDatasetForChart.map(function (a) {
        return Object.assign({}, a);
      });
      paceDatasetForChartCopy && paceDatasetForChartCopy.forEach(function (trackpoint) {
        trackpoint.value = paceSmoother(trackpoint.value);
      });
      return paceDatasetForChartCopy;
    }),

    /**
     * Filters trackpoints to display on elevation chart
     * @returns {Array} - array of filtered trackpoints to display
     */
    elevationDatasetForChart: Ember.computed('details', function () {
      var moment = Ember.get(this, 'moment');
      var trackpoints = Ember.get(this, 'details.trackpoints');
      var NON_LOCALIZED_FORMAT = Ember.get(this, 'constants.NON_LOCALIZED_FORMAT');
      trackpoints = trackpoints.filter(function (point) {
        return !point.paused;
      });
      return trackpoints.filter(function (item) {
        return !!item.elevation;
      }).map(function (point) {
        var time = moment.moment(point.date, NON_LOCALIZED_FORMAT).valueOf();
        return {
          time: time,
          distance: point.distance,
          value: point.elevation
        };
      });
    }),

    /**
     * Adds distance data to HR and Calorie trackpoints to display on heartrate chart
     * @returns {Array} - array of filtered trackpoints to display
     */
    heartRateDatasetForChart: Ember.computed('details', 'heartRateValues.dataset', function () {
      var dataset = Ember.getWithDefault(this, 'heartRateValues.dataset', []);
      var gpsTrackpoints = Ember.get(this, 'details.trackpoints');
      var heartRateValuesData; // extrapolate missing data points

      if (dataset.length) {
        heartRateValuesData = this._extrapolateHeartRateData(dataset);

        this._formatTimeSeriesTimeData(heartRateValuesData);

        if (gpsTrackpoints) {
          this._addDistanceValues(heartRateValuesData);
        }
      }

      return heartRateValuesData;
    }),

    /**
     * Adds distance data to HR and Calorie trackpoints to display on calorie chart
     * @returns {Array} - array of filtered trackpoints to display
     */
    calorieDatasetForChart: Ember.computed('details', 'calorieValues.dataset', function calorieDatasetForChart() {
      var dataset = Ember.getWithDefault(this, 'calorieValues.dataset', []);
      var gpsTrackpoints = Ember.get(this, 'details.trackpoints');
      var calorieValuesData;

      if (dataset.length) {
        // copy the data so the source dataset does not get mutated
        calorieValuesData = dataset.map(function (datum) {
          return _objectSpread({}, datum);
        });

        this._formatTimeSeriesTimeData(calorieValuesData);

        if (gpsTrackpoints) {
          this._addDistanceValues(calorieValuesData);
        }
      }

      return calorieValuesData;
    }),

    /**
     * Downloads TCX file based on tcxLink property with ajax request
     * @returns {Promise}
     */
    downloadTCX: function downloadTCX() {
      var tcxLink = Ember.get(this, 'tcxLink');
      var downloadPromise = null;

      if (tcxLink) {
        var ajax = Ember.get(this, 'ajax');
        downloadPromise = ajax.request(tcxLink, {
          processData: false,
          dataType: 'text'
        });
      } else {
        downloadPromise = Ember.RSVP.reject(new Error('TCX is unavailable for this activity'));
      }

      return downloadPromise;
    },

    /**
     * Removed the timezone offset from the end of the date object
     * to preserve the time of the exercise and prevent localization
     * of the start and end time.
     *
     * 2017-03-11T11:49:51.000-08:00, remove -8:00
     * 2017-03-11T11:49:51.000Z, remove Z
     *
     * @param date
     * @private
     */
    _removeTmz: function _removeTmz(date) {
      var timezoneRegex = /(?:Z|[+-][0-9]{2}:[0-9]{2})$/;
      return date.replace(timezoneRegex, '');
    },

    /**
     * An object containing unpaused trackpoint k/v pairs where the key is the trackpoint's date formatted by time as HH:mm:ss and the
     * value is the trackpoint.
     *
     * @returns {Object}
     */
    trackpointsObjectByTime: Ember.computed('details.trackpoints', function () {
      var _this = this;

      var trackpoints = Ember.get(this, 'details.trackpoints');
      return trackpoints.reduce(function (obj, point) {
        // Only include unpaused values.
        if (!point.paused) {
          var timeArr = _this._removeTmz(point.date).split('T')[1].split(':');

          var _timeArr = _slicedToArray(timeArr, 3),
              hours = _timeArr[0],
              minutes = _timeArr[1],
              seconds = _timeArr[2];

          var formattedTime = "".concat(hours, ":").concat(minutes, ":").concat(seconds.split('.')[0]);
          obj[formattedTime] = point;
        }

        return obj;
      }, {});
    }),

    /**
     * Add distance values to HR and calorie data for charts
     * @param {Array} data Timeseries data
     * @private
     */
    _addDistanceValues: function _addDistanceValues(data) {
      var _this2 = this;

      var _EmberGetProperties3 = Ember.getProperties(this, 'distance', 'details', 'trackpointsObjectByTime'),
          distance = _EmberGetProperties3.distance,
          trackpointsObjectByTime = _EmberGetProperties3.trackpointsObjectByTime,
          trackpointsArray = _EmberGetProperties3.details.trackpoints;

      if (trackpointsArray.length) {
        var momentService = Ember.get(this, 'moment');
        var endTime = momentService.moment(this._removeTmz(trackpointsArray[trackpointsArray.length - 1].date)).valueOf();
        var dataLastIndex = data.length - 1;
        data.forEach(function (item, i) {
          var time = new Date(item.time);
          var trackpointVal = null;

          while (!trackpointVal) {
            var _this2$_generateHHmms = _this2._generateHHmmss(time),
                hours = _this2$_generateHHmms.hours,
                minutes = _this2$_generateHHmms.minutes,
                seconds = _this2$_generateHHmms.seconds;

            trackpointVal = trackpointsObjectByTime["".concat(hours, ":").concat(minutes, ":").concat(seconds)];

            if (!trackpointVal) {
              if (i === dataLastIndex) {
                item.distance = distance;
                trackpointVal = -1;
              } else {
                // Increment time by 1 second to look up trackpoint on trackpointsObjectByTime again during the loop
                time = new Date(time.getTime() + 1000); // Stop the loop because the end has been reached

                if (time.getTime() >= endTime) {
                  item.distance = distance;
                  trackpointVal = -1;
                }
              }
            } else {
              item.distance = trackpointVal.distance;
            }
          }
        });
      }
    },

    /**
     * From a date object, create an object with hours, minutes, and seconds as strings.
     *
     * The values should be left-padded with a 0 if less than 9.
     *
     * @param {Date} time
     * @returns {{hours: string, minutes: string, seconds: string}}
     * @private
     */
    _generateHHmmss: function _generateHHmmss(time) {
      var hours = time.getHours(),
          minutes = time.getMinutes(),
          seconds = time.getSeconds();
      var hourPlaceholder = hours > 9 ? '' : '0';
      var minPlaceholder = minutes > 9 ? '' : '0';
      var secondPlaceholder = seconds > 9 ? '' : '0';
      return {
        hours: "".concat(hourPlaceholder).concat(hours),
        minutes: "".concat(minPlaceholder).concat(minutes),
        seconds: "".concat(secondPlaceholder).concat(seconds)
      };
    },

    /**
     * Format pace into m:ss string.
     * @param {Number} pace Pace in seconds
     * @returns {?String} Formatted string as m:ss or null
     * @private
     */
    _formatPace: function _formatPace(pace) {
      var formattedPace = null;

      if (pace) {
        var _secondsToMinutesAndS = secondsToMinutesAndSeconds(pace),
            minutes = _secondsToMinutesAndS.minutes,
            seconds = _secondsToMinutesAndS.seconds;

        seconds = roundToDigits(seconds, 0);

        if (seconds == 60) {
          minutes++;
          seconds = 0;
        }

        if (!minutes) {
          minutes = '00';
        } else {
          minutes = "".concat(minutes);
        }

        if (!seconds) {
          seconds = '00';
        } else if (seconds < 10) {
          seconds = "0".concat(seconds);
        }

        formattedPace = "".concat(minutes, "'").concat(seconds, "\"");
      }

      return formattedPace;
    },

    /**
     * Convert distance value from kilometers to supported units.
     * @param {Number} distance
     * @param {String|undefined} to The unit to convert distance to.
     * @returns {*}
     * @private
     */
    _convertDistanceFromKilometers: function _convertDistanceFromKilometers(distance) {
      var to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      switch (to.toLowerCase()) {
        case 'meter':
          distance = kilometersToMeters(distance);
          break;

        case 'yard':
          distance = kilometersToYards(distance);
          break;

        default:
          Logger.error("".concat(to, " unit is not supported"));
      }

      return distance;
    },

    /**
     * Convert distance value from miles to supported units.
     * @param {Number} distance
     * @param {String|undefined} to The unit to convert distance to.
     * @returns {*}
     * @private
     */
    _convertDistanceFromMiles: function _convertDistanceFromMiles(distance) {
      var to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      switch (to.toLowerCase()) {
        case 'meter':
          distance = milesToMeters(distance);
          break;

        case 'yard':
          distance = milesToYards(distance);
          break;

        default:
          Logger.error("".concat(to, " unit is not supported"));
      }

      return distance;
    },

    /**
     * Formatting the timestamp from time-series (calories and HR) to MS.
     * Including the date and timezone offset for the date of the exercise.
     * Endpoint only returns time with no context. (E.g., '10:32:00')
     * @param data
     * @returns {null}
     * @private
     */
    _formatTimeSeriesTimeData: function _formatTimeSeriesTimeData(data) {
      var _this3 = this;

      // Using momentDate, which is based off of the `date` property to normalize the date object across browsers.
      var date = Ember.get(this, 'momentDate').toDate();
      var prevHour;

      if (data && data.length > 0) {
        var _data$0$time$split = data[0].time.split(':');

        var _data$0$time$split2 = _slicedToArray(_data$0$time$split, 1);

        prevHour = _data$0$time$split2[0];
      }

      data && data.filter(function (point) {
        return typeof point.time === 'string';
      }).forEach(function (point) {
        var _point$time$split = point.time.split(':'),
            _point$time$split2 = _slicedToArray(_point$time$split, 1),
            currHour = _point$time$split2[0];

        if (parseInt(prevHour, 10) === 23 && parseInt(currHour, 10) === 0) {
          // Add date for timestamps that occur over midnight '23:59:00' to '00:00:00'
          date.setDate(date.getDate() + 1);
        }

        prevHour = currHour;
        date = _this3._convertTimeString(point.time, date);
        point.time = date.valueOf();
      });
    },

    /**
     * Smooth the data to remove extraneous outliers
     *
     * @param period
     * @returns {Function}
     * @private
     */
    _movingAverager: function _movingAverager(period) {
      var nums = [];
      return function (num) {
        var sum = 0,
            n = period;
        nums.push(num);

        if (nums.length > period) {
          nums.splice(0, 1);
        }

        nums.forEach(function (num) {
          sum += num;
        });

        if (isNaN(sum)) {
          return 0;
        }

        if (nums.length < period) {
          n = nums.length;
        }

        return sum / n;
      };
    },

    /**
     * Converts a time string into js date object
     * @param {String} time eg. "12:34:03"
     * @param {Object} date
     * @returns {Object} js date object
     * @private
     */
    _convertTimeString: function _convertTimeString(time) {
      var date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
      var timeArr = time.split(':');
      date.setHours.apply(date, _toConsumableArray(timeArr));
      return date;
    },

    /**
     * Converts a date object into time string
     * @param {Date} date
     * @returns {String} eg. "12:34:03"
     * @private
     */
    _convertDateObj: function _convertDateObj(date) {
      var dso = this._generateHHmmss(date);

      return "".concat(dso.hours, ":").concat(dso.minutes, ":").concat(dso.seconds);
    },

    /**
     * Adds missing seconds and bpm values for a more complete chart-friendly dataset
     * @param {Object} data - heart rate values data from server
     * @returns {Object} augmented heart rate values data
     * @private
     */
    _extrapolateHeartRateData: function _extrapolateHeartRateData(data) {
      var result = [];
      var dataLength = data.length; // cache length to speed up loop
      //cycle through original json entries

      for (var i = 1; i < dataLength; i++) {
        // get the difference in time
        var timePrev = this._convertTimeString(data[i - 1].time);

        var timeCurr = this._convertTimeString(data[i].time);

        var timeDiff = Math.floor((timeCurr - timePrev) / 1000); // get the difference in bpm

        var bpmPrev = data[i - 1].value;
        var bpmCurr = data[i].value;
        var bpmDiff = bpmCurr - bpmPrev; // get average change in bpm per second

        var bpmShiftPerSec = bpmDiff / timeDiff;
        var timeNew = new Date(timePrev); // generate missing data points

        for (var j = 0; j < timeDiff; j++) {
          // add one increment second for each missing data point
          timeNew.setSeconds(timeNew.getSeconds() + 1); // extrapolate the missing bpm value

          var extrapolatedBpm = bpmPrev + j * bpmShiftPerSec;
          result.push({
            time: this._convertDateObj(timeNew),
            value: Math.round(extrapolatedBpm)
          });
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});