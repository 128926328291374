define("fitbit-site-ui/adapters/user-prop-info", ["exports", "fitbit-site-ui/adapters/application", "fitbit-site-ui/constants/user-props"], function (_exports, _application, _userProps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: _userProps.USER_PROPS_ADMIN_NAMESPACE,
    pathForType: function pathForType() {
      return 'prop-info';
    }
  });

  _exports.default = _default;
});