define("fitbit-site-ui/models/device/scale/user-list", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    users: _emberData.default.hasMany('device/scale/user')
  });

  _exports.default = _default;
});