define("fitbit-site-ui/components/device-clock-face-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    viewport: Ember.inject.service('device/layout'),
    imageRes: '2x',
    onSettingsChanged: function onSettingsChanged() {},
    hasOrientation: Ember.computed.bool('setting.orientation'),
    horizontalOptions: Ember.computed.filterBy('clockFaceOptions', 'orientation', 'HORIZONTAL'),
    verticalOptions: Ember.computed.filterBy('clockFaceOptions', 'orientation', 'VERTICAL'),
    clockFaceOptions: Ember.computed('options.[]', function () {
      var imageRes = this.get('imageRes');
      var options = this.get('options');

      if (options) {
        return options.map(function (option) {
          return Ember.assign(option, {
            imageUrl: option.imageUrl.replace('@2x', "@".concat(imageRes))
          });
        });
      }
    }),
    actions: {
      onClockFaceChanged: function onClockFaceChanged(clockFaceObject) {
        var _this = this;

        var formatter = this.get('i18n');
        var setting = this.get('setting'); // Only change if the settings are different.

        if (setting.name !== clockFaceObject.name) {
          this.set('setting', clockFaceObject);
          Ember.RSVP.resolve(this.get('onSettingsChanged')()).then(function () {
            _this.showFlashMessage('success', formatter.format('updated_settings'));
          }).catch(function (err) {
            var error = err && err.errors && err.errors[0];
            var errorMessage = error && error.message || formatter.format('device_setting_err');

            _this.showFlashMessage('alert', errorMessage);
          });
        }
      }
    }
  });

  _exports.default = _default;
});