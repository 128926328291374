define("fitbit-site-ui/models/goal/daily", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    activeMinutes: _emberData.default.attr('number'),
    caloriesOut: _emberData.default.attr('number'),
    distance: _emberData.default.attr('number'),
    floors: _emberData.default.attr('number'),
    steps: _emberData.default.attr('number')
  }); // const userID = this.currentUser.get('id');
  // this.store.findRecord('goal/daily', userID);


  _exports.default = _default;
});