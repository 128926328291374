define("fitbit-site-ui/models/device/scale/user", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    bodyType: _emberData.default.attr(),
    scaleUserName: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    userInfo: _emberData.default.attr(),
    //This could probably belongTo the profile model -- might change depending on privacy
    scaleId: _emberData.default.attr(),
    userIconId: _emberData.default.attr('number'),
    scale: _emberData.default.belongsTo('device/scale/user-scale'),
    fullName: Ember.computed.alias('userInfo.displayName'),
    avatar: Ember.computed.alias('userInfo.avatar')
  });

  _exports.default = _default;
});