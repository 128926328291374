define("fitbit-site-ui/routes/settings/corporate", ["exports", "snapdragon-common/routes/authenticated", "fitbit-site-ui/constants/settings/corporate-fsc"], function (_exports, _authenticated, _corporateFsc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    titleToken: function titleToken() {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('route_title', 'route.settings.corporate');
    },
    model: function model(_params, transition) {
      var store = Ember.get(this, 'store');
      var cwPrograms = store.peekAll('cw-program-participant');
      var programs = cwPrograms.filterBy('program.profileTabEnabled');

      if (programs.length === 0) {
        transition.abort();
        return this.replaceWith('/404');
      }

      return {};
    },
    afterModel: function afterModel() {
      this.set('breadCrumb', {
        title: this.titleToken()
      });
      this.metrics.trackPage((0, _corporateFsc.fitbitCareDesktopEvent)({
        view: _corporateFsc.FITBIT_CARE_DESKTOP_PROFILE_TAB_VIEW
      }));
    }
  });

  _exports.default = _default;
});