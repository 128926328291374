define("fitbit-site-ui/serializers/journal-survey-question-single-choice", ["exports", "fitbit-site-ui/serializers/journal-survey-question"], function (_exports, _journalSurveyQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _journalSurveyQuestion.default.extend({});

  _exports.default = _default;
});