define("fitbit-site-ui/serializers/journal-survey", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      questions: {
        serialize: true,
        embedded: 'always'
      }
    },
    keyForAttribute: function keyForAttribute(attr, method) {
      if (method === 'serialize') {
        return Ember.String.dasherize(attr);
      } else {
        return Ember.String.camelize(attr);
      }
    },
    serialize: function serialize() {
      var serialization = this._super.apply(this, arguments); // Convert journal-survey-question types to backend expected


      serialization.questions.forEach(function (question) {
        delete question.questionId;
      });
      return serialization;
    },
    extractRelationship: function extractRelationship(relationshipModelName, relationshipHash) {
      var type = relationshipHash.type;
      relationshipHash.type = "journal-survey-question-".concat(type);
      return this._super.apply(this, arguments);
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'journal-survey-question-multiple-choices') {
        return payloadKey;
      }

      return this._super.apply(this, arguments);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      if (modelName === 'journal-survey') {
        return 'survey';
      } else {
        return this._super.apply(this, arguments);
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var survey = payload.survey;
      var surveyId = "".concat(survey['reference-namespace'], "-").concat(survey.reference);
      Ember.set(survey, 'id', surveyId);
      survey.questions.forEach(function (question, index) {
        question.questionId = "".concat(surveyId, "-").concat(question.id);
        question.order = index + 1; // Display index starting at 1

        question.survey = surveyId;
      }); // Use serializer _super() to generate JSONAPI-like response

      return this._super(store, primaryModelClass, {
        'journal-survey': survey
      }, surveyId, requestType);
    }
  });
  /*
  Fake example data
  const x = {
      survey: {
          'reference-namespace': 'heart-alert-high',
          reference: '12345',
          version: '0.5',
          title: 'Heart Survey',
          questions: [
              {
                  id: 'HEART_RATE_FACTORS',
                  title:
                      'Were you experiencing or using any of the below leading up to the notification?',
                  subtitle:
                      'These factors are known to raise heart rate. Please mark all that apply.',
                  'category-title': 'Potential causes',
                  type: 'multiple-choices',
                  responses: [
                      {
                          id: 'STRESS',
                          title: 'Stress or intense mood',
                          subtitle: 'Includes anger, grief, fear, frustration',
                          selected: false,
                          'journal-entry': {
                              'entry-type': 'STRESS',
                              value: 'true',
                              meta: '',
                          },
                      },
                  ],
              },
          ],
      },
  };
  */


  _exports.default = _default;
});