define("fitbit-site-ui/components/flash-message", ["exports", "ember-cli-flash/components/flash-message"], function (_exports, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _flashMessage.default.extend({
    classNames: ['toast']
  });

  _exports.default = _default;
});