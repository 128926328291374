define("fitbit-site-ui/routes/settings/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    titleToken: function titleToken() {
      var i18n = this.get('i18n');
      return i18n.formatByNamespace('route_title', 'route.settings.notification-settings');
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var userId = Ember.get(this, 'currentUser.id');
      return store.findRecord('notification-settings-list', userId);
    },
    afterModel: function afterModel() {
      this.set('breadCrumb', {
        title: this.titleToken()
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});