define("fitbit-site-ui/serializers/weight-goal", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var weightGoal = payload.goal;
      weightGoal.id = id;
      var newPayload = {
        'weight-goal': weightGoal
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});