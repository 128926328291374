define("fitbit-site-ui/models/cover-photo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /**
     * Cover image as a path to a png file. Offered in
     * multiple sizes (small, medium, large)
     */
    url: _emberData.default.attr('string', {
      defaultValue: null
    }),
    smallUrl: _emberData.default.attr('string', {
      defaultValue: null
    }),
    mediumUrl: _emberData.default.attr('string', {
      defaultValue: null
    }),
    largeUrl: _emberData.default.attr('string', {
      defaultValue: null
    })
  });

  _exports.default = _default;
});