define("fitbit-site-ui/components/panel-component", ["exports", "fitbit-site-ui/mixins/prevent-flash-duplicate"], function (_exports, _preventFlashDuplicate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_preventFlashDuplicate.default, {
    tagName: 'section',
    classNames: ['panel-component', 'row', 'column', 'collapse', 'border', 'body'],
    attributeBindings: ['panelOpened:aria-expanded', 'ariaLabel:aria-labelledby'],
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    panelStates: null,
    panelOpened: Ember.computed.alias('panelStates.panelOpened'),
    editPanelOpened: Ember.computed.alias('panelStates.editPanelOpened'),
    ariaLabel: Ember.computed('title', function () {
      var title = this.get('title');
      return title ? Ember.String.dasherize(title) : '';
    }),

    /**
     * Show flash messages by type.
     * @param {String} type The type of message.
     * @param {String} message Message to display.
     * @param {Object} options Flash message config object.
     * @returns {Object} Flash messages service.
     */
    showFlashMessage: function showFlashMessage(type, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var flashMessages = this.get('flashMessages');
      (false && !(flashMessages[type]) && Ember.assert("The ".concat(type, " type of flash message does not exist."), flashMessages[type]));

      this._clearMessageFromQueue(message);

      return flashMessages[type](message, options);
    },
    updateSettings: function updateSettings() {
      var _this = this;

      var formatter = this.get('i18n'); // Wrap in a promise in case the onSettingsChanged doesn't return one.

      return Ember.RSVP.resolve(this.get('onSettingsChanged')()).then(function () {
        _this.showFlashMessage('success', formatter.format('updated_settings'));
      }).catch(function (err) {
        var error = err && err.errors[0];
        var errorMessage = error.message || formatter.format('device_setting_err');

        _this.showFlashMessage('alert', errorMessage);

        throw err;
      });
    },
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty('panelOpened');
      },
      edit: function edit() {
        this.toggleProperty('editPanelOpened');
      }
    }
  });

  _exports.default = _default;
});