define("fitbit-site-ui/adapters/sleep-date", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.2/user',
    query: function query(store, type, _query) {
      var startDate = _query.startDate,
          endDate = _query.endDate;
      return this._super.apply(this, arguments).then(function (payload) {
        payload.startDate = startDate;
        payload.endDate = endDate;
        return payload;
      });
    },
    queryRecord: function queryRecord(store, type, query) {
      var date = query.date;
      return this._super.apply(this, arguments).then(function (payload) {
        payload.date = date;
        return payload;
      });
    },
    pathForType: function pathForType() {
      return '';
    },
    urlForQuery: function urlForQuery(query) {
      return "".concat(this._super.apply(this, arguments), "/").concat(query.id, "/sleep/date/").concat(query.startDate, "/").concat(query.endDate, ".json");
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      return "".concat(this._super.apply(this, arguments), "/").concat(query.id, "/sleep/date/").concat(query.date, ".json");
    }
  });

  _exports.default = _default;
});