define("fitbit-site-ui/adapters/device/tracker/settings/options", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'devices/tracker/options';
    },
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '.json';
    },

    /**
     * Override urlForFindRecord to make it act like urlFindAll. The endpoint with <id>.json returns incorrect data.
     * @param id
     * @param modelName
     * @param snapshot
     * @returns {*}
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._super('', modelName, snapshot);
    }
  });

  _exports.default = _default;
});