define("fitbit-site-ui/serializers/application-authorization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'applications') {
        return 'my-application';
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});