define("fitbit-site-ui/templates/settings/resend-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P5JcGBck",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"app-loader \",[27,\"unless\",[[23,[\"isLoading\"]],\"hide\"],null]]]],[9],[1,[27,\"format\",[\"loading\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"email-resend-container\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"email-resend-title row column small-12\"],[9],[1,[27,\"format-by-namespace\",[\"resend_email_title\",\"route.email-resend\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"email-resend-subtext row column small-12\"],[9],[1,[27,\"format-by-namespace\",[[27,\"if\",[[27,\"eq\",[[23,[\"responseState\"]],[23,[\"VERIFIED\"]]],null],\"resend_email_already_verified\",\"resend_email_subtext\"],null],\"route.email-resend\"],null],false],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"value\",\"data-test-qa\",\"disabled\"],[\"text\",\"column small-9 medium-6\",[23,[\"model\",\"profile\",\"email\"]],\"current-email-to-change\",true]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column shrink email-resend-indicator\"],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"email-success-indicator \",[27,\"lowercase\",[[23,[\"responseState\"]]],null]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"responseState\"]],[23,[\"FAILURE\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"row column small-12 error verified-email-row\"],[9],[1,[21,\"failureText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"responseState\"]],[23,[\"VERIFIED\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"row column small-12 verified-email-row\"],[9],[1,[21,\"verifiedText\"],false],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/settings/resend-email.hbs"
    }
  });

  _exports.default = _default;
});