define("fitbit-site-ui/adapters/health-programs/user", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    namespace: '1/user/:userid/hp/admin',
    pathForType: function pathForType() {
      return 'user';
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var queryUserId = query && Ember.get(query, 'userId');
      delete query.userId;
      var url = "".concat(this._super.apply(this, arguments), ".json");
      var userId = queryUserId || Ember.get(this, 'currentUser.id') || '-';
      return url.replace(':userid', userId);
    }
  });

  _exports.default = _default;
});