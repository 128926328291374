define("fitbit-site-ui/serializers/achievement", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var currentUserId = this.get('currentUser.id');
      payload.achievements.forEach(function (achievement) {
        var achievementType = achievement.achievementType;
        var challengeType = achievement.challengeType;
        achievement.id = "".concat(currentUserId, "_").concat(challengeType).concat(achievementType).toLowerCase();
        return achievement;
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});