define("fitbit-site-ui/models/user-prop-info", ["exports", "ember-data", "fitbit-site-ui/constants/user-props"], function (_exports, _emberData, _userProps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    spaceName: _emberData.default.belongsTo('user-space'),
    key: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    creator: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    ajax: Ember.inject.service(),
    _manageSingleUser: function _manageSingleUser(email, value) {
      var ajax = this.ajax;
      var encodedEmail = encodeURIComponent(email);
      return ajax.put("/user/".concat(encodedEmail, "/props"), {
        namespace: _userProps.USER_PROPS_ADMIN_NAMESPACE,
        data: [{
          name: this.key,
          value: value
        }],
        contentType: 'application/json',
        dataType: 'text'
      });
    },
    addUserToWhitelist: function addUserToWhitelist(email) {
      return this._manageSingleUser(email, true);
    },
    removeUserFromWhitelist: function removeUserFromWhitelist(email) {
      return this._manageSingleUser(email, false);
    },

    /**
     * @param userList - csv string of `email,value` pairs
     * @private
     */
    _manageMultipleUsers: function _manageMultipleUsers(userList) {
      var ajax = this.ajax;
      var whitelist = this.key;
      return ajax.post("/prop/prop-info/".concat(whitelist), {
        namespace: _userProps.USER_PROPS_ADMIN_NAMESPACE,
        data: userList,
        contentType: 'application/csv',
        dataType: 'text'
      });
    },
    _batchManageMultipleUsers: function _batchManageMultipleUsers(userList, fileType) {
      var ajax = this.ajax;
      var whitelist = this.key;
      return ajax.post("/prop/prop-info/".concat(whitelist, "/bulkByEmails"), {
        namespace: _userProps.USER_PROPS_ADMIN_NAMESPACE,
        data: userList,
        processData: false,
        contentType: fileType || 'application/octect-stream'
      });
    },
    bulkUpdateUsersInWhitelist: function bulkUpdateUsersInWhitelist(userList) {
      return this._manageMultipleUsers(userList);
    },
    batchUpdateUsersInWhitelist: function batchUpdateUsersInWhitelist(userList, fileType) {
      return this._batchManageMultipleUsers(userList, fileType);
    }
  });

  _exports.default = _default;
});