define("fitbit-site-ui/templates/components/device-main-goal-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q7AF0bS/",
    "block": "{\"symbols\":[\"mainGoalOpt\"],\"statements\":[[7,\"p\"],[11,\"class\",\"description\"],[9],[0,\"\\n    \"],[1,[27,\"format-by-namespace\",[\"main_goal_detail\",\"templates.components.device-main-goal-setting\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-6\"],[9],[0,\"\\n        \"],[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectMainGoalOption\"],[[\"value\"],[\"target.value\"]]]],[11,\"data-test-qa\",\"main-goal-dropdown\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"selectableMainGoalOptions\"]]],null,{\"statements\":[[0,\"                \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"eq\",[[23,[\"mainGoalSetting\"]],[22,1,[]]],null]],[9],[1,[27,\"format-by-namespace\",[[22,1,[]],\"templates.components.device-main-goal-setting\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-main-goal-setting.hbs"
    }
  });

  _exports.default = _default;
});