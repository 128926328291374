define("fitbit-site-ui/models/user-achievement-summary", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _relationships.belongsTo)('profile', {
      async: false,
      inverse: 'achievementSummary'
    }),

    /**
     *
     * Best achievement stats.
     * Unknown possibly sensitive origin
     * {
     *     "total": {
     *         "floors": {
     *             "date": "2015-04-12",
     *             "value": 87.00000013224
     *         }
     *     },
     *     "tracker": {
     *         "distance": {
     *             "date": "2013-06-16",
     *             "value": 19.662203971253998
     *         },
     *         "floors": {
     *             "date": "2015-04-12",
     *             "value": 87.00000013224
     *         },
     *         "steps": {
     *             "date": "2013-06-16",
     *             "value": 36438
     *         }
     *     }
     * }
     */
    best: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    /**
     * Lifetime achievement stats.
     * Unknown possibly sensitive origin
     * {
     *    "total": {
     *        "activeScore": -1,
     *        "caloriesOut": -1,
     *        "distance": 12394.15,
     *        "floors": 36954,
     *        "steps": 33823814
     *    },
     *    "tracker": {
     *        "activeScore": -1,
     *        "caloriesOut": -1,
     *        "distance": 8595.57,
     *        "floors": 36954,
     *        "steps": 18518001
     *    }
     * }
     */
    lifetime: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    })
  });

  _exports.default = _default;
});