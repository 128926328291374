define("fitbit-site-ui/components/settings-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userDevices: null,
    deviceTypes: null,
    deviceGuides: null,
    devices: Ember.computed('userDevices.[]', 'deviceTypes.[]', function () {
      var userDevices = Ember.get(this, 'userDevices');
      var deviceTypes = Ember.get(this, 'deviceTypes');
      (false && !(Ember.isArray(userDevices)) && Ember.assert('userDevices must be an array', Ember.isArray(userDevices)));
      (false && !(Ember.isArray(deviceTypes)) && Ember.assert('deviceTypes must be an array', Ember.isArray(deviceTypes))); // Filter out user devices that that do not have a matching name.)

      return userDevices.filter(function (userDevice) {
        return deviceTypes.findBy('name', Ember.get(userDevice, 'name')) || Ember.get(userDevice, 'isLegacy');
      });
    }),
    guides: Ember.computed('devices.[]', 'deviceGuides.[]', function () {
      var deviceGuides = Ember.get(this, 'deviceGuides');

      if (!deviceGuides) {
        return false;
      }

      var devices = Ember.get(this, 'devices');
      return deviceGuides.filter(function (guide) {
        return devices.findBy('name', guide.name);
      });
    })
  });

  _exports.default = _default;
});