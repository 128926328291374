define("fitbit-site-ui/models/weight-goal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    goalType: _emberData.default.attr({
      defaultValue: null
    }),
    startDate: _emberData.default.attr(),
    startWeight: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    weight: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    weightThreshold: _emberData.default.attr('number', {
      defaultValue: 50
    }),
    currentUser: Ember.inject.service(),

    /**
     * User's current weight goal
     */
    goalWeight: Ember.computed.oneWay('weight'),

    /**
     * Indicates that user has not set a weight goal yet.
     */
    hasNoGoal: Ember.computed('goalType', function () {
      return !Ember.get(this, 'goalType');
    }),

    /**
     * Indicates that goal was reached.
     */
    goalReached: Ember.computed('goalType', 'goalWeight', 'currentUser.weight', function () {
      var goalType = Ember.get(this, 'goalType');
      var currentWeight = Ember.get(this, 'currentUser.weight');
      var goalWeight = Ember.get(this, 'goalWeight');
      var weightThreshold = Ember.get(this, 'weightThreshold');

      if (goalType === 'LOSE') {
        return currentWeight <= goalWeight + weightThreshold;
      } else if (goalType === 'GAIN') {
        return currentWeight >= goalWeight - weightThreshold;
      } else {
        return currentWeight >= goalWeight - weightThreshold && currentWeight <= goalWeight + weightThreshold;
      }
    }),

    /**
     * Indicates if the goal is in progress
     * @returns { bool } - True if the goal is currently in progress, false if not
     */
    goalInProgress: Ember.computed('hasNoGoal', 'goalReached', function () {
      return !Ember.get(this, 'hasNoGoal') && !Ember.get(this, 'goalReached');
    }),

    /**
     * Weight difference from user's starting weight
     */
    weightDifference: Ember.computed('startWeight', 'currentUser.weight', function () {
      return Math.abs(Ember.get(this, 'startWeight') - Ember.get(this, 'currentUser.weight'));
    }),

    /**
     * Weight remaining to achieve goal
     */
    weightRemaining: Ember.computed('goalWeight', 'currentUser.weight', function () {
      return Math.abs(Ember.get(this, 'currentUser.weight') - Ember.get(this, 'goalWeight'));
    })
  });

  _exports.default = _default;
});