define("fitbit-site-ui/templates/family/family-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vzw/bFKO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n          \"],[7,\"h1\"],[9],[1,[27,\"format\",[\"join_family\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n          \"],[7,\"p\"],[9],[1,[27,\"format\",[\"accept_family_invitation\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n          \"],[7,\"p\"],[9],[1,[27,\"format\",[\"part_of_app\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[11,\"style\",\"padding-bottom:20px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n         \"],[7,\"a\"],[11,\"class\",\"store-link\"],[11,\"href\",\"http://itunes.apple.com/us/app/fitbit-activity-calorie-tracker/id462638897?mt=8&uo=4\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"src\",\"/assets/images/onboarding/appstores/app-store.png\"],[11,\"alt\",\"app store\"],[9],[10],[0,\"\\n         \"],[10],[0,\"\\n         \"],[7,\"a\"],[11,\"class\",\"store-link\"],[11,\"href\",\"http://play.google.com/store/apps/details?id=com.fitbit.FitbitMobile\"],[9],[0,\"\\n             \"],[7,\"img\"],[11,\"src\",\"/assets/images/onboarding/appstores/googleplay.png\"],[11,\"alt\",\"play store\"],[9],[10],[0,\"\\n         \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/family/family-invite.hbs"
    }
  });

  _exports.default = _default;
});