define("fitbit-site-ui/controllers/settings/notifications", ["exports", "fitbit-site-ui/constants/settings/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    sortedNotificationList: Ember.computed('list.[]', function () {
      var list = Ember.get(this, 'model.list');
      return list.sortBy('label');
    }),
    _toggleNotificationState: function _toggleNotificationState(notification, type) {
      var currentState = Ember.get(notification, "".concat(type, "CheckState"));

      this._setNotificationState(notification, type, !currentState ? _notifications.STATE_ON : _notifications.STATE_OFF);
    },
    _setNotificationState: function _setNotificationState(notification, type, state) {
      Ember.set(notification, "".concat(type, "CheckState"), state === _notifications.STATE_ON);
      Ember.set(notification, "".concat(type, "State"), state);
    },
    actions: {
      toggleNotificationState: function toggleNotificationState(notification, type) {
        return this._toggleNotificationState(notification, type);
      },
      resetNotifications: function resetNotifications(list) {
        return list.forEach(function (r) {
          r.rollbackAttributes();
        });
      },
      saveNotifications: function saveNotifications(list) {
        var _this = this;

        Ember.set(this, 'isLoading', true);
        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        return list.save().then(function () {
          flashMessages.success(formatter.formatByNamespace('notification_settings_updated', 'components.notification-settings'));
        }).catch(function () {
          flashMessages.alert(formatter.formatByNamespace('notification_settings_error', 'components.notification-settings'));
        }).finally(function () {
          Ember.set(_this, 'isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});