define("fitbit-site-ui/models/device/scale/invited-user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    inviteId: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    scaleUserName: (0, _attr.default)('string'),
    userInfo: (0, _attr.default)(),
    fullName: Ember.computed.alias('email'),
    avatar: Ember.computed.alias('userInfo.avatar'),
    isInvitedUser: Ember.computed.bool('inviteId'),
    scaleUserList: (0, _relationships.belongsTo)('device/scale/invited-user-list', {
      inverse: 'users'
    })
  });

  _exports.default = _default;
});