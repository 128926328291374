define("fitbit-site-ui/serializers/health-programs/program", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'health-programs/program';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'program';
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var programs = Ember.get(payload, 'programs');
      programs.forEach(function (p) {
        if (p.programVersions) {
          Ember.set(p, 'programVersions', p.programVersions.sortBy('versionName'));
        }
      });
      Ember.set(payload, 'programs', programs.sortBy('title'));
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});