define("fitbit-site-ui/controllers/settings/data/account-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    isLoading: false,
    firstPartyAuthorizations: Ember.computed.filter('model.authorizations', function (authorization) {
      return Ember.get(authorization, 'application.firstPartyApp');
    }),
    firstPartyAuthorizationsToDisplay: Ember.computed.filter('firstPartyAuthorizations', function (authorization) {
      return Ember.get(authorization, 'sessionData');
    }),
    deviceModelSplit: Ember.computed('firstPartyAuthorizationsToDisplay', function () {
      var authorizations = Ember.get(this, 'firstPartyAuthorizationsToDisplay');
      var length = Ember.get(authorizations, 'length');
      var halfLength = Math.ceil(length / 2);
      return {
        left: authorizations.slice(0, halfLength),
        right: authorizations.slice(halfLength)
      };
    }),
    deviceModelLeft: Ember.computed.alias('deviceModelSplit.left'),
    deviceModelRight: Ember.computed.alias('deviceModelSplit.right'),
    actions: {
      revokeAuthorization: function revokeAuthorization(authorization) {
        var _this = this;

        if (!Ember.get(this, 'isLoading')) {
          Ember.set(this, 'isLoading', true);
          var formatter = Ember.get(this, 'i18n');
          return authorization.destroyRecord().then(function () {
            Ember.get(_this, 'flashMessages').success(formatter.formatByNamespace('revoke_specific_success_msg', 'templates.settings.account-access'));
          }).catch(function () {
            Ember.get(_this, 'flashMessages').alert(formatter.formatByNamespace('revoke_specific_error_msg', 'templates.settings.account-access'));
          }).finally(function () {
            Ember.set(_this, 'isLoading', false);
          });
        }
      },
      revokeAllAuthorizations: function revokeAllAuthorizations() {
        var _this2 = this;

        if (!Ember.get(this, 'isLoading')) {
          Ember.set(this, 'isLoading', true);
          var ajax = Ember.get(this, 'ajax');
          var store = Ember.get(this, 'store');
          var formatter = Ember.get(this, 'i18n');
          return ajax.del('/user/-/application-authorizations?filter[byUserId]=true').then(function () {
            store.unloadAll('application-authorization');
            Ember.set(_this2, 'isLoading', false);
          }).catch(function () {
            Ember.get(_this2, 'flashMessages').alert(formatter.formatByNamespace('revoke_specific_error_msg', 'templates.settings.account-access'));
            Ember.set(_this2, 'isLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});