define("fitbit-site-ui/adapters/friend/sent-email-invitation", ["exports", "fitbit-site-ui/adapters/friend/sent-invitation"], function (_exports, _sentInvitation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sentInvitation.default.extend();

  _exports.default = _default;
});