define("fitbit-site-ui/templates/components/user-action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0oIY5GOk",
    "block": "{\"symbols\":[\"@dataTestQa\",\"@userActionType\",\"@buttonTitle\",\"@buttonType\",\"@onClick\"],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"button user-action-button \",[21,\"class\"]]]],[12,\"data-test-qa\",[28,[\"user-action-button \",[22,2,[]],\"-button \",[27,\"if\",[[22,1,[]],[22,1,[]]],null]]]],[12,\"title\",[28,[[22,3,[]]]]],[12,\"aria-label\",[28,[[22,3,[]]]]],[12,\"type\",[28,[[27,\"or\",[[22,4,[]],\"button\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"fit-glyph\",null,[[\"data-test-qa\",\"type\"],[\"fit-glyph\",[22,2,[]]]]],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[22,5,[]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-action-button.hbs"
    }
  });

  _exports.default = _default;
});