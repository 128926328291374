define("fitbit-site-ui/adapters/food-meal", ["exports", "ember-data", "snapdragon-common/mixins/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _emberData.default.JSONAPIAdapter.extend(_application.default, {
    namespace: '1.1/user',

    /**
     * Returns the model name without applying pluralization transforms.
     *
     * @override
     * @param {string} modelName
     * @returns {string}
     */
    pathForType: function pathForType(modelName) {
      return modelName;
    },

    /**
     * Builds URL for `findAll`
     *
     * @override
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var userId = this._getUserIdFromSnapshot(snapshot);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(modelName, snapshot), ".json");
    },

    /**
     * Builds URL for `findRecord`.
     *
     * @override
     * @param {string|number} id The ID of the record to find.
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var userId = this._getUserIdFromSnapshot(snapshot);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(id, modelName, snapshot), ".json");
    },

    /**
     * Builds URL for `createRecord`.
     *
     * @override
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var userId = this._getUserIdFromSnapshot(snapshot);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(modelName, snapshot), ".json");
    },

    /**
     * Builds URL for `updateRecord`.
     *
     * @override
     * @param {string|number} id The ID of the record to update.
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var userId = this._getUserIdFromSnapshot(snapshot);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(id, modelName, snapshot), ".json");
    },

    /**
     * Builds URL for `deleteRecord`.
     *
     * @override
     * @param {string|number} id The ID of the record to delete.
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var userId = this._getUserIdFromSnapshot(snapshot);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(id, modelName, snapshot), ".json");
    },

    /**
     * Builds URL for `query`.
     *
     * @override
     * @param {object} $0
     * @param {string|undefined} $0.userId
     * @param {...object} query
     * @param {string} modelName
     * @returns {string}
     */
    urlForQuery: function urlForQuery(_ref, modelName) {
      var _ref$userId = _ref.userId,
          userId = _ref$userId === void 0 ? '-' : _ref$userId,
          query = _objectWithoutProperties(_ref, ["userId"]);

      modelName = this._buildModelNameWithUserId(userId);
      return "".concat(this._super(query, modelName), ".json");
    },

    /**
     * Lookup the userId from `snapshot.adapterOptions.userId` or return the default `-`.
     *
     * @param {object} snapshot
     * @returns {string}
     * @private
     */
    _getUserIdFromSnapshot: function _getUserIdFromSnapshot(snapshot) {
      return Ember.getWithDefault(snapshot, 'adapterOptions.userId', '-');
    },

    /**
     * Combine a modelName with `userId`.
     *
     * @param {string} userId User ID.
     * @param {string|undefined} [modelName=meals] The model name used for URL creation.
     * @returns {string}
     * @private
     */
    _buildModelNameWithUserId: function _buildModelNameWithUserId(userId) {
      var modelName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'meals';
      return "".concat(userId, "/").concat(modelName);
    }
  });

  _exports.default = _default;
});