define("fitbit-site-ui/constants/optimizely", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OPTIMIZELY_DATAFILE_HASH = void 0;

  /**
   * Datafile endpoint hash
   */
  // Optimiely Config
  // App Beta Production env: 5N3sKYE6YSqWdZjm4S5a5L
  // App Production env: WYs5sjKCFL8ye9rdLgL3WY
  var apiUrl = _emberGetConfig.default.fitbit.apiUrl;
  var OPTIMIZELY_DATAFILE_HASH = apiUrl.includes('web-api.fitbit.com') ? 'WYs5sjKCFL8ye9rdLgL3WY' : '5N3sKYE6YSqWdZjm4S5a5L';
  _exports.OPTIMIZELY_DATAFILE_HASH = OPTIMIZELY_DATAFILE_HASH;
});