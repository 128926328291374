define("fitbit-site-ui/adapters/sleep-stat", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    namespace: '1.2/user',
    pathForType: function pathForType() {
      return '';
    },
    urlForQuery: function urlForQuery(query) {
      var id = query.id,
          startDate = query.startDate,
          endDate = query.endDate;
      delete query.id;
      delete query.startDate;
      delete query.endDate;
      return "".concat(this._super.apply(this, arguments), "/").concat(id, "/sleep/stats/date/").concat(startDate, "/").concat(endDate, ".json");
    }
  });

  _exports.default = _default;
});