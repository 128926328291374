define("fitbit-site-ui/components/device-auto-run-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    autoRunAvailable: Ember.computed('gpsSettings', function () {
      var gpsSettings = Ember.get(this, 'gpsSettings');
      var run = gpsSettings.find(function (item) {
        return item.exerciseId === 2; // run
      });
      return run && run.gpsOn;
    }),
    actions: {
      autoRunSettingChanged: function autoRunSettingChanged() {
        this.updateSettings();
      }
    }
  });

  _exports.default = _default;
});