define("fitbit-site-ui/constants/user-props", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_PROPS_ADMIN_NAMESPACE = _exports.USER_PROPS_NAMESPACE = void 0;
  var USER_PROPS_NAMESPACE = '1/user-props';
  _exports.USER_PROPS_NAMESPACE = USER_PROPS_NAMESPACE;
  var USER_PROPS_ADMIN_NAMESPACE = '1/user-props/admin';
  _exports.USER_PROPS_ADMIN_NAMESPACE = USER_PROPS_ADMIN_NAMESPACE;
});