define("fitbit-site-ui/templates/components/device-tap-gesture-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y56JZNvj",
    "block": "{\"symbols\":[\"tapGestureSetting\"],\"statements\":[[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"format\",[\"tap_gestures_description\"],[[\"deviceName\"],[[23,[\"deviceName\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"tapGestures\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[11,\"class\",\"label-input\"],[12,\"data-test-qa\",[28,[\"tap-gesture-\",[22,1,[\"name\"]]]]],[11,\"tabindex\",\"0\"],[9],[0,\"\\n        \"],[1,[27,\"radio-button\",null,[[\"value\",\"groupValue\"],[[22,1,[\"name\"]],[23,[\"selectedTapGesture\"]]]]],false],[0,\"\\n        \"],[1,[22,1,[\"displayName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/device-tap-gesture-setting.hbs"
    }
  });

  _exports.default = _default;
});