define("fitbit-site-ui/serializers/weight-graph-trendline", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'dateTime',
    _getTimeForRecords: function _getTimeForRecords(dateTime, averagedWeights) {
      if (averagedWeights) {
        // Averages
        return "".concat(dateTime.substr(0, 10), " 23:59:59");
      } else {
        return dateTime.replace('T', ' ');
      }
    },
    _generateWeightTrend: function _generateWeightTrend(graphValues, averagedWeights) {
      var _this = this;

      return graphValues.map(function (g) {
        var dateTime = _this._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          'weight-graph': dateTime,
          value: g.weight,
          dateTime: dateTime
        };
      });
    },
    _generateWeightGraph: function _generateWeightGraph(graphValues, averagedWeights) {
      var _this2 = this;

      return graphValues.map(function (g) {
        var dateTime = _this2._getTimeForRecords(g.dateTime, averagedWeights);

        return {
          'weight-graph-trendline': dateTime,
          dateTime: dateTime
        };
      });
    },

    /**
     * Returns true if the graphValues were requested with 3m, 12m, or all
     * @param graphValues - Array of graph values
     * @returns {boolean}
     * @private
     */
    _spansMultipleMonths: function _spansMultipleMonths(meta) {
      switch (meta.durationType) {
        case '7d':
        case '1m':
          return false;

        default:
          return true;
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var graphValues = payload.graphValues,
          nextGraphValues = payload.nextGraphValues,
          prevGraphValues = payload.prevGraphValues;
      var meta = payload.meta; // Default to 3m if no queryParams

      if (!meta) {
        meta = {
          durationType: '3m'
        };
      }

      var averagedWeights = this._spansMultipleMonths(meta);

      var combinedGraphValues = graphValues.concat(nextGraphValues, prevGraphValues);
      var newPayload = {
        'weight-graph-trendline': this._generateWeightTrend(combinedGraphValues, averagedWeights),
        'weight-graph': this._generateWeightGraph(combinedGraphValues, averagedWeights)
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});