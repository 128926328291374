define("fitbit-site-ui/components/scale-people-settings", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'user.scaleUserName': (0, _emberCpValidations.validator)('length', {
      description: 'Nickname length',
      min: 1,
      max: 3,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('nickname_msg', {
          min: '{min}',
          max: '{max}'
        });
      })
    })
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['user-scale'],
    isCurrentUser: Ember.computed('user.id', function () {
      //equal computed property does not work here, something about service injection?
      return this.get('currentUser.id') === this.get('user.id');
    }),
    emailText: '',
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty('panelOpened');
      },

      /*
       * Toggles the body type
       * @param {String} value REGULAR|LEAN
       */
      toggleBodyType: function toggleBodyType(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.set('user.bodyType', value);

            _this.get('user').save().then(function () {
              return flashMessages.success(formatter.format('updated_settings'));
            });
          }
        });
      },

      /*
       * Changes the nickname registered on the scale
       */
      focusOutNickname: function focusOutNickname() {
        var _this2 = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.validate().then(function (_ref2) {
          var validations = _ref2.validations;

          if (validations.get('isValid')) {
            _this2.get('user').save().then(function () {
              return flashMessages.success(formatter.format('new_nickname'));
            });
          } else {
            flashMessages.alert(formatter.format('fix_errors'));
          }
        });
      },
      resendInvite: function resendInvite(invitedUser) {
        var formatter = this.get('i18n');
        var inviteEmail = invitedUser.get('email'),
            scaleId = invitedUser.get('scaleUserList.id'),
            //TODO when we i18n: make sure this string matches the default used in scales
        defaultEmailText = this.get('emailText'),
            flashMessages = this.get('flashMessages');
        invitedUser.get('scaleUserList').then(function (list) {
          list.inviteUser(inviteEmail, defaultEmailText, scaleId).then(function () {
            flashMessages.success(formatter.format('resend_success', {
              inviteEmail: inviteEmail
            }));
          }).catch(function () {
            return flashMessages.alert(formatter.format('resent_err', {
              inviteEmail: inviteEmail
            }));
          });
        });
      }
    }
  });

  _exports.default = _default;
});