define("fitbit-site-ui/templates/components/user-notifications/user-notification-friend-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Xh1QDQp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"user-notifications/user-notification-base\",null,[[\"notification\",\"markAsRead\"],[[23,[\"notification\"]],[23,[\"markAsRead\"]]]],{\"statements\":[[4,\"link-to\",[\"profile\",[23,[\"notification\",\"sentBy\",\"encodedId\"]]],[[\"class\"],[\"notification-profile-link\"]],{\"statements\":[[0,\"        \"],[1,[27,\"format-by-namespace\",[\"view_profile\",\"templates.components.settings-nav\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-notifications/user-notification-friend-request.hbs"
    }
  });

  _exports.default = _default;
});