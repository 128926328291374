define("fitbit-site-ui/components/device-battery-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    /**
     * @property batteryStatus
     * @type {string}
     */
    batteryStatus: '',

    /**
     * @property level
     * @type {string}
     * @param {string} batteryStatus from device object
     */
    level: Ember.computed('batteryStatus', function () {
      var level = this.get('batteryStatus') || 'undefined';
      return 'battery-' + level.toLowerCase();
    })
  });

  _exports.default = _default;
});