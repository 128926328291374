define("fitbit-site-ui/templates/components/user-profile-friends-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FuidW1ys",
    "block": "{\"symbols\":[\"friend\"],\"statements\":[[7,\"div\"],[11,\"class\",\"user-profile-list user-profile-friends-list\"],[11,\"data-test-qa\",\"user-profile-friends-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"friends\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[4,\"link-to\",[\"profile.index\",[22,1,[\"id\"]]],[[\"class\",\"data-test-qa\"],[\"list-item row align-middle collapse\",\"user-profile-friends-list-item\"]],{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"column shrink\"],[9],[0,\"\\n                \"],[7,\"img\"],[11,\"class\",\"friend-avatar\"],[12,\"alt\",[27,\"concat\",[[22,1,[\"displayName\"]],[27,\"format-by-namespace\",[\"image\",\"templates.components.user-profile\"],null]],null]],[11,\"data-test-qa\",\"friend-avatar\"],[12,\"src\",[28,[[22,1,[\"avatar\"]]]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"row uncollapse-child\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"column\"],[11,\"data-test-qa\",\"friend-display-name\"],[9],[1,[22,1,[\"displayName\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbit-site-ui/templates/components/user-profile-friends-list.hbs"
    }
  });

  _exports.default = _default;
});