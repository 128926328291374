define("fitbit-site-ui/components/bgr-breadcrumbs", ["exports", "@bagaar/ember-breadcrumbs/components/bgr-breadcrumbs"], function (_exports, _bgrBreadcrumbs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bgrBreadcrumbs.default;
    }
  });
});