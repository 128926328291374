define("fitbit-site-ui/constants/profile-roles-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PRIVACY_SET_TO_PUBLIC = _exports.PRIVACY_SET_TO_FRIENDS = _exports.PRIVACY_SET_TO_PRIVATE = _exports.VIEW_ROLE_PUBLIC = _exports.VIEW_ROLE_FRIENDS = _exports.VIEW_ROLE_OWNER = void 0;

  /**************************************************
   * PROFILE PRIVACY AND VIEW ROLES
   *************************************************/

  /**
   * Profile owner views own profile as self role
   * @type {String}
   */
  var VIEW_ROLE_OWNER = 'owner';
  /**
   * Profile owner views own profile as friend role
   * @type {string}
   */

  _exports.VIEW_ROLE_OWNER = VIEW_ROLE_OWNER;
  var VIEW_ROLE_FRIENDS = 'friends';
  /**
   * Profile owner views own profile as public role
   * @type {String}
   */

  _exports.VIEW_ROLE_FRIENDS = VIEW_ROLE_FRIENDS;
  var VIEW_ROLE_PUBLIC = 'public';
  /**
   * Privacy setting per data field: private
   * @type {string}
   */

  _exports.VIEW_ROLE_PUBLIC = VIEW_ROLE_PUBLIC;
  var PRIVACY_SET_TO_PRIVATE = 'private';
  /**
   * Privacy setting per data field: friends
   * @type {string}
   */

  _exports.PRIVACY_SET_TO_PRIVATE = PRIVACY_SET_TO_PRIVATE;
  var PRIVACY_SET_TO_FRIENDS = 'friends';
  /**
   * Privacy setting per data field: public
   * @type {string}
   */

  _exports.PRIVACY_SET_TO_FRIENDS = PRIVACY_SET_TO_FRIENDS;
  var PRIVACY_SET_TO_PUBLIC = 'public';
  _exports.PRIVACY_SET_TO_PUBLIC = PRIVACY_SET_TO_PUBLIC;
});