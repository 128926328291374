define("fitbit-site-ui/models/time-series/step", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    activitiesSteps: _emberData.default.attr(),
    activitiesStepsIntraday: _emberData.default.attr(),
    date: Ember.computed.alias('activitiesSteps.0.dateTime'),
    summaryVal: Ember.computed.alias('activitiesSteps.0.value'),
    dataset: Ember.computed.alias('activitiesStepsIntraday.dataset')
  }); // Sample URL & Data
  // GET /1/user/-/activities/steps/date/2016-06-05/1d/15min.json
  // Unknown possibly sensitive origin
  // {
  //   "activities-steps":  [
  //      {
  //       "dateTime": "2016-06-05",
  //       "value": "8486"
  //     }
  //   ],
  //   "activities-steps-intraday":  {
  //     "dataset":  [
  //        {
  //         "time": "00:00:00",
  //         "value": 5
  //       },
  //        {
  //         "time": "00:15:00",
  //         "value": 8
  //       },
  //        {
  //         "time": "00:30:00",
  //         "value": 0
  //       },
  //       ..
  //     ]
  // 	}
  // }


  _exports.default = _default;
});