define("fitbit-site-ui/serializers/favorite-food", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var loggedFoods = [];
      var favorites = payload.map(function (food) {
        var foodId = Ember.get(food, 'foodId');
        Ember.set(food, 'favorite', true);
        var defaultServingUnit = Ember.get(food, 'defaultUnit.name');
        Ember.set(food, 'defaultServingUnit', defaultServingUnit);
        Ember.set(food, 'isQuickCaloriesAdd', false); // Explicitly set the corresponding food item's favorite indicator to true because the food item
        // matches this favorite food

        loggedFoods.push({
          foodId: foodId,
          favorite: true
        });
        return {
          id: foodId,
          loggedFood: foodId,
          foodItem: foodId,
          nutritionalValues: Ember.get(food, 'nutritionalValues'),
          servings: Ember.get(food, 'servings'),
          units: Ember.get(food, 'units')
        };
      });
      var newPayload = {
        'food/food-item': payload,
        'food/logged-food': loggedFoods,
        'favorite-food': favorites
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      // Explicity set the food item and logged food indicators to true
      var newPayload = {
        'food/food-item': {
          favorite: true,
          foodId: id
        },
        'food/logged-food': {
          favorite: true,
          foodId: id
        },
        'favorite-food': {
          id: id,
          loggedFood: id,
          foodItem: id
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
      // Explicity set the food item and logged food favorite indicators to false
      var newPayload = {
        'food/food-item': {
          favorite: false,
          foodId: id
        },
        'food/logged-food': {
          favorite: false,
          foodId: id
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});