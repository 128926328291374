define("fitbit-site-ui/models/favorite-food", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Favorite foods are no longer a relationship from food-item or logged-food towards favorite foods because the order
   * of serialization between recent/frequent/custom needed to explicitly set the relationship to null, while the serialization
   * of the favorite food records would initialize it to the corresponding record.  Because this order could not be
   * enforced, it was changed to a boolean with a default value that was only modified by the favorite-food serializer
   */
  var _default = _emberData.default.Model.extend({
    loggedFood: _emberData.default.belongsTo('food/logged-food'),
    foodItem: _emberData.default.belongsTo('food/food-item'),
    nutritionalValues: _emberData.default.attr(),
    servings: _emberData.default.attr(),
    units: _emberData.default.attr(),

    /**
     * Alias for log-food-list-group
     */
    foodListItem: Ember.computed.alias('foodItem')
  });

  _exports.default = _default;
});