define("fitbit-site-ui/components/friends-list", ["exports", "fitbit-site-ui/components/user-messaging-form"], function (_exports, _userMessagingForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['friends-list'],
    viewport: Ember.inject.service('device/layout'),
    i18n: Ember.inject.service(),

    /**
     * Selected user for message modal
     */
    selectedUser: null,

    /**
     * Model for user-messaging-form component.
     *
     * @returns {UserMessagingFormModel}
     */
    userMessagingFormModel: Ember.computed(function () {
      return this.createUserMessagingFormModel();
    }),

    /**
     * Reset data used for messaging form.
     * Is reset when the form is closed.
     *
     * @returns {UserMessagingFormModel}
     */
    createUserMessagingFormModel: function createUserMessagingFormModel() {
      var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _EmberGetProperties = Ember.getProperties(source, 'avatar', 'name'),
          _EmberGetProperties$a = _EmberGetProperties.avatar,
          avatar = _EmberGetProperties$a === void 0 ? '' : _EmberGetProperties$a,
          _EmberGetProperties$n = _EmberGetProperties.name,
          name = _EmberGetProperties$n === void 0 ? '' : _EmberGetProperties$n,
          _EmberGetProperties$m = _EmberGetProperties.message,
          message = _EmberGetProperties$m === void 0 ? '' : _EmberGetProperties$m;

      return _userMessagingForm.UserMessagingFormModel.create({
        avatar: avatar,
        name: name,
        message: message
      });
    },

    /**
     * Group inactive and hidden users.  Support for "hidden" users has been discontinued,
     * and should not be send by v1.1 api despite a field exists
     * Should only run once, so not too computationally heavy
     */
    inactiveAndHiddenUsers: Ember.computed.union('inactiveUsers', 'hiddenUsers'),

    /**
     * Stub for sendMessageToId function passed by parent
     * called by onSendMessageToId action when submitting a message
     * in the message modal
     */
    sendMessageToId: function sendMessageToId() {},

    /**
     * Closes the messages modal
     */
    closeMessageModal: function closeMessageModal() {
      var leaderboardMessageModal = Ember.get(this, 'leaderboardMessageModal');

      if (leaderboardMessageModal) {
        Ember.run.scheduleOnce('afterRender', function () {
          leaderboardMessageModal.close();
        });
      }
    },
    actions: {
      /**
       * Clears current message text (if any) and opens the message modal
       */
      openMessageModal: function openMessageModal() {
        var leaderboardMessageModal = Ember.get(this, 'leaderboardMessageModal');

        if (leaderboardMessageModal) {
          // Update the `userMessagingFormModel` computed property a new object with populated avatar and name.
          Ember.set(this, 'userMessagingFormModel', this.createUserMessagingFormModel(this.selectedUser));
          Ember.run.scheduleOnce('afterRender', leaderboardMessageModal, 'open');
        }
      },

      /**
       * Closes the messages modal
       */
      closeMessageModal: function closeMessageModal() {
        var leaderboardMessageModal = Ember.get(this, 'leaderboardMessageModal');

        if (leaderboardMessageModal) {
          Ember.run.scheduleOnce('afterRender', leaderboardMessageModal, 'close');
        }
      }
    }
  });

  _exports.default = _default;
});