define("fitbit-site-ui/serializers/privacy-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadType: function modelNameFromPayloadType() {
      //Needs to be plural because that's the name of the weightsite model type
      //Can't change to privacy-setting due to other clients
      return 'privacy-settings';
    },
    //Overwritten because the endpoint expects the request body data to be nested in an array instead of a single object
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.relationships;
      return {
        data: [json.data]
      };
    },
    //Overwritten because the endpoint returns all the settings instead of just the one we updated
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload.data,
          updatedRecord = data && data.findBy('id', id);
      return this._super(store, primaryModelClass, {
        data: updatedRecord
      }, id, requestType);
    }
  });

  _exports.default = _default;
});