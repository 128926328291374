define("fitbit-site-ui/serializers/friend", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'person') {
        return this._super('friend/leaderboard/person');
      }

      return this._super(key);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _payload$data = payload.data,
          data = _payload$data === void 0 ? [] : _payload$data;
      var newPayload = {
        data: {
          id: id,
          type: 'friend',
          relationships: {
            list: {
              data: data
            }
          }
        },
        included: data
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});