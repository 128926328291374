define("fitbit-site-ui/serializers/profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'encodedId',
    attrs: {
      fullName: 'fullname',
      dateOfBirth: 'birthday'
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'profile';
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.user) {
        /**
         * Using the `id` as a reference to other records
         */
        payload.user.coverPhoto = id;
        payload.user.friends = id;
        payload.user.devices = id;
        payload.user.features = id;
        payload.user.exercisePreferences = id;
        payload.user.sleepGoal = id;
        payload.user.achievementSummary = id;
        var topBadges = payload.user.topBadges;

        if (topBadges) {
          payload.user.topBadges = topBadges.map(function (topBadge) {
            return topBadge.encodedId;
          });
        }

        if (payload.user.waterUnit === 'en_US' && payload.user.waterUnitName === 'cup') {
          payload.user.waterUnit = 'en_US_cup';
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // Deleting because it's extra payload is not necessary


      delete json.topBadges;
      delete json.friends;
      delete json.devices;
      delete json.features;
      delete json.exercisePreferences;
      delete json.sleepGoal;
      delete json.coverPhoto;
      json.customHeartRateZoneEnabled = json.customHeartRateZone.enabled;
      json.customMaxHeartRateEnabled = json.customMaxHeartRate.enabled;

      if (json.customHeartRateZoneEnabled) {
        json.customHeartRateZoneMin = json.customHeartRateZone.min;
        json.customHeartRateZoneMax = json.customHeartRateZone.max;
      }

      delete json.customHeartRateZone;

      if (json.customMaxHeartRateEnabled) {
        json.customMaxHeartRate = json.customMaxHeartRate.maxHeartRate;
      } else {
        //We're setting it back to default, don't need this object
        delete json.customMaxHeartRate;
      }

      if (json.autoStrideEnabled === 'true') {
        json.strideLengthRunning = 0;
        json.strideLengthWalking = 0;
      } else if (json.autoStrideEnabled === 'disabled') {
        //Means that the user doesn't have a device to calculate autostride
        //Need to remove this from the request
        delete json.autoStrideEnabled;
      }

      if (json.waterUnit === 'en_US_cup') {
        json.waterUnit = 'en_US';
        json.waterUnitName = 'cup';
      }

      return json;
    }
  });

  _exports.default = _default;
});