define("fitbit-site-ui/adapters/food-date-log", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1.1/user',
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(snapshot, 'adapterOptions.user.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/date/").concat(id, ".json");
    },

    /**
     * Generates URL for foods by day for food history list
     * @param query
     * @returns {string}
     */
    urlForQuery: function urlForQuery(query) {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(query, 'user.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/listByDay.json");
    }
  });

  _exports.default = _default;
});