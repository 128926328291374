define("fitbit-site-ui/adapters/water-goal", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: '1/user',
    currentUser: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord() {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(this, 'currentUser.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/water/goal.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(this, 'currentUser.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/water/goal.json");
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var baseURL = this.buildURL();
      var userID = Ember.getWithDefault(this, 'currentUser.id', '-');
      return "".concat(baseURL, "/").concat(userID, "/foods/log/water/goal.json");
    }
  });

  _exports.default = _default;
});