define("fitbit-site-ui/models/time-series/calories-in", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dateTime: _emberData.default.attr(),
    value: _emberData.default.attr('number')
  });
  /*
   Sample URL & Data
   Unknown possibly sensitive origin
  
   GET /1/user/-/foods/log/caloriesIn/date/2017-12-05/2017-12-03.json
  
   {
   "foods-log-caloriesIn": [
   {
   "dateTime": "2017-12-03",
   "value": "0"
   },
   {
   "dateTime": "2017-12-04",
   "value": "
   },
   {
   "dateTime": "2017-12-05",
   "value": "0"
   }]
   }
   */


  _exports.default = _default;
});