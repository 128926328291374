define("fitbit-site-ui/serializers/search-food-list", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.isPresent(payload) || !Ember.isPresent(Ember.get(payload, 'foods'))) {
        var _newPayload = {
          'search-food-list': {
            searchFoods: [],
            id: payload.queryParams.query
          }
        };
        return this._super(store, primaryModelClass, _newPayload, id, requestType);
      }

      var foods = payload.foods,
          queryParams = payload.queryParams;
      var searchFoodIds = foods.map(function (food) {
        return food.foodId;
      });
      var searchFoodRelationships = searchFoodIds.map(function (foodId) {
        return {
          foodItem: foodId,
          foodId: foodId
        };
      });
      var newPayload = {
        'food/food-item': foods,
        'search-food': searchFoodRelationships,
        'search-food-list': {
          searchFoods: searchFoodIds,
          id: queryParams.query
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});