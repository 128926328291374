define("fitbit-site-ui/serializers/time-series/calories-in", ["exports", "fitbit-site-ui/serializers/time-series"], function (_exports, _timeSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _timeSeries.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'time-series/calories-in';
    },
    primaryKey: 'dateTime',

    /**
     * Returning an array is deprecated. Normalize response so that a single record is returned
     * @override
     * @param {Object} store
     * @param {Function} primaryModelClass
     * @param {*} payload
     * @param {null} id
     * @param {String} requestType
     * @returns {Object|null}
     */
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var _ref = Ember.get(payload, 'foods-log-caloriesIn') || [],
          _ref2 = _slicedToArray(_ref, 1),
          record = _ref2[0];

      if (record) {
        // Set the first object as the record for queried record.
        Ember.set(payload, 'foods-log-caloriesIn', record);
      } else {
        payload = {};
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});