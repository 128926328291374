define("fitbit-site-ui/models/time-series/heart", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    moment: Ember.inject.service(),
    activitiesHeart: _emberData.default.attr(),
    activitiesHeartIntraday: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          dataset: []
        };
      }
    }),
    date: Ember.computed.readOnly('activitiesHeart.dateTime'),
    heartRateZoneValues: Ember.computed('activitiesHeart.heartRateZones', function () {
      var HRZones = Ember.get(this, 'activitiesHeart.heartRateZones');
      return HRZones.map(function (zone) {
        return {
          zone: zone.name,
          threshold: zone.min
        };
      });
    }),
    dataset: Ember.computed.readOnly('activitiesHeartIntraday.dataset')
  }); // Sample URL & Data
  // GET /1/user/-/activities/heart/date/2016-05-07/1d/1sec/time/12:20/12:45.json
  // Unknown possibly sensitive origin
  // {
  //   "activities-heart":  [
  //      {
  //       "customHeartRateZones":  [],
  //       "dateTime": "2016-05-07",
  //       "heartRateZones":  [
  //          {
  //           "caloriesOut": 33.215,
  //           "max": 99,
  //           "min": 30,
  //           "minutes": 26,
  //           "name": "Out of Range"
  //         },
  //          {
  //           "caloriesOut": 0,
  //           "max": 139,
  //           "min": 99,
  //           "minutes": 0,
  //           "name": "Fat Burn"
  //         },
  //          {
  //           "caloriesOut": 0,
  //           "max": 169,
  //           "min": 139,
  //           "minutes": 0,
  //           "name": "Cardio"
  //         },
  //          {
  //           "caloriesOut": 0,
  //           "max": 220,
  //           "min": 169,
  //           "minutes": 0,
  //           "name": "Peak"
  //         }
  //       ],
  //       "value": "50.56"
  //     }
  //   ],
  //   "activities-heart-intraday":  {
  //     "dataset":  [
  //        {
  //         "time": "12:20:00",
  //         "value": 48
  //       },
  //        {
  //         "time": "12:20:10",
  //         "value": 48
  //       },
  //        {
  //         "time": "12:20:20",
  //         "value": 47
  //       },
  //       ...
  //       ]
  //   }
  // }


  _exports.default = _default;
});