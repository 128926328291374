define("fitbit-site-ui/serializers/weight-list", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var weightArr = payload.weight,
          pagination = payload.pagination;
      var beforeDate = pagination.beforeDate;
      var nextRecordUrl = pagination.next;
      var newPayload = {
        'weight-log': weightArr,
        // Create new weight-set for history list
        'weight-list': {
          id: id,
          beforeDate: beforeDate,
          list: weightArr.map(function (log) {
            return log.logId;
          }),
          nextRecordUrl: nextRecordUrl
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});