define("fitbit-site-ui/components/advanced-settings", ["exports", "fitbit-site-ui/mixins/panel-mixin"], function (_exports, _panelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_panelMixin.default, {
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    //TODO: i18n displayNames
    units: Ember.computed(function () {
      return {
        height: [{
          value: 'en_US',
          displayName: 'height_US'
        }, {
          value: 'METRIC',
          displayName: 'height_metric'
        }],
        weight: [{
          value: 'en_US',
          displayName: 'weight_US'
        }, {
          value: 'en_GB',
          displayName: 'weight_GB'
        }, {
          value: 'METRIC',
          displayName: 'weight_metric'
        }],
        water: [{
          displayName: 'water_unit_US',
          value: 'en_US'
        }, {
          displayName: 'water_unit_US_cups',
          value: 'en_US_cup'
        }, {
          displayName: 'water_unit_metric',
          value: 'METRIC'
        }]
      };
    }),
    PANEL_LIST: Ember.computed(function () {
      return ['locale', 'foodsLocale', 'units', 'startDayOfWeek', 'clock', 'timezone', 'sleepTracking', 'strideLength', 'heartRate'];
    }),
    languageDisplayName: Ember.computed('profile.{locale,locales}', function () {
      var currentLocale = this.get('profile.locale'),
          locales = this.get('profile.locales');

      if (locales) {
        var foundLocale = locales.findBy('value', currentLocale); // There's a low chance that the user's currentLocale does not exist in the list lof locales.

        if (foundLocale) {
          return Ember.get(foundLocale, 'displayName');
        }
      }
    }),
    startDayOfWeekDisplay: Ember.computed('profile.startDayOfWeek', function () {
      var formatter = this.get('i18n');
      var startDay = this.get('profile.startDayOfWeek');
      return formatter.format(startDay.toLowerCase());
    }),
    foodLocaleDisplayName: Ember.computed('profile.{foodsLocale,foodDatabases}', function () {
      var currentFoodDb = this.get('profile.foodsLocale'),
          foodDbs = this.get('profile.foodDatabases');

      if (foodDbs) {
        return foodDbs.findBy('value', currentFoodDb).get('label');
      }
    }),
    timezoneDisplay: Ember.computed('profile.{timezone,timezones}', function () {
      var currentTimezone = Ember.get(this, 'profile.timezone');
      var timezones = Ember.get(this, 'profile.timezones');

      if (timezones) {
        var matchedTimezone = timezones.findBy('id', currentTimezone); // IPD-55309 -- some users have a particular timezone that is set in iOS
        // so look up for a matching name, otherwise display the currentTimezone

        if (matchedTimezone) {
          return Ember.get(matchedTimezone, 'name');
        } else {
          return currentTimezone;
        }
      }
    }),

    /**
     * IPD-113135 Sort locales by country name to be similar with how iOS and Android displays locale settings.
     *
     * The order isn't exact though. E.g. Korean is displayed before Japanese because the default sorting is different.
     * This would require a little more finessing and figuring out how iOS and Android sorts by default
     * since a Locale Collator isn't being used in iOS and Android.
     */
    localesSortDef: Object.freeze(['countryName']),
    sortedLocales: Ember.computed.sort('profile.locales', 'localesSortDef'),
    unitsSettingDisplay: Ember.computed('profile.{waterUnit,heightUnit,weightUnit}', function () {
      var _this = this;

      var formatter = this.get('i18n');
      var units = this.get('units');

      var getDisplayName = function getDisplayName(unit) {
        var property = "profile.".concat(unit, "Unit");
        return formatter.format(units[unit].findBy('value', _this.get(property)).displayName);
      };

      return Object.keys(units).map(getDisplayName).join(', ');
    }),
    isClock12: Ember.computed.oneWay('profile.isClock12'),
    clockDisplayTime: Ember.computed('isClock12', function () {
      var formatter = this.get('i18n');
      var key = this.get('isClock12') ? '12_hour' : '24_hour';
      return formatter.format(key);
    }),
    actions: {
      selectLocale: function selectLocale(locale) {
        this.set('profile.locale', locale);
      },
      selectFoodDb: function selectFoodDb(foodDb) {
        this.set('profile.foodsLocale', foodDb);
      },
      selectTimezone: function selectTimezone(timezone) {
        this.set('profile.timezone', timezone);
      },
      toggleClockDisplay: function toggleClockDisplay(is12hour) {
        var clockDisplay = is12hour === 'true' ? '12hour' : '24hour';
        this.set('profile.clockTimeDisplayFormat', clockDisplay);
      }
    }
  });

  _exports.default = _default;
});