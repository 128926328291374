define("fitbit-site-ui/models/device/tracker/exercise-preferences", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    i18n: Ember.inject.service(),
    swimSettings: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {
          poolLength: 10,
          poolLengthUnit: 'Yard'
        };
      }
    }),
    autoRunOn: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isSwimSettingsYard: Ember.computed.equal('swimSettings.poolLengthUnit', 'Yard'),
    displaySwimSetting: Ember.computed('swimSettings.poolLength,swimSettings.poolLengthUnit', function () {
      var poolLength = Ember.get(this, 'swimSettings.poolLength');
      var poolLengthUnit = Ember.get(this, 'swimSettings.poolLengthUnit');
      var formatter = Ember.get(this, 'i18n');

      if (!poolLength && !poolLengthUnit) {
        return formatter.formatByNamespace('default_swim_display', 'templates.components.device-swim-setting');
      } else if (Ember.get(this, 'isSwimSettingsYard')) {
        return formatter.formatByNamespace('swim_display_yards', 'templates.components.device-swim-setting', {
          yards: poolLength
        });
      } else {
        return formatter.formatByNamespace('swim_display_meters', 'templates.components.device-swim-setting', {
          meters: poolLength
        });
      }
    }),
    displayAutoRunSetting: Ember.computed('autoRunOn', function () {
      var formatter = Ember.get(this, 'i18n');
      return Ember.get(this, 'autoRunOn') ? formatter.formatByNamespace('on', 'common.messages') : formatter.formatByNamespace('off', 'common.messages');
    })
  });

  _exports.default = _default;
});