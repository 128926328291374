define("fitbit-site-ui/constants/user-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_NOTIFICATIONS_DISPLAYED = _exports.MAX_NOTIFICATIONS_REQUESTED = _exports.MAX_MESSAGES_REQUESTED = void 0;
  var MAX_MESSAGES_REQUESTED = 100;
  _exports.MAX_MESSAGES_REQUESTED = MAX_MESSAGES_REQUESTED;
  var MAX_NOTIFICATIONS_REQUESTED = 100;
  _exports.MAX_NOTIFICATIONS_REQUESTED = MAX_NOTIFICATIONS_REQUESTED;
  var MAX_NOTIFICATIONS_DISPLAYED = 100;
  _exports.MAX_NOTIFICATIONS_DISPLAYED = MAX_NOTIFICATIONS_DISPLAYED;
});