define("fitbit-site-ui/models/data-deletion-ui-link", ["exports", "gdd-console-engine/models/data-deletion-ui-link"], function (_exports, _dataDeletionUiLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dataDeletionUiLink.default;
    }
  });
});