define("fitbit-site-ui/adapters/fitbit-explore", ["exports", "fitbit-site-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return Ember.String.dasherize(modelName);
    },
    // /1/user/:userId/fitbit-explore/bundles/:bundleId.json
    // Usage: const exploreQuery = store.queryRecord('fitbit-explore', {bundleId: 'programs' });
    urlForQueryRecord: function urlForQueryRecord(query) {
      return "".concat(this._super.apply(this, arguments), "/bundles/").concat(query.bundleId, ".json");
    }
  });

  _exports.default = _default;
});