define("fitbit-site-ui/adapters/notification-setting", ["exports", "fitbit-site-ui/adapters/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    namespace: '1/user',
    disableRoot: true,
    pathForType: function pathForType() {
      return '';
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return "".concat(this._super(id, modelName, snapshot), "/notification-settings.json");
    }
  });

  _exports.default = _default;
});