define("fitbit-site-ui/instance-initializers/load-webview-auth-token", ["exports", "ember-fitbit-webview-auth/instance-initializers/load-webview-auth-token"], function (_exports, _loadWebviewAuthToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loadWebviewAuthToken.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _loadWebviewAuthToken.initialize;
    }
  });
});