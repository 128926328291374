define("fitbit-site-ui/components/device-meridiem-select", ["exports", "snapdragon-common/components/device-meridiem-select"], function (_exports, _deviceMeridiemSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _deviceMeridiemSelect.default;
    }
  });
});