define("fitbit-site-ui/components/device-quick-view-setting", ["exports", "fitbit-site-ui/components/panel-component"], function (_exports, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelComponent.default.extend({
    i18n: Ember.inject.service(),
    actions: {
      quickViewChanged: function quickViewChanged(value) {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages'),
            watchCheckEnabled = value === 'CLOCK';

        if (this.get('settings.currentSettings').hasOwnProperty('watchCheckEnabled')) {
          this.set('settings.currentSettings.watchCheckEnabled', watchCheckEnabled);
        } else {
          this.set('settings.currentSettings.watchCheck', value);
        }

        this.set('quickViewSetting', value);
        this.get('settings').then(function (model) {
          return model.save().then(function () {
            var successMessage = formatter.format('device_setting_saved');

            _this._clearMessageFromQueue(successMessage);

            flashMessages.success(successMessage);
          });
        });
      }
    }
  });

  _exports.default = _default;
});