define("fitbit-site-ui/serializers/user-achievement-summary", ["exports", "fitbit-site-ui/serializers/application-jsonapi"], function (_exports, _applicationJsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationJsonapi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var profileModelClass = store.modelFor('profile');
      var newPayload = {
        data: {
          id: id,
          type: primaryModelClass.modelName,
          attributes: payload,
          relationships: {
            user: {
              data: {
                id: id,
                type: profileModelClass.modelName
              }
            }
          }
        }
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});