define("fitbit-site-ui/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(params
  /*, hash*/
  ) {
    var word = params[0];
    return word && Ember.String.capitalize(word);
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});