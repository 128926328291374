define("fitbit-site-ui/serializers/sleep-log", ["exports", "fitbit-site-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'logId',
    moment: Ember.inject.service(),
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var moment = this.get('moment');
      var startMoment = moment.moment(json.startTime);
      return {
        date: startMoment.format('YYYY-MM-DD'),
        startTime: startMoment.format('HH:mm'),
        duration: json.duration
      };
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {
        'sleep-log': payload.sleep
      };
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });

  _exports.default = _default;
});