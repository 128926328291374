define("fitbit-site-ui/components/device-swim-setting", ["exports", "ember-cp-validations", "fitbit-site-ui/components/panel-component"], function (_exports, _emberCpValidations, _panelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'exercisePreferences.swimSettings.poolLength': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 10,
      lte: 100,
      message: Ember.computed(function () {
        return this.get('model.i18n').format('swim_validation_msg');
      })
    })
  });

  var _default = _panelComponent.default.extend(Validations, {
    i18n: Ember.inject.service(),
    actions: {
      swimSettingChanged: function swimSettingChanged() {
        var _this = this;

        var formatter = this.get('i18n');
        var flashMessages = this.get('flashMessages');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('exercisePreferences').then(function (model) {
              model.save().then(function () {
                var successMessage = formatter.format('device_setting_saved');

                _this._clearMessageFromQueue(successMessage);

                flashMessages.success(successMessage);
              }).catch(function () {
                var failureMessage = formatter.format('something_went_wrong');

                _this._clearMessageFromQueue(failureMessage);

                flashMessages.alert(formatter.format('something_went_wrong'));
              });
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});