define("fitbit-site-ui/adapters/food-note-date", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    namespace: '1.1/user/-',
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this._super(), "/foods/note/date/").concat(id);
    }
  });

  _exports.default = _default;
});