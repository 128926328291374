define("fitbit-site-ui/constants/legacy-device-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LEGACY_DEVICE_VERSIONS = void 0;

  /**
   * List of legacy devices fitbit no longer supports
   * @type {string[]}
   */
  var LEGACY_DEVICE_VERSIONS = ['ultra', 'force'];
  _exports.LEGACY_DEVICE_VERSIONS = LEGACY_DEVICE_VERSIONS;
});