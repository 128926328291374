define("fitbit-site-ui/adapters/sleep-log", ["exports", "fitbit-site-ui/adapters/application", "ember-cli-form-data/mixins/form-data-adapter"], function (_exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_formDataAdapter.default, {
    disableRoot: true,
    namespace: '1.2/user/-',
    pathForType: function pathForType() {
      return '';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this._super.apply(this, arguments), "/sleep.json");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      var url = this._super.apply(this, arguments);

      return this._urlForRecord(url, id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      var url = this._super.apply(this, arguments);

      return this._urlForRecord(url, id);
    },
    _urlForRecord: function _urlForRecord(url, id) {
      url = url.substring(0, url.lastIndexOf('/'));
      return "".concat(url, "/sleep/").concat(id, ".json");
    }
  });

  _exports.default = _default;
});